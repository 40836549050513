<template>

	<div>

		<div v-if="loading" class="content-loading"></div>
		<div v-else class="wp100 relative" style="height:200px">
			<FeedbackWorkChart :chartData="chartData" :options="chartOptions" style="height:300px"/>
		</div>

		<div class="periods" id="totalstat">
			<ul class="group">
				<li data-id="calendarweek" :class="{'current':winperiod === 'calendarweek'}" @click="changePeriod('calendarweek')">Текущая</li>
				<li data-id="calendarweekprev" :class="{'current':winperiod === 'calendarweekprev'}" @click="changePeriod('calendarweekprev')">Прошлая</li>
				<li data-id="month" :class="{'current':winperiod === 'month'}" @click="changePeriod('month')">Месяц</li>
			</ul>
		</div>

	</div>

</template>

<script>
import axios from "axios"
import FeedbackWorkChart from '@/components/vigets/FeedbackWorkChart'

export default {
	name: "FeedbackWork",
	components: {
		FeedbackWorkChart
	},
	data() {
		return {
			chartData: {
				labels: [],
				datasets: [
					{
						label: 'Обработано',
						backgroundColor: "#2C3E50",
						barThickness: 6,
						data: []
					},
					{
						label: 'Согласия',
						backgroundColor: "#4DB6AC",
						barThickness: 6,
						data: []
					}
				]
			},
			chartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				height: 300,
				plugins: {
					//legend: false
				},
				layout: {
					padding: 10
				},
				scales: {
					xAxes: [{
						gridLines: {
							//display:false,
							drawOnChartArea: false
						},
						stacked: true,
					}],
					yAxes: [{
						gridLines: {
							//display:false,
							//color: "rgba(0, 0, 0, 1)",
						},
						stacked: true,
					}],
				}
			},
			win: {},
			winperiod: "calendarweek",
			error: "",
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: true,
			xcolors: {
				"win": "#00838F",
				"lose": "#B71C1C",
				"task": "#FF8F00",
				"na": "#455A64",
				"step": "#1565C0",
			}
		}
	},
	created() {
		this.getFeedbackChart()
	},
	methods: {
		getFeedbackChart() {

			this.loading = true

			axios.post(this.apiBaseURL + '/api/vigets/', {
					action: 'stat',
					period: this.winperiod
				},
				{
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				}).then(
				(data) => {

					let dataset = []

					this.win = data.data.result
					this.loading = false

					for (let i = 0; i < this.win.data.length; i++) {

						dataset[i] = {
							label: this.win.data[i].label,
							backgroundColor: this.xcolors[this.win.data[i].xtype],
							barThickness: this.winperiod === 'month' ? 6 : 50,
							data: this.win.data[i].data
						}

					}

					this.chartData = {
						labels: this.win.labels,
						datasets: dataset
					}

				}
			)
				.catch(error => {

					if (error.response !== undefined) {

						this.$toast.error({
							title: 'Error',
							message: error.response.status + ": " + error.response.statusText,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

					}

				})

		},
		changePeriod(payload){

			this.winperiod = payload
			this.getFeedbackChart()

		}
	}
}
</script>

<style scoped>

</style>