<template>

	<div class="gstring pl10 pr10" style="overflow: unset">

		<div class="divider pt10 pb10">{{language.City}}</div>

		<div class="no-border relative cleared">

			<input type="text" id="city" class="wp100 p5 pl10 pr10" :placeholder="language.City" autocomplete="on" v-model="cfilter.city" @keyup="updateFilter" @focusout="updateFilter">
			<div class="clearinputs" @click="clearInput('city')"><i class="icon-block-1 red"></i></div>

		</div>

	</div>

</template>

<script>
export default {
	name: "Filters",
	model:{
		prop: "cfilter",
		event: "change"
	},
	props:{
		cfilter:{
			type: Object,
			default: () => {
				return {
					city: '',
					project: [],
				}
			}
		}
	},
	data(){
		return {
			guides: this.$store.getters.guides,
			language: this.$store.state.language,
		}
	},
	watch: {

	},
	computed:{
	},

	methods:{
		updateFilter(){
			//console.log("filter", this.cfilter)
			this.$emit("change", this.cfilter)
		},

		clearInput(element){
			this.$store.state.zarplata.filters.zarplata[element] = '';
		}
	},

}
</script>

<style scoped>

</style>