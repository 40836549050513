<!--
Универсальный компонент даталист
<Datalist
	v-model="form.tip"
	:value="form.tip"
	:name="'Должность'"
	:items="tips"
	:required="true"
	@update="tipChanged"
/>
-->
<template>

	<div class="flex-string relative material cleared" :class="wclass" v-click-outside="onHide">

		<input type="text" placeholder=" " id="input" v-model="selected" class="wp100 pl10" :class="{'required':required}" @focusin="onShow" ref="input" autocomplete="off">
		<label for="input">{{ inputName }}</label>
		<div class="clearinputs mr5" @click="selected = ''"><i class="icon-block red fs-14"></i></div>

		<ul class="vu-suggestion mt2" v-if="show" ref="suggestionlist">
			<li v-for="item in items" :key="item" @click="select(item)">
				<slot name="first-string">
					<div class="fs-11">{{ item }}</div>
				</slot>
				<slot name="third-string"></slot>
			</li>
		</ul>

	</div>

</template>

<script>

export default {
	name: "Datalist",
	props: {
		value: String, // установленное значение
		name: String, // Label
		required: Boolean, // обязательность заполнения
		classes: String, // дополнительные классы, применимые к input
		items: Array
	},
	data() {
		return {
			current: '',
			filtered: {},
			default: this.name,
			show: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false
		}
	},
	computed: {
		require() {
			return this.required === true ? this.required : false
		},
		wclass() {
			return this.classes !== undefined ? this.classes : 'wp100'
		},
		minChars() {
			return this.min !== undefined ? this.min : 0
		},
		inputName() {
			return this.name !== undefined ? this.name : "Input"
		},
		/*response: {
			get(){
				return this.response
			},
			set(payload){
				this.$emit("update", payload)
			}
		},*/
		selected: {
			get(){
				return this.value
			},
			set(payload){
				this.$emit("update", payload)
			}
		}
	},
	created() {

	},
	watch:{
		current(){
			this.selected = this.current
		}
	},
	mounted() {

	},
	methods: {
		select(payload) {
			this.selected = payload
			this.onHide()
		},
		update(payload) {
			this.selected = payload
		},
		clean() {
			this.selected = ''
			this.$emit("update", '')
		},
		onShow() {
			this.show = true
		},
		onHide() {
			this.show = false
		},
	}
}
</script>

<style scoped>
ul.vu-suggestion {
	max-height : 200px;
}
.material{
	margin-bottom: 0;
}
</style>