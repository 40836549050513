<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				Settings. Reports
			</div>

		</div>

		<div v-if="loading" class="content-loading"></div>

		<div v-if="!loading" class="page--body">

			<div class="page--body">

				<div class="infodiv mt10">
					В этом разделе вы можете управлять доступными отчетами.
				</div>

				<div class="wp100 mx-auto mt20 pt10">

					<div class="flex-content typelist reports p5 tbl--header sticked--top">

						<div class="flex-string">Название</div>
						<div class="flex-string">Файл</div>
						<div class="flex-string">Ограничения</div>
						<div class="flex-string">Активность</div>
						<div class="flex-string text-center">Действия</div>

					</div>

					<div v-for="item in list" :key="item.id" class="flex-content typelist reports ha border-bottom p5" :class="{'gray graybg-sublite':!item.active}">

						<div class="flex-string Bold">{{item.title}}</div>
						<div class="flex-string">{{item.file}}</div>
						<div class="flex-string tagbox">
							<div v-for="(user, index) in item.userlist" :key="index" class="tag fs-09" :title="user.user"><i class="icon-user-1 blue"></i>{{user.user}}</div>
						</div>
						<div class="flex-string text-center"><i v-if="item.active" class="icon-ok green"></i></div>
						<div class="flex-string text-center">

							<a href="javascript:void(0)" title="Редактировать" @click="editItem(item.id)" class="button dotted bluebtn m0"><i class="icon-pencil"></i></a>
							<a href="javascript:void(0)" title="Удалить" @click="deleteItem(item.id)" class="button dotted redbtn m0"><i class="icon-cancel-circled"></i></a>

						</div>

					</div>

				</div>

				<div class="space-100"></div>

				<div class="mt20 p0 bottom-block">

					<A href="javascript:void(0)" @click="editItem(-1)" title="Добавить">
						<i class="icon-plus"></i>
					</A>

				</div>

				<div v-if="floading" class="loading"></div>

			</div>

		</div>

		<Dialog ref="dialog" :show="showdialog" :widthString="widthString" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="(id > 0 || id === -1)" v-slot:dlgbody>
				<StatisticForm :id="id" @close="closeDialog" @loaded="dialogLoaded" @update="load"/>
			</template>
		</Dialog>

	</div>

</template>

<script>

import axios from "axios"
import Dialog from "@/components/modal/Dialog"
import StatisticForm from '@/pages/settings/StatisticForm.vue'

export default {
	name: "StatisticSet",
	components: {
		Dialog,
		StatisticForm
	},
	data() {
		return {
			list: {},
			loading: false,
			floading: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			showdialog: false,
			dialogloaded: false,
			widthString: "70vw",
			id: 0,
		}
	},
	mounted() {
		this.load()
	},
	methods: {
		load() {

			this.loading = true

			let param = {};

			param.action = 'list'

			axios.post(this.apiBaseURL + '/api/reports/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {
						//console.log(data.data)

						this.list = data.data.result.list

						this.$emit("loaded")

						this.loading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		editItem(payload) {
			this.id = parseInt(payload)
			this.deleteit = false
			this.showdialog = true
		},
		deleteItem(payload) {

			this.$swal.fire({
				title: "Are You Sure?",
				text: 'Item will be deleted forever',
				icon: 'question',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: '#3085D6',
				cancelButtonColor: '#D33',
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel"
			}).then((result) => {

				if (result.value) {

					// отправка формы здесь
					let param = {};

					param['id'] = payload
					param['action'] = "delete"

					axios.post(this.apiBaseURL + '/api/reports/', param, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							(data) => {

								this.message = data.data.result.result

								this.load()
								this.closeDialog()

								this.$toast.success({
									title: 'Success',
									message: data.data.result.result,
									position: "bottom center",
									timeOut: 3000
								})

							},
						)
						.catch(error => {

							this.$toast.error({
								title: 'Error '+ error.code,
								message: "" + error.text,
								position: "bottom center",
								timeOut: 3000
							})

						})

				}

			});

		},
		closeDialog() {
			this.id = 0
			this.showdialog = false
			this.dialogwidth = 800
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Reports / Settings',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.page--title {
	left       : 20px;
	display    : block;
	width      : 250px !important;
	text-align : left;
}

@media (min-width : 768px) and (max-width : 1024px) {

	.page--title {
		left : 0 !important;
	}

}
</style>