<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				{{ language.NextStep }}
			</div>

		</div>

		<div class="page--body">

			<div v-if="loading" class="loading"></div>

			<h2 class="uppercase hidden">{{language.NextStep}}</h2>

			<div class="wp100 mx-auto mt10 pt10">

				<div class="infodiv mb5">

						<div class="flex-container box--child">

							<div class="flex-string wp30 relative cleared">
								<input type="text" id="word" v-model="filters.word" class="wp100 fs-09 p5" placeholder="Имя, Email, Skype" onkeydown="if(event.keyCode===13){ getList(); return false }">
								<div class="clearinputs mr5" @click="clearSearch"><i class="icon-block red fs-14"></i></div>
							</div>
							<div class="flex-string wp50 pt10"></div>
							<div class="flex-string wp20 pt10 text-right">

								<div>Всего <b>{{total}}</b> записей.</div>

							</div>

						</div>

				</div>

				<div class="flex-content2 typelist reaction p5 tbl--header sticked--top">

					<div class="flex-string2">ID</div>
					<div class="flex-string2">{{language.Date}}</div>
					<div class="flex-string2 ellipsis">{{language.Vacancy}}, {{language.Project}}</div>
					<div class="flex-string2">{{language.Address.Address}}</div>
					<div class="flex-string2">{{language.Candidate}}</div>
					<div class="flex-string2">{{language.Result}}</div>
					<div class="flex-string2">{{language.Operator}}</div>
					<div class="flex-string2 text-center">{{language.Actions}}</div>

				</div>

				<div v-for="item in items" :key="item.id" class="flex-content typelist reaction ha border-bottom p5" :class="{'graybg-sub':item.results === null || item.results.id === null}">
					<div class="flex-string">{{ item.id }}</div>
					<div class="flex-string">{{ $moment(item.date_create).format('DD.MM.YY H:mm') }}</div>
					<div class="flex-string hand" @click="viewFeedbackCard(item.id)">

						<div v-if="item.title !== null" class="fs-12 flh-12 Bold blue ellipsis">{{item.title}}</div>
						<div v-else class="fs-12 flh-12 Bold red ellipsis">Не указано</div>

						<div class="fs-09 text-wrap mt5"><i v-if="!item.projectActive" class="icon-attention-1 red" title="Проект отключен"></i>{{item.projectName}}</div>

					</div>
					<div class="flex-string">
						<div v-if="item.address !== null && item.address.id > 0" class="hand" @click="viewAddressCard(item.address.id)" :title="item.soiskatel.title">
							<div class="Bold fs-10 ellipsis">{{ item.address.title }}</div><br>
							<div class="fs-09 flh-11 mt10 ellipsis">{{ item.address.address }}</div>
						</div>
						<div class="fs-09 blue text-wrap mt5">{{item.city}}</div>
					</div>
					<div class="flex-string">
						<div v-if="item.sid > 0" :title="item.soiskatel.title">

							<div :class="{'hand':item.sid > 0}" @click="viewCandidateCard(item.sid)">
								<div class="fs-11 Bold text-wrap">{{item.soiskatel.title}}</div>
								<div class="fs-09 text-wrap mt5">
									<span v-if="item.soiskatel.age" class="Bold blue">{{item.soiskatel.age}},</span>
									<span v-if="item.soiskatel.phone" class=""> {{item.soiskatel.phone}},</span>
									<span v-if="item.soiskatel.email" class="Bld"> {{item.soiskatel.email}}</span>
								</div>
								<div v-if="item.soiskatel.skype" class="fs-09 text-wrap success mt5">
									<i class="icon-skype blue"></i> {{item.soiskatel.skype}}
								</div>
							</div>
						</div>
					</div>
					<div class="flex-string">
						<div v-if="item.results !== null">
							<div class="Bold" :class="item.results.color">{{item.results.title}}</div>
							<div v-if="item.scheduleone" class="mt5 fs-09"><b class="blue">{{$moment(item.scheduleone.date_from).format('H:mm')}}</b>, {{$moment(item.scheduleone.date_from).format('DD.MM.YY')}}</div>
						</div>
						<div v-if="item.reason" class="fs-09 text-wrap mt5" v-html="item.reason"></div>
					</div>
					<div class="flex-string">

						<div v-if="item.user !== null" class="ellipsis Bold" :title="item.user">{{item.user}}</div>
						<div v-else-if="item.user === null">-</div>

						<div v-if="item.date_work !== null" class="elipsis fs-09 red">{{$moment(item.date_work).format('DD.MM.YY H:mm')}}</div>
						<div v-if="item.date_end !== null" class="elipsis fs-09 green">{{$moment(item.date_end).format('DD.MM.YY H:mm')}}</div>

					</div>
					<div class="flex-string text-right">

						<a :title="language.NextStep" @click="editFeedbackStep(item.id)" class="button dotted bluebtn m0">
							<i class="icon-forward-1"></i>
						</a>

						<a v-if="item.isSchedule" title="Удалить задачу" class="button dotted redbtn m0">
							<i class="icon-cancel-circled"></i>
						</a>

					</div>
				</div>

			</div>

			<div class="pagediv">

				<a href="javascript:void(0)" title="Первая" v-if="page > 1" @click="changePage(1)">&nbsp;<i class="icon-angle-double-left"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Предыдущая" v-if="page > 1" @click="changePage(page - 1)">&nbsp;<i class="icon-angle-left"></i>&nbsp;&nbsp;</a>
				{{ page }} из {{ pageall }}
				<a href="javascript:void(0)" title="Следующая" v-if="page >= 1 && page < pageall" @click="changePage(page + 1)">&nbsp;<i class="icon-angle-right"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Последняя" v-if="page < pageall && pageall > 1" @click="changePage(pageall)">&nbsp;<i class="icon-angle-double-right"></i>&nbsp;&nbsp;</a>

			</div>

			<div class="space-100"></div>

			<div class="mt20 p0 bottom-block">

				<A href="javascript:void(0)" @click="getList" title="Обновить" class="indigo ml5">
					<i class="icon-arrows-ccw"></i>
				</A>

			</div>

		</div>

		<SubPanel ref="subpanel" :show="showsubpanel">
			<template v-if="addresscard > 0" v-slot:swbody>
				<AddressCard :id="addresscard" @close="closeCard" @edit="editAddressItem"></AddressCard>
			</template>
			<template v-else-if="feedbackcard > 0" v-slot:swbody>
				<FeedbackCard :id="feedbackcard" @close="closeCard" @edit="editFeedbackItem" @cedit="editCandidateItem"></FeedbackCard>
			</template>
			<template v-else-if="candidatecard > 0" v-slot:swbody>
				<CandidateCard :id="candidatecard" @close="closeCard" @edit="editCandidateItem"></CandidateCard>
			</template>
		</SubPanel>

		<Dialog ref="dialog" :show="showdialog" :width="dialogwidth" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="idaddress > 0" v-slot:dlgbody>
				<AddressForm :id="idaddress" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="idcandidate > 0" v-slot:dlgbody>
				<CandidateForm :id="idcandidate" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="!step && idfeedback > 0" v-slot:dlgbody>
				<FeedbackForm :id="idfeedback" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="step && idfeedback > 0" v-slot:dlgbody>
				<FeedbackStepsForm :id="idfeedback" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
import axios from "axios"

import SubPanel from "@/components/modal/SubPanel"
import Dialog from "@/components/modal/Dialog"

import CandidateCard from "@/pages/candidate/CandidateCard"
import CandidateForm from "@/pages/candidate/CandidateForm"

import FeedbackCard from "@/pages/feedback/FeedbackCard"
import FeedbackForm from "@/pages/feedback/FeedbackForm"
import FeedbackStepsForm from "@/pages/feedback/FeedbackStepsForm"

import AddressCard from "@/pages/address/AddressCard"
import AddressForm from "@/pages/address/AddressForm"

export default {
	name: "FeedbackSteps",
	components: {
		Dialog,
		SubPanel,
		FeedbackCard,
		FeedbackForm,
		FeedbackStepsForm,
		AddressCard,
		AddressForm,
		CandidateCard,
		CandidateForm
	},
	data() {
		return {
			loading: false,
			filters: {},
			items: null,
			page: 1,
			pageall: 1,
			total: 0,
			dialog: {},
			addresscard: 0,
			idaddress: 0,
			feedbackcard: 0,
			idfeedback: 0,
			candidatecard: 0,
			idcandidate: 0,
			step: false,
			showsubpanel: false,
			showdialog: false,
			dialogloaded: false,
			dialogplusloaded: false,
			dialogwidth: 800,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language
		}
	},

	created() {
		this.getList()
		this.pageTitle = this.language.NextStep
	},

	watch: {
		// при изменениях маршрута запрашиваем данные снова
		routes: 'getList'
	},

	methods: {
		getList() {

			let param = [];

			param = JSON.parse(JSON.stringify(this.$store.state.steps.filters))

			let feedbacks = param.feedbacks

			for (let key in feedbacks) {
				if (feedbacks[key]) {
					param[key] = feedbacks[key]
				}
			}

			param['page'] = this.$store.state.steps.feedback.page
			param['limit'] = 100

			this.loading = true

			axios.post(this.apiBaseURL + '/api/steps/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {
					this.filters = data.data.result.filters
					this.items = data.data.result.list
					this.page = data.data.result.page
					this.pageall = data.data.result.pageall
					this.total = data.data.result.total
					this.$store.commit('steps/SET_FEEDBACK', data.data.result)

					this.loading = false
				},
				/*(response) => {
					console.log("address:", response)
				}*/
			)

		},
		updateList(){

			if( this.showsubpanel && this.addresscard > 0 ){

				let c = this.addresscard

				this.closeCard()

				let self = this;
				setTimeout(function() {
					self.viewAddressCard(c)
				}, 100);

			}
			else if( this.showsubpanel && this.feedbackcard > 0 ){

				let c = this.feedbackcard

				this.closeCard()

				let self = this;
				setTimeout(function() {
					self.viewFeedbackCard(c)
				}, 100);

			}
			else if( this.showsubpanel && this.candidatecard > 0 ){

				let c = this.candidatecard

				this.closeCard()

				let self = this;
				setTimeout(function() {
					self.viewCandidateCard(c)
				}, 100);

			}

			this.getList();

		},
		changePage(page) {
			this.page = page
			this.$store.commit("steps/CHANGE_PAGE", page)

			this.getList()
		},

		viewAddressCard(payload) {
			this.candidatecard = 0
			this.feedbackcard = 0
			this.addresscard = parseInt(payload)
			this.showsubpanel = true
		},
		editAddressItem(payload) {
			this.idfeedback = 0
			this.idcandidate = 0
			this.idaddress = parseInt(payload)
			this.showdialog = true
		},

		viewCandidateCard(payload) {
			this.addresscard = 0
			this.feedbackcard = 0
			this.candidatecard = parseInt(payload)
			this.showsubpanel = true
		},
		editCandidateItem(payload) {
			this.idaddress = 0
			this.idfeedback = 0
			this.idcandidate = parseInt(payload)
			this.showdialog = true
		},

		viewFeedbackCard(payload) {
			this.addresscard = 0
			this.candidatecard = 0
			this.feedbackcard = parseInt(payload)
			this.showsubpanel = true
		},
		editFeedbackItem(payload) {
			this.idaddress = 0
			this.idcandidate = 0
			this.idfeedback = parseInt(payload)
			this.showdialog = true
		},
		editFeedbackStep(payload) {
			this.idaddress = 0
			this.idcandidate = 0
			this.idfeedback = parseInt(payload)
			this.step = true
			this.approve = false
			this.approveData = {}
			this.exportData = false
			this.compareData = false
			this.bigreportData = false
			this.showdialog = true
		},
		deleteItem(payload) {

			this.$swal.fire({
				title: "Are You Sure?",
				text: 'Item will be deleted forever',
				icon: 'question',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: '#3085D6',
				cancelButtonColor: '#D33',
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel"
			}).then((result) => {

				if (result.value) {

					// отправка формы здесь
					let param = {};

					param.id = payload
					param.action = "delete"

					axios.post('http://tmc.local/api/feedback/', param, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							(data) => {

								this.message = data.data.result.result
								this.getList()

								this.$toast.success({
									title: 'Success',
									message: data.data.result.result,
									position: "bottom center",
									timeOut: 3000
								})

							},
						)
						.catch(error => {

							this.$toast.error({
								title: 'Error '+ error.code,
								message: "" + error.text,
								position: "bottom center",
								timeOut: 3000
							})

						})

				}

			});

		},

		closeCard() {
			this.addresscard = 0
			this.candidatecard = 0
			this.feedbackcard = 0
			this.showsubpanel = false
			this.step = false
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
		closeDialog() {
			this.idaddress = 0
			this.idfeedback = 0
			this.idcandidate = 0
			this.step = false
			this.showdialog = false
			this.dialogwidth = 800
		},
		closeAllFilters() {
			this.$refs.filters.closeAll()
		},
		clearSearch() {
			this.filters.word = ''
			this.getList()
		}
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Next Steps',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.page--title{
	left: 45px;
	display: block;
	width: 200px !important;
	text-align: left;
}
</style>