const state = {
	address: {
		page: 1,
		total: 1,
		pageall: 1
	},
	filters: {
		project: [],
		addresses: {},
		sort: "address",
		order: "desc"
	},
};

const mutations = {
	// address
	SET_FILTER_PROJECTS (state, projects) {
		state.filters.project = projects
	},

	SET_FILTER (state, addresses) {
		state.filters.addresses = addresses
	},

	SET_FILTER_ORDER (state, order) {
		state.filters.order = order
	},

	SET_FILTER_SORT (state, sort) {
		state.filters.sort = sort
	},

	SET_CLEAN_FILTERS (state) {
		state.filters = {
			project: [],
			addresses: {
				content: '',
				metro: '',
				city: '',
				isarchive: '',
				isactive: '',
				emptygeo: '',
				channel: []
			},
			sort: "address",
			order: "desc"
		}
	},

	SET_ADDRESS(state, payload) {
		state.address.items = payload.list
		state.address.page = payload.page
		state.address.pageall = payload.pageall
		state.address.total = payload.total
	},
	SET_FILTERS(state, payload) {
		state.filters = payload
	},

	CHANGE_PAGE(state, page) {
		state.address.page = page
	},
};

export default {
	namespaced: true,
	state,
	mutations
}