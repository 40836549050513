const state = {
	marketing: {
		//items: {},
		page: 1,
		total: 1,
		pageall: 1
	},
	filters: {
		project: [],
		marketing: {
			title: '',
			city: '',
			archive: '',
			active: '',
			zero: false,
			old: '',
			channel: [],
			task: false
		},
		sort: "mrkt.date_end",
		order: "desc"
	},
};

const mutations = {
	// address
	SET_FILTER_PROJECTS (state, projects) {
		state.filters.project = projects
	},

	SET_FILTER (state, items) {
		state.filters.marketing = items
	},

	SET_FILTER_ORDER (state, order) {
		state.filters.order = order
	},

	SET_FILTER_SORT (state, sort) {
		state.filters.sort = sort
	},

	SET_CLEAN_FILTERS (state) {
		state.filters = {
			project: [],
			marketing: {
				title: '',
				city: '',
				archive: '',
				active: '',
				old: '',
				zero: false,
				channel: [],
				task: false
			},
			sort: "mrkt.date_end",
			order: "desc"
		}
	},

	SET_MEET(state, payload) {
		state.marketing.page = payload.page
		state.marketing.pageall = payload.pageall
		state.marketing.total = payload.total
	},
	SET_FILTERS(state, payload) {
		state.filters = payload
	},

	CHANGE_PAGE(state, page) {
		state.marketing.page = page
	},
};

export default {
	namespaced: true,
	state,
	mutations
}