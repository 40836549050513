const state = {
	zarplata: {
		//items: {},
		page: 1,
		total: 1,
		pageall: 1
	},
	filters: {
		project: [],
		zarplata: {
			city: '',
		},
		sort: "zrp.city",
		order: "desc"
	},
};

const mutations = {
	// address
	SET_FILTER_PROJECTS (state, projects) {
		state.filters.project = projects
	},

	SET_FILTER (state, items) {
		state.filters.zarplata = items
	},

	SET_FILTER_ORDER (state, order) {
		state.filters.order = order
	},

	SET_FILTER_SORT (state, sort) {
		state.filters.sort = sort
	},

	SET_CLEAN_FILTERS (state) {
		state.filters = {
			project: [],
			zarplata: {
				city: '',
			},
			sort: "zrp.city",
			order: "asc"
		}
	},

	SET_MEET(state, payload) {
		state.zarplata.page = payload.page
		state.zarplata.pageall = payload.pageall
		state.zarplata.total = payload.total
	},
	SET_FILTERS(state, payload) {
		state.filters = payload
	},

	CHANGE_PAGE(state, page) {
		state.zarplata.page = page
	},
};

export default {
	namespaced: true,
	state,
	mutations
}