<template>
	<div>

		<router-view @guide="guide"></router-view>

	</div>
</template>

<script>

export default {
	name: "Settings",
	components: {
	},
	methods:{
		guide(){
			this.$emit("guide")
		}
	}
}
</script>

<style scoped>

</style>