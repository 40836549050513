<template>

	<div>

		<DIV class="zagolovok">{{ language.Add }}/{{ language.Edit }} {{ language.Marketing.Tarif }}</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading && showit" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="space-10"></div>

				<div data-id="feedback">

					<div class="flex-container mt10 mb5 box--child">

						<div class="flex-string wp100 relative material cleared">

							<input type="text" id="title" class="required wp100" placeholder=" " autocomplete="off" v-model="form.title" :v="$v.form.title" :class="{'required--error':$v.form.title.$error}">
							<label for="title">{{ language.Title }}</label>
							<div class="clearinputs mr5" @click="clearInput('title')"><i class="icon-block red fs-14"></i></div>

						</div>

					</div>

					<div class="flex-container mb10 box--child">

						<div class="flex-string wp100 mt10 relative material mradio">

							<ChannelRadio v-model="form.channel" :id="form.channel" :label="true" :exclude="excludedChannel" @select="channelChanged" :class="{'required--error':$v.form.channel.$error}"/>

						</div>

					</div>

					<div class="flex-container mt10 mb5 box--child">

						<div class="flex-string wp100 relative material cleared">

							<input type="text" id="title" class="required wp100" placeholder=" " autocomplete="off" v-model="form.period" :v="$v.form.period" :class="{'required--error':$v.form.period.$error}">
							<label for="title">{{ language.Period }}, дней</label>
							<div class="clearinputs mr5" @click="clearInput('period')"><i class="icon-block red fs-14"></i></div>

						</div>

					</div>

					<div class="flex-container mt10 mb5 box--child">

						<div class="flex-string wp100 relative material cleared">

							<input type="number" inputmode="decimal" step="0.01" id="title" class="required wp100" placeholder=" " autocomplete="off" v-model="form.cost" :v="$v.form.cost" :class="{'required--error':$v.form.cost.$error}">
							<label for="title">{{ language.Marketing.Cost }}</label>
							<div class="clearinputs mr5" @click="clearInput('cost')"><i class="icon-block red fs-14"></i></div>

						</div>

					</div>

					<div class="flex-container mt10 mb5 box--child">

						<div class="flex-string wp100 relative material like-input pt20">

							<div class="radio wp30 inline p5">
								<label class="middle">
									<input type="radio" value="false" v-model="form.archive">
									<span class="custom-radio"><i class="icon-radio-check"></i></span>
									<span class="fs-09 Bold blue">{{ language.Active }}</span>
								</label>
							</div>&nbsp;

							<div class="radio wp30 inline p5">
								<label class="middle">
									<input type="radio" value="true" v-model="form.archive">
									<span class="custom-radio alert"><i class="icon-radio-check"></i></span>
									<span class="fs-09 Bold red">{{ language.Archive }}</span>
								</label>
							</div>

							<div class="label">{{ language.Activity }}</div>

						</div>

					</div>

				</div>

				<div class="space-100"></div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<button @click="formSubmit" class="button"><i class="icon-ok"></i>{{language.Save}}</button>
				<button @click="closeForm" class="button graybtn"><i class="icon-cancel"></i>{{language.Cancel}}</button>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
import {required, minLength, numeric, decimal} from 'vuelidate/lib/validators';
import ChannelRadio from "@/components/elements/channel-radio";
import Vue from "vue";

export default {
	name: "TarifForm",
	components: {
		ChannelRadio
	},
	props: {
		id: Number,
	},
	data() {
		return {
			//loading: false,
			form: {
				card: this.id,
				action: "edit",
				channel: null,
				title: "",
				period: 0,
				cost: "0.00",
				archive: false,
			},
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			loading: false,
			message: "",
			submitStatus: "",
			dialogplusloaded: false,
			dialogpluswidth: 800,
			showit: true,
			isAdmin: this.$store.state.user.isAdmin,
			excludedChannel: [],
			shortcuts: Vue.prototype.$shortcuts,
		}
	},
	validations: {
		form: {
			channel: {
				required,
				minValue: 1
			},
			title: {
				required,
				minLength: minLength(2)
			},
			cost: {
				decimal,
				minLength: minLength(1),
			},
			period: {
				numeric,
				minLength: minLength(1),
			},
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.$v.form.$touch()
		this.excludeChannel()
	},
	mounted() {
		//console.log(this.id)
		this.loadCard()
	},
	watch: {
		city() {
			return this.form.soiskatel.city
		}
	},
	methods: {
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: this.language.msg.EmptyRequiredFields,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				// do your submit logic here
				this.submitStatus = 'PENDING'

				// отправка формы здесь
				let param = {};

				param['action'] = 'edit'
				param.id = this.card

				for (let key in this.form) {
					if (this.form[key]) {
						param[key] = this.form[key]
					}
				}

				this.loading = true

				axios.post(this.apiBaseURL + '/api/tarifs/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.closeForm()
							this.$emit("update")

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.loading = false

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

				//console.log(this.submitStatus)

			}

		},
		loadCard() {

			this.loading = true

			//console.log(this.card)

			if (this.card > 0) {

				let param = {};

				param.id = this.card
				param.action = 'info'

				axios.post(this.apiBaseURL + '/api/tarifs/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							let tarif = data.data.result

							this.form = {
								action: "edit",
								title: tarif.title,
								channel: parseInt(tarif.channel),
								period: tarif.period,
								cost: tarif.cost,
								archive: tarif.archive,
							}

							this.$emit("loaded")

							this.loading = false

						},
					)
					.catch(error => {
						console.log(error)
					})

			} else {

				this.loading = false

				this.$emit("loaded")

			}

		},
		closeForm() {
			this.$emit("close")
		},
		clearInput(element) {
			this.form[element] = ''
		},
		channelChanged(payload) {
			if (payload !== undefined) {
				this.form.channel = payload
			}
		},

		excludeChannel(){

			let rez = []

			for (let key=0; key < this.guides.channels.length; key++) {

				if (!this.guides.channels[key].active) {

					rez.push(this.guides.channels[key].id)

				}

			}

			//console.log(rez)

			this.excludedChannel = rez

		}

	}
}
</script>

<style>



</style>