<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				Settings. User Logs
			</div>

		</div>

		<div v-if="loading" class="content-loading"></div>

		<div v-if="!loading" class="page--body">

			<div v-if="loading" class="loading"></div>

			<DIV class="mt20 infodiv">

				<div class="mb10">В этом разделе вы можете увидеть события по основным действиям сотрудников.</div>

			</DIV>

			<div class="wp100 mx-auto mt10 pt10">

				<div class="flex-content loglist p5 tbl--header sticked--top">

					<div class="flex-string">Дата</div>
					<div class="flex-string text-center">Код</div>
					<div class="flex-string">Содержание</div>
					<div class="flex-string">Сотрудник</div>
					<div class="flex-string">IP</div>

				</div>

				<div v-for="item in items" :key="item.id" class="flex-content typelist loglist ha border-bottom p5">

					<div class="flex-string">{{$moment(item.datum).format('DD.MM.YY, HH:mm')}}</div>
					<div class="flex-string Bold text-center" :class="item.color">{{item.code}}</div>
					<div class="flex-string">{{item.content}}</div>
					<div class="flex-string">{{item.user}}</div>
					<div class="flex-string">{{item.ip}}</div>

				</div>

			</div>

			<div class="pagediv">

				<a href="javascript:void(0)" title="Первая" v-if="page > 1" @click="changePage(1)">&nbsp;<i class="icon-angle-double-left"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Предыдущая" v-if="page > 1" @click="changePage(page - 1)">&nbsp;<i class="icon-angle-left"></i>&nbsp;&nbsp;</a>
				{{ page }} из {{ pageall }}
				<a href="javascript:void(0)" title="Следующая" v-if="page >= 1 && page < pageall" @click="changePage(page + 1)">&nbsp;<i class="icon-angle-right"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Последняя" v-if="page < pageall && pageall > 1" @click="changePage(pageall)">&nbsp;<i class="icon-angle-double-right"></i>&nbsp;&nbsp;</a>

			</div>

			<div class="space-100"></div>

			<div class="mt20 p0 bottom-block"></div>

		</div>

	</div>

</template>

<script>
import axios from "axios";

export default {
	name: "UserlogsSet",
	data() {
		return {
			loading: false,
			items: null,
			page: 1,
			pageall: 1,
			total: 0,
			showdialog: false,
			dialogloaded: false,
			dialogwidth: 800,
			apiBaseURL: this.$store.getters.apiBaseURL,
		}
	},
	created() {
		this.getList()
	},
	methods:{
		getList() {

			let param = {};

			param['page'] = this.page
			param['limit'] = 100

			this.loading = true

			axios.post(this.apiBaseURL + '/api/userlog/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					this.items = data.data.result.list
					this.page = data.data.result.page
					this.pageall = data.data.result.pageall
					this.total = data.data.result.total

					this.loading = false
				},
			)

		},
		changePage(page) {
			this.page = page
			this.getList()
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
		closeDialog() {
			this.idaddress = 0
			this.idfeedback = 0
			this.idcandidate = 0
			this.showdialog = false
			this.dialogwidth = 800
			this.approve = false
			this.approveData = null
			this.exportData = false
		},
	}
}
</script>

<style scoped>

</style>