<template>

	<div class="subdialog" :class="classplus">

		<div class="subbody">

			<div class="close" @click="closeCard" title="Закрыть или нажмите ^ESC"><i class="icon-cancel"></i></div>

			<DIV class="zagolovok">Расписание выбранной точки</DIV>

			<div v-if="loading" class="content-loading wp100"></div>

			<div v-if="!loading" class="height100">

				<div class="attention mb5">
					<div class="Bold fs-11">{{address.title}}</div>
					<div class="broun mt5">Проект: <b>{{address.projectName}}</b>, Адрес: <b>{{address.address}}</b></div>
				</div>

				<div class="schedulebox">

					<div class="sch-header sticked--top">
						<div class="sch-day sch-timecol"></div>
						<div v-for="item in header" :key="item.name" class="sch-day" :class="{'weekend':item.isweekend}">{{item.date}}, {{item.name}}</div>
					</div>

					<div class="sch-body">

						<div class="sch-day sch-timecol">

							<div v-for="item in hours" :key="item.hour" class="sch-time">
								<span class="Bold fs-11 flh-14 pull-left pl10">{{item.hour}}</span>
								<span class="fs-07 flh-09 gray-blue">{{item.hour_to}}</span>
							</div>

						</div>

						<div v-for="item in calendar" :key="item.date" class="sch-day" :data-day="item.day" :data-date="item.date">

							<div v-for="event in item.events" :key="event.time" class="sch-time" :class="{'greenbg-sublite':event.add, 'redbg-sub':!event.add}" :data-time="event.time" :data-scope="event.scope" :data-date="event.date">

								<div v-if="event.add" class="sch-adder relative" @click="select([item.date, event.time])">
									<span v-if="event.event" class="sch-count"><i class="icon-user-1 orange" :title="'Записано: ' + event.event"></i></span>
									<i class="icon-plus-circled-1"></i>
								</div>
								<div v-else-if="!event.add" class="sch-noadder relative text-center hidden">
									<span v-if="event.event" class="sch-count"><i class="icon-user-1 gray-dark" :title="'Записано:' + event.event"></i></span>
									<i class="icon-minus-circled red"></i>
								</div>

							</div>

						</div>

					</div>

				</div>

				<div class="button--pane text-right fs-12 Bold">

					<A href="javascript:void(0)" @click="loadCalendar()" class="button m0 pull-left"><i class="icon-calendar-1"></i>&nbsp;Текущая неделя</A>
					<A href="javascript:void(0)" @click="changePeriod('prev')" class="button greenbtn m0 mr5" :class="{'disabled':prev === null}"><i class="icon-left-open"></i>&nbsp;Предыдущая</A>
					<A href="javascript:void(0)" @click="changePeriod('next')" class="button greenbtn m0">Следующая&nbsp;<i class="icon-right-open"></i></A>

				</div>

			</div>

		</div>

	</div>

</template>

<script>
import axios from "axios";

export default {
	name: "ScheduleCalendar",
	props:{
		id: Number,
		classplus: String
	},
	data(){
		return {
			address: {},
			header: {},
			calendar:{},
			hours: {},
			period: null,
			currentweeknum: 0,
			next: 0,
			prev: 0,
			loading: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
		}
	},
	computed:{
		card(){
			return this.id
		}
	},
	watch:{
		id(){
			this.loadCalendar()
		}
	},
	created(){
		this.loading = true
		this.loadCalendar()
	},
	methods: {
		loadCalendar() {

			//this.loading = true

			let param = {};

			param.id = this.card
			param.action = 'schedule'
			param.period = this.period

			axios.post(this.apiBaseURL + '/api/feedback/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 600000,
			})
				.then(
					(data) => {
						this.feedback = data.data.result.feedback
						this.candidate = data.data.result.candidate
						this.task = data.data.result.task

						this.address = data.data.result.address
						this.header = data.data.result.header
						this.hours = data.data.result.hours
						this.calendar = data.data.result.calendar

						this.currentweeknum = data.data.result.currentweeknum
						this.next = data.data.result.nextnum
						this.prev = data.data.result.prevnum

						if(data.data.result.period === null) {
							this.period = data.data.result.currentweeknum
						}

						this.loading = false

						this.$emit("loaded")
					},
				).catch(error => {

				console.log(error)

			})

		},
		changePeriod(payload){
			switch (payload){
				case "next":
					this.period = this.next
					break;
				case "prev":
					this.period = this.prev
					break;
				default:
					this.period = this.currentweeknum
					break;
			}
			//console.log("payload: ", payload)
			//console.log("period: ", this.period)
			this.loadCalendar()
		},
		closeCard(){

			this.$emit("close")

			this.address = {}
			this.header = {}
			this.calendar ={}
			this.hours = {}
			this.currentweeknum = 0
			this.period = ''

		},
		select(payload){
			this.$emit("edit", payload)
		},
	}
}
</script>

<style>
.subdialog {
	display  : block;
	z-index  : 10;
	height   : 100%;
	width: 80vw;
	position : fixed;
	background: var(--white);
	padding: 10px;

	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
}

.subdialog .close {
	position    : absolute;
	top         : 5px;
	right       : 10px;
	width       : 20px;
	height      : 20px;
	padding     : 5px 5px;
	color       : #222;
	font-size   : 1.5em;
	line-height : 20px;
	text-align  : center;
	cursor      : pointer;
}

.subdialog .subbody{
	height: calc(100% - 20px);
}
</style>