<template>

	<div class="gstring pl10 pr10">

		<div class="divider filter pt10 pb10" data-id="address">{{ language.Name }}, {{ language.Address.Address }}</div>

		<div class="filterblock no-border relative cleared">

			<input type="text" id="content" class="wp100 p5 pl10 pr10" :placeholder="language.Name +', '+ language.Address.Address" autocomplete="on" v-model="cfilter.content" @keyup="updateFilter">
			<div class="clearinputs" @click="clearInput('content')"><i class="icon-block-1 red"></i></div>

		</div>

		<div class="divider filter pt10 pb10" data-id="address">Email</div>

		<div class="filterblock no-border relative cleared">

			<input type="text" id="email" class="wp100 p5 pl10 pr10" placeholder="Email" autocomplete="on" v-model="cfilter.email" @keyup="updateFilter" @focusout="updateFilter">
			<div class="clearinputs" @click="clearInput('email')"><i class="icon-block-1 red"></i></div>

		</div>

		<div class="divider filter pt10 pb10" data-id="address">{{ language.Phone }}</div>

		<div class="filterblock no-border relative cleared">

			<input type="text" id="phone" class="wp100 p5 pl10 pr10" :placeholder="language.Phone" autocomplete="on" v-model="cfilter.phone" @keyup="updateFilter" @focusout="updateFilter">
			<div class="clearinputs" @click="clearInput('phone')"><i class="icon-block-1 red"></i></div>

		</div>

		<div class="divider pt10 pb10">{{ language.City }}</div>

		<div class="no-border relative cleared">

			<input type="text" id="city" class="wp100 p5 pl10 pr10" :placeholder="language.City" autocomplete="on" v-model="cfilter.city" @keyup="updateFilter" @focusout="updateFilter">
			<div class="clearinputs" @click="clearInput('city')"><i class="icon-block-1 red"></i></div>

		</div>

		<div class="divider pt10 pb10">{{ language.Other }}</div>

		<div class="filterblock no-border checkbox popblock-item" title="Archived" data-id="address">

			<label class="wp100 pl10 mt5 flex-container float">
				<input class="taskss" name="approved" type="checkbox" id="approve" value="true" v-model="cfilter.approved" @change="updateFilter">
				<span class="custom-checkbox"><i class="icon-ok"></i></span>
				<span class="flex-string float pl10 text-wrap">
					<span class="block Bold fs-11">{{ language.Approved }}</span>
				</span>
			</label>

		</div>

	</div>

</template>

<script>

export default {
	name: "Filters",
	model:{
		prop: "cfilter",
		event: "change"
	},
	props:{
		cfilter:{
			type: Object,
			default: () => {
				return {
					content: "",
					city: "",
					email: "",
					phone: "",
					users: []
				}
			}
		}
	},
	data(){
		return {
			language: this.$store.state.language,
		}
	},

	methods:{
		updateFilter(){
			this.$emit("change", this.cfilter)
		},

		clearInput(element){
			this.$store.state.candidates.filters.candidates[element] = '';
		}
	},
}
</script>

<style scoped>

</style>