<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				Settings. Smtp Server
			</div>

		</div>

		<div v-if="loading" class="content-loading"></div>

		<div v-if="!loading" class="page--body">

			<div class="page--body">

				<div class="infodiv mt10">Раздел содержит настройки SMTP-сервера, позволяющие корректно отправлять email-сообщения сотрудникам и клиентам с прохождением спам-фильтров</div>

				<div class="wp90 mx-auto mt20 pt10">

					<div class="flex-container mt10 mb10 box--child">

						<div class="flex-string wp1001 relative material like-input pt10" data-type="radioblock">

							<div class="radio wp20 inline p5">
								<label class="middle">
									<input type="radio" v-model="form.smtp.active" value="yes">
									<span class="custom-radio"><i class="icon-radio-check"></i></span>
									<span class="fs-09 Bold">Да</span>
								</label>
							</div>

							<div class="radio wp20 inline p5">
								<label class="middle">
									<input type="radio" v-model="form.smtp.active" value="no">
									<span class="custom-radio"><i class="icon-radio-check"></i></span>
									<span class="fs-09 Bold">Нет</span>
								</label>
							</div>

							<div class="label">Использовать сервер</div>

						</div>

					</div>

					<div class="flex-container mt10 mb10 box--child">

						<div class="flex-string wp100 relative material mselect" :class="{'checked':servers.length > 0}">

							<span class="label">Известные сервера</span>
							<span class="select">
								<select id="smtp" class="wp100" v-model="servers" @change="loadServer">
									<option v-for="(item, name) in smtp" :key="item.host" :value="name">{{item.host}}</option>
								</select>
							</span>

						</div>

					</div>

					<div class="divider mt20 mb20">
						{{language.AuthorizationParameter}}
					</div>

					<div class="flex-container mt10 mb10 box--child">

						<div class="flex-string wp100 relative material">

							<input type="text" id="smtp.user" class="required wp100" v-model="form.smtp.user" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.smtp.user.$error}">
							<label for="smtp.user">Email / Логин</label>

						</div>

					</div>

					<div class="flex-container mt10 mb10 box--child">

						<div class="flex-string wp100 relative material">

							<input :type="passtype" id="smtp.pass" class="required wp100" v-model="form.smtp.pass" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.smtp.pass.$error}">
							<div class="showpass" id="showpass" @click="togglePass" @mouseout="togglePass">
								<i class="hand gray" :class="{'icon-eye':passtype === 'text','icon-eye-off':passtype === 'password'}" title="Посмотреть пароль"></i>
							</div>
							<label for="smtp.pass">Пароль</label>

						</div>

					</div>

					<div class="divider mt20 mb20">
						Параметры сервера
					</div>

					<div class="flex-container mt10 mb10 box--child">

						<div class="flex-string wp100 relative material">

							<input type="text" id="smtp.host" class="required wp100" v-model="form.smtp.host" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.smtp.host.$error}">
							<label for="smtp.host">Адрес SMTP-сервера</label>

						</div>

					</div>

					<div class="flex-container mt10 mb10 box--child">

						<div class="flex-string wp100 relative material">

							<input type="text" id="smtp.port" class="required w160" v-model="form.smtp.port" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.smtp.port.$error}">
							<label for="smtp.port">Порт</label>

						</div>

					</div>

					<div class="flex-container mt10 mb10 box--child">

						<div class="flex-string wp100 relative material like-input pt10" data-type="radioblock">

							<div class="radio wp30 inline p5">
								<label class="middle">
									<input type="radio" v-model="form.smtp.auth" value="0">
									<span class="custom-radio"><i class="icon-radio-check"></i></span>
									<span class="fs-09 Bold">Без авторизации</span>
								</label>
							</div>

							<div class="radio wp30 inline p5">
								<label class="middle">
									<input type="radio" v-model="form.smtp.auth" value="1">
									<span class="custom-radio"><i class="icon-radio-check"></i></span>
									<span class="fs-09 Bold">С авторизацией</span>
								</label>
							</div>

							<div class="label">Авторизация</div>

						</div>

					</div>

					<div class="flex-container mt10 mb10 box--child">

						<div class="flex-string wp100 relative material like-input pt10" data-type="radioblock">

							<div class="radio wp30 inline p5">
								<label class="middle">
									<input type="radio" v-model="form.smtp.secure" value="">
									<span class="custom-radio"><i class="icon-radio-check"></i></span>
									<span class="fs-09 Bold">Без шифрования</span>
								</label>
							</div>

							<div class="radio wp30 inline p5">
								<label class="middle">
									<input type="radio" v-model="form.smtp.secure" value="tls">
									<span class="custom-radio"><i class="icon-radio-check"></i></span>
									<span class="fs-09 Bold">TLS</span>
								</label>
							</div>

							<div class="radio wp30 inline p5">
								<label class="middle">
									<input type="radio" v-model="form.smtp.secure" value="ssl">
									<span class="custom-radio"><i class="icon-radio-check"></i></span>
									<span class="fs-09 Bold">SSL</span>
								</label>
							</div>

							<div class="label">Тип шифрования</div>

						</div>

					</div>

					<div class="flex-container mt10 mb10 box--child">

						<div class="flex-string wp100 relative material">

							<input type="text" id="smtp.fromname" class="required wp100" v-model="form.smtp.fromname" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.smtp.fromname.$error}">
							<label for="smtp.fromname">Имя отправителя</label>

						</div>

					</div>

					<div class="flex-container mt10 mb10 box--child">

						<div class="flex-string wp100 relative material">

							<input type="text" id="smtp.from" class="required wp100" v-model="form.smtp.from" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.smtp.from.$error}">
							<label for="smtp.from">Email отправителя</label>

						</div>

					</div>

					<div v-if="checkit" :class="{'success':checkitresult.result === 'ok', 'warning':checkitresult.result === 'errors'}">
						{{checkitresult.message}}
					</div>

					<div class="button--pane mt20 p0 fs-12 Bold pt20 pb20 sticked--bottom">

						<A href="javascript:void(0)" @click="formSubmit" class="button m0 mr5"><i class="icon-ok-circled"></i>Сохранить</A>
						<A href="javascript:void(0)" @click="check" class="button redbtn m0"><i class="icon-help-circled-1"></i>Проверить</A>

					</div>

				</div>

				<div class="space-100"></div>

				<div class="mt20 p0 bottom-block">

					<A href="javascript:void(0)" @click="formSubmit" title="Сохранить">
						<i class="icon-ok"></i>
					</A>

				</div>

				<div v-if="floading" class="loading"></div>

			</div>

		</div>

	</div>

</template>

<script>

import axios from "axios"
import {required} from "vuelidate/lib/validators"
import SMTP from "@/mixins/smtp.json"

export default {
	name: "SmtpSet",
	components: {},
	data() {
		return {
			form: {
				smtp: {
					active: "yes",
					user: "",
					pass: "",
					host: "",
					port: "465",
					auth: "1",
					secure: "ssl",
					fromname: "",
					from: ""
				}
			},
			servers: '',
			smtp: SMTP,
			checkit: false,
			checkitresult: {},
			loading: false,
			floading: false,
			passtype: "password",
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
		}
	},
	validations: {
		form: {
			smtp:{
				user: {
					required
				},
				pass: {
					required
				},
				host: {
					required
				},
				port: {
					required
				},
				fromname: {
					required
				},
				from: {
					required
				},
			}
		}
	},
	created() {
		//this.$v.form.$touch()
	},
	mounted() {
		this.load()
	},
	methods: {
		load() {

			this.loading = true

			let param = {};

			param.action = 'info'

			axios.post(this.apiBaseURL + '/api/smtp/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {
						//console.log(data.data)

						//if(data.data.result.smtp.length > 0) {
							this.form.smtp = data.data.result
						//}

						this.$emit("loaded")

						this.loading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: "Не заполнены обязательные поля",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				// do your submit logic here
				this.submitStatus = 'PENDING'

				// отправка формы здесь
				let param = {};

				param['action'] = 'edit'

				for (let key in this.form) {
					if (this.form[key]) {
						param[key] = this.form[key]
					}
				}

				this.floading = true
				this.checkit = false

				//console.log(param)

				axios.post(this.apiBaseURL + '/api/company/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.$toast.success({
								title: 'Success',
								message: data.data.result.message,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

							this.floading = false

						},
					)
					.catch(error => {
						console.log(error)

						this.floading = false

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

				//console.log(this.submitStatus)

			}

		},
		check(){

			// отправка формы здесь
			let param = {};

			param['action'] = 'check'

			for (let key in this.form) {
				if (this.form[key]) {
					param[key] = this.form[key]
				}
			}

			this.floading = true
			this.checkit = true

			//console.log(param)

			axios.post(this.apiBaseURL + '/api/smtp/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.checkitresult = data.data.result

						this.floading = false

					},
				)
				.catch(error => {
					console.log(error)

					this.floading = false

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},
		loadServer(event){

			let value = event.target.value

			//console.log(this.smtp[value])

			this.form.smtp.host = this.smtp[value].host
			this.form.smtp.port = this.smtp[value].port
			this.form.smtp.auth = this.smtp[value].auth
			this.form.smtp.secure = this.smtp[value].secure

		},
		togglePass(){
			this.passtype = this.passtype === 'password' ? "text" : "password"
		}
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Smtp Server / Settings',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.page--title {
	left       : 20px;
	display    : block;
	width      : 200px !important;
	text-align : left;
}

@media (min-width : 768px) and (max-width : 1024px) {

	.page--title {
		left : 0 !important;
	}

}
</style>