<template>

	<div>

		<DIV class="zagolovok">Add/Edit SMS Gate</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.sender" id="sender" class="required wp100" placeholder=" " autocomplete="off">
						<label for="sender">Отправитель</label>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.user" id="user" class="required wp100" placeholder=" " autocomplete="off">
						<label for="user">Login</label>

					</div>

				</div>

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material">

						<input :type="passtype" v-model="form.pass" id="pass" class="wp100 required" placeholder=" " autocomplete="off">
						<div class="showpass" id="showpass" @click="togglePass" @mouseout="togglePass">
							<i class="hand gray" :class="{'icon-eye':passtype === 'text','icon-eye-off':passtype === 'password'}" title="Посмотреть пароль"></i>
						</div>
						<label for="pass">Password</label>

					</div>

				</div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<A href="javascript:void(0)" @click="formSubmit" class="button m0">Save</A>
				<A href="javascript:void(0)" @click="closeForm" class="button graybtn">Cancel</A>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";

export default {
	name: "SmstemplatesGate",
	props: {
		id: Number,
		// передается только для того, чтобы vue обновлял с сервера
		params: Object
	},
	data() {
		return {
			form: {
				sender: "",
				user: "",
				pass: ""
			},
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false,
			message: {},
			passtype: "password",
		}
	},
	created() {
		this.form = {
			sender: this.params.sender,
			user: this.params.user,
			pass: this.params.pass,
		}

		this.$emit("loaded")
		this.loading = false

	},
	methods: {
		formSubmit() {

				let param = {}

				param = this.form
				param['action'] = 'gate'

				axios.post(this.apiBaseURL + '/api/smstemplates/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.$emit("update")
							this.closeForm()

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

		},
		closeForm() {
			this.$emit("close")
		},
		togglePass(){
			this.passtype = this.passtype === 'password' ? "text" : "password"
		},
	}
}
</script>

<style scoped>

</style>