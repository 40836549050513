const state = {
	candidate: {
		page: 1,
		total: 1,
		pageall: 1
	},
	filters: {
		project: [],
		candidates: {
			content:"",
			city:"",
			email:"",
			phone:"",
			users: []
		},
		sort: "title",
		order: "desc"
	},
};

const mutations = {
	// candidate
	SET_FILTER_PROJECTS (state, payload) {
		state.filters.project = payload.project
		state.filters.order = payload.order
	},

	SET_FILTER_CANDIDATES (state, payload) {
		state.filters.candidates = payload
	},

	SET_FILTER_ORDER (state, order) {
		state.filters.order = order
	},

	SET_FILTER_SORT (state, sort) {
		state.filters.sort = sort
	},

	SET_CLEAN_FILTERS (state) {
		state.filters = {
			project: [],
			candidates: {
				content: "",
				city: "",
				email: "",
				phone: "",
				users: []
			},
			sort: "title",
			order: "desc"
		}
	},

	SET_CANDIDATE(state, payload) {
		state.candidate.items = payload.list
		state.candidate.page = payload.page
		state.candidate.pageall = payload.pageall
		state.candidate.total = payload.total
	},
	SET_FILTERS(state, payload) {
		state.filters = payload
	},
	CHANGE_PAGE(state, page) {
		state.candidate.page = page
	},
};

export default {
	namespaced: true,
	state,
	mutations
}