<template>

	<div>

		<div v-if="!loading">

			<DIV class="zagolovok">Большой и ежедневный отчеты</DIV>

			<div class="warning">!!! Функция работает в тестовом режиме</div>

			<div class="flex-container mt20 box--child">

				<div class="flex-string wp100 relative material like-input pt20">

					<div class="flex-container">

						<div class="flex-string wp50 border-box">
							<div class="radio">
								<label class="middle">
									<input class="taskss" name="report" type="radio" v-model="form.report" value="big" checked>
									<span class="custom-radio"><i class="icon-radio-check"></i></span>
									<span>Большой отчет</span>
								</label>
							</div>
						</div>

						<div class="flex-string wp50 border-box">
							<div class="radio">
								<label class="middle">
									<input class="taskss" name="report" type="radio" v-model="form.report" value="dayly">
									<span class="custom-radio"><i class="icon-radio-check"></i></span>
									<span>Ежедневный отчет</span>
								</label>
							</div>
						</div>

					</div>

					<div class="label">Вид отчета</div>

				</div>

			</div>

			<div class="flex-container mt10 box--child" v-if="form.report === 'big'">

				<div class="flex-string material like-input wp100 relative mt10 pt20">

					<div class="tagbox monthcount">
						<div v-for="(n, index) in 6" :key="index" class="tag monthcount" :class="{'current':n === current}" @click="current = n">{{ n }} мес.</div>
					</div>
					<div class="label">Выбор периода</div>

				</div>

			</div>

			<div class="flex-container mt10 box--child">

				<div class="flex-string wp100 relative material like-input pt20">

					<div class="flex-container">

						<div class="flex-string wp50 border-box">
							<div class="radio">
								<label class="middle">
									<input class="taskss" name="type" type="radio" v-model="form.type" value="excel" checked>
									<span class="custom-radio"><i class="icon-radio-check"></i></span>
									<span>
										<i class="icon-file-excel green"></i> Excel
									</span>
								</label>
							</div>
						</div>

						<div class="flex-string wp50 border-box">
							<div class="radio">
								<label class="middle">
									<input class="taskss" name="type" type="radio" v-model="form.type" value="csv">
									<span class="custom-radio"><i class="icon-radio-check"></i></span>
									<span>
										<i class="icon-doc-text-inv red"></i> CSV
									</span>
								</label>
							</div>
						</div>

						<div class="flex-string wp50 border-box">
							<div class="fs-09 flh-09 gray block wp80 pull-right">готовый отчет (формируется ооочень долго)</div>
						</div>

						<div class="flex-string wp50 border-box">
							<div class="fs-09 flh-09 gray block wp80 pull-right">данные для ручной вставки (формируется быстро)</div>
						</div>

					</div>

					<div class="label">Результаты</div>

				</div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<button @click="formSubmit" class="button"><i class="icon-ok"></i>{{language.Save}}</button>
				<button @click="closeForm" class="button graybtn"><i class="icon-cancel"></i>{{language.Cancel}}</button>

			</div>

		</div>

		<div v-if="loading">

			<div class="">Обрабатываю запрос..</div>
			<div class="space-50"></div>
			<div class="loading"></div>

		</div>

	</div>

</template>

<script>
import axios from "axios";
import {required} from "vuelidate/lib/validators";

export default {
	name: "FeedbackBigReport",
	data() {
		return {
			form: {
				action: "bigreport",
				report: "big",
				type: "excel"
			},
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			loading: false,
			message: "",
			submitStatus: "",
			current: 3
		}
	},
	validations: {
		form: {
			project: {
				required,
				minValue: 1
			},
		},
	},
	mounted() {
		this.form = {
			action: "bigreport",
			report: "big",
			type: "excel",
			silence: false
		}
		this.$emit("loaded")

	},
	watch: {},
	methods: {
		formSubmit() {

			// отправка формы здесь
			let param = {};

			param['action'] = 'bigreport'
			param['period'] = this.current

			for (let key in this.form) {
				if (this.form[key]) {
					param[key] = this.form[key]
				}
			}

			this.$emit('hide')
			this.loading = true

			axios.post(this.apiBaseURL + '/api/feedback/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.loading = false

						this.$toast.success({
							title: 'Success',
							message: data.data.result.result,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

						this.closeForm()

					},
				)
				.catch(error => {

					//error => this.status = error.response.data.status;
					console.log(error.response)

					this.loading = false
					this.closeForm()

					this.$toast.error({
						title: 'Error',
						message: error.response.status + ": " + error.response.statusText,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},
		closeForm() {
			this.$emit("close")
			this.form = {
				action: "bigreport",
				report: "big",
				type: "excel"
			}
		}
	}
}
</script>

<style scoped>
.loading {
	z-index : 100;
}

.dragover {
	border     : 3px dashed var(--blue) !important;
	background : var(--blueliter);
}

.tagbox {
	display               : grid;
	grid-template-columns : 1fr 1fr 1fr 1fr 1fr 1fr;
}

.tag {
	padding    : 5px 15px !important;
	text-align : center;
}

.tag.current {
	font-weight : bold;
	color       : var(--white);
	background  : var(--blue);
}
</style>