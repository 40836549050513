<template>

	<div class="flex-string relative material cleared" :class="wclass" v-click-outside="onHide">

		<input type="text" placeholder=" " id="vacancy" v-model="vacancy" class="wp100 pl10" :class="{'required':required}" :disabled="project === undefined || project === 0 || project === null" @focusin="search" ref="vacancyinput" autocomplete="off">
		<label for="vacancy">{{inputName}}</label>
		<div class="clearinputs mr5" @click="vacancy = ''"><i class="icon-block red fs-14"></i></div>
		<ul class="vu-suggestion" v-if="show" ref="suggestionlist">

			<li v-for="(item, index) in vacancylist" :key="index" @click="select(item)">
				<div class="fs-11 Bold">{{ item.vacancy }}</div>
				<div class="fs-09 gray mt5">{{ item.projectName }}</div>
			</li>

		</ul>

	</div>

</template>

<script>

export default {
	name: "VacancyList",
	props: {
		value: String,
		name: String,
		required: Boolean,
		classes: String,
		project: Number,
		min: Number,
		time: Object
	},
	data() {
		return {
			vacancy: '',
			vacancylist: [],
			language: this.$store.state.language,
			vacancyBase: this.$store.state.guides.vacancylist,
			projectList: this.$store.state.guides.projects,
			projectName: "",
			default: this.name,
			show: false
		}
	},
	computed:{
		require(){
			return this.required === true ? this.required : false
		},
		wclass(){
			return this.classes !== undefined ? this.classes : 'wp100'
		},
		minChars(){
			return this.min !== undefined ? this.min : 0
		},
		inputName(){
			return this.name !== undefined ? this.name : this.language.Vacancy
		},
		/*vacancy:{
			get(){
				return this.value
			},
			set(payload){
				this.$emit("update", payload)
			}
		}*/
	},
	watch:{
		time(){
			this.vacancy = this.value
		}
	},
	created() {
		this.vacancy = this.value
	},
	mounted() {

	},
	methods: {
		search() {

			if( this.project !== undefined ) {

				let vm = this.project

				for(let index in this.projectList){

					if(parseInt(this.projectList[index].id) === vm){
						this.projectName = this.projectList[index].name
						//console.log(this.projectName)
						break
					}

				}

				this.vacancylist = this.vacancyBase.filter(function (e) {
					return e.project === vm || e.project === 0;// || e.attached === vm;
				})

				if (!this.show) {
					this.onShow()
				}

			}

		},
		select(payload){
			this.vacancy = payload.vacancy
			this.$emit("updatefull", payload)
			this.onHide()
			this.update()
		},
		update() {
			this.$emit("update", this.vacancy)
		},
		clean(){
			this.vacancy = ''
			this.update()
		},
		onShow(){
			this.show = true
		},
		onHide(){
			this.show = false
			//this.update()
		},
	}
}
</script>

<style scoped>
ul.vu-suggestion {
	max-height: 300px;
}
</style>