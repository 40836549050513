<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				Settings. Company
			</div>

		</div>

		<div v-if="loading" class="content-loading"></div>

		<div v-if="!loading" class="page--body">

			<div class="infodiv mt10">Эти сведения используются при регистрации, а также в email-сообщениях вашим клиентам</div>

			<div class="wp90 mx-auto mt20 pt10">

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" id="company" class="required wp100" v-model="form.company" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.company.$error}">
						<label for="company">Название компании (краткое)</label>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" id="company_full" class="required wp100" v-model="form.company_full" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.company_full.$error}">
						<label for="company_full">Название компании (полное)</label>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" id="company_site" class="wp100" v-model="form.company_site" placeholder=" " autocomplete="on" :class="{'required--error':$v.form.company_site.$error}">
						<label for="company_site">Сайт</label>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" id="company_mail" class="required wp100" v-model="form.company_mail" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.company_mail.$error}">
						<label for="company_mail">Email</label>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" id="company_phone" class="required wp100" v-model="form.company_phone" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.company_phone.$error}">
						<label for="company_phone">Телефон</label>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" id="company_address" class="required wp100" v-model="form.company_address" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.company_address.$error}">
						<label for="company_address">Адрес для почты</label>

					</div>

				</div>

				<div class="space-20"></div>

				<div class="button--pane mt20 p0 fs-12 Bold pt20 pb20 sticked--bottom">

					<A href="javascript:void(0)" @click="formSubmit" class="button m0"><i class="icon-ok-circled"></i>Сохранить</A>

				</div>

			</div>

			<div class="space-100"></div>

			<div class="mt20 p0 bottom-block">

				<A href="javascript:void(0)" @click="formSubmit" title="Сохранить">
					<i class="icon-ok"></i>
				</A>

			</div>

		</div>

	</div>

</template>

<script>

import axios from "axios";
import {email, required, url} from "vuelidate/lib/validators";

export default {
	name: "AboutSet",
	components: {},
	data() {
		return {
			form: {
				company: "",
				company_full: "",
				company_site: "",
				company_mail: "",
				company_phone: "",
				company_address: ""
			},
			loading: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language
		}
	},
	validations: {
		form: {
			company: {
				required
			},
			company_full: {
				required
			},
			company_site: {
				url
			},
			company_mail: {
				required,
				email
			},
			company_phone: {
				required
			},
			company_address: {
				required
			},
		}
	},
	created() {
		this.$v.form.$touch()
	},
	mounted() {
		this.load()
	},
	methods: {
		load() {

			this.loading = true

			let param = {};

			param.action = 'info'

			axios.post(this.apiBaseURL + '/api/company/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {
						//console.log(data.data)

						this.form = data.data.result

						this.$emit("loaded")

						this.loading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: "Не заполнены обязательные поля",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				// do your submit logic here
				this.submitStatus = 'PENDING'

				// отправка формы здесь
				let param = {};

				param['action'] = 'edit'

				for (let key in this.form) {
					if (this.form[key]) {
						param[key] = this.form[key]
					}
				}

				this.loading = true

				//console.log(param)

				axios.post(this.apiBaseURL + '/api/company/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.$toast.success({
								title: 'Success',
								message: data.data.result.message,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

							this.loading = false

						},
					)
					.catch(error => {
						console.log(error)

						this.loading = false

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

				//console.log(this.submitStatus)

			}

		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Company / Settings',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.page--title {
	left       : 20px;
	display    : block;
	width      : 200px !important;
	text-align : left;
}

@media (min-width : 768px) and (max-width : 1024px) {

	.page--title {
		left : 0 !important;
	}

}
</style>