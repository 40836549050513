<template>

	<div id="dialog_container" class="dialog_container" :class="{'open':show}" ref="dialog_container">

		<div class="dialog-preloader" :class="{'hidden':loaded}">
			<img src="/assets/images/rings.svg" width="128">
		</div>
		<div class="dialog" id="dialog" :class="{'hidden':!loaded}" :style="{'width':dialogWidth}">
			<div class="close" @click="closeDialog" title="Закрыть или нажмите ^ESC"><i class="icon-cancel"></i></div>
			<div id="resultdiv" class="resultdiv">
				<slot name="dlgbody" @load="loadedDialog" @close="closeDialog"></slot>
			</div>
		</div>

	</div>

</template>

<script>
export default {
	name: "Dialog",
	props:{
		show: Boolean,
		loaded: Boolean,
		width: Number,
		widthString: String
	},
	data(){
		return {

		}
	},
	watch:{
		loaded(){
			this.loaded = true
		}
	},
	computed: {
		dialogWidth(){
			return this.widthString !== undefined ? this.widthString : this.width + 'px'
		}
	},
	mounted() {
		// это должно закрывать модальное окно
		/*let vm = this
		document.addEventListener("click", function (item){
			//console.log(item)
			if(item.target === vm.$refs['dialog_container'] && vm.show === true){
				vm.closeModal()
			}
		})*/
	},
	methods:{
		closeDialog(){
			this.$emit("close")
		},
		loadedDialog(){
			//console.log("load")
			this.loaded = true
		},
	}
}
</script>

<style>

.dialog{
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.dialog .resultdiv{
	max-height: 80vh;
	top: calc(10vh);
}

</style>