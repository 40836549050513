<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				Settings. Server
			</div>

		</div>

		<div v-if="loading" class="content-loading"></div>

		<div v-if="!loading" class="page--body">

			<div class="page--body">

				<div class="infodiv mt10">Раздел содержит различные настройки, влияющие на работу системы</div>

				<div class="wp90 mx-auto mt20 pt10">

					<div class="flex-container mt10 mb10 box--child">

						<div class="flex-string wp100 relative material">

							<input type="text" id="notify_email" class="required wp100" v-model="form.notify_email" placeholder=" " autocomplete="off" :class="{'format--error':$v.form.notify_email.$error}">
							<label for="notify_email">Email для уведомлений</label>

						</div>

					</div>

					<div class="flex-container mb10 mt20 box--child">

						<div class="flex-string wp100 relative material">

							<input type="text" name="maxupload" id="maxupload" class="required wp100" v-model="form.maxupload" placeholder=" " autocomplete="off" :class="{'format--error':$v.form.maxupload.$error}">
							<label for="maxupload">Максимальный размер файла, Mb</label>

						</div>

					</div>

					<div class="flex-container mt10 mb10 box--child">

						<div class="flex-string wp100 relative material mselect" :class="{'checked':form.timezone !== null}">

							<span class="label">Временная зона</span>
							<span class="select">
								<select id="timezone" class="wp100" v-model="form.timezone">
									<option v-for="(item, index) in timezones" :key="index" :value="item.zone">{{item.title}}</option>
								</select>
							</span>

						</div>

					</div>

					<div class="flex-container mb10 mt20 box--child hidden">

						<div class="flex-string wp100 relative material">

							<input type="text" id="yandex_key" class="wp100 linked" v-model="form.yandex_key" placeholder=" " autocomplete="on">
							<label for="yandex_key">Ключ для Яндекс.Translate</label>
							<a href="https://translate.yandex.ru/developers/keys" data-for="yandex_key" class="linked" target="_blank" title="Получить ключ">Получить ключ</a>

						</div>

					</div>

					<div class="divider">Интеграция с ДаДата</div>

					<div class="infodiv mt20">Используется для получения гео-координат по загруженным адресам. Есть ограничения на бесплатном тарифе.</div>

					<div class="flex-container mb10 mt20 box--child">

						<div class="flex-string wp100 relative material">

							<input type="text" id="dadata_key" class="wp100 linked" v-model="form.dadata_key" placeholder=" " autocomplete="on">
							<label for="dadata_key">Token для Dadata</label>
							<a href="https://dadata.ru/api/detect_address_by_ip/#authorization_popup" data-for="dadata_key" class="linked" target="_blank" title="Получить ключ">Получить ключ</a>
							<div class="fs-09 gray">Ищи в примерах слово Token</div>

						</div>

					</div>

					<div class="flex-container mb10 mt20 box--child">

						<div class="flex-string wp100 relative material">

							<input type="text" id="dadata_token" class="wp100 linked" v-model="form.dadata_token" placeholder=" " autocomplete="on">
							<label for="dadata_token">Secret для Dadata</label>
							<a href="https://dadata.ru/api/geocode/" data-for="dadata_token" class="linked" target="_blank" title="Получить ключ">Получить Secret</a>
							<div class="fs-09 gray">Ищи в примерах слово X-Secret</div>

						</div>

					</div>

					<div class="space-20"></div>

					<div class="button--pane mt20 p0 fs-12 Bold pt20 pb20 sticked--bottom">

						<A href="javascript:void(0)" class="button m0"><i class="icon-ok-circled"></i>Сохранить</A>

					</div>

				</div>

				<div class="space-100"></div>

				<div class="mt20 p0 bottom-block">

					<A href="javascript:void(0)" @click="formSubmit" title="Сохранить">
						<i class="icon-ok"></i>
					</A>

				</div>

			</div>

		</div>

	</div>

</template>

<script>

import axios from "axios"
import {required} from "vuelidate/lib/validators"

export default {
	name: "ServerSet",
	components: {},
	data() {
		return {
			form: {
				notify_email: "",
				maxupload: "",
				yandex_key: "",
				dadata_key: "",
				dadata_token: "",
				timezone: null
			},
			timezones: {},
			loading: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
		}
	},
	validations: {
		form: {
			notify_email: {
				required
			},
			maxupload: {
				required
			},
		}
	},
	created() {
		this.$v.form.$touch()
	},
	mounted() {
		this.load()
	},
	methods: {
		load() {

			this.loading = true

			let param = {};

			param.action = 'info'

			axios.post(this.apiBaseURL + '/api/server/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {
						//console.log(data.data)

						this.form = data.data.result
						this.timezones = data.data.result.timezones

						this.$emit("loaded")

						this.loading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: "Не заполнены обязательные поля",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				// do your submit logic here
				this.submitStatus = 'PENDING'

				// отправка формы здесь
				let param = {};

				param['action'] = 'edit'

				for (let key in this.form) {
					if (this.form[key]) {
						param[key] = this.form[key]
					}
				}

				this.loading = true

				//console.log(param)

				axios.post(this.apiBaseURL + '/api/company/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.$toast.success({
								title: 'Success',
								message: data.data.result.message,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

							this.loading = false

						},
					)
					.catch(error => {
						console.log(error)

						this.loading = false

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

				//console.log(this.submitStatus)

			}

		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Server/ Settings',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.page--title {
	left       : 20px;
	display    : block;
	width      : 200px !important;
	text-align : left;
}

@media (min-width : 768px) and (max-width : 1024px) {

	.page--title {
		left : 0 !important;
	}

}
</style>