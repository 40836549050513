<template>

	<div>

		<DIV class="zagolovok">{{ language.NextStep }}</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading && showit" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="divider mt10 mb20">
					<div class="green">{{language.CandidateData}}</div>
				</div>

				<div data-id="candidate">

					<div class="flex-container mt10 box--child">

						<div class="flex-string wp70 relative material cleared">

							<autocomplete
								:classes="'wp99'"
								:class="{'required--error':$v.form.soiskatel.title.$error}"
								v-model="form.soiskatel.title"
								:value="form.soiskatel.title"
								:name="language.CandidateName"
								:required="true"
								:method="'candidate'"
								:action="'search'"
								:params="{goal:'title'}"
								:min="3"
								:vars="['title','phone','city']"
								@update="soiskatelChanged"
								@updatefield="soiskatelChangedValue"
							>
							</autocomplete>

						</div>

						<div class="flex-string relative wp30">

							<date-picker lang="ru" format="DD.MM.YYYY" :default-value="form.soiskatel.birthday" v-model="form.soiskatel.birthday" valueType="format" @input="changeBirthday">

								<template v-slot:input>
									<div class="wp100 relative material">
										<input type="text" id="birthday" class="wp100" placeholder=" " autocomplete="off" v-model="form.soiskatel.birthday">
										<label for="birthday">{{language.Human.Birthday}}</label>
									</div>
								</template>
								<template v-slot:icon-clear></template>
								<template v-slot:icon-calendar></template>

							</date-picker>
							<div v-if="age > 0" class="fs-10 Bold" :class="{'red': age < 18, 'green': age >= 18}" style="position:absolute; right: 30px; top:20px; z-index: 2" data-id="age">{{ age }}</div>

						</div>

					</div>

					<div class="flex-container mt5 box--child">

						<nationality id="nationality" class="wp100" v-model="form.soiskatel.nationality" :value="form.soiskatel.nationality" @update="nationalityChanged"/>

					</div>

					<div class="flex-container mt5 box--child">

						<div class="flex-string wp50 relative material cleared">

							<autocomplete
								:classes="'wp99'"
								v-model="form.soiskatel.email"
								:value="form.soiskatel.email"
								:name="'Email'"
								:required="true"
								:method="'candidate'"
								:action="'search'"
								:params="{type:'email',goal:'email'}"
								:min="3"
								:vars="['title','phone','email','city']"
								:class="{'format--error':$v.form.soiskatel.email.$error}"
								@update="soiskatelChanged"
								@updatefield="soiskatelChangedValue"
							>
							</autocomplete>

							<div class="red fs-09" :class="{'hidden':!$v.form.soiskatel.email.$error}">Не корректный формат: не соответствует Email</div>

						</div>

						<div class="flex-string wp50 relative material cleared">

							<autocomplete
								:classes="'wp100'"
								v-model="form.soiskatel.phone"
								:value="form.soiskatel.phone"
								:name="language.Phone"
								:required="true"
								:method="'candidate'"
								:action="'search'"
								:params="{type:'phone',goal:'phone'}"
								:min="3"
								:vars="['phone','title','email','city']"
								:class="{'format--error':$v.form.soiskatel.phone.$error}"
								@update="soiskatelChanged"
								@updatefield="soiskatelChangedValue"
								@keyup="validatePhone"
							>
							</autocomplete>

							<div class="red fs-09" :class="{'hidden':phoneiscorrect}">Не корректный формат: <b>{{ phonelength }}</b> цифр из 11</div>

						</div>

					</div>

					<div class="flex-container mt10 mb10 box--child infodiv dotted">

						<div class="Bold fs-09 blue mb20">Заполните поле</div>

						<div class="flex-string wp100 relative material cleared">

							<input type="text" v-model="form.soiskatel.skype" id="skype" class="wp100 required" :v="$v.form.soiskatel.skype" :class="{'required--error':$v.form.soiskatel.skype.$error}" placeholder=" " autocomplete="off">
							<label for="skype">{{ language.Skype }}</label>
							<div class="clearinputs mr5"><i class="icon-block red fs-14"></i></div>

						</div>

					</div>

					<div class="flex-container mt10 mb0 box--child relative">

						<city-suggestions v-model="form.soiskatel.city" id="city" :classes="'wp30 pr5'" :city="form.soiskatel.city" @update="cityChanged" ref="city"></city-suggestions>

						<address-suggestions v-model="form.soiskatel.address" id="address" :classes="'wp70'" :value="form.soiskatel.address" @update="addressChanged"/>

					</div>

					<div class="flex-container box--child">

						<div class="flex-string wp100 relative material">

							<input type="text" v-model="form.soiskatel.url_1" id="url_1" class="wp100 linked" placeholder=" " autocomplete="off" :v="$v.form.soiskatel.url_1" :class="{'format--error':$v.form.soiskatel.url_1.$error}">
							<label for="url_1">Url</label>
							<a v-if="form.soiskatel.url_1 !== '' && form.soiskatel.url_1 !== null && !$v.form.soiskatel.url_1.$error" :href="form.soiskatel.url_1" target="_blank" class="linked urlopen" :title="language.View">{{language.View}}</a>

						</div>

					</div>

					<div class="flex-container mt5 box--child">

						<div class="flex-string wp100 relative material">

							<TextareaAutosize
								class="wp100"
								id="description"
								placeholder=" "
								ref="description"
								v-model="form.soiskatel.description"
								:min-height="100"
								:max-height="200"
								spellcheck="false"
							/>

							<label for="description">{{ language.Comment }}</label>

						</div>

					</div>

				</div>

				<div class="space-10"></div>

				<div class="divider mt10 mb20">
					<div class="red">{{language.WorkResult}}</div>
				</div>

				<div class="space-10"></div>

				<div data-id="result">

					<div class="flex-container mb10 box--child">

						<div class="flex-string wp100 relative material mradio">

							<Result v-model="form.results" :id="parseInt(form.results)" :label="true" :required="true" :hidden="[form.oldstep]" :results="results" :name="language.NextStep" @select="selectResult"></Result>
							<div class="fs-09 gray pl10">Заполняется при обработке</div>

						</div>

					</div>

					<div v-if="resultstype === 'lose'" class="flex-container mb10 box--child" data-block="reasons">

						<div class="flex-string wp100 relative material mradio">

							<Reason v-model="form.reason" :value="form.reason" :label="true" :required="reasonreq" @select="selectReason"></Reason>

						</div>

					</div>

					<div v-if="resultstype === 'task'" class="flex-container mt10 box--child" data-block="task">

						<div class="flex-string wp100 relative material">

							<date-picker lang="ru" type="datetime" :show-second="false" format="DD.MM.YYYY H:mm" :default-value="form.task.date" v-model="form.task.date" valueType="format" @input="changeTaskDay">

								<template v-slot:input>
									<div class="wp100 relative material">
										<input type="text" id="day" class="required wp100" placeholder=" " autocomplete="off" v-model="form.task.date">
										<label for="day">Дата и время звонка</label>
									</div>
								</template>
								<template v-slot:icon-clear></template>
								<template v-slot:icon-calendar></template>

							</date-picker>
							<div class="fs-09 gray">Заполняется только при необходимости перезвонить</div>

						</div>

					</div>

					<div v-if="resultstype === 'win'" class="flex-container mt5 mb20 box--child" data-block="scheduler">

						<div class="flex-string wp100 relative material" data-type="scheduler"></div>
						<div class="flex-string wp100 relative material">

							<input v-model="form.schedule" type="text" id="schedule" class="wp100 linked" value="" placeholder=" " autocomplete="off" @click="showScheduler">
							<a href="javascript:void(0)" @click="showScheduler" class="linked greenbtn"><i class="icon-calendar-inv"></i>Календарь</a>
							<label for="schedule">Дата и время собеседования</label>
							<div class="fs-09 gray pl10">Укажите новое, если надо назначить / переназначить или
								<a href="javascript:void(0)" @click="form.schedule = ''" class="red Bold">Очистить поле</a>
							</div>

						</div>

					</div>

					<div class="flex-container mt5 box--child">

						<div class="flex-string wp100 relative material">

							<TextareaAutosize
								class="wp100"
								id="comment"
								placeholder=" "
								ref="description"
								v-model="form.comment"
								v-html="form.comment"
								:min-height="100"
								:max-height="200"
								spellcheck="false"
							/>
							<label for="comment">{{ language.Comment }}</label>

						</div>

					</div>

				</div>

				<div class="space-100"></div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<button @click="formSubmit" class="button"><i class="icon-ok"></i>{{language.Save}}</button>
				<button @click="closeForm" class="button graybtn"><i class="icon-cancel"></i>{{language.Cancel}}</button>

			</div>

		</form>

		<template v-if="schedulefor > 0">
			<ScheduleCalendar :id="schedulefor" @close="closeDialogPlus" @loaded="dialogPlusLoaded" @edit="selectSchedule"/>
		</template>

	</div>

</template>

<script>
import axios from "axios";
import {required, minLength, maxLength, url, email, numeric} from 'vuelidate/lib/validators';

import AddressSuggestions from "@/components/elements/address-suggestions";
import CitySuggestions from "@/components/elements/city-suggestions";
import Nationality from "@/components/elements/nationality";
import Reason from "@/components/elements/reason";
import Result from "@/components/elements/results";
import ScheduleCalendar from "@/components/ScheduleCalendar";
import Autocomplete from "@/components/elements/autocomplete";

export default {
	name: "FeedbackStepsForm",
	components: {
		Autocomplete,
		Result,
		Reason,
		AddressSuggestions,
		CitySuggestions,
		Nationality,
		ScheduleCalendar
	},
	props: {
		id: Number,
	},
	data() {
		return {
			//loading: false,
			form: {
				card: this.id,
				action: "edit",
				sid: 0,
				soiskatel: {
					title: "",
					birthday: this.$moment().subtract(20, 'years').format('DD.MM.YYYY'),
					city: "",
					address: "",
					phone: "7",
					email: "",
					skype: "",
					url_1: "",
					nationality: "",
					description: "",
				},
				project: 0,
				oldstep: 0,
				results: 0,
				reason: "",
				comment: "",
				schedule: "",
				scheduleid: 0,
				task: {
					date: ""
				}
			},
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			loading: false,
			city: "",
			message: "",
			submitStatus: "",
			age: 0,
			reasonreq: false,
			resultstype: "",
			results: [],
			schedule: {},
			showdialogplus: false,
			dialogplusloaded: false,
			dialogpluswidth: 800,
			schedulefor: 0,
			showit: true,
			phoneiscorrect: true,
			phonelength: 0,
			date_create: null,
			isAdmin: this.$store.state.user.isAdmin
		}
	},
	validations: {
		form: {
			soiskatel: {
				title: {
					required,
					minLength: minLength(2)
				},
				skype: {
					required,
					minLength: minLength(2)
				},
				//birthday: {},
				email: {
					email,
					minLength: minLength(2)
				},
				phone: {
					numeric,
					minLength: minLength(11),
					maxLength: maxLength(11)
				},
				url_1: {url},
			},
			results:{
				numeric,
				required
			}
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.$v.form.$touch()
	},
	mounted() {
		//console.log(this.id)
		this.loadCard()
	},
	watch: {
		city() {
			return this.form.soiskatel.city
		}
	},
	methods: {
		formSubmit() {

			this.$v.form.$touch()

			//console.log(this.$v.form.$error)

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: this.language.msg.EmptyRequiredFields,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				// do your submit logic here
				this.submitStatus = 'PENDING'

				// отправка формы здесь
				let param = {};

				param['action'] = 'edit'
				param.id = this.card

				for (let key in this.form) {
					if (this.form[key] && key !== 'schedule') {
						param[key] = this.form[key]
					}
				}

				param['schedule'] = this.form.schedule

				if(this.isAdmin){

					param.date_create = this.$moment(this.date_create).format('YYYY-MM-DD H:mm')

				}

				this.loading = true

				//console.log(param)

				axios.post(this.apiBaseURL + '/api/feedback/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.closeForm()
							this.$emit("update")

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.loading = false

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

				//console.log(this.submitStatus)

			}

		},
		loadCard() {

			this.loading = true

			//console.log(this.card)

			if (this.card > 0) {

				let param = {};

				param.id = this.card
				param.action = 'card'

				axios.post(this.apiBaseURL + '/api/feedback/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {
							//console.log(data.data)

							let feedback = data.data.result

							this.form = {
								action: "edit",
								sid: feedback.sid,
								soiskatel: {
									title: feedback.candidate.title,
									birthday: feedback.candidate.birthday,
									city: feedback.candidate.city,
									address: feedback.candidate.address,
									phone: feedback.candidate.phone,
									email: feedback.candidate.email,
									url_1: feedback.candidate.url_1,
									nationality: feedback.candidate.nationality,
									description: feedback.candidate.odescription,
								},
								project: parseInt(feedback.feedback.project),
								oldstep: feedback.feedback.results.id,
								results: 0,
								reason: feedback.feedback.reason,
								comment: feedback.feedback.comment,
								//schedule: feedback.feedback.schedule,
								scheduleid: feedback.feedback.schedule.id,
								task: {
									date: ""
								}
							}

							this.schedule = feedback.feedback.schedule
							this.resultstype = feedback.feedback.results.type
							this.reasonreq = feedback.feedback.results.type === 'lose'
							this.date_create = this.isAdmin ? this.$moment(feedback.feedback.date_create).format('DD.MM.YYYY H:mm') : null,

							this.calculateAge()
							this.prepareResults()

							this.$emit("loaded")

							this.loading = false

						},
					)
					.catch(error => {
						console.log(error)
					})

			} else {

				this.loading = false
				this.calculateAge()

				this.$emit("loaded")

			}

		},
		closeForm() {
			this.$emit("close")
		},
		clearInput(element) {
			this.form[element] = ''
		},
		clearSoiskatelInput(element) {
			this.form.soiskatel[element] = ''
			//console.log(this.form.soiskatel[element])
			//this.$forceUpdate();
		},
		selectReason(payload) {
			this.form.results = payload.id
			this.reasonreq = payload.type === 'win'
			this.resultstype = payload.type
			this.reasonreq = false

			if( this.resultstype === 'win' ){
				this.form.task.date = ''
				this.reason = ''
			}
			else if( this.resultstype === 'lose' ){
				this.form.task.date = ''
				this.schedule = ''
				this.reasonreq = true
			}
			else if( this.resultstype === 'task' ){
				this.reason = ''
				this.schedule = ''
				this.form.task.date = this.$moment().add(30, 'm').format('DD.MM.YYYY HH:mm')
			}
		},
		selectResult(payload) {

			console.log("result", payload)

			this.form.results = payload.id
			this.reasonreq = payload.type === 'win'
			this.resultstype = payload.type
			this.reasonreq = false

			if( this.resultstype === 'win' ){
				this.form.task.date = ''
				this.reason = ''
			}
			else if( this.resultstype === 'step' ){
				this.form.task.date = ''
				this.schedule = ''
				this.reasonreq = false
			}
			else if( this.resultstype === 'lose' ){
				this.form.task.date = ''
				this.schedule = ''
				this.reasonreq = true
			}
			else if( this.resultstype === 'task' ){
				this.reason = ''
				this.schedule = ''
				this.form.task.date = this.$moment().add(30, 'm').format('DD.MM.YYYY HH:mm')
			}

		},
		changeTaskDay(payload) {
			this.form.task.date = payload
		},
		cityChanged(payload) {

			if (payload !== undefined) {
				this.form.soiskatel.city = payload
			}

		},
		nationalityChanged(payload) {

			//console.log(payload)

			if (payload !== undefined) {
				this.form.soiskatel.nationality = payload
			}

		},
		addressChanged(payload) {

			//console.log("from address:", payload)

			if (payload.data !== undefined) {

				this.form.soiskatel.address = payload.value
				this.form.soiskatel.city = payload.data.city

				this.$refs.city.city = payload.data.city

			}

		},
		addressPointChanged(payload) {

			//console.log("from address:", payload)

			if (payload.id > 0) {

				this.form.addressname = payload.title
				this.form.address = payload.id
				this.form.project = payload.idproject

			}

		},

		calculateAge() {

			const formats = ["DD.MM.YYYY", "DD/MM/YYYY", "DD-MM-YYYY", "DD MM YYYY"]

			if (this.form.soiskatel.birthday !== '' && this.form.soiskatel.birthday !== null) {

				let castDate = this.$moment(this.form.soiskatel.birthday, formats)
				let a = this.$moment().diff(castDate, 'years', false)

				this.age = parseInt(a)

				if (this.age < 16) {

					//this.form.birthday = ''
					//this.age = 0

					this.$toast.warn({
						title: 'Attention',
						message: "Возраст менее 16 лет",
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})

				}

				//console.log(this.age)

			}

		},
		changeBirthday(date) {
			this.form.soiskatel.birthday = date
			this.calculateAge()
		},
		validatePhone() {

			if(this.form.soiskatel.phone !== undefined) {

				//this.form.soiskatel.phone = this.form.soiskatel.phone.replace(/\D/g, "")
				//console.log(this.form.soiskatel.phone)

				let phone = this.form.soiskatel.phone.split("")

				this.phonelength = phone.length

				if (phone.length > 0 && phone[0] !== '7') {
					this.form.soiskatel.phone = "7"
					this.phoneiscorrect = false
				} else if (phone.length > 0 && phone.length !== 11) {
					this.phoneiscorrect = false
				} else {
					this.phoneiscorrect = true
				}

				//this.form.soiskatel.phone = this.form.soiskatel.phone.replace(/\D/g, "")

			}

		},
		changeDateCreate(payload) {
			this.date_create = payload
		},

		dialogPlusLoaded() {
			this.dialogloaded = true
		},
		closeDialogPlus() {
			this.showdialogplus = false
			this.dialogplusloaded = false
			this.dialogpluswidth = 800

			this.showit = true
			this.schedulefor = 0

		},

		showScheduler() {

			if (this.form.address > 0) {

				this.schedulefor = parseInt(this.form.address)
				this.showdialogplus = true
				//this.showit = false

			} else {

				this.$toast.error({
					title: 'Error',
					message: 'Select Address first',
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

			}
		},
		selectSchedule(payload){

			this.showit = true
			this.schedulefor = 0

			this.form.schedule = payload[0] + ' ' + payload[1]

		},

		soiskatelChanged(payload){

			//console.log("soiskatel", payload)

			this.form.soiskatel = {
				sid: payload.id,
				title: payload.title,
				nationality: payload.nationality,
				email: payload.email,
				phone: payload.phones,
				city: payload.city,
				url_1: payload.url_1,
				description: payload.description,
				birthday: payload.birthday,
			}

			//if(payload.length > 0){

				/*this.form.sid = payload.id
				this.form.soiskatel.title = payload.title
				this.form.soiskatel.nationality = payload.nationality
				this.form.soiskatel.email = payload.email
				this.form.soiskatel.phone = payload.phones
				this.form.soiskatel.city = payload.city
				this.form.soiskatel.url_1 = payload.url_1
				this.form.soiskatel.description = payload.description
				this.form.soiskatel.birthday = payload.birthday*/

				//payload = {}

			//}

		},
		soiskatelChangedValue(field, payload){

			if(field === 'phone'){
				let newval = payload.replace(/\D/g, "")

				if(newval === ""){
					newval = "7"
				}

				this.form.soiskatel.phone = newval
				this.validatePhone()
			}
			else{
				this.form.soiskatel[field] = payload
			}

		},

		prepareResults(){

			let rez = []

			for (let key=0; key < this.guides.results.length; key++) {

				if (this.guides.results[key].id !== this.form.oldstep) {

					rez.push(this.guides.results[key])

				}

			}

			//console.log(rez)

			this.results = rez

		}
	}
}
</script>

<style>



</style>