<template>
	<div id="dialog_container_plus" class="dialog_container" :class="{'open':show}" ref="dialog_container_plus">

		<div class="dialog-preloader" :class="{'hidden':loaded}">
			<img src="/assets/images/rings.svg" width="128">
		</div>
		<div class="dialog" id="dialog_plus" :class="{'hidden':!loaded}" :style="{'width':width + 'px'}">
			<div class="close_plus" @click="closeDialog" title="Закрыть или нажмите ^ESC"><i class="icon-cancel"></i></div>
			<div id="resultdiv_plus" class="resultdiv">
				<slot name="dlgplusbody" @load="loadedDialog" @close="closeDialog"></slot>
			</div>
		</div>

	</div>
</template>

<script>
export default {
	name: "DialogPlus",
	props:{
		show: Boolean,
		loaded: Boolean,
		width: Number
	},
	watch:{
		loaded(){
			this.loaded = true
		}
	},
	methods:{
		closeDialog(){
			this.$emit("close")
		},
		loadedDialog(){
			//console.log("load")
			this.loaded = true
		},
	}
}
</script>

<style scoped>

.dialog{
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.dialog .resultdiv{
	max-height: 80vh;
	top: calc(10vh);
}

</style>