<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				Settings. Cron Manager
			</div>

		</div>

		<div class="page--body">

			<div class="infodiv mb20">
				<p>В этом разделе вы можете управлять заданиями для Crontab.</p>
				<div>Для успешного функционирования плагина следует добавить единственное задание в стандартный планировщик заданий:</div>

				<pre class="">* * * * * path/to/phpbin {{path}}cron/scheduler.php 1>> /dev/null 2>&1</pre>
			</div>

			<div class="mt20 p0">
				<a href="javascript:void(0)" @click="editItem(-1)" class="button bluebtn mb20"><i class="icon-plus-circled"></i> Добавить задание</a>
			</div>

			<div class="flex-container box--child p10 fs-09 Bold gray2 float bluebg-sub shadow sticked--top">

				<div class="flex-string w100 hidden-ipad">Дата</div>
				<div class="flex-string float">Название задания</div>
				<div class="flex-string w160 hidden-ipad">Периодичность</div>
				<div class="flex-string w180 hidden-ipad"></div>

			</div>

			<div v-for="item in list" :key="item.id" class="ha bgwhite border-bottom" :class="{'graybg gray no-border':!item.active}">

				<div class="flex-container typelist box--child p5 float">

					<div class="flex-string w100 p5">{{item.datum}}</div>
					<div class="flex-string float p5">
						<div class="Bold fs-12">{{item.name}}</div>
						<div class="fs-09 blue mt5">След. выполнение: {{item.next}}</div>
						<div class="mt15">{{item.bin}}</div>
					</div>
					<div class="flex-string w160 p5">{{item.parent}}</div>
					<div class="flex-string w180 text-right p5">

						<a href="javascript:void(0)" @click="editItem(item.id)" class="button small bluebtn dotted m0" title="Редактировать"><i class="icon-pencil"></i></a>
						<a href="javascript:void(0)" @click="deleteItem(item.id)" class="button small redbtn dotted m0" title="Удалить"><i class="icon-cancel-circled"></i></a>
						<a href="javascript:void(0)" @click="viewItem(item.id)" class="button small orangebtn dotted m0" title="Лог"><i class="icon-list-nested"></i></a>

					</div>

				</div>
				<div class="flex-container box--child p10">
					<div class="flex-string wp100 text-wrap viewdiv p0 pl10 pr10 fs-09">
						<pre class="text-wrap">{{item.cmd}} {{item.id}}</pre>
					</div>
				</div>

			</div>

			<div class="space-100"></div>

			<div class="mt20 p0 bottom-block">

				<A href="javascript:void(0)" @click="editItem(-1)" title="Добавить">
					<i class="icon-plus"></i>
				</A>

				<A href="javascript:void(0)" @click="load" title="Обновить" class="indigo ml5">
					<i class="icon-arrows-ccw"></i>
				</A>

			</div>

			<div v-if="loading" class="loading"></div>

		</div>

		<Dialog ref="dialog" :show="showdialog" :width="dialogwidth" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="(id > 0 || id === -1) && !viewit" v-slot:dlgbody>
				<CronmanForm :id="id" @close="closeDialog" @loaded="dialogLoaded" @update="load"/>
			</template>
			<template v-else-if="(id > 0 || id === -1) && viewit" v-slot:dlgbody>
				<CronmanViewForm :id="id" @close="closeDialog" @loaded="dialogLoaded" @update="load"/>
			</template>
		</Dialog>

	</div>

</template>

<script>

import axios from "axios";
import Dialog from "@/components/modal/Dialog"
import CronmanForm from '@/pages/settings/CronmanForm.vue'
import CronmanViewForm from '@/pages/settings/CronmanViewForm.vue'

export default {
	name: "CronmanSet",
	components:{
		Dialog,
		CronmanForm,
		CronmanViewForm
	},
	data(){
		return {
			list: {},
			path: null,
			loading: false,
			showsubpanel: false,
			showdialog: false,
			dialogloaded: false,
			dialogwidth: 700,
			id: 0,
			viewit: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language
		}
	},
	created() {
		//console.log(this.guides)
	},
	mounted() {
		this.load()
	},
	methods: {
		load() {

			this.loading = true

			let param = {};

			param.action = 'list'

			axios.post(this.apiBaseURL + '/api/cronman/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {
						//console.log(data.data)

						this.list = data.data.result.list
						this.path = data.data.result.path

						this.$emit("loaded")

						this.loading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		editItem(payload) {
			this.id = parseInt(payload)
			this.viewit = false
			this.dialogwidth = 700
			this.showdialog = true
		},
		viewItem(payload) {
			this.id = parseInt(payload)
			this.viewit = true
			this.dialogwidth = 700
			this.showdialog = true
		},
		deleteItem(payload){

			this.$swal.fire({
				title: "Are You Sure?",
				text: 'Item will be deleted forever',
				icon: 'question',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: '#3085D6',
				cancelButtonColor: '#D33',
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel"
			}).then((result) => {

				if (result.value) {

					// отправка формы здесь
					let param = {};

					param['id'] = payload
					param['action'] = "delete"

					axios.post(this.apiBaseURL + '/api/cronman/', param, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							(data) => {

								this.message = data.data.result.result

								this.load()
								//this.$emit("guide")
								this.closeDialog()

								this.$toast.success({
									title: 'Success',
									message: data.data.result.result,
									position: "bottom center",
									timeOut: 3000
								})

							},
						)
						.catch(error => {

							this.$toast.error({
								title: 'Error '+ error.code,
								message: "" + error.text,
								position: "bottom center",
								timeOut: 3000
							})

						})

				}

			});

		},
		closeDialog() {
			this.id = 0
			this.showdialog = false
			this.dialogwidth = 800
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Cron Manager / Settings',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.page--title{
	left: 20px;
	display: block;
	width: 200px !important;
	text-align: left;
}

@media (min-width : 768px) and (max-width : 1024px) {

	.page--title{
		left: 0 !important;
	}

}
</style>