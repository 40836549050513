<template>

	<div>

		<DIV class="zagolovok">Сотрудники по проектам</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabs" class="pl5 pr5">

				<div class="flex-container mt5 mb5"></div>

				<div class="flex-container mt5 mb5">

					<div v-for="project in form.user" :key="project.id" class="flex-string wp100 relative mt10 material like-input pt20">

						<div v-for="user in project.users" :key="user.id" class="boxx">

							<div class="infodiv p5 wp100 text-wrap fs-09 border-box relative mb2 ha" :title="user.name" :class="{'bgwhite':!form.user[project.id].users[user.id].selected}">
								<div class="checkbox">
									<label class="middle">
										<input class="taskss" type="checkbox" v-model="form.user[project.id].users[user.id].selected" :value="true">
										<span class="custom-checkbox hidden" :class="{'secondary':!form.user[project.id].users[user.id].selected}"><i class="icon-ok"></i></span>
										<div class="ellipsis wp85 h0" :class="{'gray':!form.user[project.id].users[user.id].selected}">
											<div class="fs-09 Bold">{{user.name}}</div>
											<div class="fs-07 blue">{{user.tip}}</div>
										</div>
									</label>
								</div>
							</div>

						</div>

						<div class="label">{{ project.name }}</div>

					</div>

				</div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<A href="javascript:void(0)" @click="formSubmit" class="button m0">Save</A>
				<A href="javascript:void(0)" @click="closeForm" class="button graybtn">Cancel</A>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
//import Vue from 'vue';
//Vue.forceUpdate();

export default {
	name: "UsersByProjectsForm",
	props: {
		// передается только для того, чтобы vue обновлял с сервера
		time: Object
	},
	data() {
		return {
			form: {
				user: {}
			},
			guides: this.$store.state.guides,
			projectList: this.$store.state.guides.projects,
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false,
			message: "",
		}
	},
	created() {
		this.loadCard()
	},
	watch: {
		time(){
			this.loadCard()
		}
	},
	methods: {
		loadCard() {

			this.$forceUpdate();

			this.loading = true

			let param = {};

			param.action = 'byproject'

			axios.post(this.apiBaseURL + '/api/users/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.form.user = data.data.result

						this.$emit("loaded")
						this.loading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		formSubmit() {

			let param = {}

			param = this.form.user
			param['action'] = 'byprojectedit'

			axios.post(this.apiBaseURL + '/api/users/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.message = data.data.result.result
						this.$emit("update")
						this.closeForm()

						this.$toast.success({
							title: 'Success',
							message: data.data.result.result,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

					},
				)
				.catch(error => {
					console.log(error)

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},
		closeForm() {
			this.$emit("close")
			//this.$destroy()
		},
	}
}
</script>

<style scoped>
.boxx{
	float: left;
	width: calc(25% - 2px);
	margin-right: 2px;
	margin-bottom: 2px;
	box-sizing: border-box;
}
.gray .blue{
	color: inherit;
}
</style>