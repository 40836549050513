<template>

	<div>

		<DIV class="zagolovok">Add/Edit Channel</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.name" id="name" class="required wp100" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.name.$error}">
						<label for="name">Title</label>

					</div>

				</div>

				<div class="flex-container mb10 box--child">

					<div class="flex-string wp100 mt10 relative material mradio">

						<ChannelRadio v-model="form.subid" :id="form.subid" :label="true" :name="'Main Channel'" :required="false" :exclude="[id]" :maxlevel="1" @select="channelChanged"/>

					</div>

				</div>

				<div class="flex-container mb10 box--child">

					<div class="flex-string wp100 mt10 relative material mradio">

						<project v-model="form.project" :id="parseInt(form.project)" :required="false" @select="selectProject"/>

					</div>

				</div>

				<div class="flex-container mt10 mb10">

					<div class="flex-string wp100 material">

						<input type="text" id="utm_source" v-model="form.utm_source" class="wp100" placeholder=" " autocomplete="off">
						<label for="utm_source">UTM SOURCE</label>

					</div>

				</div>

				<div class="flex-container mt10 mb10">

					<div class="flex-string wp100 material">

						<input type="text" v-model="form.destination" id="destination" class="wp100" placeholder=" " autocomplete="off">
						<label for="destination">Destination</label>

					</div>

				</div>

				<div class="flex-container mt10 mb10">

					<div class="flex-string wp100 material">

						<input type="text" v-model="form.email" id="email" class="wp100" placeholder=" " autocomplete="off">
						<label for="email">Email</label>

					</div>

				</div>

				<div class="flex-container mb10 mt10 box--child">

					<div class="flex-string wp100 relative material like-input">

						<div class="checkbox pl10">
							<label class="middle">
								<input class="taskss" v-model="form.default" type="checkbox" id="default" :value="true">
								<span class="custom-checkbox mt5"><i class="icon-ok"></i></span>
								<span class="Bold">Default</span>
							</label>
						</div>

					</div>

				</div>

				<div class="flex-container mt10 mb5 box--child">

					<div class="flex-string wp100 relative material like-input pt20">

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input type="radio" value="0" v-model="form.isarchive">
								<span class="custom-radio"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold blue">{{ language.Active }}</span>
							</label>
						</div>&nbsp;

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input type="radio" value="1" v-model="form.isarchive">
								<span class="custom-radio alert"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold red">{{ language.Archive }}</span>
							</label>
						</div>

						<div class="label">{{ language.Activity }}</div>

					</div>

				</div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<A href="javascript:void(0)" @click="formSubmit" class="button m0">Save</A>
				<A href="javascript:void(0)" @click="closeForm" class="button graybtn">Cancel</A>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
import Project from "@/components/elements/project";
import ChannelRadio from "@/components/elements/channel-radio";
import {required} from "vuelidate/lib/validators";

export default {
	name: "ChannelForm",
	props: {
		id: Number,
		// передается только для того, чтобы vue обновлял с сервера
		time: Object
	},
	components:{
		Project,
		ChannelRadio,
	},
	validations: {
		form: {
			name: {
				required,
				minValue: 1
			},
		}
	},
	data() {
		return {
			form: {
				id: this.id,
				name: "",
				project: null,
				subid: null,
				utm_source: "",
				destination: "",
				email: "",
				default: false,
				isarchive: 0
			},
			guides: this.$store.state.guides,
			projectList: this.$store.state.guides.projects,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			loading: false,
			message: "",
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.loadCard()
	},
	watch: {
		time(){
			this.loadCard()
		}
	},
	methods: {
		loadCard() {

			if(this.card > 0) {

				this.loading = true

				let param = {};

				param['id'] = this.card
				param['action'] = 'info'

				axios.post(this.apiBaseURL + '/api/channels/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							let rez = data.data.result

							this.form.id = rez.id
							this.form.name = rez.name
							this.form.project = rez.project
							this.form.subid = rez.subid
							this.form.utm_source = rez.utm_source
							this.form.destination = rez.destination
							this.form.email = rez.email
							this.form.default = rez.default

						},
					)
					.catch(error => {
						console.log(error)
					})

			}

			this.$emit("loaded")
			this.loading = false

		},
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: "Не заполнены обязательные поля",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				let param = {}

				param = this.form
				param['action'] = 'edit'
				param['id'] = this.card

				axios.post(this.apiBaseURL + '/api/channels/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.$emit("update")
							this.$emit("guide")
							this.closeForm()

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

			}

		},
		closeForm() {
			this.$emit("close")
			//this.$destroy()
		},
		channelChanged(payload) {
			if (payload !== undefined) {
				this.form.subid = payload
			}
		},
		selectProject(payload) {
			this.form.project = payload
		},
	}
}
</script>

<style scoped>

</style>