<template>

	<div>

		<BaseFilter v-model="projectFilter" @list="getList" :showprojects="true" ref="filters">
			<template v-slot:filters>
				<Filters v-model="pageFilters"></Filters>
			</template>
			<template v-slot:sorts>
				<Sorts v-model="sortFilters"></Sorts>
			</template>
			<template v-slot:title>
				Зарплаты
			</template>
		</BaseFilter>

		<div class="page--body" @click="closeAllFilters">

			<div v-if="loading" class="loading"></div>

			<h2 class="uppercase">Зарплаты</h2>

			<div class="wp100 mx-auto mt10 pt10">

				<div class="infodiv mb10">
					<div>Всего <b>{{ total }}</b> записей.</div>
					<div class="mt5" v-if="filters">
						<div v-if="filters.project && filters.project.length > 0" class="infodiv inline bgwhite mr5">{{language.Project}}: <b>{{filters.project.join(", ")}}</b></div>
						<div v-if="filters.city != null" class="infodiv inline bgwhite mr5">{{language.City}}: <b>{{filters.city}}</b></div>
					</div>
				</div>

				<div class="flex-content2 typelist marketingtarif p5 tbl--header sticked--top">

					<div class="flex-string2">ID</div>
					<div class="flex-string2">{{language.City}}</div>
					<div class="flex-string2">Зарплата</div>
					<div class="flex-string2">{{language.Project}}</div>
					<div class="flex-string2">{{language.Comment}}</div>
					<div class="flex-string2 text-center">{{language.Actions}}</div>

				</div>

				<div v-for="(item, index) in items" :key="index" class="flex-content typelist marketingtarif ha border-bottom p5" :class="{'disabled':item.archive}">
					<div class="flex-string">{{ item.id }}</div>
					<div class="flex-string">
						<div class="Bold ellipsis">{{ item.city }}</div>
					</div>
					<div class="flex-string">{{ item.zarplata }}</div>
					<div class="flex-string">
						<div class="Bold ellipsis">{{ item.projectName }}</div>
					</div>
					<div class="flex-string">
						<div class="Bold ellipsis">{{ item.zcomment }}</div>
					</div>
					<div class="flex-string text-right">

						<a @click="editItem(item.id)" :title="language.Edit" class="button dotted bluebtn m0">
							<i class="icon-pencil"></i>
						</a>
						<a @click="deleteItem(item.id)" :title="language.Delete" class="button dotted redbtn m0">
							<i class="icon-cancel-circled"></i>
						</a>

					</div>
				</div>

			</div>

			<div class="pagediv">

				<a href="javascript:void(0)" title="Первая" v-if="page > 1" @click="changePage(1)">&nbsp;<i class="icon-angle-double-left"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Предыдущая" v-if="page > 1" @click="changePage(page - 1)">&nbsp;<i class="icon-angle-left"></i>&nbsp;&nbsp;</a>
				{{ page }} из {{ pageall }}
				<a href="javascript:void(0)" title="Следующая" v-if="page >= 1 && page < pageall" @click="changePage(page + 1)">&nbsp;<i class="icon-angle-right"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Последняя" v-if="page < pageall && pageall > 1" @click="changePage(pageall)">&nbsp;<i class="icon-angle-double-right"></i>&nbsp;&nbsp;</a>

			</div>

			<div class="space-100"></div>

			<div class="mt20 p0 bottom-block" data-id="address">

				<A href="javascript:void(0)" @click="editItem(-1)" :title="language.Add">
					<i class="icon-plus"></i>
				</A>

				<A href="javascript:void(0)" @click="importZarplata" :title="language.Import" class="ml10 blue">
					<i class="icon-download-1"></i>
				</A>

				<A href="javascript:void(0)" @click="exportZarplata" :title="language.Export" class="ml10 red">
					<i class="icon-upload-1"></i>
				</A>

			</div>

		</div>

		<SubPanel ref="subpanel" :show="showsubpanel"></SubPanel>

		<Dialog ref="dialog" :show="showdialog" :width="600" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="(idcard > 0 || idcard === -1)" v-slot:dlgbody>
				<ZarplataForm :id="idcard" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="isimport" v-slot:dlgbody>
				<ZarplataImport @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
import axios from "axios"

import BaseFilter from "@/components/filters/BaseFilter"
import Filters from "@/pages/marketing/FiltersZarplata"
import Sorts from "@/pages/marketing/SortsZarplata"

import SubPanel from "@/components/modal/SubPanel"
import Dialog from "@/components/modal/Dialog"
import ZarplataImport from "@/pages/marketing/ZarplataImport.vue";
import ZarplataForm from "@/pages/marketing/ZarplataForm.vue";

export default {
	name: "MarketingZarplata",
	components: {
		ZarplataImport,
		ZarplataForm,
		Dialog,
		BaseFilter,
		Filters,
		Sorts,
		SubPanel,
	},
	data() {
		return {
			loading: false,
			filters: {},
			items: null,
			page: 1,
			pageall: 1,
			total: 0,
			dialog: {},
			idcard: 0,
			showsubpanel: false,
			showdialog: false,
			dialogloaded: false,
			dialogwidth: 800,
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			isimport: false,
		}
	},
	computed: {
		projectFilter:{
			get () {
				return {
					project: Array.isArray(this.$store.state.zarplata.filters.project) ? this.$store.state.zarplata.filters.project : [],
					order: this.$store.state.zarplata.filters.order !== '' ? this.$store.state.zarplata.filters.order : 'asc',
				}
			},

			set (value) {
				let newfilter = {
					project: value.project,
					order: value.order,
					zarplata: this.$store.state.zarplata.filters.zarplata,
					sort: this.$store.state.zarplata.filters.sort,
				}
				this.$store.commit("zarplata/SET_FILTERS", newfilter)
			}
		},

		sortFilters:{
			get () {
				return this.$store.state.zarplata.filters.sort !== '' ? this.$store.state.zarplata.filters.sort : 'zrp.city'
			},

			set (value) {
				let newfilter = {
					project: this.$store.state.zarplata.filters.project,
					zarplata: this.$store.state.zarplata.filters.zarplata,
					order: this.$store.state.zarplata.filters.order,
					sort: value
				}
				this.$store.commit("zarplata/SET_FILTERS", newfilter)
			}
		},

		pageFilters: {

			get () {
				return this.$store.state.zarplata.filters.zarplata
			},

			set (value) {
				let newfilter = {
					project: this.$store.state.zarplata.filters.project,
					zarplata: value,
					order: this.$store.state.zarplata.filters.order,
					sort: this.$store.state.zarplata.filters.sort,
				}
				this.$store.commit("zarplata/SET_FILTERS", newfilter)
			}
		},

		marketing: {
			get () {
				return this.$store.state.zarplata.filters.zarplata
			},

			set (value) {
				this.$store.commit("zarplata/SET_FILTERS", value)
			}
		},

		projects: {
			get() {
				return Array.isArray(this.$store.state.zarplata.filters.project) ? this.$store.state.zarplata.filters.project : []
			},
			set(value) {
				this.$store.commit("zarplata/SET_FILTER_PROJECTS", value)
			}
		},

		order: {
			get () {
				return this.$store.state.zarplata.filters.order !== '' ? this.$store.state.zarplata.filters.order : 'zrp.city'
			},
			set(value) {
				this.$store.commit("zarplata/SET_FILTER_ORDER", value)
			}
		}
	},
	comments: {},

	created() {
		this.getList()
		this.pageTitle = this.language.Marketing.Tarifs
	},

	watch: {
		// при изменениях маршрута запрашиваем данные снова
		routes: 'getList'
	},

	methods: {
		updateFilters() {
			this.getList()
		},
		getList() {

			let param = [];

			param = JSON.parse(JSON.stringify(this.$store.state.zarplata.filters))

			let marketing = param.zarplata

			for (let key in marketing) {
				if (marketing[key]) {
					param[key] = marketing[key]
				}
			}

			param['page'] = this.$store.state.zarplata.zarplata.page
			param['limit'] = 100
			param['action'] = "list"

			this.loading = true

			axios.post(this.apiBaseURL + '/api/zarplata/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {
					this.filters = data.data.result.filters
					this.items = data.data.result.list
					this.page = data.data.result.page
					this.pageall = data.data.result.pageall
					this.total = data.data.result.total
					this.editable = data.data.result.editor
					this.$store.commit('zarplata/SET_MEET', data.data.result)

					this.loading = false
				}
			)

		},
		updateList(){

			if( this.showsubpanel && this.idcard > 0 ){

				let c = this.idcard

				this.closeCard()

				let self = this;
				setTimeout(function() {
					self.viewCard(c)
				}, 100);

			}

			this.getList();

		},
		changePage(page) {
			this.page = page
			this.$store.commit("zarplata/CHANGE_PAGE", page)

			this.getList()
		},

		importZarplata() {
			this.idcard = 0
			this.showdialog = true
			this.isimport = true
			this.dialogwidth = 500
		},
		exportZarplata(){

			axios.post(this.apiBaseURL + '/api/zarplata/', {'action': 'export'}, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						window.open(this.apiBaseURL + '/' + data.data.result.url)

					},
				)
				.catch(error => {

					this.loading = false

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},

		editItem(payload){
			this.idcard = parseInt(payload)
			this.showdialog = true
			this.isimport = false
		},
		deleteItem(payload) {

			this.$swal.fire({
				title: "Are You Sure?",
				text: 'Item will be deleted forever',
				icon: 'question',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: '#3085D6',
				cancelButtonColor: '#D33',
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel"
			}).then((result) => {

				if (result.value) {

					// отправка формы здесь
					let param = {};

					param.id = payload
					param.action = "delete"

					axios.post(this.apiBaseURL + '/api/zarplata/', param, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							(data) => {

								this.message = data.data.result.result
								this.getList()

								this.$toast.success({
									title: 'Success',
									message: data.data.result.result,
									position: "bottom center",
									timeOut: 3000
								})

							},
						)
						.catch(error => {

							this.$toast.error({
								title: 'Error '+ error.code,
								message: "" + error.text,
								position: "bottom center",
								timeOut: 3000
							})

						})

				}

			});

		},

		closeCard() {
			this.idcard = 0
			this.showsubpanel = false
			this.isimport = false
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
		closeDialog() {
			this.idcard = 0
			this.showdialog = false
			this.dialogwidth = 800
			this.isimport = false
		},
		closeAllFilters() {
			this.$refs.filters.closeAll()
		},

	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Marketing Tarifs',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.typelist.marketingtarif {
	display               : grid;
	grid-template-columns : 80px 250px 200px 250px auto 100px;
	grid-gap              : 10px 10px;
}
@media (min-width : 1000px) and (max-width : 1300px) {

	.typelist.marketingtarif {
		display               : grid;
		grid-template-columns : 80px 150px 150px 200px auto 100px;
		grid-gap              : 10px 10px;
	}

}
</style>