<template>

	<div>

		<div class="closer" :class="{'hidden':hidebuttons}" title="Закрыть" @click="closeCard"><i class="icon-cancel-circled"></i></div>

		<div v-if="loading" class="content-loading"></div>

		<div v-if="!loading" class="ui-card" :class="{'full':hidebuttons}" v-click-outside="closeCard">

			<div class="crd--title" :class="{'hidden':hidebuttons}">Карточка Отклика</div>

			<div class="crd--body">

				<div class="divider mt10 mb20">
					<div class="blue">{{language.FeedbackData}}</div>
				</div>

				<div class="bgwhite flex-vertical relative p0 border--bottom box--child" data-id="feedback">

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">ID</div>
						<div class="flex-string wp100">
							<b class="Bold">{{id}}</b>
						</div>

						<div v-if="editable && edit && !hidebuttons" class="pull-aright fs-12">
							<div class="inline hand roundicon" @click="editFeedback()" title="Редактировать">
								<i class="icon-pencil blue"></i>
							</div>
						</div>

					</div>

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">{{language.Dates.Create}}</div>
						<div class="flex-string wp100">
							<b class="Bold">{{feedback.rdate_create}}</b>
						</div>

					</div>

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">{{language.Vacancy}}</div>
						<div class="flex-string wp100">
							<b class="Bold">{{feedback.title}}</b>
						</div>

					</div>

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">{{language.City}}</div>
						<div class="flex-string wp100">
							<b class="Bold">{{feedback.city}}</b>
						</div>

					</div>

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">{{language.Channel}}</div>
						<div class="flex-string wp100">
							<b class="Bold">{{feedback.channelName}}</b>
						</div>

					</div>

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">{{language.TypeWork}}</div>
						<div class="flex-string wp100">
							<b v-if="feedback.type_work !== null" class="Bold">{{feedback.type_work}}</b>
							<b v-else class="Bold gray-blue">Не указано</b>
						</div>

					</div>

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">{{language.TypeLead}}</div>
						<div class="flex-string wp100">
							<b v-if="feedback.type_lead !== null" class="Bold">{{feedback.type_lead}}</b>
							<b v-else class="Bold gray-blue">Не указано</b>
						</div>

					</div>

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">{{language.Project}}</div>
						<div class="flex-string wp100">
							<b class="Bold">{{feedback.projectName}}</b>
						</div>

					</div>

				</div>

				<div class="divider mt20 mb5">
					<div class="red">{{language.WorkResult}}</div>
				</div>

				<div class="mt10 p5" data-id="result">

					<div class="ha infodiv p10 box--child mb2">

						<div class="flex-vertical wp100 inline hidden border--bottom">
							<div class="flex-content">

								<div class="flex-string wp100 gray fs-07"></div>
								<div class="flex-string wp95"></div>

							</div>
						</div>

						<div v-if="feedback.address !== null" class="flex-vertical wp100 inline border--bottom">
							<div class="flex-content mb10">

								<div class="flex-string wp100 gray fs-07">{{language.Address.Address}}</div>
								<div class="flex-string wp95">
									<b v-if="feedback.address.title !== null" class="Bold">{{feedback.address.title}}</b>
									<b v-else-if="feedback.address.title === null" class="Bold">--</b>
								</div>

							</div>
						</div>

						<div class="flex-vertical wp100 inline border--bottom">
							<div class="flex-content">

								<div class="flex-string wp100 gray fs-07">{{language.Date}}</div>
								<div class="flex-string wp100">
									<b v-if="feedback.rdate_end" class="Bold blue">{{feedback.rdate_end}}</b>
									<b v-else class="Bold blue">-</b>
								</div>

							</div>
						</div>

						<div class="flex-vertical wp65 inline border--bottom">

							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{language.Vacancy}}</div>
								<div class="flex-string wp95">
									<b class="Bold">{{feedback.title}}</b>
								</div>

							</div>
							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{language.Project}}</div>
								<div class="flex-string wp95">
									<b class="Bold blue">{{feedback.projectName}}</b>
								</div>

							</div>
							<div v-if="feedback.address !== null" class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{language.Address.Address}}</div>
								<div class="flex-string wp95">
									<b v-if="feedback.address.address !== null" class="Bold">{{feedback.address.address}}</b>
									<b v-else-if="feedback.address.address === null" class="Bold">--</b>
								</div>

							</div>

						</div>

						<div class="flex-vertical wp30 pl10 inline border--bottom">

							<div v-if="feedback.address !== null && feedback.address !== undefined" class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{language.City}}</div>
								<div class="flex-string wp95">
									<b v-if="feedback.address.city !== null" class="Bold">{{feedback.address.city}}</b>
									<b v-else-if="feedback.address.city === null" class="Bold">--</b>
								</div>

							</div>
							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{language.Result}}</div>
								<div class="flex-string wp95">
									<b v-if="feedback.results.title !== null" class="Bold" :class="feedback.results.color">{{feedback.results.title}}</b>
									<b v-if="feedback.results.title === null" class="Bold red">Не обработан</b>
								</div>

							</div>

							<div v-if="feedback.user !== null && feedback.user !== ''" class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{language.Operator}}</div>
								<div class="flex-string wp95 Bold blue">
									{{feedback.user}}
								</div>

							</div>

						</div>

						<div v-if="feedback.reason !== null && feedback.reason !== ''" class="flex-vertical wp100 inline border--bottom">
							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{language.Reason}}</div>
								<div class="flex-string wp100">
									<b v-if="feedback.reason !== ''" class="Bold">{{feedback.reason}}</b>
									<b v-if="feedback.reason === ''" class="Bold">--</b>
								</div>

							</div>
						</div>

						<div v-if="feedback.covidFull !== null" class="flex-vertical wp100 inline border--bottom">
							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">Covid Status</div>
								<div class="flex-string wp100">
									<b class="Bold blue">{{feedback.covidFull.title}}</b>
								</div>

							</div>
						</div>

						<div v-if="feedback.comment !== null && feedback.comment !== ''" class="flex-vertical wp100 inline border--bottom">
							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{language.Comment}}</div>
								<div class="flex-string wp100">
									<b v-if="feedback.comment !== ''" class="Bold">{{feedback.comment}}</b>
									<b v-if="feedback.comment === ''" class="Bold">--</b>
								</div>

							</div>
						</div>

						<div v-if="feedback.dropped" class="warning mt10">

							<i class="icon-cancel-circled red"></i> Запись была отклонена СолвоПро

						</div>

						<div v-if="feedback.schedule.id > 0" class="divider mt20 mb10">{{language.Meets}}</div>

						<div v-if="feedback.schedule.id > 0" class="grid grid-3-col">

							<div class="infodiv bgwhite dotted">
								<div class="gray fs-07">{{language.Date}}</div>
								<div class="mt5"><b class="Bold blue">{{feedback.schedule.date}}</b><br>в <span>{{feedback.schedule.time}}</span></div>
							</div>

						</div>

					</div>

				</div>

				<div v-if="task.length > 0" class="divider mt10 mb5">
					<div class=""><i class="icon-calendar-inv"></i>Задачи перезвонить</div>
				</div>

				<div v-for="item in task" :key="item.id" class="p5" data-id="tasks">

					<div class="ha infodiv p10 box--child mb2 relative" :data-id="item.id">

						<div class="pull-aright" style="top:25px">
							<span v-if="item.archive">
								<i class="icon-ok green fs-30"></i>
							</span>
						</div>

						<div class="flex-vertical wp100 hidden border--bottom">
							<div class="flex-content">

								<div class="flex-string wp100 gray fs-07"></div>
								<div class="flex-string wp100"></div>

							</div>
						</div>

						<div class="flex-vertical wp50 inline border--bottom">

							<div v-if="item.result !== null" class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{language.Result}}</div>
								<div class="flex-string wp95">
									<b class="Bold">{{item.result}}</b><i :class="[item.icon,item.color]"></i>
								</div>

							</div>
							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">Перезвонить в</div>
								<div class="flex-string wp100">
									<b v-if="item.time !== ''" class="Bold blue" v-html="item.time"></b>
									<b v-else class="Bold red">Передано в Dialer</b>
								</div>

							</div>

						</div>

						<div class="flex-vertical wp50 inline border--bottom mb10">

							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{language.Dates.Work}}</div>
								<div class="flex-string wp100">
									<b class="Bold blue">{{item.date_work}}</b>
								</div>

							</div>
							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{language.Operator}}</div>
								<div class="flex-string wp100">
									<b class="Bold blue">{{item.user}}</b>
								</div>

							</div>

						</div>

						<div class="flex-vertical wp50 inline border--bottom mb10">

							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{language.Vacancy}}</div>
								<div class="flex-string wp100">
									<b class="Bold blue">{{item.vacancy}}</b>
								</div>

							</div>
							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{language.Comment}}</div>
								<div class="flex-string wp100">
									<b v-if="item.comment !== ''" class="Bold">{{item.comment}}</b>
									<b v-if="item.comment === ''" class="Bold">--</b>
								</div>

							</div>

						</div>

						<div class="flex-vertical wp50 inline border--bottom mb10">

							<div class="flex-container mt10">

								<div class="flex-string wp100 gray2">{{language.TypeLead}}</div>
								<div class="flex-string wp100">
									<b v-if="item.type_lead !== null" class="Bold">{{item.type_lead}}</b>
									<b v-else class="Bold gray-blue">Не указано</b>
								</div>

							</div>
							<div class="flex-container mt10">

								<div class="flex-string wp100 gray2">{{language.Channel}}</div>
								<div class="flex-string wp100">
									<b class="Bold">{{item.channelName}}</b>
								</div>

							</div>

						</div>

					</div>

				</div>

				<div class="divider mt10 mb20">
					<div class="green">{{language.CandidateData}}</div>
				</div>

				<div class="bgwhite flex-vertical p0 border--bottom box--child">

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">ID</div>
						<div class="flex-string wp100">
							<b class="Bold">{{candidate.id}}</b>
						</div>

					</div>

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">{{language.Human.Name}}</div>
						<div class="flex-string wp100">
							<b class="Bold">{{candidate.title}}</b>
						</div>

						<div v-if="this.$store.state.user.settings.edit.candidate === 'on'" class="pull-aright fs-12">
							<div class="inline hand roundicon" @click="editCandidate(candidate.id)" title="Редактировать">
								<i class="icon-pencil blue"></i>
							</div>
						</div>

					</div>

					<div class="flex-container p5">

						<div class="flex-string wp100 gray2">{{language.Nationality}}</div>
						<div class="flex-string wp100">
							<span v-if="candidate.nationality !== null"><b class="Bold">{{candidate.nationality}}</b></span>
							<span v-if="candidate.nationality === null"><b class="Bold gray">Не указан</b></span>
						</div>

					</div>

					<div class="flex-container p5">

						<div class="flex-string wp100 gray2">{{language.Age}}</div>
						<div class="flex-string wp100">
							<span v-if="candidate.age !== null"><i class="icon-gift blue"></i><b class="Bold">{{candidate.age}}</b>, {{candidate.birthday}}</span>
							<span v-if="candidate.age === null"><i class="icon-gift gray"></i><b class="Bold gray">Не указан</b></span>
						</div>

					</div>

					<div class="flex-container p5">

						<div class="flex-string wp100 gray2">{{language.City}}</div>
						<div class="flex-string wp100">
							<span v-if="candidate.city !== ''"><i class="icon-direction blue"></i><b class="Bold">{{candidate.city}}</b></span>
							<span v-if="candidate.city === null"><i class="icon-direction gray"></i><b class="Bold gray">Не указан</b></span>
						</div>

					</div>

					<div class="flex-container p5">

						<div class="flex-string wp100 gray2">{{language.Address.Address}}</div>
						<div class="flex-string wp100">
							<b v-if="candidate.address !== null && candidate.address !== ''" class="Bold">{{candidate.address}}</b>
							<b v-if="candidate.address === null || candidate.address === ''" class="Bold gray">Не указан</b>
						</div>

					</div>

					<div v-if="candidate.emails.length > 0" class="flex-container p5">

						<div class="flex-string wp100 gray2">Email</div>
						<div class="flex-string wp100">
							<div v-for="item in candidate.emails" :key="item.email" class="ellipsis">
								<a href="javascript:void(0)" title="Написать сообщение"><i class="icon-mail-alt"></i>
									<b class="Bold">{{item.email}}</b></a>
							</div><br>
						</div>

					</div>

					<div class="flex-container p5">

						<div class="flex-string wp100 gray2">{{language.Phone}}</div>
						<div class="flex-string wp100">
							<span v-if="candidate.phone !== null"><i class="icon-phone-1 blue"></i><b class="Bold" data-type="phone">{{candidate.phone}}</b></span>
							<span v-if="candidate.phone === null" class="gray"><i class="icon-phone-1"></i><b class="Bold" data-type="phone">не указан</b></span>
						</div>

					</div>

					<div v-if="candidate.links.length > 0" class="flex-container p5">

						<div class="flex-string wp100 gray2">URL</div>
						<div class="flex-string wp100">
							<div v-for="item in candidate.links" :key="item.link" class="pt5 pb5">
								<a :href="item.link" :title="item.link" target="_blank"><i class="icon-doc-inv-alt blue"></i>{{item.link}}</a>
							</div>
						</div>

					</div>

					<div v-if="candidate.description !== ''" class="flex-container p5">

						<div class="flex-string wp100 gray2">{{language.Comment}}</div>
						<div class="flex-string wp100 infodiv">
							<b class="Bold" v-html="candidate.description">{{candidate.description}}</b>
						</div>

					</div>

					<div v-if="candidate.uid" class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">UID</div>
						<div class="flex-string wp100">
							<b class="Bold">{{candidate.uid}}</b>
						</div>

					</div>

					<div v-if="candidate.externalid !== null" class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">ExternalID</div>
						<div class="flex-string wp100">
							<div class="infodiv Bold">{{candidate.externalid}}</div>
						</div>

					</div>

					<div v-for="item in candidate.candidateids" :key="item.uid" class="flex-container p5 border-bottom relative">

						<div class="flex-string wp100 gray2">CandidateID</div>
						<div class="flex-string wp100">
							<div class="fs-10 Bold">{{item.uid}}</div>
							<div class="fs-07 blue">{{item.projectName}}</div>
						</div>

					</div>

				</div>

				<div class="space-50"></div>

			</div>

			<div v-if="!hidebuttons" class="crd--footer">

				<div class="flex-container box--child wp100">

					<div class="flex-string wp50 pl10">
						<A href="javascript:void(0)" @click="closeCard" class="button dotted graybtn"><i class="icon-cancel-circled"></i>{{language.Close}}</A>
					</div>

					<div v-if="editable && edit" class="flex-string wp50 text-right pr10">
						<A href="javascript:void(0)" @click="editFeedback" class="button dotted bluebtn"><i class="icon-pencil-circled"></i>{{language.Edit}}</A>
					</div>

				</div>

			</div>

		</div>

	</div>

</template>

<script>
import axios from "axios";

export default {
	name: "FeedbackCard",
	props: {
		id: Number,
		nobutton: {
			type: Boolean,
			default: () => false
		},
		editabble: {
			type: Boolean,
			default: () => true
		},
	},
	data() {
		return {
			feedback: {},
			candidate: {},
			task: {},
			schedules: {},
			loading: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			edit: this.$store.state.user.settings.edit.feedback === 'on'
		}
	},
	computed:{
		card(){
			return this.id
		},
		hidebuttons(){
			return this.nobutton !== undefined ? this.nobutton : false
		},
		editable(){
			return this.editabble !== undefined && !this.edit ? this.editabble : true
		}
	},
	watch:{
		id(){
			this.loadFeedback()
		}
	},
	created(){
		this.loadFeedback()
	},
	methods: {
		loadFeedback() {

			this.loading = true

			let param = {};

			param.id = this.card
			param.action = 'card'

			axios.post(this.apiBaseURL + '/api/feedback/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
				(data) => {
					this.feedback = data.data.result.feedback
					this.candidate = data.data.result.candidate
					this.task = data.data.result.task
					this.editabble = data.data.result.editor

					this.loading = false
				},
			).catch(error => {

				console.log(error)

			})

		},
		closeCard(){

			if( !this.hidebuttons ) {

				this.$emit("close")

				this.feedback = {
					feedback: {},
					vacancy: {},
					meets: {},
					schedules: {},
				}

			}

		},
		editFeedback(){
			this.$emit("edit", this.id)
		},
		editCandidate(payload){
			this.$emit("cedit", payload)
		}
	}
}
</script>

<style scoped>

</style>