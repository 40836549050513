<!--Большая карта для рабочего места Оператора-->
<template>

	<div class="block wp100 relative" style="height: 100%">

		<div class="mapsearch wp60">
			<address-suggestions id="mapsearch" v-model="word" :classes="'wp100 m0'" :label="false" :value="word" :creaner="false" @update="searchAddress" @keydown.enter.exact.stop.prevent="searchAddress"/>
			<div class="fs-09 hidden">Введите адрес и нажмите Enter</div>
		</div>

		<l-map ref="map"
			v-if="showMap"
			:zoom="zoom"
			:center="center"
			:options="mapOptions"
			style="height: 100%"
			@fullscreenchange="fullscreenChanged"
		>
			<l-control-fullscreen
				position="topleft"
				:options="fullscreenOptions"
			/>
			<l-tile-layer
				:url="url"
				:attribution="attribution"
			/>

			<l-marker v-for="item in addresses"
				:key="item.id"
				:lat-lng="[item.lat, item.lan]"

				:options="{
					spiderfyOnMaxZoom: false,
				}"
				@click="view(item)"
			>
				<l-icon
					:icon-url="icons[item.icon].iconUrl"
					:shadow-url="icons[item.icon].shadowUrl"
					:icon-size="icons[item.icon].iconSize"
					:icon-anchor="icons[item.icon].iconAnchor"
					:popup-anchor="icons[item.icon].popupAnchor"
					:shadow-anchor="icons[item.icon].shadowAnchor"
					:shadow-size="icons[item.icon].shadowSize" />

				<l-popup :key="item.id" :options="{autoClose:true, closeOnClick: true}" ref="popups" @show="popup">
					<div class="address-bullet">
						<div class="Bold fs-14">{{ item.projectname }}</div>
						<div class="Bold fs-10 blue">{{ item.title }}</div>
						<div class="fs-09">{{ item.address }}</div>
						<div v-if="item.metro !== '' && item.metro !== null" class="fs-09 gray">М. {{ item.metro }}</div>
						<div v-if="item.vacancy !== '' && item.vacancy !== null" class="infodiv p5 mt5">
							<div class="fs-09 gray">Потребность</div>
							<div class="Bold fs-10">{{ item.vacancy }}</div>
							<div class="Bold fs-10 blue">{{ item.content }}</div>
						</div>
						<div v-if="item.meet !== '' && item.meet !== null" class="attention mt5 p5">
							<div class="fs-09 gray">Адрес собеседования</div>
							<div class="fs-10 Bold">{{ item.meet }}</div>
						</div>
						<div @click="select(item)" class="fs-09 button bluebtn dotted m0 mt10" data-id="mapbullet">Выбрать адрес</div>
					</div>
				</l-popup>

			</l-marker>

			<l-marker v-if="marker !== null" :lat-lng="marker"></l-marker>

		</l-map>

	</div>

</template>

<script>

// https://vue2-leaflet.netlify.app/components/LMarker.html#demo

import {latLng} from "leaflet";
import {LMap, LTileLayer, LMarker, LPopup, LIcon} from "vue2-leaflet";
import LControlFullscreen from 'vue2-leaflet-fullscreen';
import AddressSuggestions from "@/components/elements/address-suggestions";
//import * as L from 'leaflet';

import {Icon} from 'leaflet';
import axios from "axios";
//import {KeyControl} from "@/mixins/key-controls";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
	name: "MapPoints",
	props: {
		centerpoint: {
			type: Object,
			default: function () {
				return {
					city: 'Москва',
					lat: 55.755814,
					lan: 37.617635
				}
			}
		},
		zoom:{
			type: Number,
			default: function(){
				return 12
			}
		},
		markers: Array,
		list: Object,
		keyword: String
	},
	mixins: [
		//KeyControl
	],
	components: {
		LMap,
		LTileLayer,
		LMarker,
		LPopup,
		LControlFullscreen,
		LIcon,
		AddressSuggestions,
	},
	data() {
		return {
			//zoom: 12,
			center: latLng(this.centerpoint.lat, this.centerpoint.lan),
			url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
			mapOptions: {
				zoomSnap: 0.5
			},
			showMap: true,
			fullscreenOptions: {
				title: {
					'false': 'Switch to full-screen view',
					'true': 'Exit full-screen mode',
				},
			},
			projects: this.$store.getters.guides.projects,
			apiBaseURL: this.$store.getters.apiBaseURL,
			marker: null
		}
	},
	computed: {
		icons(){

			let myNewIcon = {};

			for(let i in this.markers){

				myNewIcon[this.markers[i]] = {
					iconUrl: 'assets/images/map/location-'+ this.markers[i] +'.png',
					iconSize: [22, 32],
					iconAnchor: [22, 22],
					popupAnchor: [-9, -25],
					shadowSize: [68, 68],
					shadowAnchor: [30, 60],
					shadowUrl: 'assets/images/map/marker-shadow.png',
				}

			}

			return myNewIcon

		},
		word:{
			get(){
				return this.keyword
			},
			set(payload){
				this.$emit("update", payload)
			}
		},
		addresses:{
			get(){
				return this.list
			},
		}
	},
	created() {
		//console.log(this.icons)
		this.center = latLng(this.centerpoint.lat, this.centerpoint.lan)
	},
	mounted() {
		/*this.$refs.map.mapObject.on('add', (e) => {
			console.log(e)

		});*/
	},
	watch: {
		centerpoint() {

			if( !isNaN(this.centerpoint.lat) ) {
				this.center = latLng(this.centerpoint.lat, this.centerpoint.lan)
			}

		},
		keyword(){
			this.search()
		},
		list(){
			//console.log( this.list )
		}
	},
	methods: {
		fullscreenChanged() {
			if (this.$refs.map.mapObject.isFullscreen()) {
				console.log('Entered full-screen mode');
			} else {
				console.log('Left full-screen mode');
			}
		},
		update(payload) {
			if(payload !== undefined) {
				this.currentLatLan = [payload.lat, payload.lng]
				this.zoom = 16
				this.$emit('update', payload)
			}
			else{
				this.marker = null
				this.word = ''
			}
		},
		select(payload) {
			if(payload !== undefined) {
				this.center = latLng(payload.lat, payload.lan)
				this.zoom = 16
				this.$emit('select', payload)
			}
			else{
				this.marker = null
				this.word = ''
			}
		},
		view(payload){
			this.center = latLng(payload.lat, payload.lan)
		},
		search(){

			axios.get(location.protocol + '//nominatim.openstreetmap.org/search?format=json&q=' + this.keyword)
				.then(
					/*(data) => {
						console.log(data)
					},*/
				)
				.catch(error => {
					console.log(error)
				})

		},
		searchAddress(payload){

			//console.log("sas:", payload)

			if(payload !== undefined) {
				this.word = payload
				this.center = latLng(payload.data.geo_lat, payload.data.geo_lon)
				this.marker = latLng(payload.data.geo_lat, payload.data.geo_lon)
			}
			else{
				this.marker = null
				this.word = ''
			}

		},
		popup(e){
			console.log(e)
		}
	}
}
</script>

<style>
.leaflet-popup-content {
	margin : 10px !important;
}
</style>