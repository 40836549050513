<template>

	<div>

		<DIV class="zagolovok">{{ language.Add }}/{{ language.Edit }} {{language.Address.Address}}</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>
			<input type="hidden" v-model="form.action" id="action">
			<input type="hidden" v-model="id">

			<div id="formtabs" class="pr20 pl20">

				<div class="flex-container mb10 box--child">

					<div class="flex-string wp100 mt10 relative material mradio">

						<project v-model="form.project" :id="parseInt(form.project)" @select="selectProject" :required="true" :class="{'required--error':$v.form.project.$error}"/>

					</div>

				</div>

				<div class="flex-container mt10 mb5 box--child">

					<div class="flex-string wp100 relative material cleared">

						<input type="text" id="code" class="wp100" placeholder=" " autocomplete="off" v-model="form.code">
						<label for="code">{{ language.Code }}</label>
						<div class="clearinputs mr5" @click="clearInput('code')"><i class="icon-block red fs-14"></i></div>

					</div>

				</div>

				<div class="flex-container mt10 mb5 box--child">

					<div class="flex-string wp100 relative material cleared">

						<input type="text" id="title" class="required wp100" placeholder=" " autocomplete="off" v-model="form.title" :v="$v.form.title" :class="{'required--error':$v.form.title.$error}">
						<label for="title">{{ language.Title }}</label>
						<div class="clearinputs mr5" @click="clearInput('title')"><i class="icon-block red fs-14"></i>
						</div>

					</div>

				</div>

				<div class="flex-container mt10 mb5 box--child">

					<div class="flex-string wp100 relative material cleared">

						<TextareaAutosize
							class="wp100"
							id="email"
							placeholder=" "
							ref="description"
							v-model="form.email"
							v-html="form.email"
							:min-height="100"
							:max-height="200"
							spellcheck="false"
						/>
						<label for="email">Director Email</label>
						<div class="clearinputs mr5" @click="clearInput('email')"><i class="icon-block red fs-14"></i>
						</div>

					</div>

				</div>

				<div class="flex-container mt10 mb5 box--child">

					<div class="flex-string wp100 relative material cleared">

						<input type="text" id="externalid" class="wp100" @click="showSolvo" placeholder=" " autocomplete="off" v-model="form.externalid">
						<label for="externalid">ExternalID</label>
						<div class="clearinputs mr5" @click="clearInput('externalid')">
							<i class="icon-block red fs-14"></i></div>

					</div>

				</div>

				<div class="flex-container mt10 mb5 box--child">

					<div class="flex-string wp100 relative material like-input pt20">

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input type="radio" value="false" v-model="form.isarchive">
								<span class="custom-radio"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold blue">{{ language.Active }}</span>
							</label>
						</div>&nbsp;

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input type="radio" value="true" v-model="form.isarchive">
								<span class="custom-radio alert"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold red">{{ language.Archive }}</span>
							</label>
						</div>

						<div class="label">{{ language.Activity }}</div>

					</div>

				</div>

				<div class="flex-container mt10 mb0 box--child relative">

					<address-suggestions v-model="form.address" id="address" :required="true" :value="form.address" :forcity="form.city" @update="addressChanged" :class="{'required--error':$v.form.address.$error}"/>

				</div>

				<div class="flex-container mb5 box--child">

					<city-suggestions v-model="form.city" id="city" :value="form.city" :city="city" @update="cityChanged" ref="city"></city-suggestions>

				</div>

				<div class="flex-container mt10 mb5 box--child">

					<div class="flex-string wp100 relative material cleared">

						<input type="text" id="metro" class="wp100" placeholder=" " autocomplete="on" v-model="form.metro">
						<label for="metro">{{ language.Address.Metro }}</label>
						<div class="gray fs-07">Попробуем получить через DaData</div>
						<div class="clearinputs mr5" @click="clearInput('metro')"><i class="icon-block red fs-14"></i>
						</div>

					</div>

				</div>

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material cleared">

						<input type="text" id="content" class="wp100" placeholder=" " autocomplete="on" v-model="form.content">
						<label for="content">Дополнительно</label>
						<div class="gray fs-07">Любая доп.информация, которая будет выведена на карте Оператора (вместе с потребностью)</div>

					</div>

				</div>

				<div class="flex-container mt10 mb5 box--child">

					<div class="flex-string wp50 relative material cleared">

						<input type="text" id="lat" class="wp97" placeholder=" " autocomplete="on" v-model="form.lat">
						<label for="lat">{{ language.Address.Longitude }}</label>
						<div class="clearinputs mr15" @click="clearInput('lat')"><i class="icon-block red fs-14"></i>
						</div>

					</div>
					<div class="flex-string wp50 relative material cleared">

						<input type="text" id="lan" class="wp100" placeholder=" " autocomplete="on" v-model="form.lan">
						<label for="lan">{{ language.Address.Latitude }}</label>
						<div class="clearinputs mr5" @click="clearInput('lan')"><i class="icon-block red fs-14"></i>
						</div>

					</div>

				</div>

				<div class="wp100 mt10 mb5" id="fmap" style="height: 400px; width: 780px">

					<Map @update="changeLatLan" :latLan="[form.lat,form.lan]"></Map>

				</div>

				<div class="flex-container mt5 box--child hidden">

					<div class="flex-string wp100 relative material like-input">

						<div class="checkbox pl10">
							<label class="middle">
								<input type="checkbox" id="silence" value="false" v-model="form.silence">
								<span class="custom-checkbox mt5"><i class="icon-ok"></i></span>
								<span class="Bold">Обработать адрес через DaData</span>
							</label>
						</div>

					</div>

					<div class="infodiv mt10">
						<div>
							<b class="red">Внимание!</b> За обработку адресов может взиматься плата. Однако она помогает получить правильный Адрес и Город точки, ближайшую станцию Метро, а так же гео-координаты
						</div>
						<div class="mt5">
							<b class="red">Важно!</b> При включенной обработке импорт будет проходить дольше
						</div>
					</div>

				</div>

				<div class="divider mt20 mb20">{{ language.Schedule }}</div>

				<div class="flex-container mt10 mb5 box--child">

					<div v-for="(day, index) in sheduleDays" :key="day" class="flx-basis-33 wp30 relative material">

						<input type="text" class="wp97" value="" placeholder=" " autocomplete="on" v-model="form.schedule[index]">
						<label>{{ day }}</label>

					</div>

				</div>

				<div class="attention">
					<h2>Правила составления расписания:</h2>
					<ul class="flh-12">
						<li><b>Задается время начала и завершения</b> приема кандидатов</li>
						<li><b>Учитывается только полный час</b>, т.е. задавать время работы с 9:30 до 18:30
							<b class="red">не допустимо</b></li>
						<li>Заполняйте <b>только рабочее время</b>. Если какой-то день не рабочий - оставьте поле пустым
						</li>
						<li>Можно задать
							<b>несколько периодов времени</b> в течение одного дня недели используя разделитель "," (запятая)<br>
							<span class="broun">Например: <b>9-12,15-19</b></span>
						</li>
					</ul>
				</div>

				<div class="space-100"></div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<button @click="formSubmit" class="button"><i class="icon-ok"></i>{{ language.Save }}</button>
				<button @click="closeForm" class="button graybtn"><i class="icon-cancel"></i>{{ language.Cancel }}
				</button>

			</div>

		</form>

		<template v-if="showdialogplus">
			<SolvoAddress :project="form.project" @close="closeDialogPlus" @loaded="dialogPlusLoaded" @edit="selectSolvo"/>
		</template>

	</div>

</template>

<script>
import axios from "axios";
import {required, minLength} from 'vuelidate/lib/validators';

import Project from "@/components/elements/project";
import AddressSuggestions from "@/components/elements/address-suggestions";
import CitySuggestions from "@/components/elements/city-suggestions";
import SolvoAddress from "@/components/SolvoAddress";

import Map from "@/components/Map";


export default {
	name: "AddressForm",
	components: {
		Project,
		AddressSuggestions,
		CitySuggestions,
		Map,
		SolvoAddress
	},
	props: {
		id: Number,
	},
	data() {
		return {
			//loading: false,
			form: {
				card: this.id,
				action: "edit",
				project: null,
				title: "",
				content: "",
				code: "",
				city: "",
				address: "",
				metro: "",
				email: "",
				externalid: "",
				lat: 55.755814,
				lan: 37.617635,
				silence: true,
				isarchive: false,
				schedule: {}
			},
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			loading: false,
			city: "",
			message: "",
			submitStatus: "",

			showdialogplus: false,
			dialogplusloaded: false,
			dialogpluswidth: 800,
		}
	},
	validations: {
		form: {
			project: {
				required,
				minValue: 1
			},
			title: {
				required,
				minLength: minLength(2)
			},
			address: {
				required,
			}
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
		sheduleDays() {
			return this.language.WeekName
		}
	},
	created() {
		this.loadAddress()
	},
	watch: {
		city() {
			return this.form.city
		}
	},
	methods: {
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: "Не заполнены обязательные поля",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				// do your submit logic here
				this.submitStatus = 'PENDING'

				// отправка формы здесь
				let param = {};

				param.id = this.card

				for (let key in this.form) {
					if (this.form[key] /*&& key !== 'schedule'*/) {
						param[key] = this.form[key]
					}
				}

				this.loading = true

				axios.post(this.apiBaseURL + '/api/points/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.closeForm()
							this.$emit("update")

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {

						//error => this.status = error.response.data.status;
						console.log(error.response)

						this.loading = false
						this.closeForm()

						this.$toast.error({
							title: 'Error',
							message: error.response.status + ": " + error.response.statusText,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

				//console.log(this.submitStatus)

			}

		},
		loadAddress() {

			this.loading = true

			//console.log(this.id)

			if (this.id > 0) {

				let param = {};

				param.id = this.id
				param.action = 'info'

				axios.post(this.apiBaseURL + '/api/points/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {
							//console.log(data.data)

							let address = data.data.result

							this.form = {
								action: "edit",
								project: address.project,
								title: address.title,
								content: address.content,
								code: address.code,
								city: address.city,
								address: address.address,
								metro: address.metro,
								email: address.email,
								externalid: address.externalid,
								lat: address.lat,
								lan: address.lan,
								silence: true,
								isarchive: address.isarchive,
								schedule: address.schedule
							}

							this.$emit("loaded")

							this.loading = false

						},
					)
					.catch(error => {

						//error => this.status = error.response.data.status;
						console.log(error.response)

						this.loading = false
						this.closeForm()

						this.$toast.error({
							title: 'Error',
							message: error.response.status + ": " + error.response.statusText,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

			} else {

				this.form = {
					action: "edit",
					project: null,
					title: "",
					content: "",
					code: "",
					city: "",
					address: "",
					metro: "",
					email: "",
					externalid: "",
					lat: 55.755814,
					lan: 37.617635,
					silence: true,
					isarchive: false,
					schedule: {}
				}

				this.loading = false

				this.$emit("loaded")

			}

		},
		selectProject(payload) {
			//console.log("form prj:", payload)
			this.form.project = payload
		},
		closeForm() {
			this.$emit("close")
		},
		clearInput(element) {
			this.form[element] = '';
		},
		addressChanged(payload) {

			//console.log("from address:", payload)

			if (payload !== null && payload.data !== undefined) {

				this.form.address = payload.value
				this.form.lat = payload.data.geo_lat
				this.form.lan = payload.data.geo_lon
				this.form.city = payload.data.city

				this.$refs.city.city = payload.data.city
				// this.form.metro = payload.data.metro

			} else {

				this.form.address = ''

			}

		},
		cityChanged(payload) {

			if (payload !== undefined) {

				this.form.city = payload

			}

		},
		changeLatLan(payload) {
			this.form.lat = payload.lat
			this.form.lan = payload.lng
		},

		dialogPlusLoaded() {
			this.dialogloaded = true
		},
		closeDialogPlus() {
			this.showdialogplus = false
			this.dialogplusloaded = false
			this.dialogpluswidth = 800

			this.showit = true
			this.schedulefor = 0

		},
		showSolvo() {

			if (this.form.project === 2) {
				this.showdialogplus = true
			}

		},
		selectSolvo(payload) {

			//console.log(payload)

			this.showdialogplus = false

			this.form.address = payload['address']
			this.form.email = payload['email']
			this.form.title = payload['title']
			this.form.externalid = payload['directorId']
			this.form.city = payload['city']

		},
	}
}
</script>

<style>
input.required--error,
.required--error input,
.req.required--error {
	background : var(--red-lite);
}
</style>