<template>

	<div id="filter" class="w2002 top--filters">

		<ul class="ml20 text-center spaced">
			<li data-id="gfilter" title="Filters" class="popblock" :class="{'open': filterMenu}">
				<a href="javascript:void(0)" @click="toggleFilters">
					<i class="icon-search"></i>
				</a>
				<span class="flyitbox" data-id="status"></span>
				<span class="flyitbox" data-id="users"></span>
				<div class="popblock-menu width-unset w6001">
					<div class="popblock-items">

						<div class="gray-blue Bold p10 uppercase text-center">{{ language.SearchParameters }}</div>

						<div class="gcontainer filters" data-id="all" :class="{'one': !showprojects}">

							<div v-if="showprojects" class="gstring pl10 pr10">

								<div class="divider pt10 pb10">{{ language.ByProject }}</div>

								<div class="border--bottom">

									<div v-for="item in guides.projects" class="checkbox popblock-item" :class="{'hidden':!item.active && !$store.state.user.isAdmin}" :key="item.id">
										<label class="wp100 pl10 mt5 flex-container float" :title="item.name">
											<input id="project" type="checkbox" v-model="bfilter.project" :value="item.id" @change="updateFilter">
											<span class="custom-checkbox" :class="{'secondary':!item.active}"><i class="icon-ok"></i></span>
											<span class="flex-string float pl10 text-wrap">
												<span class="pull-right"><img :src="apiBaseURL + item.logofile" height="20px"></span>
												<span class="block Bold fs-11" :class="{'gray':!item.active}">{{ item.name }}</span>
											</span>
										</label>
									</div>

								</div>

							</div>
							<div v-if="showprojects" class="gstring divider-vertical"></div>
							<slot name="filters"></slot>

						</div>
						<hr>
						<div class="text-right pr10 pl10">
							<a href="javascript:void(0)" @click="formReset" class="button w120 orangebtn pull-left mb10 border-box m0" data-tip="gfilter">{{ language.Reset }}</a>
							<a href="javascript:void(0)" @click="formSubmit" class="button w120 mb10 border-box m0" data-tip="gfilter">{{ language.Apply }}</a>
							<a href="javascript:void(0)" @click="toggleFilters" class="button w120 graybtn mb10 border-box m0 ml5" data-tip="gfilter">{{ language.Close }}</a>
						</div>

					</div>
				</div>
			</li>
			<li data-id="sort" title="Sorting" class="popblock" :class="{'open': sortMenu}">
				<a href="javascript:void(0)" @click="toggleSorts">
					<i class="icon-sort-alt-down"></i>
				</a>
				<div class="popblock-menu w300">
					<div class="popblock-items">

						<div class="gray-blue Bold p10 uppercase text-center">{{ language.SortParameters }}</div>

						<div class="divider pt10 pb10">{{ language.Sorting }}</div>

						<slot name="sorts"></slot>

						<div class="divider pt10 pb10">{{ language.SortOrder }}</div>

						<div class="sorts Bold" data-id="address">

							<div class="radio popblock-item">
								<label class="wp100 p5">
									<input name="order" type="radio" v-model="bfilter.order" value="asc" @change="updateFilter">
									<span class="custom-radio"><i class="icon-radio-check"></i></span>
									<span class="pl10">{{ language.SortOrderASC }} <i class="icon-sort-alt-down pull-right" data-for="order"></i></span>
								</label>
							</div>

							<div class="radio popblock-item no-border">
								<label class="wp100 p5">
									<input name="order" type="radio" v-model="bfilter.order" value="desc" @change="updateFilter">
									<span class="custom-radio"><i class="icon-radio-check"></i></span>
									<span class="pl10">{{ language.SortOrderDESC }} <i class="icon-sort-alt-up pull-right" data-for="order"></i></span>
								</label>
							</div>

						</div>

						<hr>

						<div class="text-center">
							<a href="javascript:void(0)" @click="formSubmit" class="button wp90 mb10 border-box m0">{{ language.Apply }}</a>
						</div>

					</div>
				</div>
			</li>
			<li data-id="reload" :title="language.Reload">
				<a href="javascript:void(0)" @click="formSubmit">
					<i class="icon-arrows-ccw"></i>
				</a>
			</li>
		</ul>

		<div class="page--title disable--select">
			<slot name="title"></slot>
		</div>

	</div>

</template>

<script>

export default {
	name: "BaseFilter",
	model: {
		prop: "bfilter",
		event: "change"
	},
	props: {
		bfilter: {
			type: Object
		},
		order: Number,
		showprojects: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			mode: 'filter',
			guides: this.$store.getters.guides,
			//addressFilters: this.$store.getters.addressFilters,
			filterMenu: false,
			sortMenu: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
		}
	},
	computed: {

	},

	created() {
		this.guides = this.$store.getters.guides
	},
	methods: {
		updateFilter() {
			this.$emit("change", this.bfilter)
		},
		formSubmit() {

			this.$emit('list')

			this.filterMenu = false
			this.sortMenu = false

		},
		formReset() {

			if (this.$route.name === 'address') {
				this.$store.commit("addresses/SET_CLEAN_FILTERS")
			} else if (this.$route.name === 'candidates') {
				this.$store.commit("candidates/SET_CLEAN_FILTERS")
			} else if (this.$route.name === 'need') {
				this.$store.commit("needs/SET_CLEAN_FILTERS")
			} else if (this.$route.name === 'feedback') {
				this.$store.commit("feedbacks/SET_CLEAN_FILTERS")
			} else if (this.$route.name === 'meets' || this.$route.name === 'meets2') {
				this.$store.commit("meets/SET_CLEAN_FILTERS")
			} else if (this.$route.name === 'task') {
				this.$store.commit("tasks/SET_CLEAN_FILTERS")
			} else if (this.$route.name === 'tarif') {
				this.$store.commit("marketingtarifs/SET_CLEAN_FILTERS")
			} else if (this.$route.name === 'campaign') {
				this.$store.commit("marketing/SET_CLEAN_FILTERS")
			} else if (this.$route.name === 'tarif') {
				this.$store.commit("marketingtarifs/SET_CLEAN_FILTERS")
			} else if (this.$route.name === 'empty') {
				this.$store.commit("marketingempty/SET_CLEAN_FILTERS")
			} else if (this.$route.name === 'zarplata') {
				this.$store.commit("zarplata/SET_CLEAN_FILTERS")
			}
		},
		toggleFilters() {
			this.filterMenu = !this.filterMenu
			this.sortMenu = false
		},
		toggleSorts() {
			this.sortMenu = !this.sortMenu
			this.filterMenu = false
		},
		closeAll() {
			this.sortMenu = false
			this.filterMenu = false
		}
	}
}
</script>

<style scoped>

</style>