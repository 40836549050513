<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				Settings. Error log
			</div>

		</div>

		<div class="page--body">

			<div class="wp100 mx-auto mt10 pt10">

				<div class="flex-content errorlist p5 tbl--header sticked--top">

					<div class="flex-string">Дата</div>
					<div class="flex-string">Файл</div>

				</div>

				<div v-for="item in logs" :key="item.t" class="flex-content typelist errorlist ha border-bottom p5">

					<div class="flex-string Bold">
						<div><b>{{item.date}}</b> {{item.time}}</div>
						<div class="fs-07 blue">{{item.timezone}}</div>
					</div>
					<div class="flex-string text-break fs-09 flh-07" v-html="item.message"></div>

				</div>

			</div>

			<div class="space-100"></div>

			<div v-if="logs.length > 0" class="mt20 p0 bottom-block">

				<A href="javascript:void(0)" @click="getit" title="Скачать" class="blue ml5">
					<i class="icon-download"></i>
				</A>

				<A href="javascript:void(0)" @click="deleteit" title="Скачать" class="red ml5">
					<i class="icon-trash"></i>
				</A>

			</div>

		</div>

	</div>

</template>

<script>

import axios from "axios";

export default {
	name: "ErrorlogSet",
	components:{
	},
	data(){
		return {
			logs: {},
			hidden: false,
			loading: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language
		}
	},
	created() {
		//console.log(this.guides)
	},
	mounted() {
		this.load()
	},
	methods: {
		load() {

			this.loading = true

			let param = {};

			param.action = 'list'

			axios.post(this.apiBaseURL + '/api/error/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {
						//console.log(data.data)

						this.logs = data.data.result

						this.$emit("loaded")

						this.loading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		getit() {

			this.loader = true

			let param = {}

			param['action'] = "download"

			axios.post(this.apiBaseURL + '/api/error/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					window.open(this.apiBaseURL + '/cash/error/' + data.data.result);

					this.loader = false

				},
			).catch(error => {

				this.$toast.error({
					title: 'Error ' + error.code,
					message: "" + error.text,
					position: "bottom center",
					timeOut: 3000
				})

			})

		},
		deleteit() {

			this.loader = true

			let param = {}

			param['action'] = "clear"

			axios.post(this.apiBaseURL + '/api/error/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					//let rez = data.data.result

					this.$toast.success({
						title: 'Success',
						message: data.data.result,
						position: "bottom center",
						timeOut: 3000
					})

					this.load()
					this.loader = false

				},
			).catch(error => {

				this.$toast.error({
					title: 'Error ' + error.code,
					message: "" + error.text,
					position: "bottom center",
					timeOut: 3000
				})

			})

		}
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Errorlog / Settings',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.page--title{
	left: 20px;
	display: block;
	width: 200px !important;
	text-align: left;
}

@media (min-width : 768px) and (max-width : 1024px) {

	.page--title{
		left: 0 !important;
	}

}
</style>