<template>

	<div class="ydropDown wp100" :class="{'checked':checked, 'like-input':label, 'req':required}" ref="usersSelect" @click="toggle" v-click-outside="onHide">

		<span title="Показатель"><i class="icon-article-alt black hidden"></i></span>
		<span class="ydropText">{{ text }}</span>
		<i class="icon-down-open pull-aright arrow"></i>

		<div class="yselectBox mt2" style="max-height: 30vh; width:100%" v-if="show">

			<div v-if="label" class="ydropString yRadio" aria-disabled="true">
				<label class="mt5 gray-blue">
					<input type="radio" v-model="user" value="null" @change="update([])">
					<span class="custom-radio"><i class="icon-ok"></i></span>
					Необходимо выбрать
				</label>
			</div>
			<div v-for="item in userlList" class="ydropString ellipsis checkbox" :key="item.id" :class="{'bluebg-sub':parseInt(item.id) === user}">
				<label class="mt5">
					<input type="radio" v-model="user" :value="parseInt(item.id)" @change="update(item)">
					<span class="custom-radio"><i class="icon-ok"></i></span>
					<span class="pl10">{{ item.title }}</span>
				</label>
			</div>

		</div>
		<div v-if="label" class="label">{{ inputName }}</div>

	</div>

</template>

<script>

export default {
	name: "UsersRadio",
	model:{
		prop: "selected",
		event: "select"
	},
	props: {
		id: Number,
		label: Boolean,
		required: Boolean,
		name: String, // Label
		exclude: Array, // исключаемые
	},
	data(){
		return {
			guides: this.$store.state.guides,
			show: false,
			count: 0,
			checked: false,
			text: "",
			user: null,
		}
	},
	computed:{
		inputName() {
			return this.name !== undefined ? this.name : "User"
		},
		excludeID(){
			return this.exclude !== undefined ? this.exclude : []
		},
		// делаем, для того, чтобы исключить не нужные каналы
		userlList(){

			let vm = this

			let u = this.guides.users.filter(function (e) {
				return !vm.excludeID.includes(e.id)
			})

			//console.log(u)

			return u

		}
	},
	created() {
		this.user = this.id
	},
	mounted() {
		this.select()
	},
	watch: {
		id(){
			//console.log("id:", this.id)
			this.user = this.id
			this.select()
		},
		user() {
			this.user = this.id
			this.select()
			//console.log("watch: ", this.channel)
		}
	},
	methods:{
		select() {

			if (this.user > 0) {

				for (let key in this.guides.users) {
					if (this.guides.users[key].id === this.user) {
						//console.log(this.guides.channel[key])
						this.text = this.guides.users[key].title
						this.checked = true
						break
					}
				}
			}

		},
		update(selected){
			this.text = selected.id > 0 ? selected.title : ''
			this.checked = selected.id > 0
			this.channel = parseInt(selected.id)

			this.$emit("select", this.user)
		},
		toggle(){
			this.show = !this.show
		},
		onShow(){
			this.show = true
		},
		onHide(){
			this.show = false
		}
	}
}
</script>

<style scoped>
	span.custom-checkbox{
		top: initial;
	}
</style>