<template>

	<div>

		<DIV class="zagolovok">Export Feedback</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="flex-container float no-border box--child">

					<div class="flex-string wp100 mb20 Bold infodiv">
						Укажите период для экспорта по дате добавления Отклика в базу
					</div>

				</div>
				<div class="flex-container no-border box--child">

					<div class="flex-string text-center">

						<div class="inline period periodblock" id="periods">

							<i class="icon-calendar-1 fs-11"></i>
							<date-picker lang="ru" format="DD.MM.YYYY" :default-value="form.d1" v-model="form.d1" valueType="format" @input="changeD1">

								<template v-slot:input>
									<input type="text" id="d1" class="text-center blue Bold" placeholder=" " autocomplete="off" v-model="form.d1">
								</template>
								<template v-slot:icon-clear></template>
								<template v-slot:icon-calendar></template>

							</date-picker>
							&divide;
							<date-picker lang="ru" format="DD.MM.YYYY" :default-value="form.d2" v-model="form.d2" valueType="format" @input="changeD2">

								<template v-slot:input>
									<input type="text" id="d2" class="text-center blue Bold" placeholder=" " autocomplete="off" v-model="form.d2">
								</template>
								<template v-slot:icon-clear></template>
								<template v-slot:icon-calendar></template>

							</date-picker>

						</div>
						<div class="inline presets popblock" :class="{'open':popmenu}" v-click-outside="popmenuHide">

							<a href="javascript:void(0)" @click="popmenuToggle"><i class="icon-ellipsis-vert fs-10"></i></a>

							<div class="popblock-menu right w--content">

								<div class="popblock-items" data-action="period" data-goal="periods" data-select="month">
									<div class="popblock-item p10 pr20 border-bottom nowrap" @click="periodSelect('today')">{{language.Periods.today | capitalize}}</div>
									<div class="popblock-item p10 pr20 border-bottom nowrap" @click="periodSelect('yestoday')">{{language.Periods.yestoday | capitalize}}</div>
									<div class="popblock-item p10 pr20 border-bottom nowrap" @click="periodSelect('calendarweek')">{{language.Periods.week | capitalize}}</div>
									<div class="popblock-item p10 pr20 border-bottom nowrap" @click="periodSelect('calendarweekprev')">{{language.Periods.calendarweekprev | capitalize}}</div>
									<div class="popblock-item p10 pr20 border-bottom nowrap" @click="periodSelect('month')">{{language.Periods.month | capitalize}}</div>
									<div class="popblock-item p10 pr20 border-bottom nowrap" @click="periodSelect('prevmonth')">{{language.Periods.prevmonth | capitalize}}</div>
									<div class="popblock-item p10 pr20 border-bottom nowrap" @click="periodSelect('quart')">{{language.Periods.quartal | capitalize}}</div>
									<div class="popblock-item p10 pr20 border-bottom nowrap" @click="periodSelect('prevquart')">{{language.Periods.prevquartal | capitalize}}</div>
									<div class="popblock-item p10 pr20 border-bottom nowrap" @click="periodSelect('year')">{{language.Periods.year | capitalize}}</div>
								</div>

							</div>

						</div>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material like-input pt20">

						<div class="flex-container">

							<div v-for="item in guides.projects" class="flex-string wp50 border-box" :key="item.id">
								<div class="checkbox mb10 pl20 fs-09 ">
									<label class="middle">
										<input type="checkbox" class="taskss" v-model="form.project" :value="item.id">
										<span class="custom-checkbox mt5" :class="{'secondary':!item.isactive}"><i class="icon-ok"></i></span>
										<span class="" :class="{'gray':!item.isactive}">{{ item.name }}</span>
										<span v-if="!item.isactive" class="pl10"><i class="icon-eye-off gray" title="Не активен"></i></span>
									</label>
								</div>
							</div>

						</div>

						<hr>

						<div class="flex-container">

							<div class="flex-string wp50 border-box">
								<div class="checkbox mb10 pl20 fs-09 ">
									<label class="middle">
										<input class="taskss" v-model="checkall" type="checkbox" id="checkall" :value="true" @click="checkAll">
										<span class="custom-checkbox alert mt5"><i class="icon-ok"></i></span>
										<span class="red Bold">Все проекты</span>
									</label>
								</div>
							</div>

							<div class="flex-string wp50 border-box">
								<div class="checkbox mb10 pl20 fs-09 ">
									<label class="middle">
										<input class="taskss" v-model="checkactive" type="checkbox" id="checkactive" :value="true" @click="checkActive">
										<span class="custom-checkbox success mt5"><i class="icon-ok"></i></span>
										<span class="green Bold">Активные проекты</span>
									</label>
								</div>
							</div>

						</div>

						<div class="label">{{language.Project}}</div>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material like-input pt20">

						<div class="flex-container">

							<div v-for="item in guides.results" class="flex-string wp50 border-box" :key="item.id">
								<div class="checkbox mb10 pl20 fs-09 ">
									<label class="middle">
										<input class="taskss" v-model="form.results" type="checkbox" :value="item.id">
										<span class="custom-checkbox mt5"><i class="icon-ok"></i></span>
										<span>
											<div class="Bold" :class="item.color">{{item.title}}</div>
											<div class="title gray fs-07">{{item.typeName}}</div>
										</span>
									</label>
								</div>
							</div>

						</div>
						<div class="label">Результаты</div>

					</div>

				</div>

				<div class="mb10 mt20 box--child"></div>

			</div>

			<div class="flex-container box--child button--pane fs-12 Bold">

				<div class="flex-string wp50">

					<div class="checkbox ml10 mt10 inline">
						<label>
							<input id="short" type="checkbox" v-model="form.short">
							<span class="custom-checkbox"><i class="icon-ok"></i></span>
							<span class="Bold ml5"><b class="blue">Краткая выгрузка</b></span>
						</label>
					</div>

				</div>

				<div class="flex-string wp50 text-right">

					<button @click="formSubmit" class="button"><i class="icon-ok"></i>{{language.Save}}</button>
					<button @click="closeForm" class="button graybtn"><i class="icon-cancel"></i>{{language.Cancel}}</button>

				</div>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";

export default {
	name: "FeedbackExport",
	data() {
		return {
			form: {
				action: "export",
				project: [],
				results: [],
				short: false,
				d1: this.$moment().startOf('month').format('DD.MM.YYYY'),
				d2: this.$moment().endOf('month').format('DD.MM.YYYY')
			},
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false,
			message: "",
			showit: true,
			popmenu: false,
			checkall: false,
			checkactive: false,
			language: this.$store.state.language
		}
	},
	mounted() {
		//console.log(this.form)
		this.loading = false
		this.$emit("loaded")
	},
	methods: {
		formSubmit() {

			// отправка формы здесь
			let param = {};

			for (let key in this.form) {
				if (this.form[key]) {
					param[key] = this.form[key]
				}
			}

			this.loading = true

			param.d1 = this.$moment(this.form.d1, 'DD.MM.YYYY').format('YYYY-MM-DD')
			param.d2 = this.$moment(this.form.d2, 'DD.MM.YYYY').format('YYYY-MM-DD')

			//console.log(param)

			axios.post(this.apiBaseURL + '/api/feedback/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.closeForm()
						this.$emit("update")

						this.$toast.success({
							title: 'Success',
							message: data.data.result.message,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

					},
				)
				.catch(error => {
					console.log(error)

					this.loading = false

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

			//console.log(this.submitStatus)

		},
		closeForm() {
			this.$emit("close")
		},
		popmenuToggle(){
			this.popmenu = !this.popmenu
		},
		popmenuHide(){
			this.popmenu = false
		},
		periodSelect(period){

			switch (period){
				case "today":

					this.form.d1 = this.$moment().format('DD.MM.YYYY')
					this.form.d2 = this.$moment().format('DD.MM.YYYY')

					break;
				case "yestoday":

					this.form.d1 = this.$moment().subtract(1, 'days').format('DD.MM.YYYY')
					this.form.d2 = this.$moment().subtract(1, 'days').format('DD.MM.YYYY')

					break;
				case "calendarweek":

					this.form.d1 = this.$moment().weekday(1).format('DD.MM.YYYY')
					this.form.d2 = this.$moment().weekday(7).format('DD.MM.YYYY')

					break;
				case "calendarweekprev":

					this.form.d1 = this.$moment().subtract(1, 'week').weekday(1).format('DD.MM.YYYY')
					this.form.d2 = this.$moment().subtract(1, 'week').weekday(7).format('DD.MM.YYYY')

					break;
				case "month":

					this.form.d1 = this.$moment().startOf('month').format('DD.MM.YYYY')
					this.form.d2 = this.$moment().endOf('month').format('DD.MM.YYYY')

					break;
				case "prevmonth":

					this.form.d1 = this.$moment().subtract(1, 'months').startOf('month').format('DD.MM.YYYY')
					this.form.d2 = this.$moment().subtract(1, 'months').endOf('month').format('DD.MM.YYYY')

					break;
				case "quart":

					this.form.d1 = this.$moment().startOf('quarter').format('DD.MM.YYYY')
					this.form.d2 = this.$moment().endOf('quarter').format('DD.MM.YYYY')

					break;
				case "prevquart":

					this.form.d1 = this.$moment().subtract(1, 'quarter').startOf('quarter').format('DD.MM.YYYY')
					this.form.d2 = this.$moment().subtract(1, 'quarter').endOf('quarter').format('DD.MM.YYYY')

					break;
				case "year":

					this.form.d1 = this.$moment().startOf('year').format('DD.MM.YYYY')
					this.form.d2 = this.$moment().endOf('year').format('DD.MM.YYYY')

					break;
			}

			this.popmenuHide()

		},
		checkAll(){

			this.checkall = !this.checkall

			switch (this.checkall){
				case true:

					this.form.project = this.guides.projects.map(function (e) {
						return e.id;
					})

					break
				case false:

					this.form.project = []

					break
			}

		},
		checkActive(){

			this.checkactive = !this.checkactive

			switch (this.checkactive){
				case true:

					this.form.project = this.guides.projects.map(function (e) {
						if(e.active) {
							return e.id;
						}
					})

					break
				case false:

					this.form.project = []

					break
			}

		},
		changeD1(date) {
			this.form.d1 = date
		},
		changeD2(date) {
			this.form.d2 = date
		},
	}
}
</script>

<style scoped>
.mx-datepicker{
	width: initial !important;
}

@media (min-width : 1100px) {

	.periodblock{
		font-size: 0.90em !important;
	}

}
</style>