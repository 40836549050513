const state = {
	tasks: {
		//items: {},
		page: 1,
		total: 1,
		pageall: 1
	},
	filters: {
		project: [],
		tasks: {
			phone: '',
			users: [],
			results: [],
			archive: '',
			active: '',
			period: null
		},
		sort: "date_work",
		order: "desc"
	},
};

const mutations = {
	// address
	SET_FILTER_PROJECTS (state, projects) {
		state.filters.project = projects
	},

	SET_FILTER (state, tasks) {
		state.filters.tasks = tasks
	},

	SET_FILTER_ORDER (state, order) {
		state.filters.order = order
	},

	SET_FILTER_SORT (state, sort) {
		state.filters.sort = sort
	},

	SET_CLEAN_FILTERS (state) {
		state.filters = {
			project: [],
			tasks: {
				phone: '',
				users: [],
				results: [],
				archive: '',
				active: '',
				period: null
			},
			sort: "date_work",
			order: "desc"
		}
	},

	SET_MEET(state, payload) {
		//state.meets.items = payload.list
		state.tasks.page = payload.page
		state.tasks.pageall = payload.pageall
		state.tasks.total = payload.total
	},
	SET_FILTERS(state, payload) {
		state.filters = payload
	},

	CHANGE_PAGE(state, page) {
		state.tasks.page = page
	},
};

export default {
	namespaced: true,
	state,
	mutations
}