<template>

	<div class="ydropDown wp100" :class="{'checked':checked, 'like-input':label, 'req':required}" ref="channelSelect">

		<div class="wp100" @click="toggle">
			<span>{{ language.Channel }}</span>
			<span class="ydropCount">{{channel.length}} {{language.Selected}}</span>
			<span class="icon"><i class="icon-down-open pull-aright"></i></span>
		</div>

		<div class="yselectBox mt2" :style="'max-height: ' + height + 'px; width:100%'" v-if="show">

			<div class="right-text">
				<div @click="clearAll" class="yunSelect w0 inline" title="Снять выделение">
					<i class="icon-minus-circled"></i>Сбросить
				</div>
			</div>

			<div v-if="label" class="ydropString yRadio" aria-disabled="true">
				<label class="mt5 gray-blue" @click="update([])">
					<input type="checkbox" v-model="channel" value="null" @change="update([])">
					<span class="custom-checkbox"><i class="icon-ok"></i></span>
					Необходимо выбрать
				</label>
			</div>
			<div v-for="item in guides.channels" class="ydropString ellipsis checkbox" :key="item.id" :class="{'bluebg-sub':parseInt(item.id) === id}">
				<label class="mt5" @click="update(item)">
					<input type="checkbox" v-model="channel" :value="item.id" @change="update(item)">
					<span class="custom-checkbox"><i class="icon-ok"></i></span>
					<span v-if="item.ismain" class="inline" :class="{'pl10':item.ismain}"><span class="strelka w5 pl10 mr10"></span></span>
					{{item.name}}
				</label>
			</div>

		</div>
		<div v-if="label" class="label">{{ language.Channel }}</div>

	</div>

</template>

<script>
export default {
	name: "channel",
	model:{
		prop: "selected",
		event: "select"
	},
	props: {
		id: Number,
		label: Boolean,
		required: Boolean,
		selected: Array,
		height: {
			type: Number,
			default: 300
		}
	},
	data(){
		return {
			guides: this.$store.state.guides,
			language: this.$store.state.language,
			//channel: [],
			show: false,
			checked: false,
			text: ""
		}
	},
	computed:{
		channel:{
			get(){
				return JSON.parse(JSON.stringify(this.selected))
			},
			set(value){
				this.$emit("select", value)
			}
		}
	},
	created() {
		this.channel = Array.isArray(this.selected) ? JSON.parse(JSON.stringify(this.selected)) : []
	},
	methods:{
		update(){
			//this.$emit("select", this.channel)
		},
		toggle(){
			this.show = !this.show
		},
		open(){
			this.show = true
		},
		close(){
			this.show = false
		},
		clearAll(){
			this.channel = []
		}
	}
}
</script>

<style scoped>

</style>