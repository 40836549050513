<template>

	<div>

		<DIV class="zagolovok">Add/Edit User</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.title" id="title" class="required wp100" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.title.$error}">
						<label for="title">Name</label>

					</div>

				</div>

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material">

						<Datalist
							v-model="form.tip"
							:value="form.tip"
							:name="'Должность'"
							:items="tips"
							:required="true"
							@update="tipChanged"
						/>

					</div>

				</div>

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material mradio">

						<UsersRadio :label="true" :id="form.mid" :name="'Boss'" :exclude="[form.id]" @select="midChanged"></UsersRadio>

					</div>

				</div>

				<div class="divider mt20">
					Параметры авторизации
				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.login" id="login" class="required wp100" placeholder=" " autocomplete="off" @keyup="loginCheck" @change="loginCheck">
						<div v-if="!checkLogin && form.login !== ''" class="red">{{message.login}}</div>
						<div v-if="checkLogin && form.login !== ''" class="textalert green">
							<i class="icon-ok" title="Логин подходит"></i> Login is good!
						</div>
						<label for="login">Login</label>

					</div>

				</div>

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material">

						<input :type="passtype" v-model="form.password" id="password" class="wp100" :class="{'required': card === 0}" placeholder=" " autocomplete="off">
						<div class="showpass" id="showpass" @click="togglePass" @mouseout="togglePass">
							<i class="hand gray" :class="{'icon-eye':passtype === 'text','icon-eye-off':passtype === 'password'}" title="Посмотреть пароль"></i>
						</div>
						<label for="password">Password</label>
						<div class="fs-09 gray" v-if="card > 0">Заполняется при необходимости изменить пароль</div>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material like-input pt20">

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input v-model="form.secrty" type="radio" :value="'yes'">
								<span class="custom-radio"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold blue">Активен</span>
							</label>
						</div>

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input v-model="form.secrty" type="radio" :value="'no'">
								<span class="custom-radio alert"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold red">Не активен</span>
							</label>
						</div>

						<div class="label">Активен</div>

					</div>

				</div>

				<div class="divider mb20">
					Контактные данные
				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.email" id="email" class="required wp100" placeholder=" " autocomplete="off" :class="{'format--error':$v.form.email.$error}">
						<label for="login">Email</label>
						<div class="red fs-09" :class="{'hidden':!$v.form.email.$error}">Не корректный формат: не соответствует Email</div>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.phone" id="phone" class="wp100" placeholder=" " autocomplete="off">
						<label for="phone">Phone</label>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.phone_in" id="phone_in" class="wp100" placeholder=" " autocomplete="off">
						<label for="phone_in">Внутренний / добавочный номер</label>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.skype" id="skype" class="wp100" placeholder=" " autocomplete="on">
						<label for="skype">Skype</label>

					</div>

				</div>

				<div class="divider mb20">
					Проекты для назначения очереди вакансий
				</div>

				<div class="flex-container mb10 mt10 box--child">

					<div class="flex-string wp100 mt10 relative material like-input pt20">

						<div class="projectselect">

							<div v-for="item in projectList" :key="item.id" class="infodiv dotted" :class="{'bgwhite':!form.usersettings.project.includes(item.id)}">

								<div class="checkbox fs-07">

									<label class="middle">
										<input class="taskss" v-model="form.usersettings.project" type="checkbox" :value="item.id">
										<span class="custom-checkbox mt5 hidden" :class="{'secondary':!form.usersettings.project.includes(parseInt(item.id))}"><i class="icon-ok"></i></span>
										<span class="Bold" :class="{'gray':!form.usersettings.project.includes(item.id)}">{{ item.name }}</span>
									</label>

								</div>

							</div>

						</div>
						<div class="label">Projects</div>

					</div>

				</div>

				<div class="divider mb20">
					Права на действия
				</div>

				<div class="flex-container mb10 mt10 box--child">

					<div class="flex-string wp100 relative material like-input pt20 fs-09">

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.isadmin" type="checkbox" id="isadmin" :value="true">
								<span class="custom-checkbox" :class="{'secondary':!form.isadmin}"><i class="icon-ok"></i></span>
								<span class="" :class="{'gray':!form.isadmin}">Права администратора</span>
							</label>
						</div>

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.usersettings.guides" type="checkbox" :value="true">
								<span class="custom-checkbox" :class="{'secondary':!form.usersettings.guides}"><i class="icon-ok"></i></span>
								<span class="" :class="{'gray':!form.usersettings.guides}">Доступ в справочники</span>
							</label>
						</div>

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.usersettings.marketing" type="checkbox" :value="true">
								<span class="custom-checkbox" :class="{'secondary':!form.usersettings.marketing}"><i class="icon-ok"></i></span>
								<span class="" :class="{'gray':!form.usersettings.marketing}">Доступ в рекламе</span>
							</label>
						</div>

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.usersettings.import" type="checkbox" :value="true">
								<span class="custom-checkbox" :class="{'secondary':!form.usersettings.import}"><i class="icon-ok"></i></span>
								<span class="" :class="{'gray':!form.usersettings.import}">Может импортировать</span>
							</label>
						</div>

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.usersettings.export" type="checkbox" :value="true">
								<span class="custom-checkbox" :class="{'secondary':!form.usersettings.export}"><i class="icon-ok"></i></span>
								<span class="" :class="{'gray':!form.usersettings.export}">Может экспортировать</span>
							</label>
						</div>

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.usersettings.stat" type="checkbox" :value="true">
								<span class="custom-checkbox" :class="{'secondary':!form.usersettings.stat}"><i class="icon-ok"></i></span>
								<span class="" :class="{'gray':!form.usersettings.stat}">Доступ в статистику</span>
							</label>
						</div>

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.usersettings.approved" type="checkbox" :value="true">
								<span class="custom-checkbox" :class="{'secondary':!form.usersettings.approved}"><i class="icon-ok"></i></span>
								<span class="" :class="{'gray':!form.usersettings.approved}">Редактирование даты принятия</span>
							</label>
						</div>

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.usersettings.notask" type="checkbox" :value="true">
								<span class="custom-checkbox" :class="{'secondary':!form.usersettings.notask}"><i class="icon-ok"></i></span>
								<span class="" :class="{'gray':!form.usersettings.notask}">Не назначать задачи "Перезвонить/Недозвон"</span>
							</label>
						</div>

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.usersettings.onlytask" type="checkbox" :value="true">
								<span class="custom-checkbox" :class="{'secondary':!form.usersettings.onlytask}"><i class="icon-ok"></i></span>
								<span class="" :class="{'gray':!form.usersettings.onlytask}">Назначать ТОЛЬКО задачи "Перезвонить"</span>
							</label>
						</div>

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.usersettings.onlyndz" type="checkbox" :value="true">
								<span class="custom-checkbox" :class="{'secondary':!form.usersettings.onlyndz}"><i class="icon-ok"></i></span>
								<span class="" :class="{'gray':!form.usersettings.onlyndz}">Назначать ТОЛЬКО задачи "Недозвон"</span>
							</label>
						</div>

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.usersettings.noany" type="checkbox" :value="true">
								<span class="custom-checkbox" :class="{'secondary':!form.usersettings.noany}"><i class="icon-ok"></i></span>
								<span class="" :class="{'gray':!form.usersettings.noany}">Не назначать ничего</span>
							</label>
						</div>

						<div class="divider mb20">Редактирование</div>

						<div class="infodiv mb20">Эти параметры актуальны для пользователей БЕЗ прав администратора</div>

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.usersettings.edit.feedback" type="checkbox" :value="true">
								<span class="custom-checkbox" :class="{'secondary':!form.usersettings.edit.feedback}"><i class="icon-ok"></i></span>
								<span class="" :class="{'gray':!form.usersettings.edit.feedback}">Редактировать отклики</span>
							</label>
						</div>

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.usersettings.edit.address" type="checkbox" :value="true">
								<span class="custom-checkbox" :class="{'secondary':!form.usersettings.edit.address}"><i class="icon-ok"></i></span>
								<span class="" :class="{'gray':!form.usersettings.edit.address}">Редактировать адреса</span>
							</label>
						</div>

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.usersettings.edit.candidate" type="checkbox" :value="true">
								<span class="custom-checkbox" :class="{'secondary':!form.usersettings.edit.candidate}"><i class="icon-ok"></i></span>
								<span class="" :class="{'gray':!form.usersettings.edit.candidate}">Редактировать кандидата</span>
							</label>
						</div>

						<div class="checkbox mb20 pl20">
							<label class="middle">
								<input class="taskss" v-model="form.usersettings.edit.vacancy" type="checkbox" :value="true">
								<span class="custom-checkbox" :class="{'secondary':!form.usersettings.edit.vacancy}"><i class="icon-ok"></i></span>
								<span class="" :class="{'gray':!form.usersettings.edit.vacancy}">Редактировать потребности</span>
							</label>
						</div>

						<div class="label">Права</div>

					</div>

				</div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<A href="javascript:void(0)" @click="formSubmit" class="button m0" :class="{'disabled':!check}">Save</A>
				<A href="javascript:void(0)" @click="closeForm" class="button graybtn">Cancel</A>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
import {required, email, minLength} from "vuelidate/lib/validators";
import UsersRadio from "@/components/elements/users-radio";
import Datalist from "@/components/elements/datalist";

export default {
	name: "UserForm",
	props: {
		id: Number,
		// передается только для того, чтобы vue обновлял с сервера
		time: Object
	},
	components: {
		UsersRadio,
		Datalist
	},
	validations: {
		form: {
			title: {
				required
			},
			email: {
				email,
				required,
				minLength: minLength(5)
			},
		}
	},
	data() {
		return {
			form: {
				id: this.card,
				title: "",
				tip: "Оператор",
				mid: 0,
				login: "",
				secrty: "yes",
				email: "",
				phone: "",
				phone_in: "",
				skype: "",
				isadmin: false,
				usersettings: {
					project: [],
					guides: false,
					marketing: false,
					import: false,
					export: false,
					stat: false,
					approved: false,
					noany: false,
					notask: false,
					onlytask: false,
					onlyndz: false,
					edit: {}
				}
			},
			guides: this.$store.state.guides,
			projectList: this.$store.state.guides.projects,
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false,
			message: {},
			check: false,
			checkEmail: true,
			checkLogin: true,
			passtype: "password",
			tips: ['Стажёр','Оператор','Старший оператор','Аналитик','Супервайзер','Руководитель','Менеджер','Главный бухгалтер','Бухгалтер']
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.loadCard()
	},
	watch: {
		time() {
			this.loadCard()
		}
	},
	methods: {
		loadCard() {

			this.loading = true

			if (this.card > 0) {

				let param = {};

				param['id'] = this.card
				param['action'] = 'info'

				axios.post(this.apiBaseURL + '/api/users/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							let rez = data.data.result

							this.form.id = rez.id
							this.form.title = rez.title
							this.form.mid = rez.mid
							this.form.tip = rez.tip
							this.form.login = rez.login
							this.form.secrty = rez.secrty
							this.form.email = rez.email
							this.form.phone = rez.phone
							this.form.phone_in = rez.phone_in
							this.form.skype = rez.skype
							this.form.isadmin = rez.isadmin === 'on'
							//this.form.usersettings = rez.usersettings
							this.form.usersettings.project = rez.usersettings.project !== null && rez.usersettings.project !== undefined ? rez.usersettings.project : []
							this.form.usersettings.guides = rez.usersettings.guides === 'on'
							this.form.usersettings.marketing = rez.usersettings.marketing === 'on'
							this.form.usersettings.import = rez.usersettings.import === 'on'
							this.form.usersettings.export = rez.usersettings.export === 'on'
							this.form.usersettings.stat = rez.usersettings.stat === 'on'
							this.form.usersettings.approved = rez.usersettings.approved === 'on'
							this.form.usersettings.noany = rez.usersettings.noany === 'on'
							this.form.usersettings.notask = rez.usersettings.notask === 'on'
							this.form.usersettings.onlytask = rez.usersettings.onlytask === 'on'
							this.form.usersettings.onlyndz = rez.usersettings.onlyndz === 'on'
							this.form.usersettings.edit = {
								"feedback": rez.usersettings.edit.feedback === 'on',
								"candidate": rez.usersettings.edit.candidate === 'on',
								"address": rez.usersettings.edit.address === 'on',
								"vacancy": rez.usersettings.edit.vacancy === 'on',
							}

							//console.log(this.form)

							this.loginCheck()

							this.$emit("loaded")
							this.loading = false

						},
					)
					.catch(error => {
						console.log(error)
					})

			}

			this.$emit("loaded")
			this.loading = false

		},
		formSubmit() {

			if(this.check) {

				this.$v.form.$touch()

				if (this.$v.form.$error) {

					this.$toast.error({
						title: 'Error',
						message: "Не заполнены обязательные поля",
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})

					return

				} else {

					let param = {}

					param = this.form
					param['action'] = 'edit'
					param['id'] = this.card

					axios.post(this.apiBaseURL + '/api/users/', param, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							(data) => {

								this.message = data.data.result.result
								this.$emit("update")
								this.$emit("guide")
								this.closeForm()

								//this.$store.dispatch("loadGuides").then(()=>{})

								this.$toast.success({
									title: 'Success',
									message: data.data.result.result,
									position: "bottom center",
									timeOut: 3000,
									progressBar: true
								})

							},
						)
						.catch(error => {
							console.log(error)

							this.$toast.error({
								title: 'Error',
								message: error.text,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})
						})

				}

			}

		},
		closeForm() {
			this.$emit("close")
			//this.$destroy()
		},
		togglePass(){
			this.passtype = this.passtype === 'password' ? "text" : "password"
		},
		tipChanged(payload){
			this.form.tip = payload
		},
		midChanged(payload){
			this.form.mid = payload
		},
		loginCheck(){

			let param = {}

			param['action'] = "check"
			param['login'] = this.form.login
			param['id'] = this.card

			axios.post(this.apiBaseURL + '/api/users/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {
					let rez = data.data.result
					if(rez === 'ok'){
						this.checkLogin = true
						this.check = true
					}
					else{
						this.checkLogin = false
						this.check = false
						this.message.login = rez
					}
				},
			)

		},
		emailCheck(){

			let param = {}

			param['action'] = "check"
			param['email'] = this.form.email

			axios.post(this.apiBaseURL + '/api/users/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {
					this.notify = data.data.result.payload.list
				},
			)

		}
	}
}
</script>

<style scoped>

.projectselect {
	display               : grid;
	grid-template-columns : repeat(auto-fill, calc(33.33% - 5px));
	grid-gap              : 5px 5px;
}
.gray .blue{
	color: inherit;
}

</style>