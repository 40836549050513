import { render, staticRenderFns } from "./NeedExport.vue?vue&type=template&id=4d84a106&scoped=true&"
import script from "./NeedExport.vue?vue&type=script&lang=js&"
export * from "./NeedExport.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d84a106",
  null
  
)

export default component.exports