const state = {
	need: {
		page: 1,
		total: 1,
		pageall: 1
	},
	filters: {
		project: [],
		needs: {},
		sort: "city",
		order: "desc"
	},
};

const mutations = {
	// address
	SET_FILTER_PROJECTS (state, projects) {
		state.filters.project = projects
	},

	SET_FILTER (state, needs) {
		state.filters.needs = needs
	},

	SET_FILTER_ORDER (state, order) {
		state.filters.order = order
	},

	SET_FILTER_SORT (state, sort) {
		state.filters.sort = sort
	},

	SET_CLEAN_FILTERS (state) {
		state.filters = {
			project: [],
			needs: {
				content: '',
				city: '',
				isarchive: '',
				isactive: '',
			},
			sort: "city",
			order: "desc"
		}
	},

	SET_NEED(state, payload) {
		state.need.items = payload.list
		state.need.page = payload.page
		state.need.pageall = payload.pageall
		state.need.total = payload.total
	},
	SET_FILTERS(state, payload) {
		state.filters = payload
	},

	CHANGE_PAGE(state, page) {
		state.need.page = page
	},
};

export default {
	namespaced: true,
	state,
	mutations
}