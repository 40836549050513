<template>

	<div class="gstring pl10 pr10">

		<div class="divider filter pt10 pb10" data-id="address">{{language.Description}}, {{language.Human.FirstName}}</div>

		<div class="filterblock no-border relative cleared">

			<input type="text" id="content" class="wp100 p5 pl10 pr10" :placeholder="language.Description + ', ' + language.Human.FirstName" autocomplete="on" v-model="cfilter.content" @keyup="updateFilter">
			<div class="clearinputs" @click="clearInput('content')"><i class="icon-block-1 red"></i></div>

		</div>

		<div class="divider filter pt10 pb10" data-id="address">{{language.Phone}}</div>

		<div class="filterblock no-border relative cleared">

			<input type="text" id="phone" class="wp100 p5 pl10 pr10" :placeholder="language.Phone" autocomplete="on" v-model="cfilter.phone" @keyup="updateFilter" @focusout="updateFilter">
			<div class="clearinputs" @click="clearInput('phone')"><i class="icon-block-1 red"></i></div>

		</div>

		<div class="divider pt10 pb10">{{language.City}}</div>

		<div class="no-border relative cleared">

			<input type="text" id="city" class="wp100 p5 pl10 pr10" :placeholder="language.City" autocomplete="on" v-model="cfilter.city" @keyup="updateFilter" @focusout="updateFilter">
			<div class="clearinputs" @click="clearInput('city')"><i class="icon-block-1 red"></i></div>

		</div>

		<div class="divider pt10 pb10">{{ language.Users }}</div>

		<div class="no-border relative">

			<users v-model="cfilter.users" @select="updateFilter"/>

		</div>

		<div class="divider pt10 pb10">{{language.Other}}</div>

		<div class="filterblock no-border checkbox popblock-item" title="Archived" data-id="address">

			<label class="wp100 pl10 mt5 flex-container float">
				<input class="taskss" name="finished" type="checkbox" id="finished" value="true" v-model="cfilter.finished" @change="updateFilter">
				<span class="custom-checkbox"><i class="icon-ok"></i></span>
				<span class="flex-string float pl10 text-wrap">
					<span class="block Bold fs-11">{{language.Finished}}</span>
				</span>
			</label>

			<label class="wp100 pl10 mt5 flex-container float">
				<input class="taskss" name="pasted" type="checkbox" id="pasted" value="true" v-model="cfilter.pasted" @change="updateFilter">
				<span class="custom-checkbox"><i class="icon-ok"></i></span>
				<span class="flex-string float pl10 text-wrap">
					<span class="block Bold fs-11">{{language.Pasted}}</span>
				</span>
			</label>

		</div>

	</div>

</template>

<script>
import Users from "@/components/elements/users";
export default {
	name: "Filters",
	components: {Users},
	model:{
		prop: "cfilter",
		event: "change"
	},
	props:{
		cfilter:{
			type: Object,
			default: () => {
				return {
					content: '',
					phone: '',
					city: '',
					finished: '',
					old: '',
					approve: '',
					users: [],
				}
			}
		}
	},
	data(){
		return {
			guides: this.$store.getters.guides,
			language: this.$store.state.language,
		}
	},
	watch: {

	},
	computed:{
	},

	methods:{
		updateFilter(){
			//console.log("filter", this.cfilter)
			this.$emit("change", this.cfilter)
		},

		clearInput(element){
			this.$store.state.meets.filters.meets[element] = '';
		}
	},

}
</script>

<style scoped>

</style>