<template>

	<div>

		<BaseFilter v-model="projectFilter" @list="getList" ref="filters">
			<template v-slot:filters>
				<Filters v-model="pageFilters"></Filters>
			</template>
			<template v-slot:sorts>
				<Sorts v-model="sortFilters"></Sorts>
			</template>
			<template v-slot:title>
				Потребности без объявлений
			</template>
		</BaseFilter>

		<div class="page--body" @click="closeAllFilters">

			<div v-if="loading" class="loading"></div>

			<h2 class="uppercase">Потребности без объявлений</h2>

			<div class="wp100 mx-auto mt10 pt10">

				<div class="infodiv mb10">
					<div>Всего <b>{{ total }}</b> записей.</div>
					<div class="mt5" v-if="filters">
						<div v-if="filters.projects && filters.projects.length > 0" class="infodiv inline bgwhite mr5">{{language.Projects}}: <b>{{filters.projects.join(", ")}}</b></div>
						<div v-if="filters.city" class="infodiv inline bgwhite mr5">{{language.City}}: <b>{{filters.city}}</b></div>
						<div v-if="filters.task" class="infodiv inline bgwhite mr5"><b>Есть задачи к размещению</b></div>
					</div>
				</div>

				<div class="flex-content2 typelist marketing p5 tbl--header sticked--top">

					<div class="flex-string2">{{language.Project}}</div>
					<div class="flex-string2">{{language.City}}</div>
					<div class="flex-string2">{{language.Channel}}</div>
					<div class="flex-string2">Зарплата</div>
					<div class="flex-string2">{{language.Comment}}</div>
					<div class="flex-string2">{{language.Need}}</div>
					<div class="flex-string2 text-center">{{language.Actions}}</div>

				</div>

				<div v-for="(item, index) in items" :key="index" class="flex-content typelist marketing ha border-bottom p5" :class="{'redbg-sub': item.vacancyCount >= 5}" >
					<div class="flex-string">
						<div class="text-wrap blue"><i v-if="!item.projectActive" class="icon-attention-1 red" title="Проект отключен"></i>{{item.projectName}}</div>
					</div>
					<div class="flex-string">
						<div class="ellipsis">{{ item.city }}</div>
					</div>
					<div class="flex-string tagbox">
						<div class="ellipsis">{{ item.channelName }}</div><br>
						<div v-if="item.task > 0" class="Bold red1 mt10 tag">
							Разместить <a @click="deleteTask(item)" title="Удалить задачу"><i class="icon-cancel-circled"></i></a>
						</div>
					</div>
					<div class="flex-string">
						<div class="ellipsis">{{ item.summa }}</div>
					</div>
					<div class="flex-string">
						<div class="Bold ellipsis">{{ item.zcomment }}</div>
					</div>
					<div class="flex-string">
						<div class="Bold" :class="{'red': item.vacancyCount >= 5, 'blue': item.vacancyCount < 5}">{{ item.vacancyCount }}</div>
					</div>
					<div class="flex-string text-right">

						<a v-if="item.task === 0" @click="addTask(item)" title="Добавить задачу" class="button dotted redbtn m0">
							<i class="icon-attention-alt"></i>
						</a>

						<a @click="editItem(item)" :title="language.Add" class="button dotted greenbtn m0">
							<i class="icon-plus-circled"></i>
						</a>

					</div>
				</div>

			</div>

			<div class="pagediv hidden">

				<a href="javascript:void(0)" title="Первая" v-if="page > 1" @click="changePage(1)">&nbsp;<i class="icon-angle-double-left"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Предыдущая" v-if="page > 1" @click="changePage(page - 1)">&nbsp;<i class="icon-angle-left"></i>&nbsp;&nbsp;</a>
				{{ page }} из {{ pageall }}
				<a href="javascript:void(0)" title="Следующая" v-if="page >= 1 && page < pageall" @click="changePage(page + 1)">&nbsp;<i class="icon-angle-right"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Последняя" v-if="page < pageall && pageall > 1" @click="changePage(pageall)">&nbsp;<i class="icon-angle-double-right"></i>&nbsp;&nbsp;</a>

			</div>

			<div class="space-100"></div>

			<div class="mt20 p0 bottom-block" data-id="address">

				<A href="javascript:void(0)" @click="emptyExport" title="Экспорт потребностей без объявлений" class="ml10 red">
					<i class="icon-upload-1"></i>
				</A>

			</div>

		</div>

		<SubPanel ref="subpanel" :show="showsubpanel"></SubPanel>

		<Dialog ref="dialog" :show="showdialog" :width="dialogwidth" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="(idcard === -1)" v-slot:dlgbody>
				<CampaignForm :id="idcard" :project="project" :city="city" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
import axios from "axios"

import BaseFilter from "@/components/filters/BaseFilter"
import Filters from "@/pages/marketing/FiltersEmpty"
import Sorts from "@/pages/marketing/SortsEmpty"

import SubPanel from "@/components/modal/SubPanel"
import Dialog from "@/components/modal/Dialog"

import CampaignForm from "@/pages/marketing/CampaignForm";

export default {
	name: "MarketingEmpty",
	components: {
		Dialog,
		BaseFilter,
		Filters,
		Sorts,
		SubPanel,
		CampaignForm,
	},
	data() {
		return {
			loading: false,
			filters: {},
			items: null,
			page: 1,
			pageall: 1,
			total: 0,
			project: 0,
			city: "",
			idcard: 0,
			dialog: {},
			showsubpanel: false,
			showdialog: false,
			dialogloaded: false,
			dialogwidth: 600,
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language
		}
	},
	computed: {
		projectFilter:{
			get () {
				return {
					project: Array.isArray(this.$store.state.marketingempty.filters.project) ? this.$store.state.marketingempty.filters.project : [],
					order: this.$store.state.marketingempty.filters.order !== '' ? this.$store.state.marketingempty.filters.order : 'desc',
				}
			},

			set (value) {
				let newfilter = {
					project: value.project,
					order: value.order,
					marketingempty: this.$store.state.marketingempty.filters.marketingempty,
					sort: this.$store.state.marketingempty.filters.sort,
				}
				this.$store.commit("marketingempty/SET_FILTERS", newfilter)
			}
		},

		sortFilters:{
			get () {
				return this.$store.state.marketingempty.filters.sort !== '' ? this.$store.state.marketingempty.filters.sort : 'title'
			},

			set (value) {
				let newfilter = {
					project: this.$store.state.marketingempty.filters.project,
					marketingempty: this.$store.state.marketingempty.filters.marketingempty,
					order: this.$store.state.marketingempty.filters.order,
					sort: value
				}
				this.$store.commit("marketingempty/SET_FILTERS", newfilter)
			}
		},

		pageFilters: {

			get () {
				return this.$store.state.marketingempty.filters.marketingempty
			},

			set (value) {
				let newfilter = {
					project: this.$store.state.marketingempty.filters.project,
					marketingempty: value,
					order: this.$store.state.marketingempty.filters.order,
					sort: this.$store.state.marketingempty.filters.sort,
				}
				this.$store.commit("marketingempty/SET_FILTERS", newfilter)
			}
		},

		marketingempty: {
			get () {
				return this.$store.state.marketingempty.filters.marketingempty
			},

			set (value) {
				this.$store.commit("marketingempty/SET_FILTERS", value)
			}
		},

		projects: {
			get() {
				return Array.isArray(this.$store.state.marketingempty.filters.project) ? this.$store.state.marketingempty.filters.project : []
			},
			set(value) {
				this.$store.commit("marketingempty/SET_FILTER_PROJECTS", value)
			}
		},

		order: {
			get () {
				return this.$store.state.marketingempty.filters.order !== '' ? this.$store.state.marketingempty.filters.order : 'title'
			},
			set(value) {
				this.$store.commit("marketingempty/SET_FILTER_ORDER", value)
			}
		}
	},
	comments: {},

	created() {
		this.getList()
		this.pageTitle = this.language.Marketing.Campaign
	},

	watch: {
		// при изменениях маршрута запрашиваем данные снова
		routes: 'getList'
	},

	methods: {
		updateFilters() {

			this.getList()

		},

		getList() {

			let param = [];

			param = JSON.parse(JSON.stringify(this.$store.state.marketingempty.filters))

			let marketingempty = param.marketingempty

			for (let key in marketingempty) {
				if (marketingempty[key]) {
					param[key] = marketingempty[key]
				}
			}

			param['page'] = this.$store.state.marketingempty.marketingempty.page
			param['limit'] = 100
			param['action'] = "list.empty"

			this.loading = true

			axios.post(this.apiBaseURL + '/api/marketing/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {
					this.filters = data.data.result.filters
					this.items = data.data.result.list
					this.page = data.data.result.page
					this.pageall = data.data.result.pageall
					this.total = data.data.result.total
					this.$store.commit('marketingempty/SET_MEET', data.data.result)

					this.loading = false
				}
			)

		},
		updateList(){

			this.idcard = 0
			this.project = 0
			this.city = ""
			this.dialogwidth = 600

			this.getList();

		},
		changePage(page) {
			this.page = page
			this.$store.commit("marketingempty/CHANGE_PAGE", page)

			this.getList()
		},

		editItem(payload){

			console.log(payload)

			this.idcard = -1
			this.project = payload.project
			this.city = payload.city
			this.showdialog = true
		},
		emptyExport(){

			axios.post(this.apiBaseURL + '/api/marketing/', {'action': 'export.empty'}, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						window.open(this.apiBaseURL + '/' + data.data.result.url)

					},
				)
				.catch(error => {

					this.loading = false

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},
		addTask(payload){

			axios.post(this.apiBaseURL + '/api/marketing/', {'action': 'add.task', 'project': payload.project, 'channel': payload.channel, 'city': payload.city}, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					() => {

						this.getList()

					},
				)
				.catch(error => {

					this.loading = false

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},
		deleteTask(payload){

			this.$swal.fire({
				title: this.language.msg.YouSure,
				text: this.language.msg.RealyDeleteThis,
				icon: 'question',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: '#3085D6',
				cancelButtonColor: '#D33',
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel"
			}).then((result) => {

				if (result.value) {

					axios.post(this.apiBaseURL + '/api/marketing/', {'action': 'delete.task', 'project': payload.project, 'channel': payload.channel, 'city': payload.city}, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							() => {

								this.getList()

							},
						)
						.catch(error => {

							this.loading = false

							this.$toast.error({
								title: 'Error',
								message: error.text,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})
						})

				}

			});

		},

		closeCard() {
			this.idcard = 0
			this.project = 0
			this.city = ""
			this.showdialog = false
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
		closeDialog() {
			this.idcard = 0
			this.project = 0
			this.city = ""
			this.showdialog = false
			this.dialogwidth = 600
		},
		closeAllFilters() {
			this.$refs.filters.closeAll()
		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Marketing Empty',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.typelist.marketing {
	display               : grid;
	grid-template-columns : 200px 200px 150px 140px auto 80px 100px;
	grid-gap              : 10px 10px;
}
@media (min-width : 1000px) and (max-width : 1300px) {

	.typelist.marketing {
		grid-template-columns : 150px 150px 150px 140px auto 80px 100px;
	}

}
.tagbox .tag {
	border                : 1px dotted var(--red-dark);
	background            : var(--red-sublite);
}
.tagbox .tag:hover {
	border     : 1px solid var(--red-sublite);
	background : var(--red-dark);
	color: var(--white);
}
.tagbox .tag:hover a{
	color: var(--white);
}
</style>