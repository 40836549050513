<template>

	<div>

		<div v-if="!loading">

			<DIV class="zagolovok">Импорт потребности</DIV>

			<div class="flex-container mb10 box--child">

				<div class="flex-string wp100 mt10 relative material mradio">

					<project v-model="form.project" :id="parseInt(form.project)" @select="selectProject" :class="{'required--error':$v.form.project.$error}"/>

				</div>

			</div>

			<div class="flex-container mt10 box--child">

				<div class="flex-string wp100 relative mt10 material like-input pt20" :class="{'dragover':over}" ref="dropzone" @dragover="over = true" @dragleave="over = false" @dragover.prevent @drop.stop.prevent="onDrop">

					<div class="filebox hidden">
						<input type="file" ref="file" class="file required wp100" @change="validatefile">
					</div>

					<a @click="$refs.file.click()" class="button m0 mb5 fs-07" id="selectFile"><i class="icon-plus-circled"></i>Выбрать</a>

					<span class="pl20">или перетащите сюда</span>

					<div v-if="selectedFile" class="infodiv bgwhite1 mt10">
						Выбран файл <b class="blue">{{ selectedFile }}</b>
					</div>

<!--					выбор нескольких файлов-->
					<div v-for="item in selectedFiles" :key="item" class="attention bgwhite mt10">
						{{ item }}
					</div>

					<div class="label">Файл по шаблону</div>

					<div class="flex-string wp100 mt5 fs-07">
						Поддерживаются форматы: *.{{allowed.join(", *.").toUpperCase()}}
					</div>

				</div>

			</div>

			<div class="attention">
				<div class="mb10"><b class="red">Внимание!</b></div>
				<div>Вакансии импортируются по выбранному проекту кроме проектов KFC, KFC Amrest, Burger King. Убедитесь, что импортируются вакансии, составленные по
					<a href="https://hunter.100crm.ru/assets/templates/vacancyTemplate.xlsx" target="_blank" title="">шаблону</a>.
				</div>
				<div class="mt5">
					Для проектов <b>KFC IRB</b>, <b>KFC Amrest</b>, <b>Burger King</b> потребности импортируются вместе с адресами.
					<a @click="$emit('aimport')" class="button redbtn">Перейти</a>
				</div>
			</div>

			<hr>

			<div class="button--pane text-right fs-12 Bold">

				<button @click="formSubmit" class="button"><i class="icon-ok"></i>{{language.Save}}</button>
				<button @click="closeForm" class="button graybtn"><i class="icon-cancel"></i>{{language.Cancel}}</button>

			</div>

		</div>

		<div v-if="loading">

			<div class="">Обрабатываю запрос..</div>
			<div class="space-50"></div>
			<div class="loading"></div>

		</div>

	</div>

</template>

<script>
import {required} from "vuelidate/lib/validators";
import axios from "axios";
import Project from "@/components/elements/project";

export default {
	name: "NeedImport",
	components: {
		Project,
	},
	data() {
		return {
			form: {
				project: 0,
				action: "import",
				file: "",
				silence: false
			},
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			loading: false,
			message: "",
			submitStatus: "",
			selectedFile: '',
			selectedFiles: [],
			over: false,
			allowed: ["csv", "xlsx", "xls"]
		}
	},
	validations: {
		form: {
			project: {
				required,
				minValue: 1
			},
		},
		loading: false
	},
	mounted() {
		this.form = {
			project: 0,
			action: "import",
			file: "",
			silence: false
		}
		this.$emit("loaded")

	},
	watch: {},
	methods: {
		formSubmit() {

			this.$v.form.$touch()

			if (this.selectedFile === '') {

				this.$toast.error({
					title: 'Error',
					message: "Не выбран файл",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: "Не заполнены обязательные поля",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				// do your submit logic here
				this.submitStatus = 'PENDING'
				this.form.file = this.$refs.file.files[0];

				// отправка формы здесь
				let formData = new FormData();

				/*
				let countFiles = this.$refs.file.files.length;
				for (let i = 0; i < countFiles; i++) {
					formData.append('file['+i +']', this.$refs.file.files[i]);
				}
				*/

				formData.append('file', this.form.file);
				formData.append('action', this.form.action);
				formData.append('project', this.form.project);
				formData.append('silence', this.form.silence);
				formData.append('token', this.$store.state.token);

				this.$emit('hide')
				this.loading = true

				//console.log(formData);

				axios.post(this.apiBaseURL + '/api/needs/', formData, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'multipart/form-data',
						'Session': this.$store.state.user.session
					},
					timeout: 60000000,
				})
					.then(
						(data) => {

							this.loading = false
							this.message = data.data.result.result
							this.closeForm()
							this.$emit("update")

							console.log(data.data.result.result)

							//if( data.data.result.result )

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

				//console.log(this.submitStatus)

			}

		},
		selectProject(payload) {
			//console.log("form prj:", payload)
			this.form.project = payload
		},
		closeForm() {
			this.$emit("close")
		},
		clearInput(element) {
			this.form[element] = '';
		},
		validatefile() {

			let filePath = this.$refs.file.value;
			let fileName = this.$refs.file.files[0].name;
			let extn = filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase();
			let slctd = [];

			if (this.allowed.includes(extn)) {

				this.selectedFile = fileName

				if (typeof (FileReader) != "undefined") {

					let countFiles = this.$refs.file.files.length;

					//loop for each file selected for uploaded.
					for (let i = 0; i < countFiles; i++) {

						let reader = new FileReader()

						reader.onload = function () {

							slctd.push(fileName)

						}
						reader.readAsDataURL(this.$refs.file.files[i])

					}
					//this.selectedFiles = slctd

				} else {

					this.$toast.error({
						title: "Attention!",
						message: "Упс, ваш браузер совсем не поддерживает технологию предпросмотра FileReader.",
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					});

				}

			} else {

				this.$toast.error({
					title: "Attention!",
					message: "Не допустимый формат файла",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				});

			}

		},
		onDrop(event){

			let vm = this

			event.preventDefault();
			event.stopPropagation();

			this.over = false
			this.$refs.file.files = event.dataTransfer.files;

			//console.log(this.$refs.file.files)

			for (let i = 0; i < event.dataTransfer.files.length; i++) {
				let file = event.dataTransfer.files.item(i);
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = function () {
					vm.validatefile();
				};
			}

		}
	}
}
</script>

<style scoped>
.loading{
	z-index: 100;
}
.dragover {
	border: 3px dashed var(--blue) !important;
	background: var(--blueliter);
}
</style>