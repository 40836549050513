<template>

	<div>

		<DIV class="zagolovok">Add/Edit Standart Vacancy</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabse" class="pr10 pl10">

				<div v-if="card === 0" class="flex-container mb10 box--child">

					<div class="flex-string wp100 mt10 relative material mradio">

						<Project v-model="form.project" :id="parseInt(form.project)" @select="selectProject" :required="false"/>

					</div>

				</div>

				<div class="flex-container mt5 box--child">

					<div class="flex-string wp100 relative material">

						<TextareaAutosize
							class="wp100"
							id="reason"
							placeholder=" "
							ref="description"
							v-model="form.content"
							v-html="form.content"
							:min-height="100"
							:max-height="350"
							spellcheck="false"
						/>
						<label for="reason">List</label>
						<div class="fs-09 gray-dark">Каждый вариант указывать с новой строки</div>

					</div>

				</div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<A href="javascript:void(0)" @click="formSubmit" class="button m0">Save</A>
				<A href="javascript:void(0)" @click="closeForm" class="button graybtn">Cancel</A>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
import Project from "@/components/elements/project";

export default {
	name: "VacancyForm",
	components: {
		Project,
	},
	props: {
		id: Number,
		// передается только для того, чтобы vue обновлял с сервера
		time: Object
	},
	data() {
		return {
			form: {
				id: this.id,
				project: 0,
				content: ""
			},
			guides: this.$store.state.guides,
			projectList: this.$store.state.guides.projects,
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false,
			message: "",
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.loadCard()
	},
	watch: {
		time() {
			this.loadCard()
		}
	},
	methods: {
		loadCard() {

			this.loading = true

			let param = {};

			param['id'] = this.card
			param['action'] = 'info'

			axios.post(this.apiBaseURL + '/api/vacancy/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						let rez = data.data.result

						this.form.id = rez.id
						this.form.content = rez.list.join("\n")
						this.form.project = rez.project

						this.$emit("loaded")
						this.loading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		formSubmit() {

			let param = {}

			param = this.form
			param['action'] = 'edit'
			param['id'] = this.card

			axios.post(this.apiBaseURL + '/api/vacancy/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.message = data.data.result.result
						this.$emit("update")
						this.$emit("guide")
						this.closeForm()

						this.$toast.success({
							title: 'Success',
							message: data.data.result.result,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

					},
				)
				.catch(error => {
					console.log(error)

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},
		closeForm() {
			this.$emit("close")
			//this.$destroy()
		},
		selectProject(payload) {
			//console.log("form prj:", payload)
			this.form.project = payload
		},
	}
}
</script>

<style scoped>

</style>