const state = {
	marketingempty: {
		//items: {},
		page: 1,
		total: 1,
		pageall: 1
	},
	filters: {
		project: [],
		marketingempty: {
			city: '',
			channel: [],
			task: false
		},
		sort: "adr.city",
		order: "desc"
	},
};

const mutations = {
	// address
	SET_FILTER_PROJECTS (state, projects) {
		state.filters.project = projects
	},

	SET_FILTER (state, items) {
		state.filters.marketingempty = items
	},

	SET_FILTER_ORDER (state, order) {
		state.filters.order = order
	},

	SET_FILTER_SORT (state, sort) {
		state.filters.sort = sort
	},

	SET_CLEAN_FILTERS (state) {
		state.filters = {
			project: [],
			marketingempty: {
				city: '',
				channel: [],
				task: false
			},
			sort: "adr.city",
			order: "desc"
		}
	},

	SET_MEET(state, payload) {
		state.marketingempty.page = payload.page
		state.marketingempty.pageall = payload.pageall
		state.marketingempty.total = payload.total
	},
	SET_FILTERS(state, payload) {
		state.filters = payload
	},

	CHANGE_PAGE(state, page) {
		state.marketingempty.page = page
	},
};

export default {
	namespaced: true,
	state,
	mutations
}