<template>

	<div>

		<DIV class="zagolovok">Add/Edit Results</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.title" id="title" class="required wp100" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.title.$error}">
						<label for="title">Title</label>

					</div>

				</div>

				<div class="flex-container mt10 mb5 box--child">

					<div class="flex-string wp100 relative material like-input pt20">

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input v-model="form.type" type="radio" :value="'win'">
								<span class="custom-radio success"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold green">Согласие</span>
							</label>
						</div>

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input v-model="form.type" type="radio" :value="'task'">
								<span class="custom-radio"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold">Перезвонить</span>
							</label>
						</div>

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input v-model="form.type" type="radio" :value="'na'">
								<span class="custom-radio"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold">Недозвон</span>
							</label>
						</div>

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input v-model="form.type" type="radio" :value="'lose'">
								<span class="custom-radio alert"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold red">Отказ</span>
							</label>
						</div>

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input v-model="form.type" type="radio" :value="'step'">
								<span class="custom-radio"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold blue">Следующий шаг</span>
							</label>
						</div>

						<div class="label">Тип результата</div>

					</div>

				</div>

				<div class="flex-container mb10 mt10 box--child">

					<div class="flex-string wp100 relative material like-input">

						<div class="checkbox pl10">
							<label class="middle">
								<input class="taskss" v-model="form.default" type="checkbox" :value="true">
								<span class="custom-checkbox mt5"><i class="icon-ok"></i></span>
								<span class="Bold">Default</span>
							</label>
						</div>

					</div>

				</div>

				<div class="flex-container mt5 box--child">

					<div class="flex-string wp100 relative material">

						<TextareaAutosize
							class="wp100"
							id="reason"
							placeholder=" "
							ref="description"
							v-model="form.reason"
							v-html="form.reason"
							:min-height="100"
							:max-height="350"
							spellcheck="false"
						/>
						<label for="reason">Reason</label>
						<div class="fs-09 gray-dark">Только для отказов. Каждый вариант указывать с новой строки</div>

					</div>

				</div>

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material">

						<input type="number" v-model="form.xorder" id="xorder" class="required wp100" placeholder=" " autocomplete="off">
						<label for="xorder">Порядок</label>

					</div>

				</div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<A href="javascript:void(0)" @click="formSubmit" class="button m0">Save</A>
				<A href="javascript:void(0)" @click="closeForm" class="button graybtn">Cancel</A>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
import {required} from "vuelidate/lib/validators";

export default {
	name: "ResultsForm",
	props: {
		id: Number,
		// передается только для того, чтобы vue обновлял с сервера
		time: Object
	},
	validations: {
		form: {
			title: {
				required
			},
		}
	},
	data() {
		return {
			form: {
				id: this.id,
				title: "",
				type: 'win',
				reason: null,
				xorder: this.$store.state.guides.results.length + 1,
				default: false
			},
			guides: this.$store.state.guides,
			projectList: this.$store.state.guides.projects,
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false,
			message: "",
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.loadCard()
	},
	watch: {
		time(){
			this.loadCard()
		}
	},
	methods: {
		loadCard() {

			if(this.card > 0) {

				this.loading = true

				let param = {};

				param['id'] = this.card
				param['action'] = 'info'

				axios.post(this.apiBaseURL + '/api/rezults/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							let rez = data.data.result

							this.form.id = rez.id
							this.form.title = rez.title
							this.form.type = rez.type
							this.form.reason = rez.reason
							this.form.xorder = rez.xorder
							this.form.default = rez.default

							this.$emit("loaded")
							this.loading = false

						},
					)
					.catch(error => {
						console.log(error)
					})

			}
			else{
				this.loading = false
			}

		},
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: "Не заполнены обязательные поля",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				let param = {}

				param = this.form
				param['action'] = 'edit'
				param['id'] = this.card

				axios.post(this.apiBaseURL + '/api/rezults/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.$emit("update")
							this.$emit("guide")
							this.closeForm()

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

			}

		},
		closeForm() {
			this.$emit("close")
			//this.$destroy()
		},
	}
}
</script>

<style scoped>

</style>