<template>

	<div class="filters Bold" data-id="address">

		<div class="radio popblock-item">
			<label class="wp100 p5">
				<input type="radio" value="zrp.city" v-model="sort" @change="updateFilter">
				<span class="custom-radio"><i class="icon-radio-check"></i></span>
				<span class="pl10">{{ language.City }}</span>
			</label>
		</div>

		<div class="radio popblock-item">
			<label class="wp100 p5">
				<input type="radio" value="prj.name" v-model="sort" @change="updateFilter">
				<span class="custom-radio"><i class="icon-radio-check"></i></span>
				<span class="pl10">{{ language.Project }}</span>
			</label>
		</div>

	</div>

</template>

<script>
export default {
	name: "Sorts",
	data() {
		return {
			language: this.$store.state.language,
		}
	},
	computed: {
		sort: {
			get() {
				return this.$store.state.zarplata.filters.sort !== undefined ? this.$store.state.zarplata.filters.sort : 'asc'
			},
			set(value) {
				//console.log("project", value)
				this.$store.commit("zarplata/SET_FILTER_SORT", value)
			}
		}
	},
	methods: {
		updateFilter() {
			//console.log(this.form)
			this.$emit("updateFilters", this.form)
		}
	},
}
</script>

<style scoped>

</style>