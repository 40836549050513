<template>

	<div class="ydropDown wp100" @click="toggle" :class="{'checked':checked, 'like-input':label, 'req':inputRequired}" ref="itemSelect">

		<span title="Показатель"><i class="icon-article-alt black hidden"></i></span>
		<span class="ydropText">{{ text }}</span>
		<i class="icon-down-open pull-aright arrow"></i>

		<div class="yselectBox" style="max-height: 250px; width:100%" v-if="show">
			<div class="ydropString yRadio" aria-disabled="true">
				<label class="wp95 gray-blue" @click="update({id:0})">
					<input type="radio" v-model="selected" value="null" @change="update({})">Необходимо выбрать
				</label>
			</div>
			<div v-for="item in ritems" class="ydropString yRadio" :key="item.id" :class="{'bluebg-sub':parseInt(item.id) === selected}">
				<label class="wp95" :class="item.color" @click="update(item)">
					<input type="radio" v-model="selected" :value="item.id" @change="update(item)">
					{{ item.title }}
				</label>
			</div>
		</div>
		<div class="label">{{ inputName }}</div>

	</div>

</template>

<script>

export default {
	name: "Result",
	props: {
		id: Number,
		label: Boolean,
		required: Boolean,
		name: String, // Label
		hidden: Array,
		results: Array
	},
	data() {
		return {
			guides: this.$store.state.guides,
			language: this.$store.state.language,
			selected: null,
			show: false,
			checked: false,
			text: "",
			ritems: this.results !== undefined && this.results.length > 0 ? this.results : this.$store.state.guides.results
		}
	},
	computed: {
		inputName() {
			return this.name !== undefined ? this.name : this.language.Result
		},
		inputRequired() {
			return this.required !== undefined ? this.required : true
		},
	},
	mounted() {

	},
	watch: {
		id() {
			//console.log("id:", this.id)
			this.selected = this.id
			this.select()
		},
		selected() {
			//console.log("selected d:", this.selected)
			this.selected = this.id
			this.select()
		}
	},
	created() {
		this.selected = this.id
		this.select()
	},
	methods: {
		select() {

			//console.log("selected a:", this.selected)

			if (this.selected > 0) {
				for (let key in this.guides.results) {
					if (this.guides.results[key].id === this.selected) {
						this.text = this.guides.results[key].title
						this.checked = true
						break
					}
				}
			}

		},
		update(selected) {

			this.text = selected.id > 0 ? selected.title : ''
			this.checked = selected.id > 0
			this.selected = selected.id

			//console.log("new prj:", this.project)

			this.$emit("select", selected)

		},
		toggle() {
			this.show = !this.show
		},
		open() {
			this.show = true
		},
		close() {
			this.show = false
		}
	}
}
</script>

<style scoped>

</style>