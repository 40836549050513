<script>
import { Bar, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
	name: "FeedbackWorkChart",
	extends: Bar,
	mixins: [reactiveProp],
	props: ['options'],
	computed:{},
	watch:{
		chartdata(){
			this.renderChart(this.chartData, this.options)
		}
	},
	mounted () {
		this.renderChart(this.chartData, this.options)
	},
	created() {

	}
}
</script>

<style scoped>

</style>