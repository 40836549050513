<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				Settings. Email Templates
			</div>

		</div>

		<div v-if="loading" class="content-loading"></div>

		<div v-if="!loading" class="page--body">

			<div class="page--body">

				<div class="infodiv mt10">

					<div class="mb10">
						В этом разделе вы можете управлять шаблонами email-сообщений для разных языков.
						<ul>
							<li>Отсутствующие шаблоны сгенерированы автоматически на основе шаблонов для Русского языка</li>
							<li>Удаление шаблонов производится при удалении языка из базы</li>
						</ul>

					</div>

					<div v-if="attention" class="warning">Проверь права на папку /cash/templates/</div>

				</div>

				<div class="wp100 mx-auto mt20 pt10">

					<div class="flex-content typelist fourcol p5 bluebg-lite sticked--top">

						<div class="flex-string">Дата</div>
						<div class="flex-string">Файл</div>
						<div class="flex-string">Размер</div>
						<div class="flex-string text-center">Действия</div>

					</div>

					<div v-for="item in list" :key="item.id" class="flex-content typelist fourcol ha border-bottom p5">

						<div class="flex-string">
							<div class="Bold">{{item.date}}</div> {{item.time}}</div>
						<div class="flex-string">
							<div class="fs-12 blue Bold" :data-value="item.lng">
								<div v-if="item.lng !== ''" class="flag"></div>{{item.name}}
							</div>
							<div class="fs-09 gray">{{item.tip}}</div>
						</div>
						<div class="flex-string">{{item.size}}</div>
						<div class="flex-string text-center">

							<a href="javascript:void(0)" @click="editItem(item.name)" title="Редактировать" class="button dotted bluebtn m0"><i class="icon-pencil"></i></a>

						</div>

					</div>

				</div>

				<div class="space-100"></div>

				<div v-if="floading" class="loading"></div>

			</div>

		</div>

		<Dialog ref="dialog" :show="showdialog" :widthString="widthString" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="file !== ''" v-slot:dlgbody>
				<EmailtemplatesForm :file="file" :time="time" @close="closeDialog" @loaded="dialogLoaded" @update="load"/>
			</template>
		</Dialog>

	</div>

</template>

<script>

import axios from "axios"
import Dialog from "@/components/modal/Dialog"
import EmailtemplatesForm from '@/pages/settings/EmailtemplatesForm.vue'

export default {
	name: "EmailtemplatesSet",
	components: {
		Dialog,
		EmailtemplatesForm,
	},
	data() {
		return {
			list: {},
			loading: false,
			floading: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			showdialog: false,
			dialogloaded: false,
			widthString: "70vw",
			file: '',
			time: 0,
			attention: false
		}
	},
	mounted() {
		this.load()
	},
	methods: {
		load() {

			this.loading = true

			let param = {};

			param.action = 'list'

			axios.post(this.apiBaseURL + '/api/emailtemplates/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {
						//console.log(data.data)

						this.list = data.data.result.list
						this.attention = data.data.result.attention

						this.$emit("loaded")

						this.loading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		editItem(payload) {
			this.time = this.$moment()
			this.file = payload
			this.showdialog = true
		},
		closeDialog() {
			this.id = 0
			this.showdialog = false
			this.dialogwidth = 800
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Email Templates / Settings',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.page--title {
	left       : 20px;
	display    : block;
	width      : 250px !important;
	text-align : left;
}

@media (min-width : 768px) and (max-width : 1024px) {

	.page--title {
		left : 0 !important;
	}

}
</style>