<template>

	<div>

		<DIV class="zagolovok">{{ language.Add }}/{{ language.Edit }} {{ language.Need }}</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>
			<input type="hidden" v-model="form.action" id="action">
			<input type="hidden" v-model="id">

			<div id="formtabs" class="pr20 pl20">

				<div class="flex-container mb10 box--child">

					<div class="flex-string wp100 mt10 relative material mradio">

						<project v-model="form.project" :id="parseInt(form.project)" @select="selectProject" :required="true" :class="{'required--error':$v.form.project.$error}"/>

					</div>

				</div>

				<div class="flex-container mt10 mb5 box--child">

					<div class="flex-string wp100 relative material cleared">

						<textarea type="text" id="content" class="wp100" placeholder=" " autocomplete="off" v-model="form.content" v-html="form.content"></textarea>
						<label for="content">{{ language.Description }}</label>
						<div class="clearinputs mr5" @click="clearInput('content')"><i class="icon-block red fs-14"></i></div>

					</div>

				</div>

				<div class="flex-container mt10 mb5 box--child">

					<div class="flex-string wp100 relative material cleared">

						<input type="text" id="count" class="wp100" placeholder=" " autocomplete="off" v-model="form.count">
						<label for="count">{{ language.Count }}</label>
						<div class="clearinputs mr5" @click="clearInput('title')"><i class="icon-block red fs-14"></i></div>

					</div>

				</div>

				<div class="flex-container mt10 mb0 box--child relative">

					<addresinput v-model="form.address" :required="true" :value="form.idaddress" :name="form.address" @update="addressChanged" :v="$v.form.address" :class="{'required--error':$v.form.address.$error}"/>

				</div>

				<div class="flex-container mt10 mb5 box--child">

					<address-suggestions v-model="form.meetaddress" id="meetaddress" :required="false" :value="form.meetaddress" :name="meetaddressName" @update="addressMeetChanged"/>

				</div>

				<div class="flex-container mt10 mb5 box--child">

					<div class="flex-string wp100 relative material like-input pt20">

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input type="radio" value="false" v-model="form.isarchive">
								<span class="custom-radio"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold blue">{{ language.Active }}</span>
							</label>
						</div>&nbsp;

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input type="radio" value="true" v-model="form.isarchive">
								<span class="custom-radio alert"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold red">{{ language.Archive }}</span>
							</label>
						</div>

						<div class="label">{{ language.Activity }}</div>

					</div>

				</div>

				<div class="flex-container mt10 mb5 box--child">

					<div class="flex-string wp100 relative material like-input pt20">

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input type="radio" value="true" v-model="form.urgency">
								<span class="custom-radio alert"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold red">{{ language.Urgency }}</span>
							</label>
						</div>&nbsp;

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input type="radio" value="false" v-model="form.urgency">
								<span class="custom-radio"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold blue">{{ language.Normally }}</span>
							</label>
						</div>

						<div class="label">{{ language.Urgency }}</div>

					</div>

				</div>

				<div class="space-50"></div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<button @click="formSubmit" class="button"><i class="icon-ok"></i>{{ language.Save }}</button>
				<button @click="closeForm" class="button graybtn"><i class="icon-cancel"></i>{{ language.Cancel }}</button>

			</div>

		</form>

	</div>

</template>

<script>
import Project from "@/components/elements/project";
import axios from "axios";
import {required} from 'vuelidate/lib/validators';

import addresinput from "@/components/elements/addresinput";
import AddressSuggestions from "@/components/elements/address-suggestions";


export default {
	name: "NeedForm",
	components: {
		Project,
		AddressSuggestions,
		addresinput,
	},
	props: {
		id: Number,
	},
	data() {
		return {
			//loading: false,
			form: {
				card: this.id,
				action: "edit",
				project: null,
				count: 0,
				content: "",
				city: "",
				address: "",
				idaddress: "",
				meetaddress: "",
				isarchive: false,
				urgency: false
			},
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			loading: false,
			message: "",
			submitStatus: ""
		}
	},
	validations: {
		form: {
			project: {
				required,
				minValue: 1
			},
			address: {
				required,
			}
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
		meetaddressName(){
			return this.language.MeetAddress
		}
	},
	created() {
		this.loadNeed()
	},
	watch: {
	},
	methods: {
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: "Не заполнены обязательные поля",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				// do your submit logic here
				this.submitStatus = 'PENDING'

				// отправка формы здесь
				let param = {};

				param.id = this.card

				for (let key in this.form) {
					if (this.form[key]) {
						param[key] = this.form[key]
					}
				}

				//param['schedule'] = this.form.schedule

				axios.post(this.apiBaseURL + '/api/needs/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.closeForm()
							this.$emit("update")

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

				//console.log(this.submitStatus)

			}

		},
		loadNeed() {

			this.loading = true

			//console.log(this.id)

			if (this.id > 0) {

				let param = {};

				param.id = this.id
				param.action = 'info'

				axios.post(this.apiBaseURL + '/api/needs/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {
							//console.log(data.data)

							let need = data.data.result.info

							this.form = {
								action: "edit",
								project: need.project,
								count: need.count,
								content: need.content,
								address: need.addressName,
								idaddress: need.address,
								meetaddress: need.meetaddress,
								urgency: need.isurgency,
								isarchive: need.isarchive,
							}

							//console.log(this.form)

							this.$emit("loaded")

							this.loading = false

						},
					)
					.catch(error => {
						console.log(error)
					})

			} else {

				this.form = {
					action: "edit",
					project: null,
					count: "",
					content: "",
					address: "",
					idaddress: "",
					meetaddress: "",
					urgency: false,
					isarchive: false,
				}

				this.loading = false

				this.$emit("loaded")

			}

		},
		selectProject(payload) {
			//console.log("form prj:", payload)
			this.form.project = payload
		},
		closeForm() {
			this.$emit("close")
		},
		clearInput(element) {
			this.form[element] = '';
		},
		addressChanged(payload) {

			//console.log("from address:", payload)

			if (payload !== undefined) {

				this.form.idaddress = payload.id
				this.form.address = payload.name
				this.form.project = parseInt(payload.idproject)

			}

		},
		addressMeetChanged(payload) {

			if (payload !== undefined) {

				this.form.meetaddress = payload

			}

		}
	}
}
</script>

<style>
/*input.required--error,
.required--error input,
.req.required--error {
	background : var(--red-lite);
}*/
</style>