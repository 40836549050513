const state = {
	schedule: {
		page: 1,
		total: 1,
		pageall: 1
	},
	filters: {
		project: [],
		schedule: {
			content: '',
			phone: '',
			city: '',
		},
		sort: "date_create",
		order: "desc"
	},
};

const mutations = {
	// address
	SET_FILTER_PROJECTS (state, projects) {
		state.filters.project = projects
	},

	SET_FILTER (state, payload) {
		state.filters.schedule = payload
	},

	SET_FILTER_ORDER (state, order) {
		state.filters.order = order
	},

	SET_FILTER_SORT (state, sort) {
		state.filters.sort = sort
	},

	SET_CLEAN_FILTERS (state) {
		state.filters = {
			project: [],
			schedule: {
				content: '',
				phone: '',
				city: ''
			},
			sort: "date_start",
			order: "desc"
		}
	},

	SET_LIST(state, payload) {
		state.schedule.items = payload.list
		state.schedule.page = payload.page
		state.schedule.pageall = payload.pageall
		state.schedule.total = payload.total
	},
	SET_FILTERS(state, payload) {
		state.filters = payload
	},

	CHANGE_PAGE(state, page) {
		state.schedule.page = page
	},
};

export default {
	namespaced: true,
	state,
	mutations
}