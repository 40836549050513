<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				Settings. Локализации
			</div>

		</div>

		<div v-if="loading" class="content-loading"></div>

		<div v-if="!loading" class="page--body">

			<div class="page--body">

				<div class="infodiv mt10">
					В этом разделе вы можете управлять доступными локализациями приложения
				</div>

				<div class="attention mt10 mb5 flh-12">

					<div class="red fs-12 Bold">Важно!</div>
					<div class="mt10">
						Файл перевода интерфейса является PHP-файлом, а весь перевод является массивом данных. Для корректной работы файла необходимо соблюдать следющие правила:
						<ul>
							<li>Все кавычки должны быть экранированы с помощью символа "\", особенно если добавляется html-разметка</li>
							<li>Не изменять ключи массива</li>
							<li>Не добавлять новые значения - это бессмысленно</li>
						</ul>
					</div>

				</div>

				<div v-if="attention" class="warning">
					<i class="icon-attention red fs-14"></i><b>Проверь права на папку</b> /settings/language/ - сейчас она не доступна для записи
				</div>

				<div class="wp100 mx-auto mt20 pt10">

					<div class="flex-content typelist translist tbl--header p5 sticked--top">

						<div class="flex-string">Язык</div>
						<div class="flex-string">Название файла</div>
						<div class="flex-string ">Дата изменения</div>
						<div class="flex-string text-center">Действия</div>

					</div>

					<div v-for="item in list" :key="item.id" class="flex-content typelist translist ha border-bottom p5" :class="{'gray graybg-sub':!item.exist}" :title="{'Ошибка: Нет файла локализации':item.exist}">

						<div class="flex-string Bold">{{item.lng}}</div>
						<div class="flex-string">
							{{item.name}}
							<div v-if="!item.exist" class="red">Ошибка: Нет файла локализации</div>
						</div>
						<div class="flex-string">
							{{item.date}} {{item.time}}
						</div>
						<div class="flex-string text-center">

							<a href="javascript:void(0)" @click="editItem(item.lng)" title="Редактировать" class="button dotted bluebtn"><i class="icon-pencil"></i></a>

						</div>

					</div>

				</div>

				<div class="space-100"></div>

				<div v-if="floading" class="loading"></div>

			</div>

		</div>

		<Dialog ref="dialog" :show="showdialog" :widthString="dialogwidth" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="file !== ''" v-slot:dlgbody>
				<TranslateForm :file="file" @close="closeDialog" @loaded="dialogLoaded" @update="load"/>
			</template>
		</Dialog>

	</div>

</template>

<script>

import axios from "axios"
import Dialog from "@/components/modal/Dialog"
import TranslateForm from '@/pages/settings/TranslateForm.vue'

export default {
	name: "TranslateSet",
	components: {
		Dialog,
		TranslateForm
	},
	data() {
		return {
			list: {},
			attention: false,
			loading: false,
			floading: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			showdialog: false,
			dialogloaded: false,
			dialogwidth: '70vw',
			file: '',
		}
	},
	mounted() {
		this.load()
	},
	methods: {
		load() {

			this.loading = true

			let param = {};

			param.action = 'list'

			axios.post(this.apiBaseURL + '/api/translate/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {
						//console.log(data.data)

						this.list = data.data.result.list
						this.attention = data.data.result.attention

						this.$emit("loaded")

						this.loading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		editItem(payload) {
			this.file = payload
			this.showdialog = true
		},
		closeDialog() {
			this.file = ''
			this.showdialog = false
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Локализации / Settings',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.page--title {
	left       : 20px;
	display    : block;
	width      : 250px !important;
	text-align : left;
}

@media (min-width : 768px) and (max-width : 1024px) {

	.page--title {
		left : 0 !important;
	}

}
</style>