<template>

	<div class="gstring pl10 pr10">

		<div class="divider filter pt10 pb10" data-id="address">{{ language.Description }}, {{ language.Address.Address }}, {{ language.Code }}</div>

		<div class="filterblock no-border relative cleared" data-id="address">

			<input type="text" id="content" class="wp100 p5 pl10 pr10" :placeholder="language.Description+', '+language.Address.Address+', '+language.Code" autocomplete="on" v-model="cfilter.content" @keyup="updateFilter">
			<div class="clearinputs" @click="clearInput('content')"><i class="icon-block-1 red"></i></div>

		</div>

		<div class="divider pt10 pb10">City</div>

		<div class="no-border relative cleared">

			<input type="text" id="city" class="wp100 p5 pl10 pr10" :placeholder="language.City" autocomplete="on" v-model="cfilter.city" @keyup="updateFilter" @focusout="updateFilter">
			<div class="clearinputs" @click="clearInput('city')"><i class="icon-block-1 red"></i></div>

		</div>

		<div class="divider pt10 pb10">Other</div>

		<div>

			<div class="filterblock no-border checkbox popblock-item" :title="language.Archived">
				<label class="wp100 pl10 mt5 flex-container float">
					<input class="taskss" name="isarchive" type="checkbox" id="isarchive" value="true" v-model="cfilter.isarchive" @change="updateFilter">
					<span class="custom-checkbox"><i class="icon-ok"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span class="block Bold fs-11">{{ language.Archived }}</span>
					</span>
				</label>
			</div>

			<div class="filterblock no-border checkbox popblock-item" :title="language.Archived" data-id="address">
				<label class="wp100 pl10 mt5 flex-container float">
					<input class="taskss" name="isactive" type="checkbox" id="isactive" value="true" v-model="cfilter.isactive" @change="updateFilter">
					<span class="custom-checkbox"><i class="icon-ok"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span class="block Bold fs-11 green">{{ language.Active }}</span>
					</span>
				</label>
			</div>

			<div class="filterblock no-border checkbox popblock-item" :title="language.Urgency" data-id="address">
				<label class="wp100 pl10 mt5 flex-container float">
					<input class="taskss" name="urgency" type="checkbox" id="urgency" value="true" v-model="cfilter.urgency" @change="updateFilter">
					<span class="custom-checkbox"><i class="icon-ok"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span class="block Bold fs-11 red">{{ language.Urgency }}</span>
					</span>
				</label>
			</div>

		</div>

	</div>

</template>

<script>
export default {
	name: "Filters",
	model: {
		prop: "cfilter",
		event: "change"
	},
	props: {
		cfilter: {
			type: Object,
			default: () => {
				return {
					content: '',
					city: '',
					isarchive: '',
					isactive: '',
					urgency: '',
				}
			}
		}
	},
	data() {
		return {
			language: this.$store.state.language,
		}
	},

	methods: {
		updateFilter() {
			this.$emit("change", this.cfilter)
		},

		clearInput(element) {
			this.$store.state.needs.filters.needs[element] = '';
		}
	},
}
</script>

<style scoped>

</style>