<template>

	<div class="ydropDown like-input wp100" @click="toggle" :class="{'checked':checked, 'req':inputRequired}" ref="sysvacancySelect">

		<span title="Показатель"><i class="icon-article-alt black hidden"></i></span>
		<span class="ydropText">{{text}}</span>
		<i class="icon-down-open pull-aright arrow"></i>

		<div class="yselectBox" style="max-height: 250px; width:100%" v-if="show">
			<div class="ydropString yRadio" aria-disabled="true">
				<label class="wp95 gray-blue" @click="update({})">
					<input type="radio" v-model="sysvacancy" value="null" @change="update({})">Необходимо выбрать
				</label>
			</div>
			<div v-for="item in sysvacancyList" class="ydropString yRadio" :key="item.id" :class="{'bluebg-sub':parseInt(item.id) === sysvacancy}">
				<label class="wp95" @click="update(item)">
					<input type="radio" v-model="sysvacancy" :value="item.id" @change="update(item)">
					{{item.title}}
				</label>
			</div>
		</div>
		<div class="label">{{ inputName }}</div>

	</div>

</template>

<script>

export default {
	name: "SysVacancy",
	props: {
		id: String,
		label: Boolean,
		required: Boolean,
		name: String, // Label
		project: Number,
	},
	data(){
		return {
			language: this.$store.state.language,
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			sysvacancy: null,
			vacancylist: [],
			show: false,
			checked: false,
			text: ""
		}
	},
	computed: {
		sysvacancyList(){

			//let vm = this
			let vl

			let prj = this.guides.projects

			for (let key in prj) {
				if (prj[key].id === this.project && prj[key].sysvacancy.length > 0) {
					vl = prj[key].sysvacancy
				}
			}

			return vl

		},
		inputRequired() {
			return this.required !== undefined ? this.required : true
		},
		inputName() {
			return this.name !== undefined ? this.name : 'Системная вакансия'
		},
	},
	mounted() {
		this.select()
	},
	watch:{
		id(){
			//console.log("id:", this.id)
			this.sysvacancy = this.id
			this.select()
		},
		sysvacancy(){
			this.sysvacancy = this.id
			this.select()
		}
	},
	created() {
		this.sysvacancy = this.id
	},
	methods:{
		select(){

			this.text = ''

			if(this.sysvacancy !== '') {

				for (let key in this.sysvacancyList) {

					if (this.sysvacancyList[key].id === this.sysvacancy) {

						this.text = this.sysvacancyList[key].title
						this.checked = true
						break

					}

				}

			}

		},
		update(selected){

			this.text = selected.id !== '' ? selected.title : ''
			this.checked = selected.id !== ''
			this.sysvacancy = selected.id

			//console.log(selected)

			this.$emit("select", this.sysvacancy)

		},
		toggle(){
			this.show = !this.show
		},
		open(){
			this.show = true
		},
		close(){
			this.show = false
		}
	}
}
</script>

<style scoped>

</style>