<!--
Универсальный компонент автопоиска
-->
<template>

	<div class="flex-string relative material cleared" :class="wclass" v-click-outside="onHide">

		<input type="text" placeholder=" " id="input" v-model="selected" class="wp100 pl10" :class="{'required':required}" @input="search" @paste="search" ref="input" autocomplete="off">
		<label for="input">{{ inputName }}</label>
		<div class="clearinputs mr5" @click="selected = ''"><i class="icon-block red fs-14"></i></div>
		<div v-if="loading" class="vu-suggestion-loader"></div>

		<ul class="vu-suggestion" v-if="show" ref="suggestionlist">
			<li v-for="item in filtered" :key="item.id" @click="select(item)">
				<slot name="first-string">
					<div class="fs-11">{{ item[vars[0]] }}</div>
				</slot>
				<slot name="second-string">
					<div class="fs-09 gray mt5">{{ item[vars[1]] }}, {{ item[vars[2]] }}</div>
				</slot>
				<slot name="third-string"></slot>
			</li>
		</ul>

	</div>

</template>

<script>
import axios from "axios"
import {debounce} from "lodash";

export default {
	name: "autocomplete",
	props: {
		value: String, // установленное значение
		name: String, // Label
		required: Boolean, // обязательность заполнения
		classes: String, // дополнительные классы, применимые к input
		params: Object, // goal - целевое поле (title, email, phone, ...), type - тип поля (title - не указано, email, phone)
		method: String, // метод API (candidate)
		action: String, // действие API (search)
		min: Number, // мин. число символов для начала поиска
		vars: Array // название полей для вывода данных: 0 - основные данные, 1 - вторая строка, 2 - третья строка
	},
	data() {
		return {
			//selected: '',
			current: '',
			items: {},
			filtered: {},
			//response: {},
			default: this.name,
			show: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false
		}
	},
	computed: {
		require() {
			return this.required === true ? this.required : false
		},
		wclass() {
			return this.classes !== undefined ? this.classes : 'wp100'
		},
		minChars() {
			return this.min !== undefined ? this.min : 0
		},
		inputName() {
			return this.name !== undefined ? this.name : "Input"
		},
		response: {
			get(){
				return this.response
			},
			set(payload){
				this.$emit("update", payload)
			}
		},
		selected: {
			get(){
				return this.value
			},
			set(payload){
				this.$emit("updatefield", this.params.goal, payload)
			}
		},
		/*debouncedInput:{
			return _.debounce(this.search, 500)
		},*/
	},
	created() {

	},
	watch:{
		current(){
			this.selected = this.current
		}
	},
	mounted() {

	},
	methods: {
		search: debounce(function(){

			if(this.selected === undefined){
				return
			}

			if (this.selected.split('').length >= this.minChars || this.minChars === 0) {

				this.loading = true

				let param = {};

				for (let key in this.params) {
					if (this.params[key]) {
						param[key] = this.params[key]
					}
				}

				param.action = this.action
				param.query = this.selected
				param.limit = 20

				axios.post(this.apiBaseURL + '/api/' + this.method + '/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.filtered = data.data.result

							//this.$emit("word", this.keyword)

							if (!this.show) {
								this.onShow()
							}

							this.loading = false

						},
					)
					.catch(error => {
						console.log(error)
					})

			}

		}, 500),
		select(payload) {
			this.selected = this.params.goal === undefined ? payload[this.vars[0]] : payload[this.params.goal]
			this.response = payload

			this.onHide()
			//this.update()
		},
		update(payload) {
			this.selected = payload
		},
		clean() {
			this.selected = ''
			this.response = {}
			this.$emit("update", {})
		},
		onShow() {
			this.show = true
		},
		onHide() {
			this.show = false
			//this.update()
		},
	}
}
</script>

<style scoped>
ul.vu-suggestion {
	max-height : 200px;
}
.material{
	margin-bottom: 0;
}
</style>