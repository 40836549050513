<template>

	<div>

		<div class="full">

			<div class="error404"></div>
			<div class="fs-40 flh-12 Bold">Ошибка</div>
			<div>Упс :(, такой страницы не существует</div>

		</div>

	</div>

</template>

<script>
export default {
	name: "NotFoundComponent"
}
</script>

<style scoped>
.full{
	display: table;
	width: calc(100vw - 290px);
	height: calc(100vh - 80px);
	text-align: center;
	overflow-y: hidden;
	padding-top: 10%;
}

.ui-layout.collapsed .full{
	width: calc(100vw - 80px);
	height: calc(100vh - 80px);
}

.error404{
	width: 350px;
	height: 250px;
	background-image: url("/assets/images/404.png");
	background-size: cover;
	background-repeat: no-repeat;
	margin: 0 auto;
}
</style>