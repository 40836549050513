<template>
	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				{{ language.Dashboard }}
			</div>

		</div>

		<h1>Бодро пожаловать, {{currentuser.name}}</h1>

		<div class="hidden">
			Lorem Ipsum - это текст-"рыба", часто используемый в печати и вэб-дизайне. Lorem Ipsum является стандартной "рыбой" для текстов на латинице с начала XVI века. В то время некий безымянный печатник создал большую коллекцию размеров и форм шрифтов, используя Lorem Ipsum для распечатки образцов. Lorem Ipsum не только успешно пережил без заметных изменений пять веков, но и перешагнул в электронный дизайн. Его популяризации в новое время послужили публикация листов Letraset с образцами Lorem Ipsum в 60-х годах и, в более недавнее время, программы электронной вёрстки типа Aldus PageMaker, в шаблонах которых используется Lorem Ipsum.
		</div>

		<div class="dashboard">

			<div class="datablock">

				<div class="xheader">Выработка за день</div>
				<div class="xbody">
					<TotalStat/>
				</div>

			</div>
			<div class="datablock">

				<div class="xheader">Динамика согласий</div>
				<div class="xbody no--overflow" data-id="chart">
					<FeedbackWork/>
				</div>

			</div>
			<div class="datablock">

				<div class="xheader">В работе..</div>
				<div class="xbody" data-id="statchart">

					<div class="p10 pt20 border-box text-center" style="height: 100%; vertical-align: middle">
						<div class="text-center fs-40 mb20 green"><i class="icon-folder-empty"></i></div>
						<div class="fs-12 Bold">Здесь будут какие-то данные..</div>
					</div>

				</div>

			</div>
			<div class="datablock">

				<div class="xheader">В работе..</div>
				<div class="xbody" data-id="statchart">

					<div class="p10 pt20 border-box text-center" style="height: 100%; vertical-align: middle">
						<div class="text-center fs-40 mb20 green"><i class="icon-folder-empty"></i></div>
						<div class="fs-12 Bold">Здесь будут какие-то данные..</div>
					</div>

				</div>

			</div>

		</div>

	</div>
</template>

<script>

import TotalStat from "@/components/vigets/TotalStat";
import FeedbackWork from "@/components/vigets/FeedbackWork";

export default {
	name: "Main",
	components: {
		TotalStat,
		FeedbackWork
	},
	data() {
		return {
			guides: this.$store.state.guides,
			currentuser: this.$store.state.user,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language
		}
	},
	methods:{
	}
}
</script>

<style>
.dashboard{
	display               : grid;
	grid-template-columns : 2fr 3fr;
	grid-template-rows: repeat(2, 350px);
	grid-gap              : 10px;
}
.datablock{
	border: 1px solid var(--gray);
	background: #FFF;
	padding: 0;
	border-radius: 5px;
	box-sizing: border-box;
	/*box-shadow: 0 1px 1px #BBB;*/
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 40px minmax(calc(100% - 40px), auto);
	grid-gap: 0;
	position: relative;
}
.datablock .xheader {
	display: table;
	color: var(--gray-darkblue);
	background: var(--gray-lite);
	font-size: 0.8em;
	line-height: 30px;
	height: 30px;
	padding: 5px 10px;
	font-weight: 700;
	text-transform: uppercase;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	z-index: initial;
}
.datablock .xbody {
	display: block;
	width: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	background: #FFF;
}
.datablock .xbody .flex-container .flex-string{
	padding: 10px;
}

/**
Выбор периода для виджетов
 */
.periods{
	position: absolute;
	top: calc(100% - 5px);
	right: 5px;
}
.periods ul.group {
	position: absolute;
	z-index: 1;
	top: calc(100% - 40px);
	right: 10px;
	display: table;
	list-style: none;
	background: rgba(245,245,245 ,0.7);
	border: 1px solid #CCD1D9;
	margin-top: 5px;
	padding: 0;
	font-size: 0.9em;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
}
.periods ul.group > li {
	margin: 0 !important;
	padding: 5px 10px !important;
	display: table-cell;
	text-align: center;
	cursor: pointer;
	border-right: 1px solid #CCD1D9;
	box-sizing: border-box !important;
}
.periods ul.group > li:last-child {
	border-right: 0;
}
.periods ul.group > li:hover,
.periods ul.group > li.current{
	color: #fff;
	background: #C0392B;
	border-color: #C0392B !important;
}
</style>
<style scoped>
.page--title{
	left: 45px;
	display: block;
	width: 200px !important;
	text-align: left;
}
</style>