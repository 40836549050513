<template>

	<div>

		<DIV class="zagolovok">{{ language.Add }}/{{ language.Edit }} {{ language.Feedback }}</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading && showit" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="divider mt10 mb20">
					<div class="green">{{language.CandidateData}}</div>
				</div>

				<div data-id="candidate">

					<div class="flex-container mt10 box--child">

						<div class="flex-string wp70 relative material cleared">

							<autocomplete
								:classes="'wp99'"
								:class="{'required--error':$v.form.soiskatel.title.$error}"
								v-model="form.soiskatel.title"
								:value="form.soiskatel.title"
								:name="language.CandidateName"
								:required="true"
								:method="'candidate'"
								:action="'search'"
								:params="{goal:'title'}"
								:min="3"
								:vars="['title','phone','city']"
								@update="soiskatelChanged"
								@updatefield="soiskatelChangedValue"
							>
							</autocomplete>

						</div>

						<div class="flex-string relative wp30">

							<date-picker lang="ru" format="DD.MM.YYYY" :default-value="form.soiskatel.birthday" v-model="form.soiskatel.birthday" valueType="format" :v="$v.form.soiskatel.birthday" :class="{'required--error':$v.form.soiskatel.birthday.$error}" @input="changeBirthday">

								<template v-slot:input>
									<div class="wp100 relative material">
										<input type="text" id="birthday" class="required wp100" placeholder=" " autocomplete="off" v-model="form.soiskatel.birthday">
										<label for="birthday">{{language.Human.Birthday}}</label>
									</div>
								</template>
								<template v-slot:icon-clear></template>
								<template v-slot:icon-calendar></template>

							</date-picker>
							<div v-if="age > 0" class="fs-10 Bold" :class="{'red': age < 18, 'green': age >= 18}" style="position:absolute; right: 30px; top:20px; z-index: 2" data-id="age">{{ age }}</div>

						</div>

					</div>

					<div class="flex-container mt5 box--child">

						<nationality id="nationality" class="wp100" v-model="form.soiskatel.nationality" :value="form.soiskatel.nationality" @update="nationalityChanged"/>

					</div>

					<div class="flex-container mt5 box--child">

						<div class="flex-string wp50 relative material cleared">

							<autocomplete
								:classes="'wp99'"
								v-model="form.soiskatel.email"
								:value="form.soiskatel.email"
								:name="'Email'"
								:required="false"
								:method="'candidate'"
								:action="'search'"
								:params="{type:'email',goal:'email'}"
								:min="3"
								:vars="['title','phone','email','city']"
								:class="{'format--error':$v.form.soiskatel.email.$error}"
								@update="soiskatelChanged"
								@updatefield="soiskatelChangedValue"
							>
							</autocomplete>

							<div class="red fs-09" :class="{'hidden':!$v.form.soiskatel.email.$error}">Не корректный формат: не соответствует Email</div>

						</div>

						<div class="flex-string wp50 relative material cleared">

							<autocomplete
								:classes="'wp100'"
								v-model="form.soiskatel.phone"
								:value="form.soiskatel.phone"
								:name="language.Phone"
								:required="true"
								:method="'candidate'"
								:action="'search'"
								:params="{type:'phone',goal:'phone'}"
								:min="3"
								:vars="['phone','title','email','city']"
								:class="{'format--error':$v.form.soiskatel.phone.$error}"
								@update="soiskatelChanged"
								@updatefield="soiskatelChangedValue"
								@keyup="validatePhone"
							>
							</autocomplete>

							<div class="red fs-09" :class="{'hidden':phoneiscorrect}">Не корректный формат: <b>{{ phonelength }}</b> цифр из 11</div>

						</div>

					</div>

					<div class="flex-container mt10 mb0 box--child relative">

						<city-suggestions v-model="form.soiskatel.city" id="city" :classes="'wp30 pr5'" :city="form.soiskatel.city" @update="cityChanged" ref="city"></city-suggestions>

						<address-suggestions v-model="form.soiskatel.address" id="address" :classes="'wp70'" :value="form.soiskatel.address" @update="addressChanged"/>

					</div>

					<div class="flex-container box--child">

						<div class="flex-string wp100 relative material">

							<input type="text" v-model="form.soiskatel.url_1" id="url_1" class="wp100 linked" placeholder=" " autocomplete="off" :v="$v.form.soiskatel.url_1" :class="{'format--error':$v.form.soiskatel.url_1.$error}">
							<label for="url_1">Url</label>
							<a v-if="form.soiskatel.url_1 !== '' && form.soiskatel.url_1 !== null && !$v.form.soiskatel.url_1.$error" :href="form.soiskatel.url_1" target="_blank" class="linked urlopen" :title="language.View">{{language.View}}</a>

						</div>

					</div>

					<div class="flex-container mt5 box--child">

						<div class="flex-string wp100 relative material">

							<TextareaAutosize
								class="wp100"
								id="description"
								placeholder=" "
								ref="description"
								v-model="form.soiskatel.description"
								:min-height="100"
								:max-height="200"
								spellcheck="false"
							/>

							<label for="description">{{ language.Comment }}</label>

						</div>

					</div>

				</div>

				<div class="divider mt10 mb20">
					<div class="blue">{{language.FeedbackData}}</div>
				</div>

				<div class="space-10"></div>

				<div data-id="feedback">

					<div v-if="isAdmin" class="flex-container mt20 box--child">

						<div class="flex-string wp100 relative material">

							<date-picker
								lang="ru"
								type="datetime"
								:show-second="false"
								format="DD.MM.YYYY H:mm"
								:default-value="date_create"
								:minute-step="5"
								v-model="date_create"
								valueType="format" @input="changeDateCreate"
								:show-time-panel="showTimePanel"
								:open.sync="openPanel"
								@change="handleChange"
								@close="handleOpenChange"
								:shortcuts="shortcuts"
							>

								<template v-slot:input>
									<div class="wp100 relative material">
										<input type="text" id="xdate_create" class="required wp100" placeholder=" " autocomplete="off" v-model="date_create">
										<label for="xdate_create">{{language.TimeReceipt}}</label>
									</div>
								</template>
								<template v-slot:icon-clear></template>
								<template v-slot:icon-calendar></template>
								<template v-slot:footer>
									<div class="blue p5 hand inline pull-left" @click="toggleTimePanel" :title="showTimePanel ? 'дата' : 'время'">
										<i :class="showTimePanel ? 'icon-calendar-inv' : 'icon-clock'"></i><span class="">{{ showTimePanel ? 'дата' : 'время' }}</span>
									</div>
									<div class="gray p5 hand inline pull-right" @click="openPanel = false" title="Завершить">
										<i class="icon-cancel-circled"></i>
									</div>
								</template>

							</date-picker>

						</div>

					</div>

					<div class="flex-container mb10 box--child">

						<div class="flex-string wp100 mt10 relative material mradio">

							<project v-model="form.project" :id="parseInt(form.project)" @select="selectProject" :exclude="excludedProject" :class="{'required--error':$v.form.project.$error}"/>

						</div>

					</div>

					<div class="flex-container mt10 box--child">

						<div class="flex-string wp100 material cleared">

							<Addresinput v-model="form.addressname" :required="false" :classes="'wp100'" :value="form.addressname" :project="form.project" :city="form.soiskatel.city" @update="addressPointChanged"/>

						</div>

					</div>

					<div class="flex-container mt10 box--child">

						<VacancyList v-model="form.title" :project="form.project" :value="form.title" :required="true" @update="vacancyChange" @updatefull="vacancyChangeFull" />

					</div>

					<div class="flex-container mb10 box--child">

						<div class="flex-string wp100 mt10 relative material mradio">

							<ChannelRadio v-model="form.channel" :id="form.channel" :label="true" :exclude="excludedChannel" @select="channelChanged" :class="{'required--error':$v.form.channel.$error}"/>

						</div>

					</div>

					<div class="flex-container mt10 mb10 box--child">

						<Typework v-model="form.type_work" :selected="form.type_work" @update="typeworkChanged"/>

					</div>

					<div class="flex-container mt10 mb10 box--child">

						<Typelead v-model="form.type_lead" :selected="form.type_lead" @update="typeleadChanged"/>

					</div>

				</div>

				<div class="divider mt10 mb20">
					<div class="red">{{language.WorkResult}}</div>
				</div>

				<div class="space-10"></div>

				<div data-id="result">

					<div class="flex-container mb10 box--child">

						<div class="flex-string wp100 mt10 relative material mradio">

							<CovidRadio v-model="form.covid" :id="form.covid" :label="true" @select="covidChanged"/>

						</div>

					</div>

					<div class="flex-container mb10 box--child">

						<div class="flex-string wp100 relative material mradio">

							<Result v-model="form.results" :id="parseInt(form.results)" :label="true" @select="selectResult"></Result>
							<div class="fs-09 gray pl10">Заполняется при обработке</div>

						</div>

					</div>

					<div v-if="resultstype === 'lose'" class="flex-container mb10 box--child" data-block="reasons">

						<div class="flex-string wp100 relative material mradio">

							<Reason v-model="form.reason" :value="form.reason" :label="true" :required="reasonreq" @select="selectReason"></Reason>

						</div>

					</div>

					<div v-if="resultstype === 'task'" class="flex-container mt10 box--child" data-block="task">

						<div class="flex-string wp100 relative material">

							<date-picker
								lang="ru"
								type="datetime"
								:show-second="false"
								format="DD.MM.YYYY HH:mm"
								:default-value="form.task.date"
								:minute-step="5"
								v-model="form.task.date"
								valueType="format"
								@input="changeTaskDay"
								:show-time-panel="showTimePanel2"
								:open.sync="openPanel2"
								@change="handleChange2"
								@close="handleOpenChange2"
								:disabled-date="notBeforeToday"
								:shortcuts="shortcuts"
							>

								<template v-slot:input>
									<div class="wp100 relative material">
										<input type="text" id="day" class="required wp100" placeholder=" " autocomplete="off" v-model="form.task.date">
										<label for="day">Дата и время звонка</label>
									</div>
								</template>
								<template v-slot:icon-clear></template>
								<template v-slot:icon-calendar></template>

							</date-picker>
							<div class="fs-09 gray">Заполняется только при необходимости перезвонить</div>

						</div>

					</div>

					<div v-if="schedule.date !== undefined && resultstype === 'win'" class="success mb20 fs-12 p10 text-center">

						Назначена встреча на <b class="green">{{ schedule.date }}</b> в
						<b class="green">{{ schedule.time }}</b>

					</div>

					<div v-if="resultstype === 'win'" class="flex-container mt5 mb20 box--child" data-block="scheduler">

						<div class="flex-string wp100 relative material" data-type="scheduler"></div>
						<div class="flex-string wp100 relative material">

							<input v-model="form.schedule" type="text" id="schedule" class="wp100 linked" value="" placeholder=" " autocomplete="off" @click="showScheduler">
							<a href="javascript:void(0)" @click="showScheduler" class="linked greenbtn"><i class="icon-calendar-inv"></i>Календарь</a>
							<label for="schedule">Дата и время собеседования</label>
							<div class="fs-09 gray pl10">Укажите новое, если надо назначить / переназначить или
								<a href="javascript:void(0)" @click="form.schedule = ''" class="red Bold">Очистить поле</a>
							</div>

						</div>

					</div>

					<div class="flex-container mt5 box--child">

						<div class="flex-string wp100 relative material">

							<TextareaAutosize
								class="wp100"
								id="comment"
								placeholder=" "
								ref="description"
								v-model="form.comment"
								v-html="form.comment"
								:min-height="100"
								:max-height="200"
								spellcheck="false"
							/>
							<label for="comment">{{ language.Comment }}</label>

						</div>

					</div>

				</div>

				<div class="space-100"></div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<button @click="formSubmit" class="button"><i class="icon-ok"></i>{{language.Save}}</button>
				<button @click="closeForm" class="button graybtn"><i class="icon-cancel"></i>{{language.Cancel}}</button>

			</div>

		</form>

		<template v-if="schedulefor > 0">
			<ScheduleCalendar :id="schedulefor" @close="closeDialogPlus" @loaded="dialogPlusLoaded" @edit="selectSchedule"/>
		</template>

	</div>

</template>

<script>
import axios from "axios";
import {required, minLength, maxLength, url, email, numeric} from 'vuelidate/lib/validators';

import Project from "@/components/elements/project";
import AddressSuggestions from "@/components/elements/address-suggestions";
import CitySuggestions from "@/components/elements/city-suggestions";
import Nationality from "@/components/elements/nationality";
import ChannelRadio from "@/components/elements/channel-radio";
import CovidRadio from "@/components/elements/covid-radio";
import Addresinput from "@/components/elements/addresinput";
import Typework from "@/components/elements/typework";
import Typelead from "@/components/elements/typelead";
import Reason from "@/components/elements/reason";
import Result from "@/components/elements/results";
import ScheduleCalendar from "@/components/ScheduleCalendar";
import VacancyList from "@/components/elements/vacancylist";
import Autocomplete from "@/components/elements/autocomplete";
import Vue from "vue";

export default {
	name: "FeedbackForm",
	components: {
		Autocomplete,
		VacancyList,
		Result,
		Reason,
		Typework,
		Typelead,
		AddressSuggestions,
		CitySuggestions,
		Nationality,
		Project,
		ChannelRadio,
		Addresinput,
		ScheduleCalendar,
		CovidRadio
	},
	props: {
		id: Number,
	},
	data() {
		return {
			//loading: false,
			form: {
				card: this.id,
				action: "edit",
				sid: 0,
				soiskatel: {
					title: "",
					birthday: this.$moment().subtract(20, 'years').format('DD.MM.YYYY'),
					city: "",
					address: "",
					phone: "7",
					email: "",
					url_1: "",
					nationality: "",
					description: "",
				},
				project: null,
				channel: null,
				title: "",
				address: 0,
				addressname: "",
				type_work: "",
				type_lead: "",
				results: 0,
				covid: 0,
				reason: "",
				comment: "",
				schedule: "",
				scheduleid: 0,
				task: {
					date: ""
				}
			},
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			loading: false,
			city: "",
			message: "",
			submitStatus: "",
			age: 0,
			reasonreq: false,
			resultstype: "",
			schedule: {},
			showdialogplus: false,
			dialogplusloaded: false,
			dialogpluswidth: 800,
			schedulefor: 0,
			showit: true,
			phoneiscorrect: true,
			phonelength: 0,
			date_create: null,
			isAdmin: this.$store.state.user.isAdmin,
			excludedChannel: [],
			excludedProject: [],
			showTimePanel: false,
			openPanel: false,
			openPanel2: false,
			showTimePanel2: false,
			showTimeRangePanel2: false,
			shortcuts: Vue.prototype.$shortcuts,
		}
	},
	validations: {
		form: {
			project: {
				required,
				minValue: 1
			},
			channel: {
				required,
				minValue: 1
			},
			soiskatel: {
				title: {
					required,
					minLength: minLength(2)
				},
				birthday: {},
				email: {
					email,
					minLength: minLength(5)
				},
				phone: {
					numeric,
					minLength: minLength(11),
					maxLength: maxLength(11)
				},
				url_1: {url},
			},
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.$v.form.$touch()
		this.excludeChannel()
	},
	mounted() {
		//console.log(this.id)
		this.loadCard()
	},
	watch: {
		city() {
			return this.form.soiskatel.city
		}
	},
	methods: {
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: this.language.msg.EmptyRequiredFields,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				// do your submit logic here
				this.submitStatus = 'PENDING'

				// отправка формы здесь
				let param = {};

				param['action'] = 'edit'
				param.id = this.card

				for (let key in this.form) {
					if (this.form[key] && key !== 'schedule') {
						param[key] = this.form[key]
					}
				}

				param['schedule'] = this.form.schedule

				if(this.isAdmin){

					param.date_create = this.$moment(this.date_create, 'DD.MM.YYYY HH:mm').format('YYYY-MM-DD HH:mm')

				}

				this.loading = true

				//console.log(param)

				axios.post(this.apiBaseURL + '/api/feedback/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.closeForm()
							this.$emit("update")

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.loading = false

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

				//console.log(this.submitStatus)

			}

		},
		loadCard() {

			this.loading = true

			//console.log(this.card)

			if (this.card > 0) {

				let param = {};

				param.id = this.card
				param.action = 'card'

				axios.post(this.apiBaseURL + '/api/feedback/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {
							//console.log(data.data)

							let feedback = data.data.result

							this.form = {
								action: "edit",
								sid: feedback.sid,
								soiskatel: {
									title: feedback.candidate.title,
									birthday: feedback.candidate.birthday,
									city: feedback.candidate.city,
									address: feedback.candidate.address,
									phone: feedback.candidate.phone,
									email: feedback.candidate.email,
									url_1: feedback.candidate.url_1,
									nationality: feedback.candidate.nationality,
									description: feedback.candidate.odescription,
								},
								project: parseInt(feedback.feedback.project),
								title: feedback.feedback.title,
								channel: parseInt(feedback.feedback.channel),
								covid: parseInt(feedback.feedback.covid),
								type_work: feedback.feedback.type_work,
								type_lead: feedback.feedback.type_lead,
								address: feedback.feedback.address !== null ? feedback.feedback.address.id : 0,
								addressname: feedback.feedback.address !== null ? feedback.feedback.address.address : "",
								results: feedback.feedback.results.id,
								reason: feedback.feedback.reason,
								comment: feedback.feedback.comment,
								//schedule: feedback.feedback.schedule,
								scheduleid: feedback.feedback.schedule.id,
								task: {
									date: ""
								}
							}

							this.schedule = feedback.feedback.schedule
							this.resultstype = feedback.feedback.results.type
							this.reasonreq = feedback.feedback.results.type === 'lose'
							this.date_create = this.isAdmin ? this.$moment(feedback.feedback.date_create).format('DD.MM.YYYY HH:mm') : null

							//console.log( 'original', feedback.feedback.date_create )
							//console.log( 'moded', this.$moment(feedback.feedback.date_create).format('DD.MM.YYYY H:mm') )
							//console.log( 'orig', this.date_create )
							//console.log( 'converted', this.$moment(this.date_create, 'DD.MM.YYYY H:mm').format('YYYY-MM-DD H:mm') )

							this.calculateAge()

							this.$emit("loaded")

							this.loading = false

						},
					)
					.catch(error => {
						console.log(error)
					})

			} else {

				this.loading = false
				this.calculateAge()

				this.$emit("loaded")

			}

		},
		closeForm() {
			this.$emit("close")
		},
		clearInput(element) {
			this.form[element] = ''
		},
		clearSoiskatelInput(element) {
			this.form.soiskatel[element] = ''
			//console.log(this.form.soiskatel[element])
			//this.$forceUpdate();
		},
		selectProject(payload) {
			this.form.project = payload
		},
		selectReason(payload) {
			this.form.reason = payload
		},
		selectResult(payload) {

			//console.log("result", payload)

			this.form.results = payload.id
			this.reasonreq = payload.type === 'win'
			this.resultstype = payload.type
			this.reasonreq = false

			if( this.resultstype === 'win' ){
				this.form.task.date = ''
				this.reason = ''
			}
			else if( this.resultstype === 'lose' ){
				this.form.task.date = ''
				this.schedule = ''
				this.reasonreq = true
			}
			else if( this.resultstype === 'task' ){
				this.reason = ''
				this.schedule = ''
				this.form.task.date = this.$moment().add(30, 'm').format('DD.MM.YYYY HH:mm')
			}

		},
		changeTaskDay(payload) {
			this.form.task.date = payload
		},
		addressChanged(payload) {

			//console.log("from address:", payload)

			if (payload.data !== undefined) {

				this.form.soiskatel.address = payload.value
				this.form.soiskatel.city = payload.data.city

				this.$refs.city.city = payload.data.city

			}

		},
		addressPointChanged(payload) {

			//console.log("from address:", payload)

			if (payload.id > 0) {

				this.form.addressname = payload.title
				this.form.address = payload.id
				this.form.project = payload.idproject

			}

		},
		cityChanged(payload) {

			if (payload !== undefined) {
				this.form.soiskatel.city = payload
			}

		},
		nationalityChanged(payload) {

			//console.log(payload)

			if (payload !== undefined) {
				this.form.soiskatel.nationality = payload
			}

		},
		channelChanged(payload) {
			if (payload !== undefined) {
				this.form.channel = payload
			}
		},
		covidChanged(payload) {
			if (payload !== undefined) {
				this.form.covid = payload
			}
		},
		typeworkChanged(payload) {
			if (payload !== undefined) {
				this.form.type_work = payload
			}
		},
		typeleadChanged(payload) {
			if (payload !== undefined) {
				this.form.type_lead = payload
			}
		},
		vacancyChange(payload) {
			if (payload !== undefined) {
				this.form.title = payload
			}
		},
		vacancyChangeFull(payload) {
			//console.log("full", payload)
			if (payload !== undefined) {
				this.form.project = payload.project
				//this.form.title = payload.vacancy
			}
		},

		calculateAge() {

			const formats = ["DD.MM.YYYY", "DD/MM/YYYY", "DD-MM-YYYY", "DD MM YYYY"]

			if (this.form.soiskatel.birthday !== '' && this.form.soiskatel.birthday !== null) {

				let castDate = this.$moment(this.form.soiskatel.birthday, formats)
				let a = this.$moment().diff(castDate, 'years', false)

				this.age = parseInt(a)

				if (this.age < 16) {

					//this.form.birthday = ''
					//this.age = 0

					this.$toast.warn({
						title: 'Attention',
						message: "Возраст менее 16 лет",
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})

				}

				//console.log(this.age)

			}

		},
		changeBirthday(date) {
			this.form.soiskatel.birthday = date
			this.calculateAge()
		},
		validatePhone() {

			if(this.form.soiskatel.phone !== undefined) {

				//this.form.soiskatel.phone = this.form.soiskatel.phone.replace(/\D/g, "")
				//console.log(this.form.soiskatel.phone)

				let phone = this.form.soiskatel.phone.split("")

				this.phonelength = phone.length

				if (phone.length > 0 && phone[0] !== '7') {
					this.form.soiskatel.phone = "7"
					this.phoneiscorrect = false
				} else if (phone.length > 0 && phone.length !== 11) {
					this.phoneiscorrect = false
				} else {
					this.phoneiscorrect = true
				}

				//this.form.soiskatel.phone = this.form.soiskatel.phone.replace(/\D/g, "")

			}

		},
		changeDateCreate(payload) {
			this.date_create = payload
		},

		dialogPlusLoaded() {
			this.dialogloaded = true
		},
		closeDialogPlus() {
			this.showdialogplus = false
			this.dialogplusloaded = false
			this.dialogpluswidth = 800

			this.showit = true
			this.schedulefor = 0

		},

		showScheduler() {

			if (this.form.address > 0) {

				this.schedulefor = parseInt(this.form.address)
				this.showdialogplus = true
				//this.showit = false

			} else {

				this.$toast.error({
					title: 'Error',
					message: 'Select Address first',
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

			}
		},
		selectSchedule(payload){

			this.showit = true
			this.schedulefor = 0

			this.form.schedule = payload[0] + ' ' + payload[1]

		},

		soiskatelChanged(payload){

			//console.log("soiskatel", payload)

			this.form.soiskatel = {
				sid: payload.id,
				title: payload.title,
				nationality: payload.nationality,
				email: payload.email,
				phone: payload.phones,
				city: payload.city,
				url_1: payload.url_1,
				description: payload.description,
				birthday: payload.birthday,
			}

			//if(payload.length > 0){

				/*this.form.sid = payload.id
				this.form.soiskatel.title = payload.title
				this.form.soiskatel.nationality = payload.nationality
				this.form.soiskatel.email = payload.email
				this.form.soiskatel.phone = payload.phones
				this.form.soiskatel.city = payload.city
				this.form.soiskatel.url_1 = payload.url_1
				this.form.soiskatel.description = payload.description
				this.form.soiskatel.birthday = payload.birthday*/

				//payload = {}

			//}

		},
		soiskatelChangedValue(field, payload){

			if(field === 'phone'){
				let newval = payload.replace(/\D/g, "")

				if(newval === ""){
					newval = "7"
				}

				this.form.soiskatel.phone = newval
				this.validatePhone()
			}
			else{
				this.form.soiskatel[field] = payload
			}

		},

		excludeChannel(){

			let rez = []

			for (let key=0; key < this.guides.channels.length; key++) {

				if (!this.guides.channels[key].active) {

					rez.push(this.guides.channels[key].id)

				}

			}

			//console.log(rez)

			this.excludedChannel = rez

		},
		excludeProject(){

			let rez = []

			for (let key=0; key < this.guides.projects.length; key++) {

				if (!this.guides.projects[key].active) {

					rez.push(this.guides.projects[key].id)

				}

			}

			//console.log(rez)

			this.excludedProject = rez

		},
		toggleTimePanel() {
			this.showTimePanel = !this.showTimePanel;
		},
		handleOpenChange() {
			this.showTimePanel = false;
		},
		handleChange(value, type) {
			//console.log(type)
			if (type === 'date') {
				this.toggleTimePanel()
			}
			if (type === 'minute') {
				this.openPanel = false
			}
		},
		toggleTimePanel2() {
			this.showTimePanel2 = !this.showTimePanel2;
		},
		handleOpenChange2() {
			this.showTimePanel2 = false;
		},
		handleChange2(value, type) {
			if (type === 'date') {
				this.toggleTimePanel2()
			}
			if (type === 'minute') {
				this.openPanel2 = false
			}
		},
		notBeforeToday(date) {
			return date < new Date(new Date().setHours(0, 0, 0, 0));
		},
	}
}
</script>

<style>



</style>