<template>

	<div class="filters Bold" data-id="address">

		<div class="radio popblock-item">
			<label class="wp100 p5">
				<input type="radio" value="date_create" v-model="sort" @change="updateFilter">
				<span class="custom-radio"><i class="icon-radio-check"></i></span>
				<span class="pl10">{{ language.Dates.Create }}</span>
			</label>
		</div>

		<div class="radio popblock-item">
			<label class="wp100 p5">
				<input type="radio" value="title" v-model="sort" @change="updateFilter">
				<span class="custom-radio"><i class="icon-radio-check"></i></span>
				<span class="pl10">{{ language.Name }}</span>
			</label>
		</div>

		<div class="radio popblock-item">
			<label class="wp100 p5">
				<input type="radio" value="address" v-model="sort" @change="updateFilter">
				<span class="custom-radio"><i class="icon-radio-check"></i></span>
				<span class="pl10">{{ language.Address.Address }}</span>
			</label>
		</div>

		<div class="radio popblock-item">
			<label class="wp100 p5">
				<input type="radio" value="phone" v-model="sort" @change="updateFilter">
				<span class="custom-radio"><i class="icon-radio-check"></i></span>
				<span class="pl10">{{ language.Phone }}</span>
			</label>
		</div>

		<div class="radio popblock-item no-border">
			<label class="wp100 p5">
				<input type="radio" value="city" v-model="sort" @change="updateFilter">
				<span class="custom-radio"><i class="icon-radio-check"></i></span>
				<span class="pl10">{{ language.City }}</span>
			</label>
		</div>

	</div>

</template>

<script>
export default {
	name: "Sorts",
	data(){
		return {
			language: this.$store.state.language,
		}
	},
	computed:{
		sort: {
			get () {
				return this.$store.state.candidates.filters.sort !== undefined ? this.$store.state.candidates.filters.sort : 'desc'
			},
			set(value) {
				//console.log("project", value)
				this.$store.commit("candidates/SET_FILTER_SORT", value)
			}
		}
	},
	methods:{
		updateFilter(){
			//console.log(this.form)
			this.$emit("updateFilters", this.form)
		}
	},
}
</script>

<style scoped>

</style>