<template>

	<div class="ydropDown wp100" @click="toggle" :class="{'checked':checked, 'like-input':label, 'req':required}" ref="itemSelect">

		<span title="Показатель"><i class="icon-article-alt black hidden"></i></span>
		<span class="ydropText">{{text}}</span>
		<i class="icon-down-open pull-aright arrow"></i>

		<div class="yselectBox" style="max-height: 250px; width:100%" v-if="show">
			<div class="ydropString yRadio" aria-disabled="true">
				<label class="wp95 gray-blue" @click="select({})">
					<input type="radio" v-model="selected" value="null" @change="select({})">Необходимо выбрать
				</label>
			</div>
			<div v-for="(item, index) in guides.reasons" class="ydropString yRadio" :key="index" :class="{'bluebg-sub':item.title === selected}">
				<label class="wp95" @click="select(item, index)">
					<input type="radio" v-model="selected" :value="item.title" @change="select(item)">
					{{item.title}}
				</label>
			</div>
		</div>
		<div class="label">{{language.Reason}}</div>

	</div>

</template>

<script>

export default {
	name: "Reason",
	props: {
		value: String,
		label: Boolean,
		required: Boolean,
	},
	data(){
		return {
			guides: this.$store.state.guides,
			language: this.$store.state.language,
			selected: "",
			show: false,
			checked: false,
			text: ""
		}
	},
	mounted() {

	},
	watch:{
		selected(){
			this.selected = this.value
			//this.select()
		},
		text(){

		}
	},
	created() {
		//console.log("value:", this.value)
		if(this.value !== "") {

			this.selected = this.value
			this.text = this.value
			this.checked = true

		}

		//this.select()
	},
	methods:{
		select(selected, index){

			this.selected = selected.title

			if(this.selected !== "" && this.selected !== undefined) {

				/*for (let key in this.guides.reasons) {

					if (this.guides.reasons[key].title.toUpperCase().replace(" ", "").match(this.selected.toUpperCase().replace(" ", ""))) {

						//console.log(this.guides.reasons[key].title)
						this.text = this.guides.reasons[key].title
						this.checked = true
						this.selected = this.guides.reasons[key].title

						break

					}

				}*/

				this.text = this.guides.reasons[index].title
				this.checked = true
				this.selected = this.guides.reasons[index].title

			}
			else{

				this.text = ''
				this.checked = true
				this.selected = ''

			}

			this.update()

		},
		update(){

			//this.text = selected.title !== '' ? selected.title : ''
			//this.checked = selected.title !== ''
			//this.selected = selected.title

			//console.log("new prj:", this.project)

			this.$emit("select", this.selected)

		},
		toggle(){
			this.show = !this.show
		},
		open(){
			this.show = true
		},
		close(){
			this.show = false
		}
	}
}
</script>

<style scoped>

</style>