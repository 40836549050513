<template>

	<div>

		<BaseFilter v-model="projectFilter" @list="updateFilters" ref="filters">
			<template v-slot:filters>
				<Filters v-model="pageFilters"></Filters>
			</template>
			<template v-slot:sorts>
				<Sorts v-model="sortFilters"></Sorts>
			</template>
			<template v-slot:title>
				{{ language.Feedbacks }}
			</template>
		</BaseFilter>

		<div class="page--body" @click="closeAllFilters">

			<div v-if="loading" class="loading"></div>

			<h2 class="uppercase">{{ language.Feedbacks }} <span v-if="total > 0">[ <span class="blue">{{ total }}</span> ]</span></h2>

			<div class="wp100 mx-auto mt10 pt10">

				<div class="infodiv mb10" v-if="filtersCount > 0 && filtersCount !== null">
					<div class="mt5">
						<div v-if="filters.projects && filters.projects.length > 0" class="infodiv inline bgwhite mr5">{{ language.Projects }}:<b>{{ filters.projects.join(", ") }}</b></div>
						<div v-if="filters.channels && filters.channels.length > 0" class="infodiv inline bgwhite mr5">{{ language.Channel }}:<b>{{ filters.channels.join(", ") }}</b></div>
						<div v-if="filters.results && filters.results.length > 0" class="infodiv inline bgwhite mr5">{{ language.Results }}:<b>{{ filters.results.join(", ") }}</b></div>
						<div v-if="filters.users && filters.users.length > 0" class="infodiv inline bgwhite mr5">{{ language.Operator }}:<b>{{ filters.users.join(", ") }}</b></div>
						<div v-if="filters.content != null" class="infodiv inline bgwhite mr5">{{ language.Content }}:<b>{{ filters.content }}</b></div>
						<div v-if="filters.phone != null" class="infodiv inline bgwhite mr5">{{ language.Phone }}:<b>{{ filters.phone }}</b></div>
						<div v-if="filters.city" class="infodiv inline bgwhite mr5">{{ language.City }}:<b>{{ filters.city }}</b></div>
						<div v-if="filters.finished" class="infodiv inline bgwhite mr5"><b>{{ language.Finished }}</b></div>
						<div v-if="filters.approved" class="infodiv inline bgwhite mr5"><b>{{ language.Approved }}</b></div>
					</div>
				</div>

				<div class="flex-content2 typelist reaction p5 tbl--header sticked--top">

					<div class="flex-string2">ID</div>
					<div class="flex-string2">{{ language.Date }}</div>
					<div class="flex-string2 ellipsis">{{ language.Vacancy }}, {{ language.Project }}</div>
					<div class="flex-string2">{{ language.Address.Address }}</div>
					<div class="flex-string2">{{ language.Candidate }}</div>
					<div class="flex-string2">{{ language.Result }}</div>
					<div class="flex-string2">{{ language.Operator }}</div>
					<div class="flex-string2 text-center">{{ language.Actions }}</div>

				</div>

				<div v-for="(item, index) in items" :key="index" class="flex-content typelist reaction ha border-bottom p5" :class="{'graybg-sub':item.results === null || item.results.id === null}">
					<div class="flex-string">{{ item.id }}</div>
					<div class="flex-string">{{ $moment(item.date_create).format('DD.MM.YY H:mm') }}</div>
					<div class="flex-string hand" @click="viewFeedbackCard(item.id)">

						<div v-if="item.title !== null" class="fs-12 flh-12 Bold blue ellipsis">{{ item.title }}</div>
						<div v-else class="fs-12 flh-12 Bold red ellipsis">Не указано</div>

						<div class="fs-09 text-wrap mt5">
							<i v-if="!item.projectActive" class="icon-attention-1 red" title="Проект отключен"></i>{{ item.projectName }}
						</div>

					</div>
					<div class="flex-string">
						<div v-if="item.address !== null && item.address.id > 0" class="hand" @click="viewAddressCard(item.address.id)" :title="item.soiskatel.title">
							<div class="Bold fs-10 ellipsis">{{ item.address.title }}</div>
							<br>
							<div class="fs-09 flh-11 mt10 ellipsis">{{ item.address.address }}</div>
						</div>
						<div class="fs-09 blue text-wrap mt5">{{ item.city }}</div>
					</div>
					<div class="flex-string">
						<div v-if="item.sid > 0" :title="item.soiskatel.title">
							<div :class="{'hand':item.sid > 0}" @click="viewCandidateCard(item.sid)">
								<div class="fs-11 Bold text-wrap">{{ item.soiskatel.title }}</div>
								<div class="fs-09 text-wrap mt5">
									<span v-if="item.soiskatel.age" class="Bold blue">{{ item.soiskatel.age }},</span>
									<span v-if="item.soiskatel.phone" class=""> {{ item.soiskatel.phone }},</span>
									<span v-if="item.soiskatel.email" class="Bld"> {{ item.soiskatel.email }}</span>
								</div>
							</div>
							<div v-if="item.date_approve !== null && item.isWin" class="Bold fs-09 success w--content border-box p3 mt5">
								Принят {{ $moment(item.date_approve).format('MM.YY') }}
								<div v-if="item.id_approved > 0" class="pull-right hand" @click="approveFeedbackDelete(item)" :title="language.DeleteMarkAsApproved">
									<i class="icon-cancel-circled-1 green"></i>
								</div>
							</div>
						</div>
						<div v-if="item.covid !== null" class="mt5 blue fs-09">{{ item.covid.title }}</div>
					</div>
					<div class="flex-string">
						<div v-if="item.results !== null">
							<div class="Bold" :class="item.results.color">
								<i v-if="item.reasonfull !== undefined" class="icon-clock orange"></i><span :class="{'orange':item.reasonfull !== undefined}">{{ item.results.title }}</span>
							</div>
							<div v-if="item.scheduleone" class="mt5 fs-09">
								<b class="blue">{{ $moment(item.scheduleone.date_from).format('H:mm') }}</b>, {{ $moment(item.scheduleone.date_from).format('DD.MM.YY') }}
							</div>
						</div>
						<div v-if="item.reason" class="fs-09 text-wrap mt5"><span v-html="item.reason"></span></div>
						<div v-if="item.dropped" class="mt10 fs-09 red">
							<a href="javascript:void(0)" @click="getDropLog(item.id)" title="Почему? Скачать лог">
								<i class="icon-cancel-circled red"></i>&nbsp;Отклонён
							</a>
						</div>
					</div>
					<div class="flex-string">

						<div v-if="item.user !== null" class="ellipsis Bold" :title="item.user">{{ item.user }}</div>
						<div v-else-if="true">-</div>

						<div v-if="item.date_work !== null" class="elipsis fs-09 red">{{ $moment(item.date_work).format('DD.MM.YY H:mm') }}</div>
						<div v-if="item.date_end !== null" class="elipsis fs-09 green">{{ $moment(item.date_end).format('DD.MM.YY H:mm') }}</div>

					</div>
					<div class="flex-string text-right">

						<a v-if="$store.state.user.settings.approved === 'on' && item.id_approved === 0 && item.results !== null && item.results.type === 'win'" href="javascript:void(0)" @click="approveFeedbackItem(item)" :title="language.MarkAsApproved" class="button dotted greenbtn m0">
							<i class="icon-handshake-o"></i>
						</a>
						<a v-else-if="$store.state.user.settings.approved === 'on' && item.id_approved > 0" @click="approveFeedbackItem(item)" :title="language.EditMarkAsApproved" class="button dotted orangebtn m0">
							<i class="icon-handshake-o"></i>
						</a>

						<a v-if="item.date_end === null" @click="setInwork(item)" :title="language.Processing" class="button dotted greenbtn m0 disabled1 no-shadow"><i class="icon-tools"></i></a>
						<a v-if="editable && item.isSchedule !== null" @click="editFeedbackItem(item.id)" :title="language.Edit" class="button dotted bluebtn m0"><i class="icon-pencil"></i></a>
						<a v-if="editable && item.isSchedule !== null" @click="deleteItem(item.id)" :title="language.Delete" class="button dotted redbtn m0"><i class="icon-cancel-circled"></i></a>

					</div>
				</div>
				<div v-if="items == null || items.length === 0">
					<div class="attention mt10">Нет записей</div>
				</div>

			</div>

			<div class="pagediv">

				<a href="javascript:void(0)" title="Первая" v-if="page > 1" @click="changePage(1)">&nbsp;<i class="icon-angle-double-left"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Предыдущая" v-if="page > 1" @click="changePage(page - 1)">&nbsp;<i class="icon-angle-left"></i>&nbsp;&nbsp;</a>
				{{ page }}
				<a href="javascript:void(0)" title="Следующая" v-if="page >= 1 && items !== null && items.length > 0" @click="changePage(page + 1)">&nbsp;<i class="icon-angle-right"></i>&nbsp;&nbsp;</a>

			</div>

			<div class="space-100"></div>

			<div v-if="$store.state.user.settings.export === 'on'" class="mt20 p0 bottom-block" data-id="address">

				<A href="javascript:void(0)" @click="editFeedbackItem(-1)" title="Добавить">
					<i class="icon-plus"></i>
				</A>

				<A href="javascript:void(0)" @click="feedbackExport" title="Экспорт" class="ml10 blue">
					<i class="icon-download-1"></i>
				</A>

				<A href="javascript:void(0)" @click="feedbackCompare" title="Сверка принятых" class="ml10 red">
					<i class="icon-list-nested"></i>
				</A>

				<A href="javascript:void(0)" @click="bigReport" title="Большой отчет" class="ml10 teal">
					<i class="icon-chart-line"></i>
				</A>

				<A href="javascript:void(0)" @click="timingReport" title="Статистическая выгрузка" class="ml10 indigo">
					<i class="icon-clock"></i>
				</A>

			</div>

		</div>

		<SubPanel ref="subpanel" :show="showsubpanel">
			<template v-if="addresscard > 0" v-slot:swbody>
				<AddressCard :id="addresscard" @close="closeCard" @edit="editAddressItem"></AddressCard>
			</template>
			<template v-else-if="feedbackcard > 0" v-slot:swbody>
				<FeedbackCard :id="feedbackcard" :editabble="true" @close="closeCard" @edit="editFeedbackItem" @cedit="editCandidateItem"></FeedbackCard>
			</template>
			<template v-else-if="candidatecard > 0" v-slot:swbody>
				<CandidateCard :id="candidatecard" @close="closeCard" @edit="editCandidateItem"></CandidateCard>
			</template>
		</SubPanel>

		<DialogPlus ref="dialogplus" :width="800" :show="droplogView" :loaded="true" @close="closeDialogPlus">
			<template v-slot:dlgplusbody>
				<DIV class="zagolovok">Просмотр лога</div>
				<div id="formtabs" class="pr20 pl20">
					<div>
						<json-tree :data="droplog"></json-tree>
					</div>
				</div>
			</template>
		</DialogPlus>

		<Dialog ref="dialog" :show="showdialog" :width="dialogwidth" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="idaddress > 0" v-slot:dlgbody>
				<AddressForm :id="idaddress" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="idcandidate > 0" v-slot:dlgbody>
				<CandidateForm :id="idcandidate" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="idfeedback > 0 || idfeedback === -1" v-slot:dlgbody>
				<FeedbackForm :id="idfeedback" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="approve" v-slot:dlgbody>
				<FeedbackApprove :params="approveData" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="exportData" :width="dialogwidth" v-slot:dlgbody>
				<FeedbackExport @close="closeDialog" @loaded="dialogLoaded"/>
			</template>
			<template v-else-if="compareData" :width="dialogwidth" v-slot:dlgbody>
				<FeedbackCompare @close="closeDialog" @loaded="dialogLoaded"/>
			</template>
			<template v-else-if="bigreportData" :width="dialogwidth" v-slot:dlgbody>
				<FeedbackBigReport @close="closeDialog" @loaded="dialogLoaded"/>
			</template>
			<template v-else-if="timingData" :width="dialogwidth" v-slot:dlgbody>
				<FeedbackTimingExport @close="closeDialog" @loaded="dialogLoaded"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
import axios from "axios"

import JsonTree from "vue-json-tree"
Vue.use(JsonTree)
Vue.component('json-tree', JsonTree)

import BaseFilter from "@/components/filters/BaseFilter"
import Filters from "@/pages/feedback/Filters"
import Sorts from "@/pages/feedback/Sorts"

import SubPanel from "@/components/modal/SubPanel"
import Dialog from "@/components/modal/Dialog"
import DialogPlus from "@/components/modal/DialogPlus"

import CandidateCard from "@/pages/candidate/CandidateCard"
import CandidateForm from "@/pages/candidate/CandidateForm"

import FeedbackCard from "@/pages/feedback/FeedbackCard"
import FeedbackForm from "@/pages/feedback/FeedbackForm"

import AddressCard from "@/pages/address/AddressCard"
import AddressForm from "@/pages/address/AddressForm"
import FeedbackApprove from "@/pages/feedback/FeedbackApprove"
import FeedbackExport from "@/pages/feedback/FeedbackExport"
import FeedbackCompare from "@/pages/feedback/FeedbackCompare"
import FeedbackBigReport from "@/pages/feedback/FeedbackBigReport"
import FeedbackTimingExport from "@/pages/feedback/FeedbackTimingExport";
import Vue from "vue";
//import { EventBus } from "@/Events.js";

export default {
	name: "Feedback",
	components: {
		FeedbackTimingExport,
		FeedbackBigReport,
		FeedbackExport,
		FeedbackApprove,
		FeedbackCompare,
		Dialog,
		DialogPlus,
		BaseFilter,
		Filters,
		Sorts,
		SubPanel,
		FeedbackCard,
		FeedbackForm,
		AddressCard,
		AddressForm,
		CandidateCard,
		CandidateForm,
		JsonTree
	},
	data() {
		return {
			loading: false,
			filters: {},
			filtersCount: 0,
			items: null,
			page: 1,
			pageall: 1,
			total: 0,
			dialog: {},
			addresscard: 0,
			idaddress: 0,
			feedbackcard: 0,
			idfeedback: 0,
			candidatecard: 0,
			idcandidate: 0,
			showsubpanel: false,
			showdialog: false,
			dialogloaded: false,
			dialogplusloaded: false,
			dialogwidth: 800,
			approve: false,
			approveData: null,
			exportData: false,
			compareData: false,
			bigreportData: false,
			timingData: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			editable: this.$store.state.user.settings.edit.feedback === 'on',
			droplog: {},
			droplogView: false,
			showsubpanel2: false,
		}
	},
	computed: {
		projectFilter: {
			get() {
				return {
					project: Array.isArray(this.$store.state.feedbacks.filters.project) ? this.$store.state.feedbacks.filters.project : [],
					order: this.$store.state.feedbacks.filters.order !== '' ? this.$store.state.feedbacks.filters.order : 'desc',
				}
			},

			set(value) {
				let newfilter = {
					project: value.project,
					order: value.order,
					feedbacks: this.$store.state.feedbacks.filters.feedbacks,
					sort: this.$store.state.feedbacks.filters.sort,
				}
				this.$store.commit("feedbacks/SET_FILTERS", newfilter)
			}
		},

		sortFilters: {
			get() {
				return this.$store.state.feedbacks.filters.sort !== '' ? this.$store.state.feedbacks.filters.sort : 'title'
			},

			set(value) {
				let newfilter = {
					project: this.$store.state.feedbacks.filters.project,
					feedbacks: this.$store.state.feedbacks.filters.feedbacks,
					order: this.$store.state.feedbacks.filters.order,
					sort: value
				}
				this.$store.commit("feedbacks/SET_FILTERS", newfilter)
			}
		},

		pageFilters: {
			get() {
				return this.$store.state.feedbacks.filters.feedbacks
			},

			set(value) {
				let newfilter = {
					project: this.$store.state.feedbacks.filters.project,
					feedbacks: value,
					order: this.$store.state.feedbacks.filters.order,
					sort: this.$store.state.feedbacks.filters.sort,
				}
				this.$store.commit("feedbacks/SET_FILTERS", newfilter)
			}
		},

		feedbacks: {
			get() {
				return this.$store.state.feedbacks.filters.feedbacks
			},

			set(value) {
				this.$store.commit("feedbacks/SET_FILTERS", value)
			}
		},

		projects: {
			get() {
				return Array.isArray(this.$store.state.feedbacks.filters.project) ? this.$store.state.feedbacks.filters.project : []
			},
			set(value) {
				this.$store.commit("feedbacks/SET_FILTER_PROJECTS", value)
			}
		},

		order: {
			get() {
				return this.$store.state.feedbacks.filters.order !== '' ? this.$store.state.feedbacks.filters.order : 'title'
			},
			set(value) {
				this.$store.commit("feedbacks/SET_FILTER_ORDER", value)
			}
		}
	},
	comments: {},

	created() {
		//console.log(this.$store.state.user)
		this.getList()
		this.pageTitle = this.language.Feedbacks
		document.title = this.$route.meta.title;
	},

	watch: {
		// при изменениях маршрута запрашиваем данные снова
		routes: 'getList'
	},

	methods: {
		updateFilters() {

			this.changePage(1)

		},
		getList() {

			let param = [];

			param = JSON.parse(JSON.stringify(this.$store.state.feedbacks.filters))

			let feedbacks = param.feedbacks

			delete param.feedbacks

			for (let key in feedbacks) {
				if (feedbacks[key]) {
					param[key] = feedbacks[key]
				}
			}

			param['page'] = this.$store.state.feedbacks.feedback.page
			param['limit'] = 50
			param['action'] = "list"

			this.loading = true

			axios.post(this.apiBaseURL + '/api/feedback/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {
					this.filters = data.data.result.filters
					this.items = data.data.result.list
					this.page = data.data.result.page
					this.pageall = data.data.result.pageall
					this.total = data.data.result.total
					this.filtersCount = data.data.result.filtersCount
					//this.editable = data.data.result.editor
					this.$store.commit('feedbacks/SET_FEEDBACK', data.data.result)

					this.loading = false
				},
				/*(response) => {
					console.log("address:", response)
				}*/
			).catch(error => {

				this.loading = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

			})

		},
		updateList() {

			if (this.showsubpanel && this.addresscard > 0) {

				let c = this.addresscard

				this.closeCard()

				let self = this;
				setTimeout(function () {
					self.viewAddressCard(c)
				}, 100);

			}
			else if (this.showsubpanel && this.feedbackcard > 0) {

				let c = this.feedbackcard

				this.closeCard()

				let self = this;
				setTimeout(function () {
					self.viewFeedbackCard(c)
				}, 100);

			}
			else if (this.showsubpanel && this.candidatecard > 0) {

				let c = this.candidatecard

				this.closeCard()

				let self = this;
				setTimeout(function () {
					self.viewCandidateCard(c)
				}, 100);

			}

			this.getList();

		},
		changePage(page) {
			this.page = page
			this.$store.commit("feedbacks/CHANGE_PAGE", page)

			this.getList()
		},

		viewAddressCard(payload) {
			this.candidatecard = 0
			this.feedbackcard = 0
			this.addresscard = parseInt(payload)
			this.showsubpanel = true
		},
		editAddressItem(payload) {
			this.idfeedback = 0
			this.idcandidate = 0
			this.idaddress = parseInt(payload)
			this.approve = false
			this.approveData = {}
			this.exportData = false
			this.compareData = false
			this.bigreportData = false
			this.timingData = false
			this.showdialog = true
		},

		viewCandidateCard(payload) {
			this.addresscard = 0
			this.feedbackcard = 0
			this.candidatecard = parseInt(payload)
			this.showsubpanel = true
		},
		editCandidateItem(payload) {
			this.idaddress = 0
			this.idfeedback = 0
			this.idcandidate = parseInt(payload)
			this.approve = false
			this.approveData = {}
			this.exportData = false
			this.compareData = false
			this.bigreportData = false
			this.timingData = false
			this.showdialog = true
		},

		viewFeedbackCard(payload) {
			this.addresscard = 0
			this.candidatecard = 0
			this.feedbackcard = parseInt(payload)
			this.showsubpanel = true
		},
		editFeedbackItem(payload) {
			this.idaddress = 0
			this.idcandidate = 0
			this.idfeedback = parseInt(payload)
			this.approve = false
			this.approveData = {}
			this.exportData = false
			this.compareData = false
			this.bigreportData = false
			this.timingData = false
			this.showdialog = true
		},
		approveFeedbackItem(payload) {
			this.idaddress = 0
			this.idfeedback = 0
			this.idcandidate = 0
			this.exportData = false
			this.compareData = false
			this.approve = true
			this.approveData = payload
			this.timingData = false
			this.showdialog = true
		},
		approveFeedbackDelete(payload) {

			this.$swal.fire({
				title: "Are You Sure?",
				text: 'Item will be deleted forever',
				icon: 'question',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: '#3085D6',
				cancelButtonColor: '#D33',
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel"
			}).then((result) => {

				if (result.value) {

					// отправка формы здесь
					let param = {};

					param.id = payload.id_approved
					param.action = "approveDelete"

					axios.post(this.apiBaseURL + '/api/feedback/', param, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							(data) => {

								this.message = data.data.result.result
								this.getList()

								this.$toast.success({
									title: 'Success',
									message: data.data.result.result,
									position: "bottom center",
									timeOut: 3000
								})

							},
						)
						.catch(error => {

							this.$toast.error({
								title: 'Error ' + error.code,
								message: "" + error.text,
								position: "bottom center",
								timeOut: 3000
							})

						})

				}

			});

		},
		feedbackExport() {
			this.idaddress = 0
			this.idfeedback = 0
			this.idcandidate = 0
			this.approve = false
			this.approveData = {}
			this.exportData = true
			this.compareData = false
			this.bigreportData = false
			this.timingData = false
			this.showdialog = true
			this.dialogwidth = 600
		},
		feedbackCompare() {
			this.idaddress = 0
			this.idfeedback = 0
			this.idcandidate = 0
			this.approve = false
			this.approveData = {}
			this.exportData = false
			this.compareData = true
			this.bigreportData = false
			this.timingData = false
			this.showdialog = true
			this.dialogwidth = 600
		},
		bigReport() {
			this.idaddress = 0
			this.idfeedback = 0
			this.idcandidate = 0
			this.approve = false
			this.approveData = {}
			this.exportData = false
			this.compareData = false
			this.bigreportData = true
			this.timingData = false
			this.showdialog = true
			this.dialogwidth = 600
		},
		timingReport() {
			this.idaddress = 0
			this.idfeedback = 0
			this.idcandidate = 0
			this.approve = false
			this.approveData = {}
			this.exportData = false
			this.compareData = false
			this.bigreportData = false
			this.timingData = true
			this.showdialog = true
			this.dialogwidth = 600
		},
		deleteItem(payload) {

			this.$swal.fire({
				title: this.language.msg.YouSure,
				text: this.language.msg.RealyDeleteThis,
				icon: 'question',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: '#3085D6',
				cancelButtonColor: '#D33',
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel"
			}).then((result) => {

				if (result.value) {

					// отправка формы здесь
					let param = {};

					param.id = payload
					param.action = "delete"

					axios.post(this.apiBaseURL + '/api/feedback/', param, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							(data) => {

								this.message = data.data.result.result
								this.getList()

								this.$toast.success({
									title: 'Success',
									message: data.data.result.result,
									position: "bottom center",
									timeOut: 3000
								})

							},
						).catch(error => {

						this.loading = false

						this.$toast.error({
							title: 'Error',
							message: error.response.status + ": " + error.response.statusText,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

					})

				}

			});

		},
		getDropLog(payload) {

			// отправка формы здесь
			let param = {};

			param.id = payload
			param.action = "getlog"

			axios.post(this.apiBaseURL + '/api/feedback/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.droplog = data.data.result.data
						this.droplogView = true

					},
				)
				.catch(error => {

					this.loading = false

					this.$toast.error({
						title: 'Error',
						message: error.response.status + ": " + error.response.statusText,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})

				})

		},

		setInwork(payload) {

			let param = {};

			param['id'] = payload.id
			param['action'] = 'inwork'

			this.loading = true

			axios.post(this.apiBaseURL + '/api/operator/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					if (data.data.result === 'ok') {
						this.inwork = 1
						this.loading = false
						//this.$router.push("/operator")
						this.$router.push({name: "operator", params: {id: payload.id}})
					}

				},
			).catch(error => {

				this.loading = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

			})

		},

		closeCard() {
			this.addresscard = 0
			this.candidatecard = 0
			this.feedbackcard = 0
			this.showsubpanel = false
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
		closeDialog() {
			this.idaddress = 0
			this.idfeedback = 0
			this.idcandidate = 0
			this.showdialog = false
			this.dialogwidth = 800
			this.approve = false
			this.approveData = null
			this.exportData = false
			this.timingData = false
		},
		closeDialogPlus(){
			this.droplog = {}
			this.droplogView = false
		},
		closeAllFilters() {
			this.$refs.filters.closeAll()
		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Feedbacks',
		//title: this.$store.state.language.Feedbacks,
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style>
.json-tree-root {
	background-color: #f7f8f9;
	border-radius: 3px;
	margin: 2px 0;
	min-width: unset;
	/*width: 100% !important;*/
	height: 100%;
	overflow-y: auto;
}
.json-tree-indent{
	display: inline-block;
	width: 10px;
}
</style>