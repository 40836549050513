<template>

	<div>

		<DIV class="zagolovok">{{ language.Add }}/{{ language.Edit }} {{ language.Marketing.Campaign }}</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading && showit" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="space-10"></div>

				<div data-id="feedback">

					<div class="flex-container mb10 box--child">

						<div class="flex-string wp100 mt10 relative material mradio">

							<project v-model="form.project" :id="parseInt(form.project)" @select="selectProject" :required="true" :class="{'required--error':$v.form.project.$error}"/>

						</div>

					</div>

					<div class="flex-container mb5 box--child">

						<city-suggestions v-model="form.city" id="city" :value="form.city" :city="city" @update="cityChanged" ref="city"/>

					</div>

					<div class="flex-container mt10 box--child">

						<VacancyList v-model="form.title" :project="form.project" :value="form.title" :required="true" @update="vacancyChange" @updatefull="vacancyChangeFull" />

					</div>

					<div v-if="xzarplata > 0" class="flex-container mt10 box--child infodiv">
						Заработная плата:  <b class="blue">  {{ xzarplata }}</b>
					</div>
					<div v-else class="flex-container mt10 mb10 box--child warning">
						Заработная плата не найдена в базе
					</div>

					<div class="flex-container mb10 box--child">

						<div class="flex-string wp100 mt10 relative material mradio">

							<TarifRadio v-model="form.tarif" :label="true" :name="language.Marketing.Tarif" :id="parseInt(form.tarif)" :required="true" :exclude="[]" @select="tarifChanged"/>

						</div>

					</div>

					<div class="flex-container mt10 mb5 box--child">

						<div class="flex-string wp100 mt10 relative material">

							<date-picker lang="ru" format="DD.MM.YYYY" :default-value="form.date_start" v-model="form.date_start" valueType="format" @input="changeDate">

								<template v-slot:input>
									<div class="wp100 relative material">
										<input type="text" id="birthday" class="wp100" placeholder=" " autocomplete="off" v-model="form.date_start">
										<label for="birthday">{{language.Dates.Start}}</label>
									</div>
								</template>
								<template v-slot:icon-clear></template>
								<template v-slot:icon-calendar></template>

							</date-picker>

						</div>

					</div>

					<div class="flex-container mt10 mb5 box--child">

						<div class="flex-string wp100 relative material like-input pt20">

							<div class="radio wp30 inline p5">
								<label class="middle">
									<input type="radio" value="false" v-model="form.archive">
									<span class="custom-radio"><i class="icon-radio-check"></i></span>
									<span class="fs-09 Bold blue">{{ language.Active }}</span>
								</label>
							</div>&nbsp;

							<div class="radio wp30 inline p5">
								<label class="middle">
									<input type="radio" value="true" v-model="form.archive">
									<span class="custom-radio alert"><i class="icon-radio-check"></i></span>
									<span class="fs-09 Bold red">{{ language.Archive }}</span>
								</label>
							</div>

							<div class="label">{{ language.Activity }}</div>

						</div>

					</div>

				</div>

				<div class="space-50"></div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<button @click="formSubmit" class="button"><i class="icon-ok"></i>{{language.Save}}</button>
				<button @click="closeForm" class="button graybtn"><i class="icon-cancel"></i>{{language.Cancel}}</button>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
import {required, minLength} from 'vuelidate/lib/validators';
import Project from "@/components/elements/project";
import Vue from "vue";
import CitySuggestions from "@/components/elements/city-suggestions.vue";
import TarifRadio from "@/components/elements/tarif-radio.vue";
import VacancyList from "@/components/elements/vacancylist";

export default {
	name: "CampaignForm",
	components: {
		Project,
		CitySuggestions,
		TarifRadio,
		VacancyList
	},
	props: {
		id: Number,
		project:{
			type: Number,
			default: 0
		},
		city:{
			type: String,
			default: ""
		}
	},
	data() {
		return {
			//loading: false,
			form: {
				card: this.id,
				action: "edit",
				project: null,
				tarif: null,
				title: "",
				city: "",
				date_start: this.$moment().format('DD.MM.YYYY'),
				archive: false,
			},
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			loading: false,
			message: "",
			xzarplata: 0,
			submitStatus: "",
			dialogplusloaded: false,
			dialogpluswidth: 800,
			showit: true,
			isAdmin: this.$store.state.user.isAdmin,
			excludedChannel: [],
			shortcuts: Vue.prototype.$shortcuts,
		}
	},
	validations: {
		form: {
			project: {
				required,
				minValue: 1
			},
			tarif: {
				required,
				minValue: 1
			},
			title: {
				required,
				minLength: minLength(2)
			},
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.$v.form.$touch()
		this.loadCard()
	},
	mounted() {
		//console.log(this.id)
		//this.loadCard()
	},
	watch: {
	},
	methods: {
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: this.language.msg.EmptyRequiredFields,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				// do your submit logic here
				this.submitStatus = 'PENDING'

				// отправка формы здесь
				let param = {};

				param['action'] = 'edit'
				param.id = this.card

				for (let key in this.form) {
					if (this.form[key]) {
						param[key] = this.form[key]
					}
				}

				this.loading = true

				axios.post(this.apiBaseURL + '/api/marketing/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.closeForm()
							this.$emit("update")

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.loading = false

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

				//console.log(this.submitStatus)

			}

		},
		loadCard() {

			this.loading = true

			//console.log(this.card)

			if (this.card > 0) {

				let param = {};

				param.id = this.id
				param.action = 'info'

				axios.post(this.apiBaseURL + '/api/marketing/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							let mrk = data.data.result

							this.form = {
								action: "edit",
								title: mrk.title,
								project: parseInt(mrk.project),
								tarif: parseInt(mrk.tarif),
								city: mrk.city,
								date_start: this.$moment(mrk.date_start).format('DD.MM.YYYY'),
								archive: mrk.archive,
							}

							this.$emit("loaded")

							this.loading = false

						},
					)
					.catch(error => {
						console.log(error)
					})

			} else {

				this.form = {
					card: this.id,
					action: "edit",
					project: this.project > 0 ? this.project : 0,
					tarif: null,
					title: "",
					city: this.city !== "" ? this.city : "",
					date_start: this.$moment().format('DD.MM.YYYY'),
					archive: false,
				}

				this.loading = false

				this.$emit("loaded")

			}

			this.loadZarplata()

		},
		loadZarplata(){

			if (this.form.project > 0 && this.form.city !== '') {

				let param = {};

				param.project = this.form.project
				param.city = this.form.city
				param.action = 'getZarplata'

				axios.post(this.apiBaseURL + '/api/zarplata/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.xzarplata = data.data.result

						},
					)
					.catch(error => {
						console.log(error)
					})

			}

		},
		closeForm() {
			/*this.form = {
				card: this.id,
				action: "edit",
				project: null,
				tarif: null,
				title: "",
				city: "",
				date_start: this.$moment().format('DD.MM.YYYY'),
				archive: false,
			}*/
			this.$emit("close")
		},
		clearInput(element) {
			this.form[element] = ''
		},

		selectProject(payload) {
			this.form.project = payload
		},
		tarifChanged(payload) {
			if (payload !== undefined) {
				this.form.tarif = payload
			}
		},
		cityChanged(payload) {

			if (payload !== undefined) {

				this.form.city = payload

				this.loadZarplata()

			}

		},
		changeDate(date) {
			this.form.date_start = date
		},

		vacancyChange(payload) {
			if (payload !== undefined) {
				this.form.title = payload
			}
		},
		vacancyChangeFull(payload) {
			if (payload !== undefined) {
				this.form.project = payload.project
			}
		},

	}
}
</script>

<style>



</style>