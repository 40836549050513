export const KeyControl = {
	data() {
		return {
			ddKeySubs: [],
		}
	},

	created() {
		this.onShow(this.listenKeys)
		this.onHide(this.unlistenKeys)
	},

	beforeDestroy() {
		this.unlistenKeys()
	},

	methods: {
		listenKeys() {
			document.addEventListener('keydown', this.listenKeyDown)
		},

		unlistenKeys() {
			document.removeEventListener('keydown', this.listenKeyDown)
		},

		listenKeyDown(event) {
			switch (event.key) {
				case 'Esc':
				case 'Escape':
				case 'Tab':
				case 'Enter':
					event.preventDefault()
					event.stopPropagation()
					return this.hide()

				case 'Up':
				case 'ArrowUp':
					event.preventDefault()
					event.stopPropagation()
					if (event.altKey)
						return this.toggle()
					return this.selectPrev()

				case 'Down':
				case 'ArrowDown':
					event.preventDefault()
					event.stopPropagation()
					if (event.altKey)
						return this.toggle()
					return this.selectNext()

				case 'Home':
					event.preventDefault()
					event.stopPropagation()
					return this.selectFirst()

				case 'End':
					event.preventDefault()
					event.stopPropagation()
					return this.selectLast()

				default:
					break
			}
		},
	},
}
