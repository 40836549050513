<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				{{ language.OperatorPlace }}
			</div>

		</div>

		<div class="feedback-list bgwhite" :class="{'open':showstat}" v-click-outside="hideStat">

			<div class="feedback-list-toggler-block">
				<div class="feedback-list-toggler" @click="toggleStat" title="Показать/Скрыть Статистику">
					<i class="icon-chart-bar"></i>{{ language.Statistic }}
				</div>
			</div>

			<div class="head">{{ language.Queue }}</div>
			<div class="list">

				<div v-if="listload" class="content-loading wp100"/>

				<div v-if="!paused">

					<div v-for="(item, index) in list" :key="index" class="ha hand feedback-item" :class="{'current':item.id === feedback}">

						<div class="inotify-item p10">

							<div class="fs-10 blue Bold">{{ item.projectName }}</div>
							<div class="fs-11 flh-12 Bold red">Не обработанный отклик #{{ item.id }}</div>
							<div class="fs-09 mt10">{{ item.rdate_create }}</div>
							<div v-if="item.task !== undefined && item.task.length > 0" class="fs-11 flh-10 Bold mt10">
								<i class="icon-clock red"></i> {{ item.task.time }}, {{ item.task.soiskatel.title }}
							</div>

						</div>

					</div>

					<div v-if="list.length === 0" class="p10">
						{{ language.Nothing }}
					</div>

				</div>
				<div v-else class="attention text-center height100 pt20 noborder">

					<div class="mt20"><i class="icon-pause fs-40 blue"></i></div>
					<div class="Bold fs-14 pt20">Ваш статус = Пауза.</div>
					<div class="mt10">Новые задания не назначаются!</div>

					<div class="text-center mt20">
						<a href="javascript:void(0)" class="button greenbtn">Продолжить обработку</a>
					</div>

				</div>

			</div>
			<div class="head">{{ language.Statistic }}</div>
			<div class="counters graybg-sublite overflow-y pt10 pb10">

				<div v-if="statload" class="content-loading wp100"/>

				<div v-if="!statload">

					<div class="block border-bottom mb10">

						<div class="flex-container float Bold fs-11 p5 mb5">
							<div class="flex-string float pl10">Среднее время</div>
							<div class="flex-string w100 text-right pr5">{{ stat.middletime.minutes }} мин. {{ stat.middletime.sec }} с.</div>
						</div>

					</div>
					<div class="block border-bottom mb10">

						<div class="flex-container float Bold fs-11 p5 mb5">
							<div class="flex-string float pl10">В очереди</div>
							<div class="flex-string w80 text-right pr5">{{ stat.queue }}</div>
						</div>

					</div>
					<div class="block border-bottom mb10">

						<div class="flex-container float Bold fs-11 p5 mb5" title="Задачи не старше 1 недели включая сегодня">
							<div class="flex-string float pl10">Задачи</div>
							<div class="flex-string w80 text-right pr5">{{ stat.oldTask }}</div>
						</div>
						<div class="flex-container float Bold fs-11 p5 mb5" title="Задачи на завтра и далее">
							<div class="flex-string float pl10">Задачи предстоящие</div>
							<div class="flex-string w80 text-right pr5">{{ stat.futureTask }}</div>
						</div>

					</div>

					<div v-for="(item, index) in stat.list" :key="index" class="block border-bottom mb10">

						<div class="flex-container float Bold fs-11 p5 mb5">
							<div class="flex-string float pl10">{{ item.name }}</div>
							<div class="flex-string w80 text-right pr5">{{ item.total }}</div>
						</div>

						<div v-for="(result, index) in item.results" :key="index" class="flex-container float border-bottom fs-09 p5">
							<div class="flex-string float pl10" :class="result.color">{{ result.name }}</div>
							<div class="flex-string w80 text-right pr5">{{ result.count }}</div>
						</div>

					</div>

				</div>

			</div>

		</div>

		<div class="page--body">

			<div class="feedback--body">

				<div class="feedback-form">

					<div class="feedback-form-block" v-if="!formload && !paused && (list.length > 0 || feedback > 0)">

						<div v-if="inwork > 0" class="feedback-container pr20 pl20">

							<div v-if="isblank" class="flex-container mt20 box--child">

								<div class="flex-string wp100 mt20 relative material">

									<date-picker lang="ru" type="datetime" :show-second="false" format="DD.MM.YYYY H:mm" :default-value="form.date_create" :minute-step="5" v-model="form.date_create" valueType="format" @input="changeDateCreate" :show-time-panel="showTimePanel" :open.sync="openPanel" @change="handleChange" @close="handleOpenChange">

										<template v-slot:input>
											<div class="wp100 relative material">
												<input type="text" id="date_create" class="required wp100" placeholder=" " autocomplete="off" v-model="form.date_create">
												<label for="date_create">Время поступления</label>
												<div class="fs-09 text-center blue">Укажите время в часовом поясе Европа/Москва</div>
											</div>
										</template>
										<template v-slot:icon-clear></template>
										<template v-slot:icon-calendar></template>
										<template v-slot:footer>
											<div class="blue p5 hand inline pull-left" @click="toggleTimePanel" :title="showTimePanel ? 'дата' : 'время'">
												<i :class="showTimePanel ? 'icon-calendar-inv' : 'icon-clock'"></i><span class="">{{ showTimePanel ? 'дата' : 'время' }}</span>
											</div>
											<div class="gray p5 hand inline pull-right" @click="openPanel = false" title="Завершить">
												<i class="icon-cancel-circled"></i>
											</div>
										</template>

									</date-picker>

								</div>

							</div>

							<div class="divider mt20 mb20">
								<div class="green">{{ language.CandidateData }}</div>
							</div>

							<div data-id="candidate">

								<div class="flex-container mt10 box--child">

									<div class="flex-string wp60 relative material cleared">

										<autocomplete :classes="'wp99'" :class="{'required--error':$v.form.soiskatel.title.$error}" v-model="form.soiskatel.title" :value="form.soiskatel.title" :name="language.CandidateName" :required="true" :method="'candidate'" :action="'search'" :params="{goal:'title'}" :min="3" :vars="['title','phone','city']" @update="soiskatelChanged" @updatefield="soiskatelChangedValue"></autocomplete>
										<a v-if="form.sid > 0" @click="viewCandidateCard(form.sid)" data-for="soiskateltitle" class="linked mr10 w50 greenbg-dark hand" title="Профиль"><i class="icon-user-1"></i></a>

									</div>

									<div class="flex-string relative wp40">

										<date-picker lang="ru" format="DD.MM.YYYY" valueType="format" :default-value="form.soiskatel.birthday" v-model="form.soiskatel.birthday" @input="changeBirthday">

											<template v-slot:input>
												<div class="wp100 relative material">
													<input type="text" id="birthday" class="wp100" placeholder=" " autocomplete="off" v-model="form.soiskatel.birthday">
													<label for="birthday">{{ language.Human.Birthday }}</label>
												</div>
											</template>
											<template v-slot:icon-clear></template>
											<template v-slot:icon-calendar></template>

										</date-picker>
										<div v-if="age > 0" class="fs-10 Bold" :class="{'red': age < 18, 'green': age >= 18}" style="position:absolute; right: 30px; top:20px; z-index: 2" data-id="age">{{ age }}</div>

									</div>

								</div>

								<div v-if="inBlackList" class="warning mb20 fs-12 text-center" data-id="blacklist">
									<i class="icon-attention-1 red"></i><b class="red">Внимание!</b> Кандидат находится в
									<b class="red">Черном списке</b>
								</div>

								<div v-if="form.sid > 0" class="warning mb20 fs-12 flh-12 text-center" data-id="existsid">

									<div class="Bold red uppercase"><i class="icon-attention-1 red"></i>Внимание!</div>
									<div>Выбран кандидат <b>{{ form.soiskatel.title }}</b>.</div>
									<div>Если хотите указать другого кандидата, эту обработку следует
										<a @click="setOutwork" class="underline">отменить</a></div>

								</div>

								<div class="flex-container mt5 box--child">

									<nationality id="nationality" class="wp100" v-model="form.soiskatel.nationality" :value="form.soiskatel.nationality" @update="nationalityChanged"/>

								</div>

								<div class="flex-container box--child">

									<div class="flex-string wp100 mt10 relative material like-input pt20">

										<div class="codeselect">

											<div v-for="item in codes" :key="item.code" class="infodiv dotted p5" :class="{'bgwhite':form.soiskatel.prefix !== item.code}">

												<div class="checkbox fs-07">

													<label class="text-center">

														<input class="taskss" v-model="form.soiskatel.prefix" type="checkbox" :value="item.code" @change="prefixChanged(item)">
														<span class="Bold" :class="{'gray':form.soiskatel.prefix !== item.code}">
															{{ item.code }}:<span class="Bold pl5">{{ item.name }}</span>
														</span>

													</label>

												</div>

											</div>

										</div>
										<div class="label">Код страны (телефонный)</div>

									</div>

								</div>

								<div class="flex-container mt5 box--child">

									<div class="flex-string wp50 relative material cleared">

										<autocomplete :classes="'wp99'" v-model="form.soiskatel.email" :value="form.soiskatel.email" :name="'Email'" :required="false" :method="'candidate'" :action="'search'" :params="{type:'email',goal:'email'}" :min="5" :vars="['title','phone','email','city']" :class="{'format--error':$v.form.soiskatel.email.$error}" @update="soiskatelChanged" @updatefield="soiskatelChangedValue"></autocomplete>

										<div class="red fs-09" :class="{'hidden':!$v.form.soiskatel.email.$error}">Не корректный формат: не соответствует Email</div>

									</div>

									<div class="flex-string wp50 relative material cleared">

										<autocomplete :classes="'wp100'" v-model="form.soiskatel.phone" :value="form.soiskatel.phone" :name="language.Phone" :required="true" :method="'candidate'" :action="'search'" :params="{type:'phone',goal:'phone'}" :min="5" :vars="['phone','title','email','city']" :class="{'format--error':$v.form.soiskatel.phone.$error}" @update="soiskatelChanged" @updatefield="soiskatelChangedValue" @keyup="validatePhone"></autocomplete>

										<div class="red fs-09" :class="{'hidden':phoneiscorrect}">Не корректный формат:
											<b>{{ phonelength }}</b> цифр из {{maxphonelength}}
										</div>

									</div>

								</div>

								<div class="flex-container mt10 mb0 box--child relative">

									<city-suggestions v-model="form.soiskatel.city" id="city" :classes="'wp30 pr5'" :city="form.soiskatel.city" @update="cityChanged" @select="citySelected" ref="city"></city-suggestions>

									<address-suggestions v-model="form.soiskatel.address" id="address" :classes="'wp70'" :value="form.soiskatel.address" @update="addressChanged"/>

								</div>

								<div class="flex-container box--child">

									<div class="flex-string wp100 relative material">

										<input type="text" v-model="form.soiskatel.url_1" id="url_1" class="wp100 linked" placeholder=" " autocomplete="off" :v="$v.form.soiskatel.url_1" :class="{'format--error':$v.form.soiskatel.url_1.$error}">
										<label for="url_1">Url</label>
										<a v-if="form.soiskatel.url_1 !== '' && form.soiskatel.url_1 !== null && !$v.form.soiskatel.url_1.$error" :href="form.soiskatel.url_1" target="_blank" class="linked urlopen" :title="language.View">{{ language.View }}</a>

									</div>

								</div>

								<div class="flex-container mt5 box--child">

									<div class="flex-string wp100 relative material">

										<TextareaAutosize class="wp100" id="description" placeholder=" " ref="description" v-model="form.soiskatel.description" :min-height="100" :max-height="200" spellcheck="false"/>

										<label for="description">{{ language.Comment }}</label>

									</div>

								</div>

								<div v-if="form.sid > 0" class="flex-container mt5 mb20 box--child hidden">

									<div class="flex-string wp100 relative text-center">

										<a @click="soiskatelReset" class="button redbtn dotted" title="Сбросить Кандидата">Сбросить Кандидата</a>

									</div>

									<div class="space-20 wp100"></div>

								</div>

							</div>

							<div class="divider mt10 mb20">
								<div class="blue">{{ language.FeedbackData }}</div>
							</div>

							<div class="space-10"></div>

							<div data-id="feedback">

								<div class="flex-container mb10 box--child">

									<div class="flex-string wp100 mt10 relative material mradio">

										<project v-model="form.project" :id="parseInt(form.project)" :all="true" :required="true" :exclude="excludedProject" @select="selectProject" :class="{'required--error':$v.form.project.$error}"/>

									</div>

								</div>

								<div class="flex-container mt10 box--child">

									<div class="flex-string wp100 material cleared">

										<Addresinput v-model="form.addressname" :required="false" :classes="'wp100'" :time="time" :min="0" :value="form.addressname" :project="form.project" :city="form.soiskatel.city" @update="addressPointChanged"/>

									</div>

								</div>

								<div class="flex-container mt10 box--child">

									<VacancyList v-model="form.title" :project="form.project" :value="form.title" :time="time" :required="true" @update="vacancyChange" @updatefull="vacancyChangeFull" :class="{'required--error':$v.form.title.$error}"/>

								</div>

								<div class="flex-container mb10 box--child">

									<div class="flex-string wp100 mt10 relative material mradio">

										<ChannelRadio v-model="form.channel" :id="form.channel" :label="true" :exclude="excludedChannel" :required="true" @select="channelChanged" :class="{'required--error':$v.form.channel.$error}"/>

									</div>

								</div>

								<div class="flex-container mt10 mb10 box--child">

									<Typework v-model="form.type_work" :selected="form.type_work" :required="true" @update="typeworkChanged" :class="{'required--error':$v.form.type_work.$error}"/>

								</div>

								<div class="flex-container mt10 mb10 box--child">

									<Typelead v-model="form.type_lead" :selected="form.type_lead" :required="true" @update="typeleadChanged" :class="{'required--error':$v.form.type_lead.$error}"/>

								</div>

							</div>

							<div class="divider mt10 mb20">
								<div class="red">{{ language.WorkResult }}</div>
							</div>

							<div class="space-10"></div>

							<div data-id="result">

								<div class="flex-container mb10 box--child hidden">

									<div class="flex-string wp100 mt10 relative material mradio">

										<CovidRadio v-model="form.covid" :id="form.covid" :label="true" @select="covidChanged"/>

									</div>

								</div>

								<div class="flex-container mb10 box--child">

									<div class="flex-string wp100 relative material mradio">

										<Result v-model="form.results" :id="parseInt(form.results)" :label="true" :required="true" @select="selectResult" :class="{'required--error':$v.form.results.$error}"></Result>

									</div>

								</div>

								<div v-if="resultstype === 'lose'" class="flex-container mb10 box--child" data-block="reasons">

									<div class="flex-string wp100 relative material mradio">

										<Reason v-model="form.reason" :value="form.reason" :label="true" :required="reasonreq" @select="selectReason"></Reason>

									</div>

								</div>

								<div v-if="resultstype === 'task'" class="flex-container mt10 box--child" data-block="task">

									<div class="flex-string wp100 relative material">

										<date-picker lang="ru" type="datetime" :show-second="false" format="DD.MM.YYYY HH:mm" :default-value="form.task.date" :minute-step="5" v-model="form.task.date" valueType="format" @input="changeTaskDay" :show-time-panel="showTimePanel2" :open.sync="openPanel2" @change="handleChange2" @close="handleOpenChange2" :disabled-date="notBeforeToday" :shortcuts="shortcuts">

											<template v-slot:input>
												<div class="wp100 relative material">
													<input type="text" id="day" class="required wp100" placeholder=" " autocomplete="off" v-model="form.task.date">
													<label for="day">Дата и время звонка</label>
												</div>
											</template>
											<template v-slot:icon-clear></template>
											<template v-slot:icon-calendar></template>
											<template v-slot:footer>
												<div class="blue p5 hand inline pull-left" @click="toggleTimePanel2" :title="showTimePanel2 ? 'дата' : 'время'">
													<i :class="showTimePanel2 ? 'icon-calendar-inv' : 'icon-clock'"></i><span class="">{{ showTimePanel2 ? 'дата' : 'время' }}</span>
												</div>
												<div class="gray p5 hand inline pull-right" @click="openPanel2 = false" title="Завершить">
													<i class="icon-cancel-circled"></i>
												</div>
											</template>

										</date-picker>
										<div class="fs-09 gray">Заполняется только при необходимости перезвонить</div>

									</div>

								</div>

								<div v-if="resultstype === 'win' && isNewSolvo" class="flex-container mt5 mb5 box--child" data-block="vacancyid">

									<div class="flex-string wp100 relative material" data-type="vacancyid"></div>
									<div class="flex-string wp100 relative material">

										<SysVacancy v-model="form.vacancyid" :id="form.vacancyid" :project="form.project" :label="true" :required="vacancyreq" @select="selectSysVacancy"/>

									</div>

								</div>

								<div v-if="resultstype === 'win' && isNewSolvo" class="flex-container mb20 box--child" data-block="vacancyid">

									<div class="flex-string wp100 relative material" data-type="vacancyid"></div>
									<div class="flex-string wp100 relative material text-center">

										<A href="javascript:void(0)" @click="checkNewSolvo()" title="Проверить возможность записи" class="button greenbtn">
											<i class="icon-check"></i> Проверить возможность записи
										</A>

									</div>

								</div>

								<div v-if="resultstype === 'win' && isMyRest" class="flex-container mb20 box--child" data-block="vacancyid">

									<div class="flex-string wp100 relative material" data-type="vacancyid"></div>
									<div class="flex-string wp100 relative material text-center">

										<A href="javascript:void(0)" @click="checkMyRest()" title="Проверить возможность записи" class="button greenbtn">
											<i class="icon-check"></i> Проверить возможность записи
										</A>

									</div>

								</div>

								<div v-if="resultstype === 'win'" class="flex-container mt15 mb20 box--child" data-block="sysvacancy">

									<div class="flex-string wp100 relative material" data-type="scheduler"></div>
									<div class="flex-string wp100 relative material">

										<input v-model="form.schedule" type="text" id="schedule" class="wp100 linked" value="" placeholder=" " autocomplete="off" @click="showScheduler">
										<a href="javascript:void(0)" @click="showScheduler" class="linked greenbtn"><i class="icon-calendar-inv"></i>Календарь</a>
										<label for="schedule">Дата и время собеседования</label>

									</div>

								</div>

								<div class="flex-container mt5 box--child">

									<div class="flex-string wp100 relative material">

										<TextareaAutosize class="wp100" id="comment" placeholder=" " ref="description" v-model="form.comment" v-html="form.comment" :min-height="100" :max-height="200" spellcheck="false"/>
										<label for="comment">{{ language.Comment }}</label>

									</div>

								</div>

							</div>

							<div class="space-100"></div>
							<div class="space-100"></div>

						</div>

						<div v-if="inwork > 0" class="flex container button--pane text-center fs-12 Bold p5 pt10">

							<a @click="sendForm" class="flex-string button wp65" :class="{'disabled':buttonsdisabled}"><i class="icon-to-end"></i> {{ language.Processing }}</a>
							<a @click="setOutwork" class="flex-string button redbtn wp30"><i class="icon-cancel-circled"></i>{{ language.Cancel }}</a>
							<a @click="sendFormAndPouse" class="button redbtn m0 hidden"><i class="icon-pause"></i>Fixit & Pause</a>

						</div>

						<div v-if="inwork === 0" class="feedback-roof text-center">

							<div class="mt20 pt40">
								<i class="icon-jobsoiskatel fs-40 hidden"></i>
								<img src="/assets/images/logo-icon.png">
							</div>
							<div class="mt20 fs-20 flh-10 Bold">
								Вы готовы принять отклик в работу?
							</div>
							<div class="mt20 fs-12 flh-12">
								ID: <b>{{ this.fulldata.feedback.id }}</b><br>
								Дата отклика: <b class="blue">{{ this.$moment(this.fulldata.feedback.date_create).format('DD.MM.YYYY HH:mm') }}</b><br>
								Проект: <b>{{ this.fulldata.feedback.projectName }}</b><br>
								Кандидат:<b>{{ this.fulldata.candidate.title }}</b>
							</div>
							<div v-if="this.fulldata.task[0] !== undefined" class="mt20 p10 flh-12">

								Отклик был обработан в {{ this.$moment(this.fulldata.task[0].workat).format('HH:mm, DD.MM.YYYY') }} со статусом "{{ this.fulldata.task[0].result }}"<br>оператором {{ this.fulldata.task[0].user }}
								<br>
								<div class="mt20 attention fs-14 p10 inline">Задача перезвонить в
									<span v-html="this.$moment(this.fulldata.task[0].totime).format('<b>HH:mm</b>, DD.MM.YYYY')"></span>
								</div>

							</div>
							<div v-if="timeInPlace !== null" class="mt20 fs-12 p5">В городе
								<b>{{ this.form.soiskatel.city }}</b> сейчас
								<b class="blue fs-14">{{ timeInPlace }}</b> (город кандидата)
							</div>
							<div class="mt20">
								<a href="javascript:void(0)" @click="setInwork" class="button greenbtn Bold fs-14">
									Взять в работу
								</a>
								<A href="javascript:void(0)" @click="getBlankForm()" title="Добавить вручную" class="button bluebtn Bold fs-14">
									<i class="icon-plus"></i> Добавить вручную
								</A>
							</div>

						</div>

					</div>

					<div class="feedback-roof text-center" v-if="list.length === 0 && feedback === 0">

						<div class="mt20 pt40">
							<i class="icon-jobsoiskatel fs-40 hidden"></i>
							<img src="/assets/images/logo-icon.png">
						</div>
						<div class="space-50"></div>
						<div class="mt20 fs-20 flh-10 Bold">
							<div><i class="icon-doc-alt fs-40"></i></div>
							Очередь пуста, Упс..
						</div>
						<div class="space-50"></div>
						<div class="mt20">
							<A href="javascript:void(0)" @click="getBlankForm()" title="Добавить вручную" class="button bluebtn Bold fs-14">
								<i class="icon-plus"></i> Добавить вручную
							</A>
						</div>

					</div>

					<div v-if="!formload && paused">

						<div class="attention text-center height100 pt20 noborder">

							<div class="mt20"><i class="icon-pause fs-40 blue"></i></div>
							<div class="Bold fs-14 pt20">Ваш статус = Пауза.</div>
							<div class="mt10">Новые задания не назначаются!</div>

						</div>
						<div class="button--pane text-center">
							<a href="javascript:void(0)" @click="setResume" class="button greenbtn">Продолжить</a>
						</div>

					</div>

					<div v-if="formload" class="content-loading wp100"></div>

					<div v-if="formapproveload" class="loading"></div>

				</div>
				<div class="feedback-questions">

					<div class="questions text-center pl20 pr20">

						<div class="text-center pt20 border-box" style="height: 100%; vertical-align: middle">

							<div class="hidden-netbook">
								<div class="fs-20 mb10 blue"><i class="icon-chat-1"></i></div>
								<div class="fs-12 Bold">Скрипты по проектам</div>
							</div>

							<div class="hidden-normal visible-netbook fs-12 Bold">
								<i class="icon-chat-1 blue"></i> Скрипты по проектам
							</div>

							<div class="mt20 pt20 div-center">

								<div class="" style="margin: 0 auto;">

									<a v-for="(item, index) in scriptlinks" :key="index" :href="item.url" class="button" :class="item.color" :title="'Скрипт ' + item.title" target="_blank">{{ item.title }}</a>

								</div>

							</div>

						</div>

					</div>
					<div class="map relative graybg" id="map">

						<MapPoints @select="selectAddress" :list="addresses" :centerpoint="centerpoint" :zoom="zoom" :markers="markers" :keyword="word"></MapPoints>

						<div class="text-center pt20 border-box hidden" style="height: 100%; vertical-align: middle">
							<div class="fs-40 mb20 blue"><i class="icon-map-o"></i></div>
							<div class="fs-12 Bold wp100 text-center pr20 pl20 border-box">
								Здесь будет подгружена карта точек по выбранному проекту
							</div>
						</div>

					</div>

					<div class="mt20 p0 bottom-block" data-id="address">

						<A href="https://www.google.com/maps/d/u/0/viewer?mid=1HkYuMzggyV-SbUeKd4PyP0689P_qh8wI&ll=64.15328497543707%2C86.672608&z=4" title="Карта Google" target="_blank" class="orange">
							<i class="icon-map-o"></i>
						</A>

						<A href="javascript:void(0)" @click="getBlankForm()" title="Добавить вручную" class="ml10 blue">
							<i class="icon-plus"></i>
						</A>

					</div>

				</div>

			</div>

		</div>

		<div v-if="popuper.visible" class="popuper">
			<div class="popuper--container">
				<div class="popuper--title">Данные отклика</div>
				<div class="popuper--close" @click="popuperClose"><i class="icon-cancel-squared"></i></div>
				<div class="popuper--html" v-html="popuper.html"></div>
			</div>
		</div>

		<SubPanel ref="subpanel" :show="showsubpanel">
			<template v-if="candidatecard > 0" v-slot:swbody>
				<CandidateCard :id="candidatecard" @close="closeCard" :nobutton="false" :editabble="false"></CandidateCard>
			</template>
		</SubPanel>

		<template v-if="schedulefor > 0">
			<ScheduleCalendar :id="schedulefor" :classplus="'operator'" @close="closeDialogPlus" @loaded="dialogPlusLoaded" @edit="selectSchedule"/>
		</template>

	</div>

</template>

<script>
import axios from "axios";
import {email, maxLength, minLength, numeric, required, url} from "vuelidate/lib/validators";

import Project from "@/components/elements/project";
import CitySuggestions from "@/components/elements/city-suggestions";
import Nationality from "@/components/elements/nationality";
import ChannelRadio from "@/components/elements/channel-radio";
import CovidRadio from "@/components/elements/covid-radio";
import Addresinput from "@/components/elements/addresinput";
import Typework from "@/components/elements/typework";
import Typelead from "@/components/elements/typelead";
import Reason from "@/components/elements/reason";
import Result from "@/components/elements/results";
import ScheduleCalendar from "@/components/ScheduleCalendar";
import VacancyList from "@/components/elements/vacancylist";
import SysVacancy from "@/components/elements/sysvacancy";
import Autocomplete from "@/components/elements/autocomplete";
import MapPoints from "@/components/MapPoints";
import AddressSuggestions from "@/components/elements/address-suggestions";
import SubPanel from "@/components/modal/SubPanel"
import CandidateCard from "@/pages/candidate/CandidateCard"
import Vue from "vue";

export default {
	name: "Operator",
	components: {
		Autocomplete,
		VacancyList,
		SysVacancy,
		Result,
		Reason,
		Typework,
		Typelead,
		CitySuggestions,
		Nationality,
		Project,
		ChannelRadio,
		Addresinput,
		ScheduleCalendar,
		MapPoints,
		AddressSuggestions,
		CovidRadio,
		SubPanel,
		CandidateCard
	},
	data() {
		return {
			form: {
				card: this.id,
				action: "workit",
				date_create: "",//this.$moment().format('DD.MM.YYYY HH:mm'),
				sid: 0,
				soiskatel: {
					sid: 0,
					title: "",
					birthday: "",
					city: "",
					address: "",
					phone: "7",
					email: "",
					url_1: "",
					nationality: "",
					description: "",
					prefix: "7"
				},
				project: null,
				channel: 0,
				title: "",
				address: 0,
				addressname: "",
				type_work: "",
				type_lead: "",
				results: 0,
				covid: 0,
				reason: "",
				comment: "",
				schedule: "",
				task: {
					date: this.$moment().subtract(30, 'm').format('DD.MM.YYYY HH:mm')
				},
				withpause: false,
				oldsid: 0,
				vacancyid: '',
				timeoffset: 0//(new Date().getTimezoneOffset() / -60)
			},
			fulldata: {
				feedback: {},
				candidate: {},
				task: {}
			},
			excludedChannel: [],
			excludedProject: [],
			guides: this.$store.state.guides,
			feedback: 0,
			loading: false,
			formapproveload: false,
			list: {},
			paused: false,
			inwork: 0,
			scriptlinks: {},
			stat: {},
			addresses: {},
			city: "",
			age: 0,
			prefix: "7",
			codes:[
				{
					name:"Россия",
					code: "7",
					length:11
				},
				{
					name:"Узбекистан",
					code:"998",
					length:12
				}
			],
			maxphonelength:11,
			reasonreq: false,
			resultstype: "",
			schedule: {},
			showdialogplus: false,
			dialogplusloaded: false,
			dialogpluswidth: 800,
			schedulefor: 0,
			showit: true,
			showstat: false,
			phoneiscorrect: true,
			phonelength: 0,
			listload: false,
			formload: true,
			statload: false,
			isblank: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			markers: this.$store.getters.guides.markers,
			language: this.$store.state.language,
			centerpoint: {
				city: 'Москва',
				lat: 55.755814,
				lan: 37.617635
			},
			zoom: 12,
			time: {},
			keyword: "",
			word: "",
			candidatecard: 0,
			showsubpanel: false,
			showTimePanel: false,
			openPanel: false,
			showTimeRangePanel: false,
			openPanel2: false,
			showTimePanel2: false,
			showTimeRangePanel2: false,
			shortcuts: Vue.prototype.$shortcuts, //this.$root.$data.shortcuts,
			popuper: {
				html: "this is html block",
				visible: false
			},
			manualid: 0,
			buttonsdisabled: false,
			refresher: null,
			refresherInterval: null,
			timeInPlace: null,
			timeInPlaceInterval: null,
			inBlackList: false,
			isNewSolvo: false,
			isMyRest: false,
			vacancyreq: false,
			// solvoCheck: false
		}
	},
	validations: {
		form: {
			project: {
				required,
				minValue: 1
			},
			/*date_create:{
				required
			},*/
			channel: {
				required,
				minValue: 1
			},
			results: {
				required,
				minValue: 1
			},
			title: {
				required,
				minLength: minLength(2)
			},
			type_work: {
				required,
				minLength: minLength(2)
			},
			type_lead: {
				required,
				minLength: minLength(2)
			},
			soiskatel: {
				title: {
					required,
					minLength: minLength(2)
				},
				birthday: {},
				email: {
					email,
					minLength: minLength(5)
				},
				phone: {
					numeric,
					minLength: minLength(11),
					maxLength: maxLength(14)
				},
				url_1: {url},
			},
		}
	},
	created() {

		this.form.timeoffset = this.$moment().utcOffset() / 60
		this.$moment.tz.setDefault("Europe/Moscow");

		this.$v.form.$touch()

		if (this.$route.params.id !== undefined && this.$route.params.id > 0) {

			this.manualid = this.$route.params.id
			this.getLists(this.manualid)
			//this.manualid = 0

		}
		else {
			this.getLists()
		}

		this.getScripts()

		this.getStat()
		this.excludeChannel()
		this.excludeProject()

	},
	methods: {
		getLists(id) {

			if (!this.$store.state.user.loggedIn) {
				clearInterval(this.refresherInterval)
				return
			}

			let param = {};

			param['action'] = 'list'

			if (id !== undefined) {
				param['id'] = id
			}

			this.listload = true

			axios.post(this.apiBaseURL + '/api/operator/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					this.list = data.data.result.list
					this.paused = data.data.result.pause
					this.inwork = data.data.result.inwork

					//console.log(data.data.result.inwork)

					if (this.list.length > 0) {
						this.feedback = this.list[0].id
					}
					// пустая очередь
					else {

						this.formload = false
						this.inwork = 0

						clearInterval(this.refresherInterval)
						this.refresherInterval = setInterval(this.refreshTasks, 60000)

					}

					this.listload = false
					this.buttonsdisabled = false

				},
			).then(
				() => {
					if (this.feedback > 0) {
						this.getForm()
					}
				}
			).catch(error => {

				this.listload = false
				this.buttonsdisabled = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				console.log(error)

			})

		},
		getScripts() {

			let param = {};

			param['action'] = 'listScripts'

			this.loading = true

			axios.post(this.apiBaseURL + '/api/operator/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					this.scriptlinks = data.data.result

					this.loading = false
				},
			).catch(error => {

				this.loading = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				console.log(error)

			})

		},
		getStat() {

			let param = {};

			param['action'] = 'statistica'

			this.statload = true

			axios.post(this.apiBaseURL + '/api/operator/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					this.stat = data.data.result
					this.statload = false

				},
			).catch(error => {

				this.statload = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				console.log(error)

			})

		},
		getAddresses(city) {

			let param = {};

			param['action'] = 'listAddreses'
			param['project'] = this.form.project
			param['city'] = city === undefined ? this.form.soiskatel.city : city

			this.loading = true

			axios.post(this.apiBaseURL + '/api/operator/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					this.addresses = data.data.result
					this.zoom = 12;

					this.loading = false

				},
			).catch(error => {

				this.loading = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				console.log(error)

			})

		},
		getForm() {

			let param = {};

			param.action = 'card'
			param.id = this.feedback

			this.form = {
				card: this.id,
				action: "workit",
				date_create: "",//this.$moment().format('DD.MM.YYYY HH:mm'),
				sid: 0,
				soiskatel: {
					sid: 0,
					title: "",
					birthday: "",
					city: "",
					address: "",
					phone: "7",
					email: "",
					url_1: "",
					nationality: "",
					description: "",
					prefix: "7"
				},
				project: null,
				channel: null,
				title: "",
				address: 0,
				addressname: "",
				type_work: "",
				type_lead: "",
				results: 0,
				covid: 0,
				reason: "",
				comment: "",
				schedule: "",
				task: {
					date: this.$moment().subtract(30, 'm').format('DD.MM.YYYY HH:mm')
				},
				withpause: false,
				oldsid: 0,
				timeoffset: 0,
				vacancyid: ''
			}
			this.prefix = "7"
			this.inBlackList = false
			this.resultstype = ""

			this.isNewSolvo = false
			this.vacancyreq = false

			this.formload = true

			clearInterval(this.refresherInterval)

			axios.post(this.apiBaseURL + '/api/feedback/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {
					//console.log(data.data)

					let feedback = data.data.result

					this.fulldata = feedback

					this.inwork = feedback.feedback.inwork;

					//console.log(this.manualid)

					// если отклик в работе || отклик взять вручную
					// отображаем форму сразу
					if (this.feedback === this.inwork || this.manualid > 0) {

						this.form.card = this.feedback
						this.form.action = "workit"
						this.form.sid = feedback.feedback.sid
						this.form.oldsid = feedback.feedback.sid
						this.form.soiskatel = {
							sid: feedback.candidate.sid,
							title: feedback.candidate.title,
							birthday: feedback.candidate.birthday,
							city: feedback.candidate.city,
							address: feedback.candidate.address,
							phone: feedback.candidate.phone,
							email: feedback.candidate.email,
							url_1: feedback.candidate.url_1,
							nationality: feedback.candidate.nationality,
							description: feedback.candidate.odescription,
							prefix: feedback.candidate.prefix,
						}
						this.prefix = feedback.candidate.prefix
						this.inBlackList = this.form.soiskatel.blacklist === 1
						this.form.project = parseInt(feedback.feedback.project)
						this.form.title = feedback.feedback.title
						this.form.channel = parseInt(feedback.feedback.channel)
						this.form.type_work = feedback.feedback.type_work
						this.form.type_lead = feedback.feedback.type_lead
						this.form.address = feedback.feedback.address !== null ? feedback.feedback.address.id : 0
						this.form.addressname = feedback.feedback.address !== null ? feedback.feedback.address.address : ""
						this.form.results = parseInt(feedback.feedback.results.id) > 0 ? feedback.feedback.results.id : null
						this.form.reason = feedback.feedback.reason !== null ? feedback.feedback.reason : ""
						this.form.comment = feedback.feedback.comment
						this.form.task = {
							date: ""
						}

						this.city = feedback.feedback.address !== null ? feedback.feedback.address.city : feedback.candidate.city
						this.word = "г " + this.city
						//this.schedule = feedback.feedback.schedule
						this.resultstype = feedback.feedback.results.type
						this.reasonreq = feedback.feedback.results.type === 'lose'
						this.timeoffset = (new Date().getTimezoneOffset() / -60)
						this.vacancyid = feedback.feedback.vacancyid

						this.form.schedule = ''
						this.zoom = 12;

						// пустой отклик
						if (this.feedback === -1) {
							this.form.date_create = this.$moment(feedback.feedback.date_create).format('DD.MM.YYYY HH:mm')
							this.form.card = feedback.id
							this.feedback = feedback.id
						}

						this.setInwork()
						clearInterval(this.refresherInterval)

						this.calculateAge()
						this.$v.form.$touch()

					}
					else {

						this.feedback = feedback.id
						this.city = feedback.feedback.address !== null ? feedback.feedback.address.city : feedback.candidate.city
						this.word = "г " + this.city

						this.cityChanged(this.city)

					}

					this.manualid = 0
					this.formload = false

				},
			).then(
				() => {

					this.getCityGeo(this.form.soiskatel.city)
					this.getAddresses()

					if (this.inwork === 0) {

						// пока отключено, т.к. хуярит без рабора, что есть отклик в работе
						this.refresherInterval = setInterval(this.refreshTasks, 120000)

					}
					else {

						clearInterval(this.refresherInterval)

					}

				},
			).catch(error => {

				console.log(error)

				this.loading = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

			})

		},
		getBlankForm() {

			// если уже есть взятый в работу отклик, то сначала снимем привязку
			if (this.feedback > 0 && this.inwork === 1) {

				this.setOutwork()

			}

			this.inwork = 1
			this.isblank = true
			this.feedback = -1

			this.getForm()

		},
		getCityGeo(city) {

			let param = {};

			param['action'] = 'citygeo'
			param['city'] = city

			this.loading = true

			axios.post(this.apiBaseURL + '/api/points/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					//console.log("data", data.data)
					//this.centerpoint = data.data.result

					this.centerpoint = {
						city: data.data.result.city,
						lat: !isNaN(data.data.result.lat) ? parseFloat(data.data.result.lat) : 0,
						lan: !isNaN(data.data.result.lan) ? parseFloat(data.data.result.lan) : 0
					}
					this.zoom = 12

					this.word = data.data.result.city

					/**
					 * Расчет текущего времени в городе Кандидата
					 */
					let diff

					if (data.data.result.offset) {

						diff = data.data.result.offset - this.$moment().utcOffset() / 60;
						this.timeInPlace = this.$moment().add(diff, 'hours').format('HH:mm')


						//console.log('current offcet: ' + this.form.timeoffset)
						//console.log('offcet: ' + data.data.result.offset)
						//console.log('diff: ' + diff)
						//console.log('orig: ' + this.$moment() + ', ' + this.$moment().format('HH:mm DD.MM.YYYY'))
						//console.log('mode: ' + this.timeInPlace)


						let vm = this

						clearInterval(this.timeInPlaceInterval)
						this.timeInPlaceInterval = setInterval(function () {
							diff = data.data.result.offset - vm.$moment().utcOffset() / 60;
							vm.timeInPlace = vm.$moment().add(diff, 'hours').format('HH:mm')
						}, 1000)

					}
					else {

						this.timeInPlace = null
						clearInterval(this.timeInPlaceInterval)

					}

					this.loading = false

				},
			).catch(error => {

				this.loading = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				console.log(error)

			})

		},

		setInwork() {

			clearInterval(this.refresherInterval)

			let param = {};

			param['id'] = this.feedback
			param['action'] = 'inwork'

			this.formapproveload = true
			this.popuper = {
				html: "",
				visible: false
			}

			this.isNewSolvo = false
			this.vacancyreq = false

			axios.post(this.apiBaseURL + '/api/operator/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					if (data.data.result === 'ok') {

						let feedback = this.fulldata

						this.inwork = feedback.feedback.inwork;
						this.feedback = feedback.id;

						this.form.card = feedback.id
						this.form.action = "workit"
						this.form.sid = feedback.feedback.sid
						this.form.oldsid = feedback.feedback.sid
						this.form.soiskatel = {
							sid: feedback.feedback.sid,
							title: feedback.candidate.title,
							birthday: feedback.candidate.birthday,
							city: feedback.candidate.city,
							address: feedback.candidate.address,
							phone: feedback.candidate.phone,
							email: feedback.candidate.email,
							url_1: feedback.candidate.url_1,
							nationality: feedback.candidate.nationality,
							description: feedback.candidate.odescription,
							prefix: feedback.candidate.prefix,
						}
						this.prefix = feedback.candidate.prefix
						this.form.project = parseInt(feedback.feedback.project)
						this.form.title = feedback.feedback.title
						this.form.channel = parseInt(feedback.feedback.channel) > 0 ? parseInt(feedback.feedback.channel) : null
						this.form.type_work = feedback.feedback.type_work
						this.form.type_lead = feedback.feedback.type_lead
						this.form.address = feedback.feedback.address !== null ? feedback.feedback.address.id : 0
						this.form.addressname = feedback.feedback.address !== null ? feedback.feedback.address.address : ""
						this.form.results = parseInt(feedback.feedback.results.id) > 0 ? feedback.feedback.results.id : null
						this.form.reason = feedback.feedback.reason !== null ? feedback.feedback.reason : ""
						this.form.comment = feedback.feedback.comment
						this.form.task = {
							date: ""
						}

						this.city = feedback.feedback.address !== null ? feedback.feedback.address.city : feedback.candidate.city
						this.word = "г " + this.city
						//this.schedule = feedback.feedback.schedule
						this.resultstype = feedback.feedback.results.type
						this.reasonreq = feedback.feedback.results.type === 'lose'
						this.timeoffset = (new Date().getTimezoneOffset() / -60)

						this.form.schedule = ''
						this.zoom = 12;

						// пустой отклик
						if (this.isblank) {
							this.form.date_create = this.$moment(feedback.feedback.date_create).format('DD.MM.YYYY HH:mm')
							this.form.card = feedback.id
							this.feedback = feedback.id
						}

						this.cityChanged(this.city)
						this.calculateAge()
						this.$v.form.$touch()

						this.inwork = 1
						this.formapproveload = false
						this.isblank = false

						let prj = this.guides.projects
						for (let key in prj) {

							if (prj[key].id === this.form.project && prj[key].solvopro.isNew) {

								this.isNewSolvo = true
								this.vacancyreq = true

								break

							}

						}

						clearInterval(this.refresherInterval)

						if (data.data.count > 1) {

							this.viewCandidateCard(this.form.sid)

							this.$toast.info({
								title: this.language.Attention,
								message: "Есть отклики от этого соискателя",
								showHideTransition: 'slide',
								bgColor: 'var(--blue)',
								textColor: '#EEE',
								allowToastClose: false,
								hideAfter: 10000,
								stack: 5,
								textAlign: 'left',
								position: 'bottom-right'
							});

						}

					}
					// если отклик уже кем-то взят в работу, то обновляем список
					else if (data.data.result === 'buzy') {

						this.$toast.error({
							title: this.language.Attention,
							message: "Отклик обрабатывается другим оператором. Загружаю другой отклик",
							showHideTransition: 'slide',
							bgColor: 'var(--blue)',
							textColor: '#EEE',
							allowToastClose: false,
							hideAfter: 10000,
							stack: 5,
							textAlign: 'left',
							position: 'bottom-right'
						});

						this.getLists()
						//this.getForm()

						let vm = this

						// берем отклик в работу
						setTimeout(function () {
							vm.setInwork()
						}, 300)

					}
					/*else{
						this.inwork = 0
					}*/

				},
			).catch(error => {

				this.loading = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				console.log(error)

			})

		},
		setOutwork() {

			let param = {};

			param['id'] = this.feedback
			param['action'] = 'outwork'

			this.formapproveload = true
			this.inwork = 0

			axios.post(this.apiBaseURL + '/api/operator/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					this.formapproveload = false

					this.feedback = 0

					this.form = {
						card: this.id,
						action: "workit",
						date_create: this.$moment().format('DD.MM.YYYY HH:mm'),
						sid: 0,
						soiskatel: {
							sid: 0,
							title: "",
							birthday: "",
							city: "",
							address: "",
							phone: "7",
							email: "",
							url_1: "",
							nationality: "",
							description: "",
						},
						project: null,
						channel: null,
						title: "",
						address: 0,
						addressname: "",
						type_work: "",
						type_lead: "",
						results: 0,
						covid: 0,
						reason: "",
						comment: "",
						schedule: "",
						task: {
							date: this.$moment().subtract(30, 'm').format('DD.MM.YYYY HH:mm')
						},
						withpause: false,
						oldsid: 0,
						timeoffset: 0
					}

					if (data.data.result === 'ok') {
						this.getLists()
					}

					clearInterval(this.refresherInterval)
					this.refresherInterval = setInterval(this.refreshTasks, 120000)

				},
			).catch(error => {

				this.loading = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				console.log(error)

			})

		},
		setResume() {

			let param = {};

			param['action'] = 'resume'

			axios.post(this.apiBaseURL + '/api/operator/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					if (data.data.result === 'ok') {
						this.paused = false
					}
					else if (data.data.result === 'buzy') {
						this.getLists()
						this.getForm()
					}

				},
			).catch(error => {

				this.loading = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				console.log(error)

			})

		},
		setPouse() {

			let param = {};

			param['action'] = 'pouse'

			axios.post(this.apiBaseURL + '/api/operator/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					if (data.data.result === 'ok') {
						this.paused = true
					}

				},
			).catch(error => {

				this.loading = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				console.log(error)

			})

		},

		sendForm() {

			this.$v.form.$touch()

			clearInterval(this.refresherInterval)

			if (this.$v.form.$error || this.$v.form.$invalid || this.form.channel === 0 || this.form.results === 0 || (this.isNewSolvo && this.form.vacancyid === '' && this.resultstype === 'win')) {

				this.$toast.error({
					title: 'Error',
					message: "Не заполнены обязательные поля",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return false

			}
			else if (this.isblank && this.form.date_create === ''){

				this.$toast.error({
					title: 'Error',
					message: "Не заполнено поле - Время поступления",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return false

			}
			else if (this.isNewSolvo && this.resultstype === 'win' && (this.form.vacancyid === '' || this.form.vacancyid === null)) {

				this.$toast.error({
					title: 'Error',
					message: "Укажите Вакансию",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return false

			}
			else {

				if (this.resultstype === 'win' && this.form.schedule === "") {

					this.$toast.error({
						title: 'Error',
						message: "Не назначено время встречи",
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})

					return false

				}

				// do your submit logic here
				this.submitStatus = 'PENDING'

				// отправка формы здесь
				let param = {};

				for (let key in this.form) {
					if (this.form[key] /*&& key !== 'schedule'*/) {
						param[key] = this.form[key]
					}
				}

				param.id = this.feedback

				this.formapproveload = true
				this.loading = true
				this.buttonsdisabled = true

				axios.post(this.apiBaseURL + '/api/operator/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 360000,
				})
					.then(
						(data) => {

							this.getScripts()
							this.getLists()
							this.getStat()

							//this.message = data.data.result.result

							//console.log('info:', data.data.result.info)
							//console.log('length:', data.data.result.info.length)

							if (data.data.result.info.name !== undefined && this.resultstype === 'win') {

								let pophtml = '<textarea class="enable--select wp100" style="height:300px;font-size:1.2em;">' +
									'ФИО: ' + data.data.result.info.name + '\n' +
									'Телефон: ' + data.data.result.info.phone + '\n' +
									'Город: ' + data.data.result.info.city + '\n' +
									'Проект: ' + data.data.result.info.project + '\n' +
									'Источник: ' + data.data.result.info.channel + '\n' +
									'Тип заявки: ' + data.data.result.info.typelead + '\n' +
									'Результат: ' + data.data.result.info.rezults + '\n' +
									'Вакансия: ' + data.data.result.info.vacancy +
									'</textarea>';

								this.popuper = {
									html: pophtml,
									visible: true
								}

							}

							this.formapproveload = false
							this.formload = false
							this.isblank = false
							this.loading = false
							this.manualid = 0
							this.feedback = 0

							this.zoom = 12;

							/*
							this.form = {
								card: this.id,
								action: "workit",
								date_create: this.$moment().format('DD.MM.YYYY HH:mm'),
								sid: 0,
								soiskatel: {
									title: "",
									birthday: this.$moment().subtract(20, 'years').format('DD.MM.YYYY'),
									city: "",
									address: "",
									phone: "7",
									email: "",
									url_1: "",
									nationality: "",
									description: "",
								},
								project: null,
								channel: null,
								title: "",
								address: 0,
								addressname: "",
								type_work: "",
								type_lead: "",
								results: 0,
								covid: 0,
								reason: "",
								comment: "",
								schedule: "",
								task: {
									date: this.$moment().subtract(30, 'm').format('DD.MM.YYYY HH:mm')
								},
								withpause: false,
								oldsid: 0,
								timeoffset: 0
							}
							*/

						},
					)
					.catch(error => {

						this.loading = false

						this.$toast.error({
							title: 'Error',
							message: error.response.status + ": " + error.response.statusText,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

						console.log(error)

					})

			}

		},
		sendFormAndPouse() {

			this.setPouse()
			this.sendForm()

		},

		selectProject(payload) {

			//console.log(payload)

			this.form.project = payload
			this.getAddresses()

			let prj = this.guides.projects

			this.isNewSolvo = false
			this.isMyRest = false
			this.vacancyreq = false
			this.vacancyid = ''

			for (let key in prj) {

				if (prj[key].id === payload && prj[key].sysvacancy.length > 0) {

					this.isNewSolvo = true
					this.vacancyreq = true

					break
				}
				if (prj[key].id === payload && Object.keys(prj[key].myrest).length > 0) {

					this.isMyRest = true

					break
				}

			}

			/*if(this.isNewSolvo){
				this.checkNewSolvo()
			}*/

		},
		selectSysVacancy(payload) {

			this.form.vacancyid = payload
			console.log('sysvacancy', payload)

		},
		selectReason(payload) {
			this.form.reason = payload
		},
		selectResult(payload) {

			//console.log("result", payload)

			this.form.results = payload.id
			this.reasonreq = payload.type === 'win'
			this.resultstype = payload.type
			this.reasonreq = false

			if (this.resultstype === 'win') {
				this.form.task.date = ''
				this.form.reason = ''
			}
			else if (this.resultstype === 'lose') {
				this.form.task.date = ''
				this.form.schedule = ''
				this.schedule = ''
				this.reasonreq = true
			}
			else if (this.resultstype === 'step') {
				this.form.task.date = ''
				this.form.schedule = ''
				this.schedule = ''
			}
			else if (this.resultstype === 'task') {
				this.form.reason = ''
				this.form.schedule = ''
				this.schedule = ''
				this.form.task.date = this.$moment().add(30, 'm').format('DD.MM.YYYY HH:mm')
			}
			else if (this.resultstype === 'na') {
				this.form.reason = ''
				this.form.schedule = ''
				this.schedule = ''
				this.form.task.date = ''
			}
			this.$v.form.results.$touch()

		},

		changeTaskDay(payload) {
			this.form.task.date = payload
		},
		changeDateCreate(payload) {
			this.form.date_create = payload
		},
		addressChanged(payload) {

			//console.log("from address:", payload)

			if (payload.data !== undefined) {

				this.form.soiskatel.address = payload.value
				this.form.soiskatel.city = payload.data.city

				this.$refs.city.city = payload.data.city
				this.time = this.$moment()

			}

		},
		addressPointChanged(payload) {

			//console.log("from address:", payload)

			if (payload.id > 0) {

				this.form.addressname = payload.name
				this.form.address = payload.id
				this.form.project = payload.idproject
				this.time = this.$moment()
				this.selectProject(payload.idproject)

				this.getAddresses(payload.city)
				//this.zoom = 16;

				this.centerpoint = {
					//city: payload.city,
					lat: payload.lat,
					lan: payload.lan
				}

				let vm = this

				setTimeout(function () {
					vm.zoom = 16;
				}, 300)

			}

		},
		cityChanged(payload) {

			//console.log(payload)

			this.form.soiskatel.city = payload

			if (payload !== undefined && payload.split('').length >= 2) {
				this.form.soiskatel.city = payload
				this.keyword = payload

				this.getCityGeo(payload)
				//this.getAddresses()

			}

			if (payload === '') {
				this.city = ''
			}

		},
		citySelected(payload) {

			//console.log(payload)

			this.form.soiskatel.city = payload

			if (payload !== undefined && payload.split('').length >= 2) {
				this.form.soiskatel.city = payload
				this.keyword = payload

				this.getCityGeo(payload)
				this.getAddresses()

			}

			if (payload === '') {
				this.city = ''
			}

		},
		nationalityChanged(payload) {

			//console.log(payload)

			if (payload !== undefined) {
				this.form.soiskatel.nationality = payload
			}

		},
		channelChanged(payload) {
			if (payload !== undefined) {
				this.form.channel = payload
			}
		},
		covidChanged(payload) {
			if (payload !== undefined) {
				this.form.covid = payload
			}
		},
		typeworkChanged(payload) {
			if (payload !== undefined) {
				this.form.type_work = payload
			}
		},
		typeleadChanged(payload) {
			if (payload !== undefined) {
				this.form.type_lead = payload
			}
		},
		vacancyChange(payload) {
			if (payload !== undefined) {
				this.form.title = payload
				this.time = this.$moment()
			}
		},
		vacancyChangeFull(payload) {
			//console.log("full", payload)
			if (payload !== undefined) {

				if (parseInt(payload.project) > 0) {
					this.form.project = payload.project
				}

				this.time = this.$moment()
			}
		},
		prefixChanged(payload){

			//console.log(payload)

			this.prefix = payload.code
			this.form.soiskatel.prefix = payload.code
			this.maxphonelength = payload.length

			this.validatePhone()

		},

		calculateAge() {

			const formats = ["DD.MM.YYYY", "DD/MM/YYYY", "DD-MM-YYYY", "DD MM YYYY"]

			if (this.form.soiskatel.birthday !== '' && this.form.soiskatel.birthday !== null) {

				let castDate = this.$moment(this.form.soiskatel.birthday, formats)
				let a = this.$moment().diff(castDate, 'years', false)

				this.age = parseInt(a)

				if (this.age < 16) {

					//this.form.birthday = ''
					//this.age = 0

					this.$toast.warn({
						title: 'Attention',
						message: "Возраст менее 16 лет",
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})

				}

				//console.log(this.age)

			}

		},
		changeBirthday(date) {
			//console.log(date)
			this.form.soiskatel.birthday = date
			this.calculateAge()
		},
		validatePhone() {

			if (this.form.soiskatel.phone !== undefined) {

				//this.form.soiskatel.phone = this.form.soiskatel.phone.replace(/\D/g, "")
				//console.log(this.form.soiskatel.phone)

				let phone = this.form.soiskatel.phone.split("")
				let xprefix = this.form.soiskatel.phone.slice(0, this.prefix.length)

				//console.log("xprefix", xprefix)

				this.phonelength = phone.length

				/*if (phone.length > 0 && phone[0] !== '7') {
					this.form.soiskatel.phone = "7" + phone[0]
					this.phoneiscorrect = false
				}*/

				if (phone.length > 0 && xprefix !== this.prefix) {

					this.form.soiskatel.phone = this.prefix

					phone = this.form.soiskatel.phone.split("")
					this.phonelength = phone.length

					this.phoneiscorrect = false

					//console.log('x1', this.form.soiskatel.phone)

				}
				else if (phone.length > 0 && phone.length < this.maxphonelength) {

					this.phoneiscorrect = false
					//console.log('x2')

				}
				else if (phone.length > this.maxphonelength) {

					this.phoneiscorrect = false
					//console.log('x2')

				}
				else {

					this.phoneiscorrect = true
					//console.log('x3')

				}

				//this.form.soiskatel.phone = this.form.soiskatel.phone.replace(/\D/g, "")

			}

		},

		dialogPlusLoaded() {
			this.dialogloaded = true
		},
		closeDialogPlus() {
			this.showdialogplus = false
			this.dialogplusloaded = false
			this.dialogpluswidth = 800

			this.showit = true
			this.schedulefor = 0

		},

		showScheduler() {

			if (this.form.address > 0) {

				this.schedulefor = parseInt(this.form.address)
				this.showdialogplus = true
				//this.showit = false

			}
			else {

				this.$toast.error({
					title: 'Error',
					message: 'Не выбран адрес',
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

			}
		},
		selectSchedule(payload) {

			this.showit = true
			this.schedulefor = 0

			this.form.schedule = payload[0] + ' ' + payload[1]

		},

		soiskatelChanged(payload) {

			//console.log("soiskatel", payload)

			this.form.soiskatel = {
				sid: payload.id,
				title: payload.title,
				nationality: payload.nationality,
				email: payload.email,
				phone: payload.phones,
				city: payload.city,
				url_1: payload.url_1,
				description: payload.description,
				birthday: payload.birthday,
				prefix: payload.prefix,
			}

			//console.log(payload.prefix)

			this.inBlackList = payload.blacklist === "1"

			this.prefix = payload.prefix
			this.form.sid = payload.id
			this.form.soiskatel.prefix = payload.prefix

			this.calculateAge()

			this.viewCandidateCard(this.form.sid)
			this.getCityGeo(this.form.soiskatel.city)
			this.getAddresses()

		},
		soiskatelChangedValue(field, payload) {

			//console.log(field, payload)

			if (field === 'phone') {

				let newval = payload.replace(/\D/g, "")

				if (newval === "") {
					newval = this.prefix
				}

				this.form.soiskatel.phone = newval
				this.validatePhone()

			}
			else {
				this.form.soiskatel[field] = payload
			}

		},

		soiskatelReset() {
			this.form.soiskatel = {
				sid: 0,
				title: "",
				birthday: "",
				city: "",
				address: "",
				phone: "7",
				email: "",
				url_1: "",
				nationality: "",
				description: "",
				prefix: "7",
			}

			this.form.sid = 0

			this.calculateAge()
		},

		selectAddress(payload) {

			if (payload.id !== undefined) {

				//console.log("sa:", payload)

				// Если проект изменился, то очистим вакансию
				if (this.form.project !== payload.project) {
					//this.form.title = ''
				}

				this.form.project = payload.project

				this.form.address = payload.id
				this.form.addressname = payload.address
				this.form.city = payload.city
				this.time = this.$moment()

			}
			else {

				this.form.address = 0
				this.form.addressname = ""

			}

		},
		searchAddress() {

			//console.log("sa2:", payload)
			this.keyword = this.word

		},

		checkNewSolvo() {

			if (this.form.soiskatel.birthday === '') {

				this.$toast.error({
					title: 'Error',
					message: "Укажите Дату рождения кандидата",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return false

			}
			else if (this.form.soiskatel.phone.length < 10) {

				this.$toast.error({
					title: 'Error',
					message: "Не корректный номер телефона",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return false

			}
			else if (this.form.vacancyid === '' || this.form.vacancyid === null) {

				this.$toast.error({
					title: 'Error',
					message: "Укажите Вакансию",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return false

			}

			let param = {}

			param['soiskatel'] = {}

			param['action'] = 'solvocheck'
			param['project'] = this.form.project
			param['vacancyid'] = this.form.vacancyid
			param['soiskatel']['title'] = this.form.soiskatel.title
			param['soiskatel']['phone'] = this.form.soiskatel.phone
			param['soiskatel']['nationality'] = this.form.soiskatel.nationality
			param['soiskatel']['city'] = this.form.soiskatel.city
			param['soiskatel']['birthday'] = this.form.soiskatel.birthday

			this.loading = true

			axios.post(this.apiBaseURL + '/api/operator/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					this.loading = false

					//console.log(data.data.result)

					if (!data.data.result) {
						this.$toast.warn({
							title: 'Записать не возможно',
							message: "Выбранный кандидат уже находится в обработке заказчика.\nПредложите другой проект!",
							position: "bottom center",
							timeOut: 30000,
							progressBar: true
						})
					}
					else {
						this.$toast.success({
							title: 'Можно записывать',
							message: "Можно записать кандидата!",
							position: "bottom center",
							timeOut: 15000,
							progressBar: true
						})
					}

				},
			).catch(error => {

				this.loading = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				console.log(error)

			})

		},
		checkMyRest() {

			if (this.form.soiskatel.birthday === '') {

				this.$toast.error({
					title: 'Error',
					message: "Укажите Дату рождения кандидата",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return false

			}
			else if (this.form.soiskatel.phone.length < 10) {

				this.$toast.error({
					title: 'Error',
					message: "Не корректный номер телефона",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return false

			}

			let param = {}

			param['soiskatel'] = {}

			param['action'] = 'myrestcheck'
			param['project'] = this.form.project
			param['vacancyid'] = this.form.vacancyid
			param['soiskatel']['title'] = this.form.soiskatel.title
			param['soiskatel']['phone'] = this.form.soiskatel.phone
			param['soiskatel']['nationality'] = this.form.soiskatel.nationality
			param['soiskatel']['city'] = this.form.soiskatel.city
			param['soiskatel']['birthday'] = this.form.soiskatel.birthday

			this.loading = true

			axios.post(this.apiBaseURL + '/api/operator/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					this.loading = false

					//console.log(data.data.result)

					if (!data.data.result) {
						this.$toast.warn({
							title: 'Записать не возможно',
							message: "Выбранный кандидат уже находится в обработке заказчика.\nПредложите другой проект!",
							position: "bottom center",
							timeOut: 30000,
							progressBar: true
						})
					}
					else {
						this.$toast.success({
							title: 'Можно записывать',
							message: "Можно записать кандидата!",
							position: "bottom center",
							timeOut: 15000,
							progressBar: true
						})
					}

				},
			).catch(error => {

				this.loading = false

				this.$toast.error({
					title: 'Error',
					message: error.response.status + ": " + error.response.statusText,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				console.log(error)

			})

		},

		toggleStat() {
			this.showstat = !this.showstat
		},
		hideStat() {
			this.showstat = false
		},

		toggleTimePanel() {
			this.showTimePanel = !this.showTimePanel;
		},
		handleOpenChange() {
			this.showTimePanel = false;
		},
		handleChange(value, type) {
			//console.log(type)
			if (type === 'date') {
				this.toggleTimePanel()
			}
			if (type === 'minute') {
				this.openPanel = false
			}
		},

		toggleTimePanel2() {
			this.showTimePanel2 = !this.showTimePanel2;
		},
		handleOpenChange2() {
			this.showTimePanel2 = false;
		},
		handleChange2(value, type) {
			if (type === 'date') {
				this.toggleTimePanel2()
			}
			if (type === 'minute') {
				this.openPanel2 = false
			}
		},

		notBeforeToday(date) {
			return date < new Date(new Date().setHours(0, 0, 0, 0));
		},

		excludeChannel() {

			let rez = []

			for (let key = 0; key < this.guides.channels.length; key++) {

				if (!this.guides.channels[key].active) {

					rez.push(this.guides.channels[key].id)

				}

			}

			//console.log(rez)

			this.excludedChannel = rez

		},
		excludeProject() {

			let rez = []

			for (let key = 0; key < this.guides.projects.length; key++) {

				if (!this.guides.projects[key].active) {

					rez.push(this.guides.projects[key].id)

				}

			}

			//console.log(rez)

			this.excludedProject = rez

		},

		viewCandidateCard(payload) {
			this.candidatecard = parseInt(payload)
			this.showsubpanel = true
		},
		closeCard() {
			this.candidatecard = 0
			this.showsubpanel = false
		},

		popuperClose() {
			this.popuper = {
				html: "",
				visible: false
			}
		},

		// обновление списка задач при простое
		refreshTasks() {

			if (this.inwork === 0) {

				//console.log( 'refresh at ' + this.$moment().format('DD.MM.YYYY HH:mm') )
				this.getLists();

			}

		}
	},

	metaInfo: {
		title: 'Operator Place',
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>

.codeselect {
	display               : grid;
	grid-template-columns : repeat(auto-fill, calc(33.33% - 5px));
	grid-gap              : 5px 5px;
}

.ui-center .body {
	overflow: hidden !important;
	padding: 0 !important;
	background: var(--white);
}

.page--title {
	left: 45px;
	display: block;
	width: 200px !important;
	text-align: left;
}

.ui-layout.collapsed .bottom-block {
	left: unset !important;
}

.bottom-block {
	left: unset !important;
	right: 20px !important;
	z-index: 10;
}

.content-loading {
	display: block;
	margin-top: 100px;
	width: 80px;
	height: 80px;
	background: url("/assets/images/loading.svg") no-repeat center center;
	background-size: contain;
}

.button--pane .button {
	height: 30px;
	line-height: 30px;
	padding: 10px 0;
}

@media (min-width: 768px) and (max-width: 1024px) {

	.page--title {
		left: 0 !important;
	}

}

.popuper {
	position: absolute;
	z-index: 20;
	bottom: 10px;
	left: calc(50% - 250px);
	width: 500px;
	height: auto;
	background: var(--gray-lite);
	border: 2px solid #1976D2;
	border-radius: 5px;
}

.popuper:after, .popuper:before {
	top: 100%;
	left: 50%;
	border: solid transparent;
	content: "";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.popuper:after {
	border-color: rgba(227, 242, 253, 0);
	border-top-color: var(--gray-lite);
	border-width: 10px;
	margin-left: -10px;
}

.popuper:before {
	border-color: rgba(25, 118, 210, 0);
	border-top-color: #1976D2;
	border-width: 13px;
	margin-left: -13px;
}

.popuper--container {
	position: relative;
	/*padding-top: 15px;*/
}

.popuper--title {
	padding: 10px 10px 0 10px;
	font-weight: bold;
}

.popuper--close {
	position: absolute;
	right: 0;
	top: 5px;
	color: var(--gray-darkblue);
	cursor: pointer;
}

.popuper--html {
	padding: 10px;
}

.underline {
	text-decoration: dashed;
	font-weight: bold;
	color: var(--red);
	cursor: pointer;
}

</style>
<style>
ul.vu-suggestion {
	max-height: 350px !important;
}

.operator .schedulebox {
	height: 63vh;
}

.subdialog.operator {
	display: block;
	z-index: 100;
	height: 100vh;
	width: 100vw;
	position: fixed;
	background: rgba(0, 0, 0, 0.8);
	padding: 0;
	/*top: 0;
	left: 0;*/
}

.subdialog.operator .zagolovok {
	color: var(--gray-darkblue);
	display: block;
	font-size: 1.2em;
	font-weight: bold;
	background: #FFF;
	border: 1px solid #FFF;
	margin-bottom: 10px;
	padding: 0 10px 10px 5px;
	border-top-left-radius: 3px;
	-webkit-border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	text-transform: uppercase;
}

.subdialog.operator .close {
	position: absolute;
	top: 5px;
	right: 10px;
	width: 20px;
	height: 20px;
	padding: 5px 5px;
	color: #222;
	font-size: 1.5em;
	line-height: 20px;
	text-align: center;
	cursor: pointer;
}

.subdialog.operator .subbody {
	background: var(--white);
	height: 80vh;
	width: 80vw;
	transform: translate(10%, 10%);
	padding: 10px;
	border-radius: 4px;
	-moz-border-radius: 4px;
	-webkit-border-radius: 4px;
}

.subdialog.operator .button--pane {
	padding: 10px;
}

/**
Стиль для рабочего места оператора
 */
.feedback--body {
	display: grid;
	grid-template-columns: 45% auto;
	grid-gap: 0 2px;
	padding: 0 !important;
	/*padding-left: 30px;*/
	overflow-y: hidden;
	height: calc(100% - 10px);
}

@media (min-width: 1200px) and (max-width: 1500px) {

	.feedback--body {
		/*grid-template-columns : 250px auto 35%;*/
	}

}

@media (min-width: 1024px) and (max-width: 1200px) {

	.feedback--body {
		/*grid-template-columns : 230px auto 30%;*/
	}

	.feedback-questions .questions a.button {
		font-size: 0.8em;
	}

}

.feedback-list {
	display: grid;
	/*grid-template-rows : 30px 30% 30px auto 30px 250px;*/
	grid-template-rows: 1px 30px 110px 30px auto;
	height: calc(100vh - 60px);
	border-right: 0 solid var(--gray);
	box-shadow: 1px 0 2px 0 rgba(50, 50, 50, 0.7);
	box-sizing: border-box;
	overflow-y: unset;
	overflow-x: unset;
	width: 300px;
	position: absolute;
	z-index: 12;
	left: -300px;
	transition: left 300ms ease;
	-webkit-transition: left 300ms ease;
	-moz-transition: left 300ms ease;
}

.feedback-list.open {
	left: 0;
}

.feedback-list > .head {
	padding: 5px;
	font-size: 0.9em;
	font-weight: 700;
	line-height: 20px;
	color: var(--green-dark);
	background: var(--gray);
	border-bottom: 1px solid var(--gray-sub);
	box-sizing: border-box;
	overflow-y: hidden;
	text-align: center;
}

.feedback-list > .recall,
.feedback-list > .list {
	overflow-y: auto;
}

.feedback-list > .recall .task-item,
.feedback-list > .list .feedback-item {
	border-bottom: 1px solid var(--gray);
	padding: 5px;
}

.feedback-list > .recall .task-item.current,
.feedback-list > .list .feedback-item.current {
	background: var(--green-sublite);
}

.feedback-list-toggler-block {
	height: calc(100vh - 60px);
	/*position: relative;*/
}

.feedback-list-toggler {
	position: absolute;
	right: -69px;
	bottom: 150px;
	font-size: 0.9em;
	text-align: center;
	color: #FFF;
	background: var(--green);
	transform: rotate(-90deg);
	padding: 5px 15px;
	border-radius: 0 0 5px 5px;
	cursor: pointer;
	box-shadow: 1px 0 2px 0 rgba(50, 50, 50, 0.7);
}

.feedback-form {
	box-sizing: border-box;
	box-shadow: 1px 0 2px 0 rgba(50, 50, 50, 0.7);
	overflow-y: hidden;
}

.feedback-form-block {
	display: grid;
	height: calc(100vh - 60px);
	grid-template-rows: auto 80px;
	overflow-y: auto;
}

.feedback-form .feedback-container {
	position: relative;
	overflow-y: auto;
	padding-right: 20px !important;
	padding-left: 30px !important;
}

.feedback-form .button--pane {
	vertical-align: middle;
	border-top: 1px solid var(--gray-sub);
	padding-top: 15px;
	padding-bottom: 15px;
	background: var(--gray);
	/*position: absolute;
	bottom: 0;
	left: 0;
	z-index: 5;*/
}

.feedback-form .feedback-roof {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--gray-lite);
	opacity: 0.9;
	z-index: 1;
}

.feedback-questions {
	display: grid;
	grid-template-rows: 30% auto;
	height: calc(100vh - 60px);
	overflow-y: auto;
	box-sizing: border-box;
	box-shadow: 1px 0 2px 0 rgba(50, 50, 50, 0.7);
}

.feedback-questions .questions {
	border-bottom: 2px solid var(--white);
}

.feedback-questions .questions a.button {
	font-size: 1.2em;
	margin-bottom: 5px;
}

.feedback-questions .map {
	z-index: 1;
}

.feedback-questions .mapsearch {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 2000;
	padding: 5px 10px;
	background: transparent;
	border-radius: 0 0 0 5px;
	height: 4em;
	font-size: 0.9em;
}

.feedback-questions .mapsearch input {
	border-color: rgba(255, 255, 255, 0.7);
	background: rgba(255, 255, 255, 0.5) !important;
}

.feedback-questions .mapsearch input:focus {
	background: rgba(255, 255, 255, 0.8) !important;
	border-color: rgba(15, 118, 110, 1);
}

@media (min-width: 1024px) and (max-width: 1200px) {

	.feedback-questions {
		grid-template-rows: 200px auto;
	}

}
</style>