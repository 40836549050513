<template>

	<div>

		<DIV class="zagolovok">Add/Edit Email Template</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabse" class="pr10 pl10">

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp60 relative material mradio pr5">

						<project v-model="form.project" :id="parseInt(form.project)" :exclude="excludeProjects" :required="true" @select="selectProject"/>

					</div>
					<div class="flex-string wp40 relative material mradio">

						<Result v-model="form.rezult" :id="form.rezult" :label="true" :results="results" @select="selectResult"></Result>

					</div>

					<div class="fs-09 gray-dark wp100 mb10"><b>Примечание:</b> Привязка к результату делается только для шаблонов, предназначенных для результатов типа "Следующий шаг"</div>

				</div>

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.theme" ref="theme" id="theme" class="required wp100" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.theme.$error}">
						<label for="theme">Theme</label>
						<div class="pull-aright mt10" v-click-outside="hideTagsmenu2">

							<a href="javascript:void(0)" @click="showTagsmenu2" title="Действия" class="tagsmenuToggler"><b class="blue">Вставить тэг</b>&nbsp;<i class="icon-angle-down" id="mapi2"></i></a>
							<div class="tagsmenu" :class="{'show':tagsmenu2}" style="right:0;">
								<ul style="max-height: 180px; z-index:200">
									<li @click="textAtCursor('{{soiskatel}}')" data-title="soiskatel"><b>ФИО кандидата</b></li>
									<li @click="textAtCursor('{{soiskatelsurname}}')" data-title="soiskatelsurname"><b>Фамилия кандидата</b></li>
									<li @click="textAtCursor('{{soiskatelname}}')" data-title="soiskatelname"><b>Имя кандидата</b></li>
									<li @click="textAtCursor('{{soiskatelpatronymic}}')" data-title="soiskatelpatronymic"><b>Отчество кандидата</b></li>
									<li @click="textAtCursor('{{nationality}}')" data-title="soiskatel"><b>Национальность</b></li>
									<li @click="textAtCursor('{{phone}}')" data-title="phone"><b>Телефон кандидата</b></li>
									<li @click="textAtCursor('{{addressName}}')" data-title="addressName"><b>Название объекта</b></li>
									<li @click="textAtCursor('{{date}}')" data-title="date"><b>Дата собеседования</b></li>
									<li @click="textAtCursor('{{time}}')" data-title="time"><b>Время собеседования</b></li>
									<li @click="textAtCursor('{{company}}')" data-title="company"><b>Компания:кратко</b></li>
								</ul>
							</div>

						</div>

					</div>

				</div>

				<div class="flex-container mt10">

					<div class="flex-string wp100 material">

						<div class="relative">

							<div class="pull-aright mt10" v-click-outside="hideTagsmenu">

								<a href="javascript:void(0)" @click="showTagsmenu" title="Действия" class="tagsmenuToggler"><b class="blue">Вставить тэг</b>&nbsp;<i class="icon-angle-down" id="mapii"></i></a>
								<div class="tagsmenu" :class="{'show':tagsmenu}" style="right:0;">
									<ul style="max-height: 180px; z-index:200">
										<li @click="addTagInEditor('{{soiskatel}}')" data-title="soiskatel"><b>ФИО кандидата</b></li>
										<li @click="addTagInEditor('{{soiskatelsurname}}')" data-title="soiskatelsurname"><b>Фамилия кандидата</b></li>
										<li @click="addTagInEditor('{{soiskatelname}}')" data-title="soiskatelname"><b>Имя кандидата</b></li>
										<li @click="addTagInEditor('{{soiskatelpatronymic}}')" data-title="soiskatelpatronymic"><b>Отчество кандидата</b></li>
										<li @click="addTagInEditor('{{nationality}}')" data-title="soiskatel"><b>Национальность</b></li>
										<li @click="addTagInEditor('{{phone}}')" data-title="phone"><b>Телефон кандидата</b></li>
										<li @click="addTagInEditor('{{email}}')" data-title="email"><b>Email кандидата</b></li>
										<li @click="addTagInEditor('{{addressName}}')" data-title="addressName"><b>Название объекта</b></li>
										<li @click="addTagInEditor('{{address}}')" data-title="address"><b>Адрес объекта</b></li>
										<li @click="addTagInEditor('{{date}}')" data-title="date"><b>Дата собеседования</b></li>
										<li @click="addTagInEditor('{{time}}')" data-title="time"><b>Время собеседования</b></li>
										<li @click="addTagInEditor('{{time_to_hour}}')" data-title="time_to_hour"><b>Время собеседования +1 час</b></li>
										<li @click="addTagInEditor('{{time_to}}')" data-title="time_to"><b>Время собеседования ДО</b></li>
										<li @click="addTagInEditor('{{company}}')" data-title="company"><b>Компания:кратко</b></li>
										<li @click="addTagInEditor('{{company_full}}')" data-title="company_full"><b>Компания:полное</b></li>
										<li @click="addTagInEditor('{{company_site}}')" data-title="company_site"><b>Сайт компании</b></li>
										<li @click="addTagInEditor('{{company_address}}')" data-title="company_address"><b>Почтовый адрес</b></li>
										<li @click="addTagInEditor('{{company_phone}}')" data-title="company_phone"><b>Телефон</b></li>
										<li @click="addTagInEditor('{{company_mail}}')" data-title="company_mail"><b>Email</b></li>
									</ul>
								</div>

							</div>

							<ckeditor
								v-model="form.content"
								:config="editorConfig"
								ref="editor"
								@input="onEditorInput"
								@focus="onEditorFocus"
								@ready="onEditorReady"
								@namespaceloaded="onNamespaceLoaded"
							/>

						</div>

					</div>

				</div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<A href="javascript:void(0)" @click="formSubmit" class="button m0">Save</A>
				<A href="javascript:void(0)" @click="closeForm" class="button graybtn">Cancel</A>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";

import Project from "@/components/elements/project";
import Result from "@/components/elements/results";

import {required} from "vuelidate/lib/validators";
import CKEditor from "ckeditor4-vue";

export default {
	name: "SmstemplatesEmailForm",
	components:{
		Project,
		Result,
		ckeditor: CKEditor.component
	},
	props: {
		id: Number,
		clone: Boolean,
		// передается только для того, чтобы vue обновлял с сервера
		time: Object
	},
	validations: {
		form: {
			project: {
				required
			},
			theme: {
				required
			},
		}
	},
	data() {
		return {
			form: {
				id: this.id,
				project: null,
				rezult: null,
				theme: "",
				content: "",
			},
			results:{},
			editor: {},
			editorData: "",
			editorConfig: {
				/*toolbar: [
					['Bold', 'Italic', 'Underline', '-', 'TextColor', 'FontSize'],
					['JustifyLeft', 'JustifyCenter', 'JustifyBlock'],
					['Undo', 'Redo', '-', 'Replace', '-', 'RemoveFormat'],
					//'/',
					['NumberedList', 'BulletedList'], ['Outdent', 'Indent'],
					['Link', 'Unlink'],
					['Source']
				],*/
				height: '350px',
				skin: 'moono-lisa',
				contentsCss: 'assets/css/editor.css',
				extraPlugins: 'textselection,codemirror,clipboard,dialog,lineutils,image2',
				allowedContent: true,
				disableNativeSpellChecker: true,
				codemirror: {

					// Set this to the theme you wish to use (codemirror themes)
					theme: 'base16-light',

					// Whether or not you want to show line numbers
					lineNumbers: true,

					// Whether or not you want to use line wrapping
					lineWrapping: true,

					// Whether or not you want to highlight matching braces
					matchBrackets: true,

					// Whether or not you want tags to automatically close themselves
					autoCloseTags: true,

					// Whether or not you want Brackets to automatically close themselves
					autoCloseBrackets: true,

					// Whether or not to enable search tools, CTRL+F (Find), CTRL+SHIFT+F (Replace), CTRL+SHIFT+R (Replace All), CTRL+G (Find Next), CTRL+SHIFT+G (Find Previous)
					enableSearchTools: true,

					// Whether or not you wish to enable code folding (requires 'lineNumbers' to be set to 'true')
					enableCodeFolding: true,

					// Whether or not to enable code formatting
					enableCodeFormatting: true,

					// Whether or not to automatically format code should be done when the editor is loaded
					autoFormatOnStart: true,

					// Whether or not to automatically format code should be done every time the source view is opened
					autoFormatOnModeChange: true,

					// Whether or not to automatically format code which has just been uncommented
					autoFormatOnUncomment: true,

					// Define the language specific mode 'htmlmixed' for html including (css, xml, javascript), 'application/x-httpd-php' for php mode including html, or 'text/javascript' for using java script only
					mode: 'htmlmixed',

					// Whether or not to show the search Code button on the toolbar
					showSearchButton: true,

					// Whether or not to show Trailing Spaces
					showTrailingSpace: true,

					// Whether or not to highlight all matches of current word/selection
					highlightMatches: true,

					// Whether or not to show the format button on the toolbar
					showFormatButton: true,

					// Whether or not to show the comment button on the toolbar
					showCommentButton: true,

					// Whether or not to show the uncomment button on the toolbar
					showUncommentButton: true,

					// Whether or not to show the showAutoCompleteButton button on the toolbar
					showAutoCompleteButton: true,

					// Whether or not to highlight the currently active line
					styleActiveLine: true
				}
			},
			apiBaseURL: this.$store.getters.apiBaseURL,
			guides: this.$store.state.guides,
			language: this.$store.state.language,
			loading: false,
			message: "",
			tagsmenu: false,
			tagsmenu2: false,
			excludeProjects: []
		}
	},
	computed: {
		card: {
			get(){
				return this.id > 0 ? this.id : 0
			},
			set(value){
				return value
			}
		},
	},
	created() {
		this.prepareResults()
		this.loadCard()
	},
	watch: {
		time(){
			this.loadCard()
		}
	},
	methods: {
		loadCard() {

			this.getProjects();

			if(this.card > 0) {

				this.loading = true

				let param = {};

				param['id'] = this.card
				param['action'] = 'info'

				axios.post(this.apiBaseURL + '/api/smstemplates/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							let rez = data.data.result

							this.form.id      = this.clone ? 0 : rez.id
							this.card         = this.clone ? 0 : rez.id
							this.form.project = rez.project
							this.form.rezult  = rez.rezult
							this.form.theme   = rez.theme
							this.form.content = rez.content
							this.editorData   = rez.content

						},
					)
					.catch(error => {
						console.log(error)
					})

			}

			this.$emit("loaded")
			this.loading = false

		},
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: "Не заполнены обязательные поля",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				let param = {}

				param = this.form
				param['action'] = 'edit'
				param['tip'] = 'email'
				//param['id'] = this.card

				axios.post(this.apiBaseURL + '/api/smstemplates/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.$emit("update")
							this.closeForm()

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

			}

		},
		closeForm() {
			this.$emit("close")
		},
		getProjects(){

			let param = {};
			param['action'] = 'projects'
			param['tip'] = 'email'

			axios.post(this.apiBaseURL + '/api/smstemplates/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.excludeProjects = data.data.result

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		selectProject(payload) {
			this.form.project = payload
		},
		selectResult(payload) {
			this.form.rezult = payload.id
		},
		showTagsmenu(){
			this.tagsmenu = !this.tagsmenu
		},
		hideTagsmenu(){
			this.tagsmenu = false
		},
		showTagsmenu2(){
			this.tagsmenu2 = !this.tagsmenu2
		},
		hideTagsmenu2(){
			this.tagsmenu2 = false
		},
		onNamespaceLoaded( CKEDITOR ) {
			this.editor = CKEDITOR
			//console.log(CKEDITOR)
			// Add external `placeholder` plugin which will be available for each
			// editor instance on the page.
			CKEDITOR.plugins.addExternal( 'codemirror', '/assets/js/ckeditor/plugins/codemirror/plugin.js', '' );
			CKEDITOR.plugins.addExternal( 'textselection', '/assets/js/ckeditor/plugins/textselection/plugin.js', '' );
			CKEDITOR.plugins.addExternal( 'stylesheetparser', '/assets/js/ckeditor/plugins/stylesheetparser/plugin.js', '' );
			CKEDITOR.plugins.addExternal( 'image2', '/assets/js/ckeditor/plugins/image2/plugin.js', '' );
			CKEDITOR.plugins.addExternal( 'dialog', '/assets/js/ckeditor/plugins/dialog/plugin.js', '' );
			CKEDITOR.plugins.addExternal( 'lineutils', '/assets/js/ckeditor/plugins/lineutils/plugin.js', '' );
			CKEDITOR.plugins.addExternal( 'clipboard', '/assets/js/ckeditor/plugins/clipboard/plugin.js', '' );
		},
		onEditorInput(){

		},
		onEditorFocus(){
			this.hideTagsmenu()
		},
		onEditorReady(){
			//this.editor = CKEDITOR
			//console.log(CKEDITOR)
		},
		textAtCursor(_text){

			let area = this.$refs.theme
			let self = this

			if ((area.selectionStart) || (area.selectionStart == '0')) {

				let p_start = area.selectionStart;
				let p_end = area.selectionEnd;

				area.value = area.value.substring(0, p_start) + _text + area.value.substring(p_end, area.value.length);

			}

			if (area.selection) {

				area.focus();
				let sel = area.selection.createRange();
				sel.text = _text;

			}

			self.form.theme = area.value
			self.tagsmenu2 = false

		},
		addTagInEditor(tag) {

			let oEditor = this.editor.instances.editor1;
			oEditor.insertText(tag);

			this.tagsmenu = false

			return true;

		},
		prepareResults(){

			let rez = []

			for (let key=0; key < this.guides.results.length; key++) {

				if (this.guides.results[key].type === "step") {

					rez.push(this.guides.results[key])

				}

			}

			//console.log(rez)

			this.results = rez

		}
	}
}
</script>

<style scoped>
#formtabse{
	overflow-y: auto;
	max-height: 70vh;
}
textarea{
	height: 400px;
	padding-top: 40px;
}
button.ck.ck-button{
	box-shadow: none !important;
}
.ck-editor__editable_inline {
	min-height: 350px;
}
</style>