<template>

	<div>

		<div class="closer" title="Закрыть" @click="closeCard"><i class="icon-cancel-circled"></i></div>

		<div v-if="loading" class="content-loading"></div>

		<div v-if="!loading" class="ui-card">

			<div class="crd--title">Notify List</div>

			<div class="crd--body">

				<div class="infodiv mb20">Уведомления хранятся 72 часа и автоматически удаляются из базы</div>
				<div v-for="(item, index) in notify" :key="item.id" class="ha inotify" :class="{'Bold bluebg-sub':item.status}" @click="notifyMark(item, index)">

					<div class="inotify-item hand">
						<i class="fs-20" :class="{'icon-bell-alt blue':item.status, 'icon-bell gray':!item.status}"></i>
					</div>

					<div class="inotify-item p10">
						<div class="fs-10 blue">{{$moment(item.datum).format('DD.MM.YY, H:mm')}}</div>
						<div class="fs-11 mt10" v-html="item.content"></div>

						<div class="pull-right"><i class="gray" :class="{'icon-eye gray-lite':!item.status, 'icon-eye-off gray-dark':item.status}"></i></div>
					</div>

				</div>
				<div v-if="notify.length === 0" class="gray p10">
					<i class="icon-doc-alt"></i> {{language.msg.Nothing}}
				</div>

			</div>

			<div class="crd--footer">

				<div class="flex-container box--child wp100">

					<div class="flex-string wp50 pl10">

						<A href="javascript:void(0)" @click="closeCard" class="button dotted graybtn"><i class="icon-cancel-circled"></i>Закрыть</A>
						<A href="javascript:void(0)" @click="MarkAll" class="button dotted greenbtn"><i class="icon-ok"></i>Прочитано всё</A>

					</div>

				</div>

			</div>

		</div>

	</div>

</template>

<script>
import axios from "axios";

export default {
	name: "NotifyList",
	data() {
		return {
			notify: null,
			loading: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language
		}
	},
	created(){
		this.loadNotify()
	},
	methods: {
		loadNotify() {

			this.loading = true

			let param = {};

			param['action'] = 'list'

			axios.post(this.apiBaseURL + '/api/notify/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {
						this.notify = data.data.result.payload.list
						this.loading = false
					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		notifyMark(payload, key){

			if( payload.status ) {

				let self = this
				let param = {}

				param['action'] = "mark"
				param['id'] = payload.id

				axios.post(this.apiBaseURL + '/api/notify/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				}).then(
					() => {
						//console.log(data)
						self.notify[key].status = false
						self.$emit("update", payload)
					},
				)

			}

		},
		MarkAll(){

			//let self = this
			let param = {}

			param['action'] = "markall"

			axios.post(this.apiBaseURL + '/api/notify/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				() => {

					for (let key in this.notify) {

						this.notify[key].status = false

					}

					this.$emit("update")

				},
			)

		},
		closeCard(){

			this.$emit("close")

			this.candidate = {
				soiskatel: {},
				feedback: {}
			}

		},
	}
}
</script>

<style scoped>

</style>