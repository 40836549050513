<template>

	<div>

		<BaseFilter v-model="projectFilter" @list="getList" ref="filters">
			<template v-slot:filters>
				<Filters v-model="pageFilters"></Filters>
			</template>
			<template v-slot:sorts>
				<Sorts v-model="sortFilters"></Sorts>
			</template>
			<template v-slot:title>
				{{language.Meets}}
			</template>
		</BaseFilter>

		<div class="page--body" @click="closeAllFilters">

			<div v-if="loading" class="loading"></div>

			<h2 class="uppercase">{{language.Meets}}</h2>

			<div class="wp100 mx-auto mt10 pt10">

				<div class="infodiv mb10">
					<div>Всего <b>{{ total }}</b> записей.</div>
					<div class="mt5" v-if="filters">
						<div v-if="filters.projects && filters.projects.length > 0" class="infodiv inline bgwhite mr5">{{language.Projects}}: <b>{{filters.projects.join(", ")}}</b></div>
						<div v-if="filters.results && filters.results.length > 0" class="infodiv inline bgwhite mr5">{{language.Results}}: <b>{{filters.results.join(", ")}}</b></div>
						<div v-if="filters.users && filters.users.length > 0" class="infodiv inline bgwhite mr5">{{language.Operator}}: <b>{{filters.users.join(", ")}}</b></div>
						<div v-if="filters.phone != null" class="infodiv inline bgwhite mr5">{{language.Phone}}: <b>{{filters.phone}}</b></div>
						<div v-if="filters.city" class="infodiv inline bgwhite mr5">{{language.City}}: <b>{{filters.city}}</b></div>
						<div v-if="filters.finished" class="infodiv inline bgwhite mr5"><b>{{language.Finished}}</b></div>
						<div v-if="filters.pasted" class="infodiv inline bgwhite mr5"><b>{{language.Pasted}}</b></div>
					</div>
				</div>

				<div class="flex-content2 typelist meets p5 tbl--header sticked--top">

					<div class="flex-string2">ID</div>
					<div class="flex-string2">{{language.Date}}</div>
					<div class="flex-string2">{{language.Project}}, {{language.Address.Address}}</div>
					<div class="flex-string2">{{language.Candidate}}</div>
					<div class="flex-string2">{{language.Result}}</div>
					<div class="flex-string2">{{language.Operator}}</div>
					<div class="flex-string2 text-center">{{language.Actions}}</div>

				</div>

				<div v-for="(item, index) in items" :key="index" class="flex-content typelist meets ha border-bottom p5" :class="{'graybg-sub':item.mccomment === null && item.days !== 0, 'orangebg-sub':item.days === 0}">
					<div class="flex-string">{{ item.id }}</div>
					<div class="flex-string text-right">

						<div class="Bold blue fs-14">{{ $moment(item.date_from).format('H:mm') }}</div>
						<div class="fs-09 gray">{{ $moment(item.date_from).format('DD.MM.YY') }}</div>
						<div v-if="item.days < 0" class="fs-07 mt10">через {{Math.abs(item.days)}} дн.</div>
						<div v-else-if="item.days > 0" class="fs-07 mt10">{{Math.abs(item.days)}} дн. назад</div>

					</div>
					<div class="flex-string">

						<div class="text-wrap Bold blue fs-14 mb10"><i v-if="!item.projectActive" class="icon-attention-1 red" title="Проект отключен"></i>{{item.projectName}}</div>

						<div v-if="item.address !== null && item.address.id > 0" class="hand" @click="viewAddressCard(item.address.id)" :title="item.address.title">
							<div class="Bold fs-10 ellipsis">{{ item.address.title }}</div><br>
							<div class="fs-09 flh-11 mt10 ellipsis">{{ item.address.address }}</div>
						</div>
						<div class="fs-09 blue text-wrap mt5">{{item.address.city}}</div>
						<div class="fs-09 text-wrap mt10 hand" title="Просмотр отклика" @click="viewFeedbackCard(item.fid)">Обработал: {{item.fuser}}, {{ $moment(item.dend).format('DD.MM.YY') }}</div>

					</div>
					<div class="flex-string">

						<div v-if="item.soiskatel.id > 0" :title="item.soiskatel.title">

							<div :class="{'hand':item.soiskatel.id > 0}" @click="viewCandidateCard(item.soiskatel.id)">
								<div class="fs-11 Bold text-wrap">{{item.soiskatel.title}}</div>
								<div class="fs-09 text-wrap mt5">
									<span v-if="item.soiskatel.age" class="Bold blue">{{item.soiskatel.age}},</span>
									<span v-if="item.soiskatel.phone" class=""> {{item.soiskatel.phone}},</span>
									<span v-if="item.soiskatel.email" class="Bld"> {{item.soiskatel.email}}</span>
								</div>
							</div>
						</div>

					</div>
					<div class="flex-string">

						<div v-if="item.mcdatum !== null" class="fs-09 gray">{{$moment(item.mcdatum).format('DD.MM.YY H:mm')}}</div>
						<div v-else>-</div>
						<div>{{item.mccomment}}</div>
						<div v-if="item.newfid > 0" @click="viewFeedbackCard(item.newfid)" class="hand success p5 rounded-2px mt5">Новый отклик</div>

					</div>
					<div class="flex-string">

						<div v-if="item.mcuser !== null" class="ellipsis Bold" :title="item.mcuser">{{item.mcuser}}</div>
						<div v-else-if="item.mcuser === null">-</div>

					</div>
					<div class="flex-string text-right">

						<a v-if="editable && item.days > 0 && item.mccomment === null" @click="setInwork(item.id)" :title="language.Processing" class="button dotted greenbtn m0">
							<i class="icon-handshake-o"></i>
						</a>
						<a v-if="editable" @click="editFeedbackItem(item.fid)" :title="language.Edit" class="button dotted bluebtn m0 hidden">
							<i class="icon-pencil"></i>
						</a>
						<a v-if="editable && item.mccomment === null" @click="deleteItem(item.id)" :title="language.Delete" class="button dotted redbtn m0">
							<i class="icon-cancel-circled"></i>
						</a>

					</div>
				</div>

			</div>

			<div class="pagediv">

				<a href="javascript:void(0)" title="Первая" v-if="page > 1" @click="changePage(1)">&nbsp;<i class="icon-angle-double-left"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Предыдущая" v-if="page > 1" @click="changePage(page - 1)">&nbsp;<i class="icon-angle-left"></i>&nbsp;&nbsp;</a>
				{{ page }} из {{ pageall }}
				<a href="javascript:void(0)" title="Следующая" v-if="page >= 1 && page < pageall" @click="changePage(page + 1)">&nbsp;<i class="icon-angle-right"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Последняя" v-if="page < pageall && pageall > 1" @click="changePage(pageall)">&nbsp;<i class="icon-angle-double-right"></i>&nbsp;&nbsp;</a>

			</div>

			<div class="space-100"></div>

			<div class="mt20 p0 bottom-block" data-id="address">

				<A href="javascript:void(0)" @click="meetExport" title="Экспорт" class="ml10 blue disabled1">
					<i class="icon-download-1"></i>
				</A>

			</div>

		</div>

		<SubPanel ref="subpanel" :show="showsubpanel">
			<template v-if="addresscard > 0" v-slot:swbody>
				<AddressCard :id="addresscard" @close="closeCard" @edit="editAddressItem" :nobutton="true" :editabble="false"></AddressCard>
			</template>
			<template v-else-if="feedbackcard > 0" v-slot:swbody>
				<FeedbackCard :id="feedbackcard" @close="closeCard" @edit="editFeedbackItem" @cedit="editCandidateItem" :nobutton="false" :editabble="false"></FeedbackCard>
			</template>
			<template v-else-if="candidatecard > 0" v-slot:swbody>
				<CandidateCard :id="candidatecard" @close="closeCard" @edit="editCandidateItem" :nobutton="false" :editabble="false"></CandidateCard>
			</template>
		</SubPanel>

		<Dialog ref="dialog" :show="showdialog" :width="dialogwidth" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="idaddress > 0" v-slot:dlgbody>
				<AddressForm :id="idaddress" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="idcandidate > 0" v-slot:dlgbody>
				<CandidateForm :id="idcandidate" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="idfeedback > 0 || idfeedback === -1" v-slot:dlgbody>
				<FeedbackForm :id="idfeedback" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
import axios from "axios"

import BaseFilter from "@/components/filters/BaseFilter"
import Filters from "@/pages/meets/Filters"
import Sorts from "@/pages/meets/Sorts"

import SubPanel from "@/components/modal/SubPanel"
import Dialog from "@/components/modal/Dialog"

import CandidateCard from "@/pages/candidate/CandidateCard"
import CandidateForm from "@/pages/candidate/CandidateForm"

import FeedbackCard from "@/pages/feedback/FeedbackCard"
import FeedbackForm from "@/pages/feedback/FeedbackForm"

import AddressCard from "@/pages/address/AddressCard"
import AddressForm from "@/pages/address/AddressForm"

export default {
	name: "Meets",
	components: {
		Dialog,
		BaseFilter,
		Filters,
		Sorts,
		SubPanel,
		FeedbackCard,
		FeedbackForm,
		AddressCard,
		AddressForm,
		CandidateCard,
		CandidateForm
	},
	data() {
		return {
			loading: false,
			filters: {},
			items: null,
			page: 1,
			pageall: 1,
			total: 0,
			dialog: {},
			addresscard: 0,
			idaddress: 0,
			feedbackcard: 0,
			idfeedback: 0,
			candidatecard: 0,
			idcandidate: 0,
			showsubpanel: false,
			showdialog: false,
			dialogloaded: false,
			dialogplusloaded: false,
			dialogwidth: 800,
			approve: false,
			approveData: null,
			exportData: false,
			compareData: false,
			bigreportData: false,
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			editable: true
		}
	},
	computed: {
		projectFilter:{
			get () {
				return {
					project: Array.isArray(this.$store.state.meets.filters.project) ? this.$store.state.meets.filters.project : [],
					order: this.$store.state.meets.filters.order !== '' ? this.$store.state.meets.filters.order : 'desc',
				}
			},

			set (value) {
				let newfilter = {
					project: value.project,
					order: value.order,
					feedbacks: this.$store.state.meets.filters.meets,
					sort: this.$store.state.meets.filters.sort,
				}
				this.$store.commit("meets/SET_FILTERS", newfilter)
			}
		},

		sortFilters:{
			get () {
				return this.$store.state.meets.filters.sort !== '' ? this.$store.state.meets.filters.sort : 'title'
			},

			set (value) {
				let newfilter = {
					project: this.$store.state.meets.filters.project,
					meets: this.$store.state.meets.filters.feedbacks,
					order: this.$store.state.meets.filters.order,
					sort: value
				}
				this.$store.commit("meets/SET_FILTERS", newfilter)
			}
		},

		pageFilters: {

			get () {
				return this.$store.state.meets.filters.meets
			},

			set (value) {
				let newfilter = {
					project: this.$store.state.meets.filters.project,
					meets: value,
					order: this.$store.state.meets.filters.order,
					sort: this.$store.state.meets.filters.sort,
				}
				this.$store.commit("meets/SET_FILTERS", newfilter)
			}
		},

		meets: {
			get () {
				return this.$store.state.meets.filters.meets
			},

			set (value) {
				this.$store.commit("meets/SET_FILTERS", value)
			}
		},

		projects: {
			get() {
				return Array.isArray(this.$store.state.meets.filters.project) ? this.$store.state.meets.filters.project : []
			},
			set(value) {
				this.$store.commit("meets/SET_FILTER_PROJECTS", value)
			}
		},

		order: {
			get () {
				return this.$store.state.meets.filters.order !== '' ? this.$store.state.meets.filters.order : 'title'
			},
			set(value) {
				this.$store.commit("meets/SET_FILTER_ORDER", value)
			}
		}
	},
	comments: {},

	created() {
		this.getList()
		this.pageTitle = this.language.Meets
	},

	watch: {
		// при изменениях маршрута запрашиваем данные снова
		routes: 'getList'
	},

	methods: {
		updateFilters() {

			this.getList()

		},
		getList() {

			let param = [];

			param = JSON.parse(JSON.stringify(this.$store.state.meets.filters))

			let meets = param.meets

			for (let key in meets) {
				if (meets[key]) {
					param[key] = meets[key]
				}
			}

			param['page'] = this.$store.state.meets.meets.page
			param['limit'] = 100
			param['action'] = "listOfMeets"

			this.loading = true

			axios.post(this.apiBaseURL + '/api/meets/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {
					this.filters = data.data.result.filters
					this.items = data.data.result.list
					this.page = data.data.result.page
					this.pageall = data.data.result.pageall
					this.total = data.data.result.total
					this.editable = data.data.result.editor
					this.$store.commit('meets/SET_MEET', data.data.result)

					this.loading = false
				},
				/*(response) => {
					console.log("address:", response)
				}*/
			)

		},
		updateList(){

			if( this.showsubpanel && this.addresscard > 0 ){

				let c = this.addresscard

				this.closeCard()

				let self = this;
				setTimeout(function() {
					self.viewAddressCard(c)
				}, 100);

			}
			else if( this.showsubpanel && this.feedbackcard > 0 ){

				let c = this.feedbackcard

				this.closeCard()

				let self = this;
				setTimeout(function() {
					self.viewFeedbackCard(c)
				}, 100);

			}
			else if( this.showsubpanel && this.candidatecard > 0 ){

				let c = this.candidatecard

				this.closeCard()

				let self = this;
				setTimeout(function() {
					self.viewCandidateCard(c)
				}, 100);

			}

			this.getList();

		},
		changePage(page) {
			this.page = page
			this.$store.commit("meets/CHANGE_PAGE", page)

			this.getList()
		},
		meetExport(){

			axios.post(this.apiBaseURL + '/api/meets/', {'action': 'export'}, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						window.open(this.apiBaseURL + '/' + data.data.result.url)

					},
				)
				.catch(error => {

					this.loading = false

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},

		viewAddressCard(payload) {
			this.candidatecard = 0
			this.feedbackcard = 0
			this.addresscard = parseInt(payload)
			this.showsubpanel = true
		},
		editAddressItem(payload) {
			this.idfeedback = 0
			this.idcandidate = 0
			this.idaddress = parseInt(payload)
			this.approve = false
			this.approveData = {}
			this.exportData = false
			this.compareData = false
			this.bigreportData = false
			this.showdialog = true
		},

		viewCandidateCard(payload) {
			this.addresscard = 0
			this.feedbackcard = 0
			this.candidatecard = parseInt(payload)
			this.showsubpanel = true
		},
		editCandidateItem(payload) {
			this.idaddress = 0
			this.idfeedback = 0
			this.idcandidate = parseInt(payload)
			this.approve = false
			this.approveData = {}
			this.exportData = false
			this.compareData = false
			this.bigreportData = false
			this.showdialog = true
		},

		viewFeedbackCard(payload) {
			this.addresscard = 0
			this.candidatecard = 0
			this.feedbackcard = parseInt(payload)
			this.showsubpanel = true
		},
		editFeedbackItem(payload) {
			this.idaddress = 0
			this.idcandidate = 0
			this.idfeedback = parseInt(payload)
			this.approve = false
			this.approveData = {}
			this.exportData = false
			this.compareData = false
			this.bigreportData = false
			this.showdialog = true
		},

		deleteItem(payload) {

			this.$swal.fire({
				title: "Are You Sure?",
				text: 'Item will be deleted forever',
				icon: 'question',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: '#3085D6',
				cancelButtonColor: '#D33',
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel"
			}).then((result) => {

				if (result.value) {

					// отправка формы здесь
					let param = {};

					param.id = payload
					param.action = "delete"

					axios.post(this.apiBaseURL + '/api/meet/', param, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							(data) => {

								this.message = data.data.result.result
								this.getList()

								this.$toast.success({
									title: 'Success',
									message: data.data.result.result,
									position: "bottom center",
									timeOut: 3000
								})

							},
						)
						.catch(error => {

							this.$toast.error({
								title: 'Error '+ error.code,
								message: "" + error.text,
								position: "bottom center",
								timeOut: 3000
							})

						})

				}

			});

		},

		setInwork(payload){

			let param = {};

			param['id'] = payload
			param['action'] = 'inwork'

			this.loading = true

			axios.post(this.apiBaseURL + '/api/meets/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					if(data.data.result.result === 'ok'){

						this.inwork = 1
						this.loading = false
						//this.$router.push("/mcontrol")
						this.$router.push({ name: "mcontrol", params: {scheduleid: payload}})

					}

					this.loading = false

				},
			)

		},

		closeCard() {
			this.addresscard = 0
			this.candidatecard = 0
			this.feedbackcard = 0
			this.showsubpanel = false
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
		closeDialog() {
			this.idaddress = 0
			this.idfeedback = 0
			this.idcandidate = 0
			this.showdialog = false
			this.dialogwidth = 800
			this.approve = false
			this.approveData = null
			this.exportData = false
		},
		closeAllFilters() {
			this.$refs.filters.closeAll()
		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Meets',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.typelist.meets {
	display               : grid;
	grid-template-columns : 80px 80px auto 250px 200px 140px 140px;
	grid-gap              : 10px 10px;
}
</style>