<template>

	<div>

		<DIV class="login--container nobg">

			<div class="login--block">

				<div class="logo"><img src="/assets/images/logo.png" height="40"></div>

				<div v-if="mode === 'logIn'" class="login--form">

					<form @submit.prevent="formSubmit" autocomplete="off">

						<div class="div-center blue mb20">
							<h2 class="gray2 uppercase">Авторизация</h2>
						</div>

						<div class="flex-container p10">

							<div class="flex-string wp100 relative div-center material">
								<input
									id="login"
									type="text"
									placeholder=" "
									class="wp100"
									v-model="form.login"
								>
								<label for="login">Логин</label>
							</div>

							<div class="flex-string wp100 relative mt10 material">
								<input
									id="password"
									:type="passtype"
									placeholder=" "
									class="wp100"
									v-model="form.password"
								>
								<label for="password">Пароль</label>
								<div class="showpass">
									<i class="hand" :class="{'icon-eye':passtype === 'text','icon-eye-off':passtype === 'password'}" @click="togglePass" title="Показать" id="showpass"></i>
								</div>
							</div>

						</div>

						<div v-if="error !== ''" class="flex-container div-center p10" data-result="error">

							<div class="flex-string warning relative material">
								<i class="icon-attention icon-2x red"></i>&nbsp;{{error}}
							</div>

						</div>

						<div class="flex-container p10">

							<div class="flex-string wp60">

								<a
									@click.prevent="changeMode('fogot')"
									class="blue tglr"
									data-id="fogot"
								>
									<i class="icon-arrows-cw"></i>Восстановить пароль
								</a>

							</div>
							<div class="flex-string wp40">
								<button type="submit" class="loginbutton">Войти</button>
							</div>

						</div>

						<div class="hidden"><input name="smit" type="submit"></div>

					</form>

				</div>
				<div v-if="mode === 'fogot'" class="login--form">

					<form @submit.prevent="formSubmitFogot" autocomplete="off">

						<div class="div-center blue mb20">
							<h2 class="gray2 uppercase">Восстановление пароля</h2>
						</div>

						<div class="flex-container p10">

							<div class="flex-string wp100 relative div-center material">
								<input
									id="login"
									type="text"
									placeholder=" "
									class="wp100"
									v-model="fogotform.email"
								>
								<label for="login">Логин / Email</label>
							</div>

						</div>

						<div v-if="fogoterror !== ''" class="flex-container div-center p10" data-result="error">

							<div class="flex-string warning relative material">
								<i class="icon-attention icon-2x red"></i>&nbsp;{{fogoterror}}
							</div>

						</div>

						<div class="flex-container p10">

							<div class="flex-string wp50">

								<a
									@click.prevent="changeMode('logIn')"
									class="blue tglr"
									data-id="fogot"
								>
									<i class="icon-user-1"></i>Войти
								</a>

							</div>
							<div class="flex-string wp50">
								<button type="submit" class="loginbutton">Дальше..</button>
							</div>

						</div>

						<div class="hidden"><input name="smit" type="submit"></div>

					</form>

				</div>
				<div v-if="mode === 'reset'" class="login--form">

					<form @submit.prevent="formSubmitReset" autocomplete="off">

						<div class="div-center blue mb20">
							<h2 class="gray2 uppercase">Установка пароля</h2>
						</div>

						<div class="flex-container p10">

							<div class="flex-string wp100 relative div-center material hidden">
								<input
									id="login"
									type="text"
									placeholder=" "
									class="wp100"
									v-model="resetform.login"
								>
								<label for="login">Логин</label>
							</div>

							<div class="flex-string wp100 relative mt10 material">
								<input
									id="newpassword"
									:type="passtype"
									placeholder=" "
									class="wp100"
									v-model="resetform.newpassword"
									autocomplete="off"
								>
								<label for="newpassword">Новый Пароль</label>
								<div class="showpass">
									<i class="hand" :class="{'icon-eye':passtype === 'text','icon-eye-off':passtype === 'password'}" @click="togglePass" title="Показать" id="showpass"></i>
								</div>
							</div>

							<div class="flex-string wp100 relative div-center material">
								<input
									id="code"
									type="text"
									placeholder=" "
									class="wp100"
									v-model="resetform.code"
								>
								<label for="code">Код подтверждения</label>
							</div>

							<div class="flex-string success relative">
								<i class="icon-info-circled-1 icon-2x green"></i>&nbsp;Код подтверждения у вас в почтовом ящике
							</div>

						</div>

						<div v-if="reseterror !== ''" class="flex-container div-center p10" data-result="error">

							<div class="flex-string warning relative material">
								<i class="icon-attention icon-2x red"></i>&nbsp;{{reseterror}}
							</div>

						</div>

						<div class="flex-container p10">

							<div class="flex-string wp50">

								<a
									@click.prevent="changeMode('logIn')"
									class="blue tglr"
									data-id="fogot"
								>
									<i class="icon-user-1"></i>Войти
								</a>

							</div>
							<div class="flex-string wp50">
								<button type="submit" class="loginbutton">Сохранить</button>
							</div>

						</div>

						<div class="hidden"><input name="smit" type="submit"></div>

					</form>

				</div>

			</div>

		</DIV>

	</div>

</template>

<script>

import axios from "axios";

export default {
	name: "Login",
	data() {
		return {
			mode: 'logIn',
			form: {
				login: '',
				password: '',
				action: 'login'
			},
			fogotform:{
				email: '',
				action: 'fogot'
			},
			resetform:{
				newpassword: '',
				login: '',
				code: '',
				action: 'reset'
			},
			error: "",
			fogoterror: "",
			reseterror: "",
			codesended: false,
			apiBaseURL: this.$store.state.config.apiBaseURL,
			passtype: "password",
		}
	},
	created() {

		//console.log(this.apiBaseURL)

	},
	methods:{
		formSubmit(){
			this.logIn()
		},
		formSubmitFogot(){
			this.fogot()
		},
		formSubmitReset(){
			this.reset()
		},

		logIn(){

			axios.post(this.apiBaseURL + '/api/auth/login', this.form, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
				},
				timeout: 60000,
			}).then(
				(data) => {

					this.$store.commit('SET_LOGGED_IN', true)
					this.$store.commit('SET_SESSION', data.data.session)
					this.$store.commit('SET_USER', data.data)

					this.$emit("update", true)

					this.$toast.success({
						title: 'Authorization',
						message: 'Success',
						position: "bottom center",
						timeOut: 1000,
						progressBar: true
					})

					//clearInterval(this.notifyCheck)
					//clearInterval(this.authCheck)

					//this.notifyCheck = setInterval(this.getNotify, 60000)
					//this.authCheck = setInterval(this.checkUserAuthStatus, 60000)

					if(data.data.UserType === 'Оператор'){
						this.$router.push({name: 'operator'})
					}

				}
			)
				.catch(error => {

					//console.log(error.response.data)

					this.error = error.response.data.message

				})
		},
		fogot(){

			axios.post(this.apiBaseURL + '/api/auth/login', this.fogotform, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
				},
				timeout: 60000,
			}).then(
				(data) => {

					if(data.data.codesended){
						this.changeMode('reset')
						this.resetform.login = data.data.login
					}

					this.$toast.success({
						title: 'Authorization',
						message: data.data.message,
						position: "bottom center",
						timeOut: 20000,
						progressBar: true
					})

				}
			)
				.catch(error => {
					this.fogoterror = error.response.data.message
				})
		},
		reset(){
			axios.post(this.apiBaseURL + '/api/auth/login', this.resetform, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
				},
				timeout: 60000,
			}).then(
				(data) => {

					if(data.data.result !== 'error') {

						this.$toast.success({
							title: 'Authorization',
							message: 'Success!' + ' ' + data.data.message,
							position: "bottom center",
							timeOut: 1000,
							progressBar: true
						})

						this.changeMode('logIn')

					}
					else{

						this.$toast.error({
							title: 'Authorization',
							message: data.data.message,
							position: "bottom center",
							timeOut: 1000,
							progressBar: true
						})

						this.reseterror =  data.data.message + ".\n" +  data.data.text

					}

				}
			)
				.catch(error => {
					this.reseterror = error.response.data.message + ".\n" + error.response.data.text
				})
		},

		changeMode(mode){

			switch (mode){

				case 'logIn':

					this.mode = 'logIn'

					this.form = {
						login: '',
						password: '',
						action: 'login'
					}

					this.error = ''

					break;
				case 'fogot':

					this.mode = 'fogot'

					this.fogotform = {
						email: '',
						action: 'fogot'
					}

					this.fogoterror = ''

					break;
				case 'reset':

					this.mode = 'reset'

					this.resetform = {
						newpassword: '',
						code: '',
						login: '',
						action: 'reset'
					}

					this.reseterror = ''

					break;

			}

			this.passtype = "password"

		},

		togglePass(){
			this.passtype = this.passtype === 'password' ? "text" : "password"
		}
	}
}
</script>

<style lang="css" scoped>

/*@import 'assets/css/app.css';
@import 'assets/css/app.layouts.css';
@import 'assets/css/app.components.css';
@import 'assets/css/app.language.css';
@import 'assets/css/fontello.css';*/

a{
	cursor: pointer;
}

.login--container {
	display     : table;
	width       : 100vw;
	height      : 100vh;
	padding-top : 100px;
	box-sizing  : border-box;
	background  : #ECEFF1;
}
.login--container:not(.nobg) {
	background      : url("/assets/images/bg.jpg") no-repeat center center;
	background-size : cover;
}

.login--block {
	position              : relative;
	font-size             : 0.95em;
	color                 : #222;
	background            : #FFF;
	width                 : 35em;
	margin                : 0 auto;
	padding               : 10px 10px 10px;
	box-sizing            : border-box;
	vertical-align        : middle;
	border                : 0;
	border-radius         : 10px;
	-moz-border-radius    : 10px;
	-webkit-border-radius : 10px;
	box-shadow            : 0 1px 5px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow    : 0 1px 5px rgba(0, 0, 0, 0.3);
	-moz-box-shadow       : 0 1px 5px rgba(0, 0, 0, 0.3);
}
.login--block .zagolovok {
	font-size      : 1.6em;
	font-weight    : 700;
	color          : #222;
	text-transform : uppercase;
	text-align     : center;
	background     : #FFF;
	border         : 0;
	margin         : 10px -10px 30px -10px;
	padding        : 10px 10px;
	border-radius  : 1px 1px 0 0;
}
.login--block .showpass {
	position  : absolute;
	font-size : 1.5em;
	top       : 15px;
	right     : 10px;
}
.login--block input {
	height         : 2.5em;
	width          : 100%;
	border         : 2px solid var(--blue);
	font-size      : 1.35em;
	font-weight    : normal;
	line-height    : 2.5em;
	padding        : 5px 10px;
	color          : #222;
	background     : rgba(250, 250, 250, 0.3);
	vertical-align : middle;
	text-align     : center;
	outline-style  : none;
	/*box-shadow         : 0 1px 0 #DDD;
	-webkit-box-shadow : 0 1px 0 #DDD;
	-moz-box-shadow    : 0 1px 0 #DDD;*/
	border-radius  : 5px;
}
.login--block input:focus {
	background         : rgba(59, 110, 170, 0.1);
	border         : 2px solid var(--blue);
	color              : #222;
	transition         : all 300ms ease;
	-webkit-transition : all 300ms ease;
	-moz-transition    : all 300ms ease;
	/*box-shadow         : 0 1px 0 #BBB;
	-webkit-box-shadow : 0 1px 0 #BBB;
	-moz-box-shadow    : 0 1px 0 #BBB;*/
}
.login--block input.required {
	border-left : 2px solid #E74C3C;
}
.login--block .logo {
	position   : absolute;
	top        : -60px;
	width      : 100%;
	text-align : center;
}
.login--block .copy {
	position : absolute;
	bottom   : -25px;
	left     : 0;
	color    : #222 !important;
}

/*.login--block i.green,*/
.login--block div.result.green {
	color : #FFF;
}
.login--block .showpass {
	position  : absolute;
	font-size : 1.5em;
	top       : 10px;
	right     : 10px;
}
.login--block .header {
	color          : #222;
	font-weight    : 700;
	text-transform : uppercase;
}
.login--block .logo {
	position   : absolute;
	top        : -70px;
	width      : 100%;
	text-align : center;
}

.agreement-block {
	display               : table;
	position              : relative;
	font-size             : 0.95em;
	color                 : #222;
	background            : #FFF;
	width                 : 35em;
	max-height            : 80vh;
	margin                : 60px auto;
	padding: 10px 0;
	box-sizing            : border-box;
	vertical-align        : middle;
	border                : 0;
	border-radius         : 10px;
	-moz-border-radius    : 10px;
	-webkit-border-radius : 10px;
	box-shadow            : 0 1px 5px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow    : 0 1px 5px rgba(0, 0, 0, 0.3);
	-moz-box-shadow       : 0 1px 5px rgba(0, 0, 0, 0.3);
}
#agreement {
	display    : block;
	height     : 80vh;
	max-height : 80vh;
	padding    : 30px 10px;
	overflow-y : auto;
}
.agreement-block ol{
	list-style: none;
	counter-reset: li;
	padding: 10px;
}
.agreement-block ol li p{
	text-transform: uppercase;
	font-weight : 700;
	display: inline;
}
.agreement-block ol li:before {
	counter-increment: li;
	content: counters(li,".") ". ";
}

button.loginbutton,
a.loginbutton {
	padding               : 5px 15px 5px 15px;
	/*height                : 2.5em;*/
	width                 : 100%;
	line-height           : 2.5em;
	font-size             : 1.15em;
	font-weight           : 700;
	display               : inline-block;
	text-align            : center;
	text-decoration       : none;
	text-transform        : uppercase;
	float                 : right;
	color                 : #FFF;
	background            : #01579B;
	border                : 1px solid #01579B;
	border-radius         : 5px;
	-moz-border-radius    : 5px;
	-webkit-border-radius : 5px;
	/*box-shadow            : 0 1px 1px #999;
	-webkit-box-shadow    : 0 1px 1px #999;
	-moz-box-shadow       : 0 1px 1px #999;*/
	box-sizing            : border-box;
	box-shadow            : 0 14px 10px -10px #01579B !important;
}

button.loginbutton#fackebutton,
a.loginbutton#fackebutton {
	color      : #222;
	background : #B0BEC5;
	border     : 1px solid #B0BEC5;
	box-shadow : 0 14px 10px -10px #B0BEC5 !important;
}

button.loginbutton:hover,
a.loginbutton:hover {
	background         : #0288D1;
	border             : 1px solid #0288D1;
	color              : #FFF;
	transition         : all 300ms ease;
	-webkit-transition : all 300ms ease;
	-moz-transition    : all 300ms ease;
}

button.loginbutton:active,
a.loginbutton:active {
	background         : #F1C40F;
	border             : 1px solid #F39C12;
	color              : #FFF;
	transition         : all 100ms linear;
	-webkit-transition : all 100ms linear;
	-moz-transition    : all 100ms linear;
}

.material {
	position      : relative;
	padding       : 10px auto !important;
	margin-top    : 10px;
	margin-bottom : 10px;
}

.material input {
	display : block;
	padding : 10px;
	height  : auto;
}

.material ~ input::placeholder,
.material input::-webkit-input-placeholder,
.material input::-moz-placeholder,
.material input:-moz-placeholder,
.material input:-ms-input-placeholder {
	color      : transparent !important;
	font-size  : 0.8em;
	text-align : left;
}

.material label,
.material input[value=""] ~ label {
	color              : #999;
	font-size          : 1.0em;
	font-weight        : 700;
	position           : absolute;
	pointer-events     : none;
	left               : 10px;
	top                : 18px;
	transition         : 0.2s ease all;
	-moz-transition    : 0.2s ease all;
	-webkit-transition : 0.2s ease all;
	z-index            : 2;
}

.material input:focus {
	/*background : #FFF !important;*/
}

.material input:not(:placeholder-shown) ~ label,
.material input:focus ~ label {
	top            : -7px;
	font-size      : 14px;
	font-weight    : 700;
	color          : #5264AE;
	background     : transparent;
	padding        : 0 5px;
	text-transform : uppercase;
}

.material input:not(:placeholder-shown) ~ label:after,
.material input:focus ~ label:after {
	content    : " ";
	position   : absolute;
	top        : 3px;
	left       : 1px;
	height     : 50%;
	background : #FFF;
	display    : block;
	width      : calc(100% - 2px);
	z-index    : -1;
}

.material .showpass {
	top : 15px;
}

@media (max-width : 767px) {

	.login--block {
		border             : 0;
		background         : transparent;
		width              : 100vw;
		box-shadow         : 0 0 0;
		-webkit-box-shadow : 0 0 0;
		-moz-box-shadow    : 0 0 0;
	}

	.login--block .zagolovok {
		background : transparent;
	}

	.login--block a.blue,
	.login--block .blue {
		/*color : #FFF;*/
	}

	.login--block input {
		background : #FFF;
	}

	.login--block input:focus {
		background : #FFF;
	}

	.login--block .copy {
		padding    : 10px;
		position   : fixed;
		bottom     : 5px;
		box-sizing : border-box;
		width      : 100vw;
	}

	.login--block div.red,
	.login--block div.green {
		background : rgba(250, 250, 250, 0.7);
		padding    : 10px;
	}

	.login--block .showpass {
		top : 10px;
	}

}

</style>