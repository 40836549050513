<template>

	<div>

		<DIV class="zagolovok">{{ language.Add }}/{{ language.Edit }} {{ language.Candidate }}</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>
			<input type="hidden" v-model="form.action" id="action">
			<input type="hidden" v-model="id">

			<div id="formtabs" class="pr20 pl20">

				<div class="flex-container mt10 box--child">

					<div class="flex-string wp70 relative material cleared">

						<input type="text" id="title" class="required wp99" placeholder=" " autocomplete="off" v-model="form.title" :v="$v.form.title" :class="{'required--error':$v.form.title.$error}">
						<label for="title">{{ language.Human.FirstName }}</label>
						<div class="clearinputs mr5" @click="clearInput('title')"><i class="icon-block red fs-14"></i>
						</div>

					</div>

					<div class="flex-string relative wp30">

						<date-picker lang="ru" format="DD.MM.YYYY" :default-value="form.birthday" v-model="form.birthday" valueType="format" @input="changeBirthday">

							<template v-slot:input>
								<div class="wp100 relative material">
									<input type="text" id="birthday" class="required1 wp100" placeholder=" " autocomplete="off" v-model="form.birthday">
									<label for="birthday">{{ language.Birthday }}</label>
								</div>
							</template>
							<template v-slot:icon-clear></template>
							<template v-slot:icon-calendar></template>

						</date-picker>
						<div v-if="age > 0" class="fs-10 Bold" :class="{'red': age < 18, 'green': age >= 18}" style="position:absolute; right: 30px; top:20px; z-index: 2" data-id="age">{{ age }}</div>

					</div>

				</div>

				<div class="flex-container box--child">

					<div class="flex-string wp100 relative like-input material">

						<div class="checkbox ml10 inline">
							<label class="middle">
								<input v-model="form.blacklist" id="blacklist" type="checkbox" value="true">
								<span class="custom-checkbox mt5"><i class="icon-ok"></i></span>
								<span class="Bold"><b class="blue">В Черном списке</b></span>
							</label>
						</div>

					</div>

				</div>

				<div class="flex-container mt5 box--child">

					<nationality id="nationality" class="wp100" v-model="form.nationality" :value="form.nationality" @update="nationalityChanged"/>

				</div>

				<div class="flex-container box--child">

					<div class="flex-string wp100 mt10 relative material like-input pt20">

						<div class="codeselect">

							<div v-for="item in codes" :key="item.code" class="infodiv dotted p5" :class="{'bgwhite':form.prefix !== item.code}">

								<div class="checkbox fs-07">

									<label class="text-center">

										<input class="taskss" v-model="form.prefix" type="checkbox" :value="item.code" @change="prefixChanged(item)">
										<span class="Bold" :class="{'gray':form.prefix !== item.code}">
											{{ item.code }}:<span class="Bold pl5">{{ item.name }}</span>
										</span>

									</label>

								</div>

							</div>

						</div>
						<div class="label">Код страны (телефонный)</div>

					</div>

				</div>

				<div class="flex-container mt5 box--child">

					<div class="flex-string wp50 relative material cleared">

						<input type="text" id="email" class="wp99" placeholder=" " autocomplete="off" v-model="form.email" :class="{'required--error':$v.form.email.$error}">
						<label for="email">Email</label>
						<div class="clearinputs mr5" @click="clearInput('email')"><i class="icon-block red fs-14"></i></div>

						<div class="red fs-09" :class="{'hidden':!$v.form.email.$error}">Не корректный формат: не соответствует Email</div>

					</div>

					<div class="flex-string wp50 relative material cleared">

						<input type="text" id="phone" class="wp100" placeholder=" " autocomplete="off" v-model="form.phone" :class="{'format--error':$v.form.phone.$error}" @keyup="validatePhone">
						<label for="phone">{{ language.Phone }}</label>
						<div class="clearinputs mr5" @click="clearInput('phone')"><i class="icon-block red fs-14"></i></div>
						<div class="red fs-09" :class="{'hidden':phoneiscorrect}">Не корректный формат: <b>{{ phonelength }}</b> цифр из {{maxphonelength}}</div>

					</div>

				</div>

				<div class="flex-container mt10 mb0 box--child relative">

					<address-suggestions v-model="form.address" id="address" :value="form.address" :forcity="form.city" @update="addressChanged"/>

				</div>

				<div class="flex-container mb5 box--child">

					<city-suggestions v-model="form.city" id="city" :city="form.city" @update="cityChanged" ref="city"></city-suggestions>

				</div>

				<div class="flex-container box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.url_1" id="url_1" class="wp100 linked" placeholder=" " autocomplete="off" :v="$v.form.url_1" :class="{'format--error':$v.form.url_1.$invalid}">
						<label for="url_1">Link #1</label>
						<a v-if="form.url_1 !== '' && form.url_1 !== null && !$v.form.url_1.$invalid" :href="form.url_1" target="_blank" class="linked urlopen" title="View">View</a>

					</div>

				</div>

				<div class="flex-container mt5 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.url_2" id="url_2" class="wp100 linked" placeholder=" " autocomplete="off" :v="$v.form.url_2" :class="{'format--error':$v.form.url_2.$invalid}">
						<label for="url_2">Link #2</label>
						<a v-if="form.url_2 !== '' && form.url_2 !== null && !$v.form.url_2.$error" :href="form.url_2" target="_blank" class="linked urlopen" title="View">View</a>

					</div>

				</div>

				<div class="flex-container mt5 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.url_3" id="url_3" class="wp100 linked" placeholder=" " autocomplete="off" :class="{'format--error':$v.form.url_3.$error}" :v="$v.form.url_3">
						<label for="url_3">Link #3</label>
						<a v-if="form.url_3 !== '' && form.url_3 !== null && !$v.form.url_2.$error" :href="form.url_3" target="_blank" class="linked urlopen" title="View">View</a>

					</div>

				</div>

				<div class="flex-container mt5 box--child">

					<div class="flex-string wp100 relative material">

						<textarea v-model="form.description" id="description" class="wp100" placeholder=" " autocomplete="off" spellcheck="false"></textarea>
						<label for="description">{{language.Description}}</label>

					</div>

				</div>

				<div class="space-50"></div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<button @click="formSubmit" class="button"><i class="icon-ok"></i>{{ language.Save }}</button>
				<button @click="closeForm" class="button graybtn"><i class="icon-cancel"></i>{{ language.Cancel }}</button>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
import {required, minLength, url, email, numeric} from 'vuelidate/lib/validators';

import AddressSuggestions from "@/components/elements/address-suggestions";
import CitySuggestions from "@/components/elements/city-suggestions";
import Nationality from "@/components/elements/nationality";

export default {
	name: "CandidateForm",
	components: {
		//Vuelidate,
		AddressSuggestions,
		CitySuggestions,
		Nationality
	},
	props: {
		id: Number,
	},
	data() {
		return {
			//loading: false,
			form: {
				card: this.id,
				action: "edit",
				title: "",
				birthday: this.$moment().subtract(20, 'years').format('DD.MM.YYYY'),
				city: "",
				address: "",
				phone: "",
				email: "",
				url_1: "",
				url_2: "",
				url_3: "",
				nationality: "",
				description: "",
				externalid: "",
				blacklist: false,
				prefix: "7"
			},
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			loading: false,
			city: "",
			message: "",
			submitStatus: "",
			age: 0,
			phoneiscorrect: true,
			phonelength: 0,
			prefix: "7",
			codes:[
				{
					name:"Россия",
					code: "7",
					length:11
				},
				{
					name:"Узбекистан",
					code:"998",
					length:12
				}
			],
			maxphonelength:11,
		}
	},
	validations: {
		form: {
			title: {
				required,
				minLength: minLength(2)
			},
			/*birthday: {
				required
			},*/
			email:{
				email,
				//minLength: minLength(5)
			},
			phone: {
				numeric,
				minLength: minLength(5),
				//maxLength: maxLength(5)
			},
			url_1: { url},
			url_2: { url},
			url_3: { url},
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.$v.form.$touch()
	},
	mounted() {
		//console.log(this.id)
		this.loadCard()
	},
	watch: {
		city() {
			return this.form.city
		}
	},
	methods: {
		formSubmit() {

			this.$v.form.$touch()

			//console.log(this.$v)

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: "Не заполнены обязательные поля",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				// do your submit logic here
				this.submitStatus = 'PENDING'

				// отправка формы здесь
				let param = {};

				param.id = this.card

				for (let key in this.form) {
					if (this.form[key] /*&& key !== 'schedule'*/) {
						param[key] = this.form[key]
					}
				}

				this.loading = true

				//param['schedule'] = this.form.schedule

				axios.post(this.apiBaseURL + '/api/candidate/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.closeForm()
							this.$emit("update")

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {

						//error => this.status = error.response.data.status;
						console.log(error.response)

						this.loading = false
						this.closeForm()

						this.$toast.error({
							title: 'Error',
							message: error.response.status + ": " + error.response.statusText,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

				//console.log(this.submitStatus)

			}

		},
		loadCard() {

			this.loading = true

			//console.log(this.id)

			if (this.id > 0) {

				let param = {};

				param.id = this.id
				param.action = 'info'

				axios.post(this.apiBaseURL + '/api/candidate/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {
							//console.log(data.data)

							let candidate = data.data.result.soiskatel

							this.form = {
								action: "edit",
								title: candidate.title,
								birthday: candidate.birthday,
								city: candidate.city,
								address: candidate.address,
								phone: candidate.phone.replace(/\D/g, ""),
								email: candidate.email,
								url_1: candidate.url_1,
								url_2: candidate.url_2,
								url_3: candidate.url_3,
								nationality: candidate.nationality,
								description: candidate.odescription,
								externalid: candidate.externalid,
								blacklist: candidate.blacklist,
								prefix: candidate.prefix,
							}
							this.prefix = candidate.prefix

							this.calculateAge()

							this.$emit("loaded")

							this.loading = false

						},
					)
					.catch(error => {
						console.log(error)
					})

			} else {

				this.loading = false

				this.calculateAge()

				this.$emit("loaded")

			}

		},
		closeForm() {
			this.$emit("close")
		},
		clearInput(element) {
			this.form[element] = '';
		},
		addressChanged(payload) {

			//console.log("from address:", payload)

			if (payload !== null && payload !== undefined && payload.data !== undefined) {

				this.form.address = payload.value
				this.form.city = payload.data.city

				this.$refs.city.city = payload.data.city

			}
			else{

				this.form.address = ''

			}

		},
		cityChanged(payload) {

			if (payload !== undefined) {
				this.form.city = payload
			}

		},
		nationalityChanged(payload) {

			if (payload !== undefined) {
				this.form.nationality = payload
			}

		},
		calculateAge() {

			const formats = ["DD.MM.YYYY", "DD/MM/YYYY", "DD-MM-YYYY", "DD MM YYYY"]

			if (this.form.birthday !== '' && this.form.birthday !== null) {

				let castDate = this.$moment(this.form.birthday, formats)
				let a = this.$moment().diff(castDate, 'years', false)

				this.age = parseInt(a)

				if (this.age < 16) {

					//this.form.birthday = ''
					//this.age = 0

					this.$toast.warn({
						title: 'Attention',
						message: "Возраст менее 16 лет",
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})

				}

				//console.log(this.age)

			}

		},
		changeBirthday(date) {
			this.form.birthday = date
			this.calculateAge()
		},

		prefixChanged(payload){

			//console.log(payload)

			this.prefix = payload.code
			this.form.prefix = payload.code
			this.maxphonelength = payload.length

			this.validatePhone()

		},


		validatePhone() {

			if (this.form.phone !== undefined) {

				//this.form.soiskatel.phone = this.form.soiskatel.phone.replace(/\D/g, "")
				//console.log(this.form.soiskatel.phone)

				let phone = this.form.phone.split("")
				let xprefix = this.form.phone.slice(0, this.prefix.length)

				//console.log("xprefix", xprefix)

				this.phonelength = phone.length

				/*if (phone.length > 0 && phone[0] !== '7') {
					this.form.soiskatel.phone = "7" + phone[0]
					this.phoneiscorrect = false
				}*/

				if (phone.length > 0 && xprefix !== this.prefix) {

					this.form.phone = this.prefix

					phone = this.form.phone.split("")
					this.phonelength = phone.length

					this.phoneiscorrect = false

					//console.log('x1', this.form.soiskatel.phone)

				}
				else if (phone.length > 0 && phone.length < this.maxphonelength) {

					this.phoneiscorrect = false
					//console.log('x2')

				}
				else if (phone.length > this.maxphonelength) {

					this.phoneiscorrect = false
					//console.log('x2')

				}
				else {

					this.phoneiscorrect = true
					//console.log('x3')

				}

				//this.form.soiskatel.phone = this.form.soiskatel.phone.replace(/\D/g, "")

			}

		},

		/*validatePhone() {

			if(this.form.phone !== undefined) {

				this.form.phone = this.form.phone.replace(/\D/g, "")

				let phone = this.form.phone.split("")

				this.phonelength = phone.length

				if (phone.length > 0 && phone[0] !== '7') {
					this.form.phone = "7"
					this.phoneiscorrect = false
				} else if (phone.length > 0 && phone.length !== 11) {
					this.phoneiscorrect = false
				} else {
					this.phoneiscorrect = true
				}

				//this.form.soiskatel.phone = this.form.soiskatel.phone.replace(/\D/g, "")

			}

		},*/
	}
}
</script>

<style>
.codeselect {
	display               : grid;
	grid-template-columns : repeat(auto-fill, calc(33.33% - 5px));
	grid-gap              : 5px 5px;
}
</style>