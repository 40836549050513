<template>

	<div>

		<DIV class="zagolovok">Add/Edit Lead type</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.title" id="title" class="required wp100" placeholder=" " autocomplete="off" :class="{'required--error':$v.form.title.$error}">
						<label for="title">Title</label>

					</div>

				</div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<A href="javascript:void(0)" @click="formSubmit" class="button m0">Save</A>
				<A href="javascript:void(0)" @click="closeForm" class="button graybtn">Cancel</A>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
import {required} from "vuelidate/lib/validators";

export default {
	name: "LeadtypeForm",
	props: {
		id: Number,
		// передается только для того, чтобы vue обновлял с сервера
		time: Object
	},
	validations: {
		form: {
			title: {
				required
			},
		}
	},
	data() {
		return {
			form: {
				id: this.card,
				title: "",
			},
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false,
			message: "",
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.loadCard()
	},
	watch: {
		time(){
			this.loadCard()
		}
	},
	methods: {
		loadCard() {

			this.loading = true

			let param = {};

			param['id'] = this.card
			param['action'] = 'info'

			axios.post(this.apiBaseURL + '/api/leadtype/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						let rez = data.data.result

						this.form.id = rez.id
						this.form.title = rez.title

						this.$emit("loaded")
						this.loading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: "Не заполнены обязательные поля",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				let param = {}

				param = this.form
				param['action'] = 'edit'
				param['id'] = this.card

				axios.post(this.apiBaseURL + '/api/leadtype/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.$emit("update")
							this.$emit("guide")
							this.closeForm()

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

			}

		},
		closeForm() {
			this.$emit("close")
			//this.$destroy()
		},
	}
}
</script>

<style scoped>

</style>