<template>

	<div class="filters Bold" data-id="address">

		<div class="radio popblock-item">
			<label class="wp100 p5">
				<input type="radio" value="title" v-model="sort" @change="updateFilter">
				<span class="custom-radio"><i class="icon-radio-check"></i></span>
				<span class="pl10">{{ language.Vacancy }}</span>
			</label>
		</div>

		<div class="radio popblock-item">
			<label class="wp100 p5">
				<input type="radio" value="date_create" v-model="sort" @change="updateFilter">
				<span class="custom-radio"><i class="icon-radio-check"></i></span>
				<span class="pl10">{{ language.Dates.Create }}</span>
			</label>
		</div>

		<div class="radio popblock-item">
			<label class="wp100 p5">
				<input type="radio" value="date_end" v-model="sort" @change="updateFilter">
				<span class="custom-radio"><i class="icon-radio-check"></i></span>
				<span class="pl10">{{ language.Dates.Work }}</span>
			</label>
		</div>

		<div class="radio popblock-item">
			<label class="wp100 p5">
				<input type="radio" value="date_approved" v-model="sort" @change="updateFilter">
				<span class="custom-radio"><i class="icon-radio-check"></i></span>
				<span class="pl10">{{ language.Dates.Approve }}</span>
			</label>
		</div>

		<div class="radio popblock-item">
			<label class="wp100 p5">
				<input type="radio" value="city" v-model="sort" @change="updateFilter">
				<span class="custom-radio"><i class="icon-radio-check"></i></span>
				<span class="pl10">{{ language.City }}</span>
			</label>
		</div>

	</div>

</template>

<script>
export default {
	name: "Sorts",
	data() {
		return {
			language: this.$store.state.language,
		}
	},
	computed: {
		sort: {
			get() {
				return this.$store.state.feedbacks.filters.sort !== undefined ? this.$store.state.feedbacks.filters.sort : 'desc'
			},
			set(value) {
				//console.log("project", value)
				this.$store.commit("feedbacks/SET_FILTER_SORT", value)
			}
		}
	},
	methods: {
		updateFilter() {
			//console.log(this.form)
			this.$emit("updateFilters", this.form)
		}
	},
}
</script>

<style scoped>

</style>