<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				{{ language.MeetControl }}
			</div>

		</div>

		<div class="page--body">

			<div class="page--left">

				<div v-if="loading" class="loading"></div>

				<h2 class="uppercase hidden">{{language.MeetControl}}</h2>

				<div v-if="inwork === 0" class="wp100 mx-auto mt10 pt10">

					<div>Отклик пока не выбран</div>

				</div>
				<div v-else>

					<MeetControlForm :id="inwork" :schedule="scheduleid" @update="worked" @close="cancelWork"></MeetControlForm>

				</div>

				<div class="space-100"></div>

			</div>
			<div class="page--right">

				<div v-if="loading" class="loading"></div>

				<div v-if="inwork === 0" class="feedback-roof text-center">

					<div class="mt20 pt40">
						<i class="icon-jobsoiskatel fs-40 hidden"></i>
						<img src="assets/images/logo-icon.png">
					</div>
					<div class="mt20 fs-14">
						<p>В этом разделе происходит обработка встреч кандидатов с HR</p>
						<p class="fs-14">Ваша задача - <b class="blue">зафиксировать результат этой встречи</b></p>
					</div>
					<div class="mt20 fs-20 flh-10 Bold">
						Вы готовы обработать встречу?
					</div>

					<div class="space-30"></div>

					<div class="mt20">
						<a href="javascript:void(0)" @click="setInwork" class="button greenbtn custom fs-14 Bold">Взять в работу</a>
					</div>

				</div>
				<div v-else>

					<FeedbackCard :id="feedbackcard" :nobutton="true" :editabble="false" :mcontrol="true"></FeedbackCard>

				</div>

			</div>

		</div>

	</div>

</template>

<script>
import axios from "axios"

import FeedbackCard from "@/pages/feedback/FeedbackCard"
import MeetControlForm from "@/pages/mcontrol/MeetControlForm"

export default {
	name: "MeetControl",
	components: {
		FeedbackCard,
		MeetControlForm
	},
	data() {
		return {
			loading: false,
			dialog: {},
			inwork: 0,
			scheduleid: 0,
			feedbackcard: 0,
			mcontrol: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			UserID: this.$store.state.user.UserID
		}
	},

	created() {

		//console.log('created', this.$route.params)

		if(this.$route.params.scheduleid !== undefined) {

			this.scheduleid = this.$route.params.scheduleid
			this.getMeetInfo(this.scheduleid)

		}

		this.pageTitle = this.language.MeetControl

	},

	watch: {

		// при изменениях маршрута запрашиваем данные снова
		routes: 'manualWork'

	},

	methods: {

		setInwork(){

			let param = {};

			param['action'] = 'inwork'
			param['scheduleid'] = this.scheduleid

			this.loading = true

			axios.post(this.apiBaseURL + '/api/mcontrol/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					//console.log(data.data)

					if(data.data.result.result === 'ok'){

						this.inwork = data.data.result.id
						this.scheduleid = data.data.result.scheduleid
						this.feedbackcard = data.data.result.fid

					}
					// если отклик уже кем-то взят в работу, то обновляем список
					else if(data.data.result.result === 'buzy'){

						// this.getLists()

						let vm = this

						// берем отклик в работу
						setTimeout(function () {
							vm.setInwork()
						}, 300)

					}

					this.loading = false

				},
			)

		},

		// для взятия в работу вручную (из раздела Встречи)
		manualWork(){

			//console.log('manual', this.$route.params)

			if(this.$route.params.scheduleid !== undefined) {

				this.getMeetInfo(this.$route.params.scheduleid)

			}

		},

		getMeetInfo(payload){

			let param = {};

			param['action'] = 'info'
			param['scheduleid'] = payload

			this.loading = true

			axios.post(this.apiBaseURL + '/api/mcontrol/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					this.inwork = data.data.result.id
					this.scheduleid = payload
					this.feedbackcard = data.data.result.fid

					this.loading = false

				},
			)

		},

		worked(payload){

			//console.log(payload)

			// если создан новый отклик, то переходим в окно оператора
			if(payload['newfeedback'] > 0){

				this.$router.push("/operator")

			}
			// или предлагаем взять в работу новую встречу
			else{

				this.inwork = 0
				this.feedbackcard = 0
				this.mcontrol = false
				this.scheduleid = 0

			}

		},

		cancelWork(){

			this.inwork = 0
			this.feedbackcard = 0
			this.mcontrol = false
			this.scheduleid = 0

		}

	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Meet Control',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.ui-card.full .crd--body {
	height: calc(100vh - 90px);
}
.page--title{
	left: 45px;
	display: block;
	width: 200px !important;
	text-align: left;
}
.page--body{
	display               : grid;
	grid-template-columns : 1fr 1fr;
	grid-gap              : 10px 10px;
}
.mcontrol {
	display               : grid;
	grid-template-columns : 100px auto 40px;
	grid-gap              : 20px 20px;
}
.button.custom{
	padding: 20px 30px !important;
}
.feedback-roof{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--gray-lite);
	opacity: 0.9;
	z-index : 1;
}
</style>