<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				Settings. Channels
			</div>

		</div>

		<div class="page--body">

			<div class="infodiv mt20">В этом разделе вы можете управлять источниками каналов.</div>

			<div class="wp100 mx-auto mt10 pt10">

				<div class="flex-content typelist channel p5 tbl--header sticked--top">

					<div class="flex-string">Название</div>
					<div class="flex-string">Проект</div>
					<div class="flex-string">Почтовый ящик</div>
					<div class="flex-string">Номер линии</div>
					<div class="flex-string">UTM_source</div>
					<div class="flex-string text-center">Вкл.</div>
					<div class="flex-string text-center">Действия</div>

				</div>

				<div v-for="item in channels" :key="item.id" class="flex-content typelist channel ha border-bottom p5" :class="{'greenbg-sub Bold':item.isDefault}">

					<div class="flex-string Bold fs-12" :class="item.color">
						<div class="inline mr10 w60 blue">ID {{item.id}}</div>
						<div v-if="item.ismain" class="inline" :class="'pl' + item.padleft"><div class="strelka w5 mr10 pl20"></div></div>
						{{item.name}}
						<span v-if="item.isDefault">[ <span class="green Bold">По умолчанию</span> ]</span>
					</div>
					<div class="flex-string">{{item.projectName}}</div>
					<div class="flex-string">{{item.email}}</div>
					<div class="flex-string">{{item.destination}}</div>
					<div class="flex-string">{{item.utm_source}}</div>
					<div class="flex-string">
						<a href="javascript:void(0)" title="Переключить" class="button dotted" :class="{'redbtn':item.archive}" @click="toggleActivity(item.id)">
							<i v-if="!item.archive" class="icon-ok green"></i>
							<i v-if="item.archive" class="icon-cancel-circled red"></i>
						</a>
					</div>
					<div class="flex-string text-center">

						<a href="javascript:void(0)" title="Редактировать" @click="editItem(item.id)" class="button dotted bluebtn m0"><i class="icon-pencil"></i></a>
						<a href="javascript:void(0)" title="Удалить" @click="deleteItem(item.id)" class="button dotted redbtn m0"><i class="icon-cancel-circled"></i></a>

					</div>

				</div>

			</div>

			<div class="space-100"></div>

			<div class="mt20 p0 bottom-block">

				<A href="javascript:void(0)" @click="editItem(-1)" title="Добавить">
					<i class="icon-plus"></i>
				</A>

			</div>

		</div>

		<Dialog ref="dialog" :show="showdialog" :width="dialogwidth" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="(id > 0 || id === -1) && !deleteit" v-slot:dlgbody>
				<ChannelForm :id="id" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="(id > 0 || id === -1) && deleteit" v-slot:dlgbody>
				<ChannelDeleteForm :id="id" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
		</Dialog>

	</div>

</template>

<script>

import Dialog from "@/components/modal/Dialog"
import ChannelForm from '@/pages/settings/ChannelForm.vue'
import ChannelDeleteForm from '@/pages/settings/ChannelDeleteForm.vue'
import axios from "axios";

export default {
	name: "ChannelsSet",
	components:{
		Dialog,
		ChannelForm,
		ChannelDeleteForm
	},
	data(){
		return {
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			showsubpanel: false,
			showdialog: false,
			dialogloaded: false,
			dialogwidth: 700,
			id: 0,
			deleteit: false,
			//channels:this.$store.getters.guides.channels
		}
	},
	watch:{
		//routes: this.$store.dispatch("loadGuides")
	},
	computed:{
		channels:{
			get(){
				return this.$store.state.guides.channels
			},
			set(){

			}
		}
	},
	created() {
		this.$store.dispatch("loadGuides").then(()=>{})
	},
	methods: {
		loadCard(){
			//this.channels = this.$store.getters.guides.channels
		},
		closeDialog() {
			this.id = 0
			this.showdialog = false
			this.dialogwidth = 800
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
		updateList(){
			this.$emit("guide")
			this.loadCard()
		},
		editItem(payload) {
			this.id = parseInt(payload)
			this.deleteit = false
			this.dialogwidth = 700
			this.showdialog = true
		},
		deleteItem(payload) {

			this.id = parseInt(payload)
			this.deleteit = true
			this.dialogwidth = 500
			this.showdialog = true

		},
		toggleActivity(payload){

			// отправка формы здесь
			let param = {};

			param.id = payload
			param.action = "toggle"

			axios.post(this.apiBaseURL + '/api/channels/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.updateList()

						this.message = data.data.result.result

						this.$toast.success({
							title: 'Success',
							message: data.data.result.message,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

					},
				)
				.catch(error => {
					console.log(error)

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Channels / Settings',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.page--title{
	left: 20px;
	display: block;
	width: 200px !important;
	text-align: left;
}

.typelist.channel {
	display               : grid;
	grid-template-columns : auto 140px 160px 140px 160px 80px 120px;
	grid-gap              : 10px 10px;
}

@media (min-width : 768px) and (max-width : 1024px) {

	.page--title{
		left: 0 !important;
	}

}
</style>