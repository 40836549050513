<template>

	<div>

		<div v-if="loading" class="content-loading"></div>

		<div class="relative" v-if="!loading">

			<div class="viget--day fs-09 gray ml15 pt10">{{date}}</div>

			<div class="space-10"></div>
			<div v-for="(item, index) in stat" class="block border-bottom mb15 wp100" :key="index">

				<div class="flex-container float Bold fs-12 p5 mb5 blue">
					<div class="flex-string float pl10">{{item.name}}</div>
					<div class="flex-string w100 text-right pr10" title="Среднее время обработки">
						<span v-if="item.middletime !== null">{{item.middletime.minutes}} мин. {{item.middletime.sec}} с.</span>
						<span v-else>--</span>
					</div>
					<div class="flex-string w100 text-right pr10">{{item.total}}</div>
				</div>

				<div v-for="(string, index) in item.stat" class="flex-container float border-bottom pl5 pr5 fs-10" :key="index">
					<div class="flex-string float p5 pl10" :class="string.color">{{string.name}}</div>
					<div class="flex-string w100 text-right p5 pr10 Bold" :class="string.color">{{string.count}}</div>
				</div>

			</div>

			<div v-if="stat.length === 0" class="p10 pt20 border-box text-center" style="height: 100%; vertical-align: middle">
				<div class="text-center fs-40 mb20 green"><i class="icon-folder-empty"></i></div>
				<div class="fs-12 Bold">Упс. Для вас нет статистики</div>
			</div>

		</div>

		<div class="space-50"></div>

		<div class="periods" id="totalstat">
			<ul class="group">
				<li data-id="today" :class="{'current':period === 'today'}" @click="changePeriod('today')">Сегодня</li>
				<li data-id="yestoday" :class="{'current':period === 'yestoday'}" @click="changePeriod('yestoday')">Вчера</li>
				<li data-id="subyestoday" :class="{'current':period === 'subyestoday'}" @click="changePeriod('subyestoday')">{{this.$moment().subtract(2, 'days').format('DD.MM')}}</li>
				<li :class="{'current':day === 3}" @click="changePeriod(null, 3)">{{this.$moment().subtract(3, 'days').format('DD.MM')}}</li>
				<li :class="{'current':day === 4}" @click="changePeriod(null, 4)">{{this.$moment().subtract(4, 'days').format('DD.MM')}}</li>
				<li :class="{'current':day === 5}" @click="changePeriod(null, 5)">{{this.$moment().subtract(5, 'days').format('DD.MM')}}</li>
				<li :class="{'current':day === 6}" @click="changePeriod(null, 6)">{{this.$moment().subtract(6, 'days').format('DD.MM')}}</li>
<!--				<li :class="{'current':day === 7}" @click="changePeriod(null, 7)">{{this.$moment().subtract(7, 'days').format('DD.MM')}}</li>-->
			</ul>
		</div>

	</div>

</template>

<script>
import axios from "axios";

export default {
	name: "TotalStat",
	data(){
		return {
			stat: {},
			period: "today",
			day: null,
			date: this.$moment().format('DD.MM'),
			error: "",
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: true
		}
	},
	created() {
		this.getData()
	},
	methods:{
		getData(){

			this.loading = true

			axios.post(this.apiBaseURL + '/api/vigets/', {'action':'works','period':this.period, 'day':this.day}, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					this.stat = data.data.result.data
					this.date = data.data.result.date
					this.loading = false

				}
			)
				.catch(error => {

					//console.log(error.response)
					//this.error = error.response

					if(error.response !== undefined) {

						this.$toast.error({
							title: 'Error',
							message: error.response.status + ": " + error.response.statusText,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

					}

				})

		},
		changePeriod(payload, day){

			if(payload !== null) {
				this.period = payload
				this.day = null
			}
			else{

				this.period = null
				this.day = day

			}

			this.getData()

		}
	}
}
</script>

<style scoped>
.viget--day{
}
</style>