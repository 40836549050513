<template>

	<div class="subdialog">

		<div class="subbody">

			<div class="close" @click="closeCard" title="Закрыть или нажмите ^ESC"><i class="icon-cancel"></i></div>

			<DIV class="zagolovok">Данные ресторанов KFC IRB</DIV>

			<div v-if="loading" class="content-loading wp100"></div>

			<div v-if="!loading" class="solvobox">

				<div class="solvobox-city">

					<div v-for="(item, index) in cities" :key="index" @click="selectCity(item.city)" class="infodiv bgwhite city-item" data-id="city">
						{{item.city}}
					</div>

				</div>
				<div class="solvobox-points">

					<div v-if="city === ''" class="success">Выбери город для загрузки адресов</div>

					<div v-if="city !== '' && addresses.length > 0" class="infodiv sticked--top">

						<input type="text" v-model="word" id="solvosearcher" class="search wp100" placeholder="Поиск ресторана по названию" @keyup="search">

					</div>
					<div v-if="city !== ''" class="point-list">

						<div v-if="subloading" class="content-loading wp100"></div>

						<div v-if="!subloading">

							<div v-for="(item, index) in addresses" :key="index" @click="selectAddress(item)" class="infodiv bgwhite point-item mb5">
								<div class="fs-14 Bold">{{item.title}}</div>
								<div class="fs-10 Bold mt10 gray-dark">{{item.address}}</div>
								<div class="fs-09 blue mt10 infodiv Bold">{{item.directorId}}</div>
							</div>

							<div v-if="addresses.length === 0" class="warning">Данные отсутствуют</div>

						</div>

					</div>

				</div>

			</div>

		</div>

	</div>

</template>

<script>
import axios from "axios";

export default {
	name: "SolvoAddress",
	props:{
		project: Number
	},
	data(){
		return {
			city: "",
			cities: {},
			addresses: {},
			addressesBase: {},
			loading: false,
			subloading: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			word: ""
		}
	},
	computed: {
		card() {
			return this.project > 0 ? this.project : 0
		}
	},
	created(){
		this.loading = true
		this.load()
	},
	methods: {
		load() {

			//this.loading = true

			let param = {};

			param.action = 'city'
			param.project = this.card

			axios.post(this.apiBaseURL + '/api/solvo/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.cities = data.data.result.data

						this.loading = false

						this.$emit("loaded")

					},
				).catch(error => {

				console.log(error)

			})

		},
		selectCity(city){

			this.city = city
			this.subloading = true

			let param = {};

			param.city = city
			param.project = this.card
			param.action = 'address'

			axios.post(this.apiBaseURL + '/api/solvo/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.addresses = data.data.result.data
						this.addressesBase = data.data.result.data
						this.subloading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		selectAddress(payload){

			this.$emit("edit", payload)

		},
		search(){

			let keyword = this.word.toLocaleLowerCase()
			let list = this.addressesBase

			if (keyword !== '') {

				this.addresses = list.filter(function (e) {
					return e.title.toLowerCase().indexOf(keyword) !== -1;
				})

			}
			else{
				this.addresses = this.addressesBase
			}

		},

		closeCard(){

			this.$emit("close")

			this.city = ""
			this.cities = {}
			this.addresses = {}

		},
		select(payload){
			this.$emit("edit", payload)
		},
	}
}
</script>

<style>

</style>