<template>

	<div class="flex-string relative material cleared" :class="wclass" v-click-outside="onHide">

		<input type="text" class="wp100" placeholder=" " v-model="nationality" :class="{'required':required}" autocomplete="on" @keyup="search" @focus="onShow" ref="nationalityinput"
			@keydown.up.exact.stop.prevent="selectPrev()"
			@keydown.down.exact.stop.prevent="selectNext()"
			@keydown.enter.exact.stop.prevent="selectCurrent()"
			@keydown.home.stop.prevent="selectFirst()"
			@keydown.end.stop.prevent="selectLast()"
		>
		<label>{{language.Nationality}}</label>
		<div class="clearinputs mr5" @click="clean" ref="clearinputs"><i class="icon-block red fs-14"></i></div>
		<ul class="vu-suggestion" v-if="show" ref="suggestionlist">
			<li v-for="(item, index) in nationalities" :class="{'current':index === currentIndex}" :key="index" @click="select(item, index)" v-html="$options.filters.highlight(item, nationality)">{{ item }}</li>
		</ul>

	</div>

</template>

<script>

import {KeyControl} from "@/mixins/key-controls";

export default {
	name: "Nationality",
	props: {
		value: String,
		required: Boolean,
		classes: String
	},
	mixins: [
		KeyControl
	],
	computed: {
		require() {
			return this.required === true ? this.required : false
		},
		wclass(){
			return this.classes !== undefined ? this.classes : 'wp100'
		},
		nationality:{
			get(){
				return this.value
			},
			set(payload){
				this.$emit("update", payload)
			}
		}
	},
	data() {
		return {
			language: this.$store.state.language,
			nationalities: [],
			default: this.value,
			nationalityBase: [
				'Россия',
				'Республика Беларусь',
				'Армения',
				'Абхазия',
				'Азербайджан',
				'Грузия',
				'Республика Молдова',
				'Казахстан',
				'Киргизия',
				'Таджикистан',
				'Туркменистан',
				'Латвия',
				'Литва',
				'Эстония',
				'Узбекистан',
				'Украина',
			],
			show: false,
			currentIndex: 0
		}
	},
	created() {
		//this.nationality = this.value
		this.nationalities = this.nationalityBase
	},
	events: {
		onHide: function () {
			this.onHide()
		}
	},
	methods: {
		search() {
			this.loading = true

			this.nationalities = this.nationalityBase

			if (!this.show) {
				this.onShow()
			}

		},
		select(payload, index) {
			//console.log(index)

			this.currentIndex = parseInt(index)
			this.nationality = payload
			this.default = payload

			this.onHide()
			this.update()
		},
		selectCurrent(){
			this.nationality = this.nationalityBase[this.currentIndex]
			this.onHide()
		},
		selectIndex(index) {
			this.currentIndex = parseInt(index)
			this.nationality = this.nationalityBase[this.currentIndex]
		},
		selectFirst() {
			this.currentIndex = 0
			this.nationality = this.nationalityBase[this.currentIndex]
		},
		selectNext() {

			let container = this.$refs.suggestionlist

			if(this.currentIndex < this.nationalityBase.length - 1){

				this.currentIndex++
				container.scrollTop = this.currentIndex * 30;

			}

			this.nationality = this.nationalityBase[this.currentIndex]
		},
		selectPrev() {

			let container = this.$refs.suggestionlist

			if(this.currentIndex > 0){

				this.currentIndex--
				container.scrollTop = this.currentIndex * 30;

			}

			this.nationality = this.nationalityBase[this.currentIndex]
		},
		selectLast() {
			this.currentIndex = this.nationalityBase.length
			this.nationality = this.nationalityBase[this.currentIndex]
		},
		update() {
			//this.$emit("update", this.nationality)
		},
		clean() {
			this.nationality = ''
			this.default = ''
			this.update()
		},
		onShow() {
			this.show = true
		},
		onHide() {
			this.show = false
			//this.update()
		},
	}
}
</script>

<style scoped>

</style>