<template>

	<div class="gstring pl10 pr10" style="overflow: unset">

		<div class="divider filter pt10 pb10" data-id="address">{{language.Title}}</div>

		<div class="filterblock no-border relative cleared">

			<input type="text" id="content" class="wp100 p5 pl10 pr10" :placeholder="language.Title" autocomplete="on" v-model="cfilter.title" @keyup="updateFilter">
			<div class="clearinputs" @click="clearInput('title')"><i class="icon-block-1 red"></i></div>

		</div>

		<div class="divider pt10 pb10">{{language.Channels}}</div>

		<div class="no-border relative">

			<channel v-model="cfilter.channel" :selected="cfilter.channel" :height="400" @select="updateFilter"/>

		</div>

		<div class="divider pt10 pb10">{{ language.Other }}</div>

		<div>

			<div class="filterblock no-border checkbox popblock-item" title="Archived" data-id="address">
				<label class="wp100 pl10 mt5 flex-container float">
					<input class="taskss" name="isarchive" type="checkbox" id="isarchive" value="true" v-model="cfilter.archive" @change="updateFilter">
					<span class="custom-checkbox"><i class="icon-ok"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span class="block Bold fs-11">{{ language.Archived }}</span>
					</span>
				</label>
			</div>
			<div class="filterblock no-border checkbox popblock-item" title="Archived" data-id="address">
				<label class="wp100 pl10 mt5 flex-container float">
					<input class="taskss" name="isactive" type="checkbox" id="isactive" value="true" v-model="cfilter.active" @change="updateFilter">
					<span class="custom-checkbox"><i class="icon-ok"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span class="block Bold fs-11 green">{{ language.Active }}</span>
					</span>
				</label>
			</div>

		</div>

	</div>

</template>

<script>
import Channel from "@/components/elements/channel";
export default {
	name: "Filters",
	components: {Channel},
	model:{
		prop: "cfilter",
		event: "change"
	},
	props:{
		cfilter:{
			type: Object,
			default: () => {
				return {
					title: '',
					archive: '',
					active: '',
					channel: [],
				}
			}
		}
	},
	data(){
		return {
			guides: this.$store.getters.guides,
			language: this.$store.state.language,
		}
	},
	watch: {

	},
	computed:{
	},

	methods:{
		updateFilter(){
			//console.log("filter", this.cfilter)
			this.$emit("change", this.cfilter)
		},

		clearInput(element){
			this.$store.state.marketingtarifs.filters.tarifs[element] = '';
		}
	},

}
</script>

<style scoped>

</style>