<template>

	<div>

		<DIV class="zagolovok">{{ language.Delete }} {{ language.Marketing.Tarif }}</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabse" class="pr10 pl10" style="overflow-y: unset !important;">

				<div class="flex-container mb10 box--child">

					<div class="flex-string wp100 mt10 relative material mradio">

						<TarifRadio v-model="form.newid" :label="true" :name="'Новый тариф'" :required="true" :exclude="[form.id]" @select="tarifChanged"/>

					</div>

					<div class="infodiv mt10">

						<h3>Внимание!</h3>
						<p>К этому тарифу могут быть привязаны записи. Укажите тариф, к которому нужно прикрепить такие записи</p>

					</div>

				</div>

			</div>

			<div class="button--pane text-right Bold">

				<A href="javascript:void(0)" @click="formSubmit" class="button m0">{{ language.Do }}</A>
				<A href="javascript:void(0)" @click="closeForm" class="button graybtn">{{ language.Cancel }}</A>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
import TarifRadio from "@/components/elements/tarif-radio";

export default {
	name: "TarifDeleteForm",
	props: {
		id: Number,
		// передается только для того, чтобы vue обновлял с сервера
		time: Object
	},
	components:{
		TarifRadio,
	},
	data() {
		return {
			form: {
				id: this.id,
				newid: null,
			},
			guides: this.$store.state.guides,
			language: this.$store.state.language,
			projectList: this.$store.state.guides.projects,
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false,
			message: "",
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.loadCard()
	},
	watch: {
		time(){
			this.loadCard()
		}
	},
	methods: {
		loadCard() {

			this.loading = true

			let param = {};

			param['id'] = this.card
			param['action'] = 'info'

			axios.post(this.apiBaseURL + '/api/tarifs/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						let rez = data.data.result

						this.form.id = rez.id

						this.$emit("loaded")
						this.loading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		formSubmit() {

			let param = {}

			param = this.form
			param['action'] = 'delete'
			param['id'] = this.card
			param['newid'] = this.newid

			axios.post(this.apiBaseURL + '/api/tarifs/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.message = data.data.result.result
						this.$emit("update")
						this.$emit("guide")
						this.closeForm()

						this.$toast.success({
							title: 'Success',
							message: data.data.result.result,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

					},
				)
				.catch(error => {
					console.log(error)

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},
		closeForm() {
			this.$emit("close")
			//this.$destroy()
		},
		tarifChanged(payload) {
			if (payload !== undefined) {
				this.form.newid = payload
			}
		},
	}
}
</script>

<style scoped>

</style>