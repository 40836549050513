<template>

	<div class="gstring pl10 pr10">

		<div class="divider filter pt10 pb10" data-id="address">{{language.Phone}}</div>

		<div class="filterblock no-border relative cleared">

			<input type="text" id="phone" class="wp100 p5 pl10 pr10" :placeholder="language.Phone" autocomplete="on" v-model="cfilter.phone" @keyup="updateFilter" @focusout="updateFilter">
			<div class="clearinputs" @click="clearInput('phone')"><i class="icon-block-1 red"></i></div>

		</div>

		<div class="divider pt10 pb10">{{ language.Users }}</div>

		<div class="no-border relative">

			<users v-model="cfilter.users" @select="updateFilter"/>

		</div>

		<div class="divider pt10 pb10">{{language.Results}}</div>

		<div>

			<div v-for="item in guides.results" :key="item.id" class="filterblock no-border checkbox popblock-item" :class="{'hidden': !['na', 'task'].includes(item.type)}" :title="item.title">
				<label v-if="item.type === 'na' || item.type === 'task'" class="wp100 pl10 mt5 flex-container float">
					<input type="checkbox" :value="item.id" v-model="cfilter.results" @change="updateFilter">
					<span class="custom-checkbox"><i class="icon-ok"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span class="block Bold fs-11" :class="{'red':item.type === 'lose','green':item.type === 'win'}">{{ item.title }}</span>
					</span>
				</label>
			</div>

		</div>

		<div class="divider pt10 pb10">{{ language.Other }}</div>

		<div>

			<div class="filterblock no-border checkbox popblock-item" title="Archived" data-id="address">
				<label class="wp100 pl10 mt5 flex-container float">
					<input class="taskss" name="isarchive" type="checkbox" id="isarchive" value="true" v-model="cfilter.archive" @change="updateFilter">
					<span class="custom-checkbox"><i class="icon-ok"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span class="block Bold fs-11">{{ language.Archived }}</span>
					</span>
				</label>
			</div>
			<div class="filterblock no-border checkbox popblock-item" title="Archived" data-id="address">
				<label class="wp100 pl10 mt5 flex-container float">
					<input class="taskss" name="isactive" type="checkbox" id="isactive" value="true" v-model="cfilter.active" @change="updateFilter">
					<span class="custom-checkbox"><i class="icon-ok"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span class="block Bold fs-11 green">{{ language.Active }}</span>
					</span>
				</label>
			</div>

		</div>

		<div class="divider pt10 pb10">{{ language.Period }}</div>

		<div>

			<div class="filterblock no-border radio popblock-item">
				<label class="wp100 pl10 mt5 flex-container float" @click="updateFilter">
					<input type="radio" v-model="cfilter.period" :value="null" @change="updateFilter">
					<span class="custom-radio"><i class="icon-radio-check"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span>Все</span>
					</span>
				</label>
			</div>

			<div class="filterblock no-border radio popblock-item">
				<label class="wp100 pl10 mt5 flex-container float" @click="updateFilter">
					<input type="radio" v-model="cfilter.period" value="today" @change="updateFilter">
					<span class="custom-radio"><i class="icon-radio-check"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span>Сегодня</span>
					</span>
				</label>
			</div>

			<div class="filterblock no-border radio popblock-item">
				<label class="wp100 pl10 mt5 flex-container float" @click="updateFilter">
					<input type="radio" v-model="cfilter.period" value="tomorrow" @change="updateFilter">
					<span class="custom-radio"><i class="icon-radio-check"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span>Завтра</span>
					</span>
				</label>
			</div>

			<div class="filterblock no-border radio popblock-item">
				<label class="wp100 pl10 mt5 flex-container float" @click="updateFilter">
					<input type="radio" v-model="cfilter.period" value="old" @change="updateFilter">
					<span class="custom-radio"><i class="icon-radio-check"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span>Вчера и ранее</span>
					</span>
				</label>
			</div>

			<div class="filterblock no-border radio popblock-item" title="Активные задачи не старше 1 недели, включая сегодня">
				<label class="wp100 pl10 mt5 flex-container float" @click="updateFilter">
					<input type="radio" v-model="cfilter.period" value="queue" @change="updateFilter">
					<span class="custom-radio"><i class="icon-radio-check"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span>Очередь</span>
					</span>
				</label>
			</div>

		</div>

	</div>

</template>

<script>
import Users from "@/components/elements/users";
export default {
	name: "Filters",
	components: {Users},
	model:{
		prop: "cfilter",
		event: "change"
	},
	props:{
		cfilter:{
			type: Object,
			default: () => {
				return {
					phone: '',
					finished: '',
					users: [],
					results: [],
					archive: '',
					active: '',
					period: "today"
				}
			}
		}
	},
	data(){
		return {
			guides: this.$store.getters.guides,
			language: this.$store.state.language,
		}
	},

	methods:{
		updateFilter(){
			//console.log("filter", this.cfilter)
			this.$emit("change", this.cfilter)
		},

		clearInput(element){
			this.$store.state.tasks.filters.tasks[element] = '';
		}
	},

	// created() {
	// 	JSON.parse(JSON.stringify(this.addresses))
	// }
}
</script>

<style scoped>

</style>