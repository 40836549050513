import Vue from 'vue'
import App from './App.vue'
import router from "./router/router"
import {store} from './store'
import axios from "axios"
import VueAxios from "vue-axios"
import Vuelidate from 'vuelidate'
import CxltToastr from 'cxlt-vue2-toastr'
import VueMeta from 'vue-meta'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueSweetalert2 from 'vue-sweetalert2'
import VueTextareaAutosize from 'vue-textarea-autosize'
import CKEditor from 'ckeditor4-vue';
import VueCodemirror from 'vue-codemirror';

import 'cxlt-vue2-toastr/dist/css/cxlt-vue2-toastr.css'
import 'sweetalert2/dist/sweetalert2.min.css'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ru'

import 'leaflet/dist/leaflet.css'
import * as L from 'leaflet'
Vue.L = Vue.prototype.$L = L

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
//import TreeView from "vue-json-tree-view"

//import * as Sentry from "@sentry/vue";
//import { BrowserTracing } from "@sentry/tracing";

import VueNativeSock from 'vue-native-websocket'
Vue.use(VueNativeSock, 'wss://hunter.100crm.ru:8090', {
	connectManually: true,
})

// конфигурация
//import * as configs from 'config'
//Vue.configs = Vue.prototype.$configs = configs

Vue.use(VueMeta)
Vue.use(VueAxios, axios)
Vue.use(Vuelidate)
Vue.use(CxltToastr)
//Vue.use(VueMoment)
Vue.use(VueMoment, {
	moment,
})
Vue.use(VueSweetalert2)
Vue.use(DatePicker)
Vue.use(VueTextareaAutosize)
Vue.use(CKEditor)
Vue.use(VueCodemirror)
Vue.use(VueLodash, { lodash: lodash })
//Vue.use(TreeView)

Vue.config.productionTip = false
Vue.prototype.$store = store;
//Vue.configs = configs

/**
 * Подсветка слова в строке
 *  v-html="$options.filters.highlight(item, city)"
 */
Vue.filter('highlight', function (word, query) {
	let check = new RegExp(query, "ig");
	return word.toString().replace(check, function (matchedText) {
		return ('<span class="hight-light">' + matchedText + '</span>');
	});
});

/**
 * Клик вне указанного объекта
 * help: https://ru.vuejs.org/v2/guide/custom-directive.html
 */
Vue.directive('click-outside', {
	bind: function (el, binding, vnode) {
		el.clickOutsideEvent = function (event) {
			// here I check that click was outside the el and his children
			if (!(el == event.target || el.contains(event.target))) {
				// and if it did, call method provided in attribute value
				vnode.context[binding.expression](event);
			}
		};
		document.body.addEventListener('click', el.clickOutsideEvent)
	},
	unbind: function (el) {
		document.body.removeEventListener('click', el.clickOutsideEvent)
	},
});

/**
 * Делает первую букву в строке большой
 */
Vue.filter("capitalize", function (value) {
	if (!value)
		return '';
	value = value.toString();
	return value.charAt(0).toUpperCase() + value.slice(1);
});

/**
 * Проверяет объект на пустоту
 */
Vue.filter(("isObjEmpty"), function(value){
	return Object.entries(value).length === 0
})

/**
 * Преобразует Объект в Массив. Походу не работает
 */
Vue.filter(("objToArray"), function (value){
	return lodash.map(value, function(e){
		return e
	})
})

/*
Sentry.init({
	Vue,
	environment: "production",
	//dsn: "http://2a46d83b901048139a58d97efc876195@192.168.156.11:9000/2",
	dsn: "https://0e8124d98e504d03ab434bb6fb3d6df7@o1184997.ingest.sentry.io/6303397",
	integrations: [
		new BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			tracingOrigins: ["hunter.takemycall.ru",/^\//],
		}),
	],
	tracesSampleRate: 1.0,
});
*/

/**
 * Глобальные переменные
 */
Vue.prototype.moment = moment
moment.tz.setDefault("Europe/Moscow");

axios.get('language/ru-RU.json').then(
	(response) => {
		store.commit("SET_LANGUAGE", response.data)
	},
)

axios.get('config.json').then(
	(response) => {
		if(response.data.custom) {
			response.data.apiBaseURL = location.origin
		}
		store.commit("SET_CONFIG", response.data)

	},
).then(
	() => {

		new Vue({
			router,
			store,
			data:{
				shortcuts: [
					{
						text: 'Сегодня',
						onClick() {
							const date = new Date();
							// return a Date
							return date;
						},
					},
					{
						text: '+10 мин',
						onClick() {
							const date = new Date();
							date.setTime(date.getTime() + 600 * 1000);
							console.log('date1:' + date)
							return date;
						},
					},
					{
						text: '+20 мин',
						onClick() {
							const date = new Date();
							date.setTime(date.getTime() + 2 * 600 * 1000);
							return date;
						},
					},
					{
						text: '+30 мин',
						onClick() {
							const date = new Date();
							date.setTime(date.getTime() + 3 * 600 * 1000);
							return date;
						},
					},
					{
						text: '+1 час',
						onClick() {
							const date = new Date();
							date.setTime(date.getTime() + 3600 * 1000);
							return date;
						},
					},
					{
						text: '+2 час',
						onClick() {
							const date = new Date();
							date.setTime(date.getTime() + 2 * 3600 * 1000);
							return date;
						},
					},
					{
						text: 'Завтра',
						onClick() {
							const date = new Date();
							date.setTime(date.getTime() + 3600 * 1000 * 24);
							return date;
						},
					},
				],
			},
			render: h => h(App),
		}).$mount('#app')

	}
)