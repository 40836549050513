<template>

	<div class="ydropDown wp100" @click="toggle" :class="{'checked':checked, 'like-input':label, 'req':required}" ref="tarifSelect" v-click-outside="onHide">

		<span title="Показатель"><i class="icon-article-alt black hidden"></i></span>
		<span class="ydropText">{{ text }}</span>
		<i class="icon-down-open pull-aright arrow"></i>

		<div class="yselectBox mt2" style="max-height: 300px; width:100%" v-if="show">

			<div v-if="label" class="ydropString yRadio" aria-disabled="true">
				<label class="mt5 gray-blue" @click="update([])">
					<input type="radio" v-model="tarif" value="null" @change="update([])">
					Необходимо выбрать
				</label>
			</div>
			<div v-for="item in tarifList" class="ydropString ellipsis checkbox" :key="item.id" :class="{'bluebg-sub':parseInt(item.id) === tarif}">
				<label class="wp97 mt5" @click="update(item)">
					<input type="radio" v-model="tarif" :value="parseInt(item.id)" @change="update(item)">
					<span>{{ item.title }}</span>
					<span class="pull-right blue fs-09">{{ item.subtitle }}</span>
				</label>
			</div>

		</div>
		<div v-if="label" class="label">{{ inputName }}</div>

	</div>

</template>

<script>
import axios from "axios";
import lodash from "lodash";

export default {
	name: "TarifRadio",
	props: {
		id: Number,
		label: Boolean,
		required: Boolean,
		name: String, // Label
		exclude: Array, // исключаемые
	},
	data() {
		return {
			language: this.$store.state.language,
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			tarif: 0,
			show: false,
			checked: false,
			text: "",
			tarifList: []
		}
	},
	computed: {
		inputName() {
			return this.name !== undefined ? this.name : this.language.Marketing.Tarif
		},
		excludeID(){
			return this.exclude !== undefined ? this.exclude : []
		},
		// делаем, для того, чтобы исключить не нужные каналы
		/*tarifList(){

			let vm = this

			return this.xtarifList.filter(function (e) {
				return !vm.excludeID.includes(e.id)
			})

		}*/
	},
	created() {
		this.tarif = this.id
		this.getList()
	},
	watch: {
		tarif() {
			this.select()
			//console.log("watch: ", this.channel)
		}
	},
	methods: {
		getList() {

			let param = [];

			param['action'] = "guide"

			this.loading = true

			axios.post(this.apiBaseURL + '/api/tarifs/', {action:"guide"}, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					let vm = this
					let xtarifList = data.data.result.list

					// преобразуем обхект в массив
					xtarifList = lodash.map(xtarifList, function(e){
						return e
					});

					// xtarifList = this.filters.objToArray(xtarifList)

					// фильтруем массив
					this.tarifList = xtarifList.filter( (e) => {
						return !vm.excludeID.includes(e.id)
					})

					this.select()

					this.loading = false

				}
			)

		},
		select() {

			if (this.tarif > 0) {

				for (let key in this.tarifList) {
					if (this.tarifList[key].id === this.tarif) {
						//console.log(this.tarifList[key])
						this.text = this.tarifList[key].title
						this.checked = true
						break
					}
				}
			}

		},
		update(selected) {

			this.text = selected.id > 0 ? selected.title : ''
			this.checked = selected.id > 0
			this.tarif = parseInt(selected.id)

			this.$emit("select", this.tarif)

		},
		toggle() {
			this.show = !this.show
		},
		onShow() {
			this.show = true
		},
		onHide() {
			this.show = false
		}
	}
}
</script>

<style scoped>

</style>