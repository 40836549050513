const state = {
	tarifs: {
		//items: {},
		page: 1,
		total: 1,
		pageall: 1
	},
	filters: {
		//project: [],
		tarifs: {
			title: '',
			archive: '',
			active: '',
			channel: []
		},
		sort: "trf.title",
		order: "desc"
	},
};

const mutations = {
	// address
	/*SET_FILTER_PROJECTS (state, projects) {
		state.filters.project = projects
	},*/

	SET_FILTER (state, items) {
		state.filters.tarifs = items
	},

	SET_FILTER_ORDER (state, order) {
		state.filters.order = order
	},

	SET_FILTER_SORT (state, sort) {
		state.filters.sort = sort
	},

	SET_CLEAN_FILTERS (state) {
		state.filters = {
			//project: [],
			tarifs: {
				title: '',
				archive: '',
				active: '',
				channel: []
			},
			sort: "trf.title",
			order: "desc"
		}
	},

	SET_MEET(state, payload) {
		state.tarifs.page = payload.page
		state.tarifs.pageall = payload.pageall
		state.tarifs.total = payload.total
	},
	SET_FILTERS(state, payload) {
		state.filters = payload
	},

	CHANGE_PAGE(state, page) {
		state.tarifs.page = page
	},
};

export default {
	namespaced: true,
	state,
	mutations
}