// https://habr.com/ru/post/421551/

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import candidates from "@/store/modules/candidates"
import addresses from "@/store/modules/addresses"
import needs from "@/store/modules/needs"
import feedbacks from "@/store/modules/feedbacks"
import steps from "@/store/modules/steps"
import mcontrol from "@/store/modules/mcontrol"
import meets from "@/store/modules/meets"
import tasks from "@/store/modules/tasks"
import marketing from "@/store/modules/marketing"
import marketingtarifs from "@/store/modules/marketingtarifs"
import marketingempty from "@/store/modules/marketingempty"
import zarplata from "@/store/modules/zarplata"
import axios from "axios";

Vue.use(Vuex);

export const actions = {

	loadGuides(context) {

		console.log('loadGuides')

		axios.post(this.apiBaseURL + '/api/guides/', {}, {
		headers: {
			"Token": this.$store.getters.token,
			'Content-Type': 'application/json',
			'Session': this.$store.state.user.session
		},
		timeout: 60000,
		}).then(
			(data) => {
				//this.guides = data.data.result
				context.commit('SET_GUIDES', data.data.result)
			},
		)

	},

}

export const store = new Vuex.Store({
	modules:{
		candidates,
		addresses,
		needs,
		feedbacks,
		steps,
		mcontrol,
		meets,
		tasks,
		marketing,
		marketingtarifs,
		marketingempty,
		zarplata
	},
	state: {
		seen: false,
		user: {
			loggedIn: false,
			UserID: 0,
			isAdmin: false,
			session: "",
			name: "",
			email: "",
			avatar: "",
			tip: "",
			settings: {},
		},
		guides: {
			projects: {},
			channels: {},
			results: {},
			work_types: {},
			lead_types: {},
			reasons: {},
			other: {},
			users: {},
			vacancylist: {},
			vacancy: {}
		},
		token: "",
		//token: configs.VUE_TOKEN,
		//dadata: configs.VUE_DADATA,
		apiBaseURL: "",
		menuCollapsed: true,
		language: {},
		config:{
			API_TOKEN: "",
			DADATA_TOKEN: "",
			apiBaseURL: ""
		}
	},
	getters: {
		getSeen: state => {
			return state.seen
		},
		getGuides: state => {
			return state.guides
		},
		auth(state) {
			return state.user
		},
		token: state => {
			return state.config.API_TOKEN
		},
		address: state => {
			return state.address
		},
		addressFilters: state => {
			return state.addresses.filters
		},
		guides: state => {
			return state.guides
		},
		apiBaseURL: state => {
			return state.config.apiBaseURL
		},
		usetData: state => {
			return state.user
		}

	},
	mutations: {
		SET_CONFIG(state, payload){

			state.config = payload
			state.token = payload.API_TOKEN
			state.apiBaseURL = payload.apiBaseURL

		},
		SET_LANGUAGE(state, payload){

			state.language = payload

		},
		SET_USER(state, payload){
			//console.log(payload)
			state.user.name = payload.user.name
			state.user.email = payload.user.email
			state.user.settings = payload.user.settings
			state.user.avatar = payload.user.avatar
			state.user.tip = payload.UserType
			state.user.isAdmin = payload.user.isadmin
			state.user.UserID = payload.UserID
			state.user.comet = payload.comet
		},
		SET_USER_SHORT(state, payload){
			//console.log(payload)
			state.user.name = payload.user.name
			state.user.email = payload.user.email
			state.user.settings = payload.user.settings
			state.user.avatar = payload.user.avatar
			state.user.tip = payload.UserType
			state.user.isAdmin = payload.user.isadmin
			state.user.UserID = payload.UserID
		},
		SET_LOGGED_IN(state, status) {
			state.user.loggedIn = status
		},
		SET_LOGGED_OUT(state) {
			state.user = {
				loggedIn: false,
				UserID: 0,
				isAdmin: false,
				session: "",
				name: "",
				email: "",
				avatar: "",
				tip: "",
				settings: {},
			}
		},
		SET_SESSION(state, status) {
			state.user.session = status
		},
		SET_MENU_COLLAPSED(state) {
			state.menuCollapsed = !state.menuCollapsed
		},
		SET_GUIDES(state, payload) {
			state.guides = payload
		},
		SET_GUIDES_PROJECT(state, payload) {
			state.guides.projects = payload
		},
		SET_GUIDES_REZULTS(state, payload) {
			state.guides.results = payload
		},
		SET_GUIDES_LEAD(state, payload) {
			state.guides.lead_types = payload
		},
		SET_GUIDES_VACANCY(state, payload) {
			state.guides.vacancylist = payload
		},
		SET_GUIDES_USER(state, payload) {
			state.guides.users = payload
		},
	},
	actions: {

		loadGuides(context) {

			//console.log('loadGuides')

			axios.post(context.state.apiBaseURL + '/api/guides/', {}, {
				headers: {
					"Token": context.state.token,
					'Content-Type': 'application/json',
					'Session': context.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {
					//this.guides = data.data.result
					context.commit('SET_GUIDES', data.data.result)
				},
			)

		},

	},
	plugins: [
		createPersistedState({key: 'hunterstore'})
	]
});
