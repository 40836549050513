import { render, staticRenderFns } from "./FeedbackCard.vue?vue&type=template&id=6eb2138e&scoped=true&"
import script from "./FeedbackCard.vue?vue&type=script&lang=js&"
export * from "./FeedbackCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6eb2138e",
  null
  
)

export default component.exports