<template>

	<div class="gstring pl10 pr10">

		<div class="divider filter pt10 pb10" data-id="address">{{ language.Description }}, {{ language.Address.Address }}, {{ language.Code }}</div>

		<div class="filterblock no-border relative cleared" data-id="address">

			<input type="text" id="content" class="wp100 p5 pl10 pr10" :placeholder="language.Description +', '+ language.Address.Address +', '+ language.Code" autocomplete="on" v-model="cfilter.content" @keyup="updateFilter">
			<div class="clearinputs" @click="clearInput('content')"><i class="icon-block-1 red"></i></div>

		</div>

		<div class="divider filter pt10 pb10" data-id="address">{{ language.Address.Metro }}</div>

		<div class="filterblock no-border relative cleared" data-id="address">

			<input type="text" id="metro" class="wp100 p5 pl10 pr10" :placeholder="language.Address.Metro" autocomplete="on" v-model="cfilter.metro" @keyup="updateFilter" @focusout="updateFilter">
			<div class="clearinputs" @click="clearInput('metro')"><i class="icon-block-1 red"></i></div>

		</div>

		<div class="divider pt10 pb10">{{ language.City }}</div>

		<div class="no-border relative cleared">

			<input type="text" id="city" class="wp100 p5 pl10 pr10" :placeholder="language.City" autocomplete="on" v-model="cfilter.city" @keyup="updateFilter" @focusout="updateFilter">
			<div class="clearinputs" @click="clearInput('city')"><i class="icon-block-1 red"></i></div>

		</div>

		<div class="divider pt10 pb10">{{ language.Channel }}</div>

		<div class="no-border relative">

			<channel v-model="cfilter.channel" :selected="cfilter.channel" @select="updateFilter"/>

		</div>

		<div class="divider pt10 pb10">{{ language.Other }}</div>

		<div>

			<div class="filterblock no-border checkbox popblock-item" title="Archived" data-id="address">
				<label class="wp100 pl10 mt5 flex-container float">
					<input class="taskss" name="isarchive" type="checkbox" id="isarchive" value="true" v-model="cfilter.isarchive" @change="updateFilter">
					<span class="custom-checkbox"><i class="icon-ok"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span class="block Bold fs-11">{{ language.Archived }}</span>
					</span>
				</label>
			</div>
			<div class="filterblock no-border checkbox popblock-item" title="Archived" data-id="address">
				<label class="wp100 pl10 mt5 flex-container float">
					<input class="taskss" name="isactive" type="checkbox" id="isactive" value="true" v-model="cfilter.isactive" @change="updateFilter">
					<span class="custom-checkbox"><i class="icon-ok"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span class="block Bold fs-11 green">{{ language.Active }}</span>
					</span>
				</label>
			</div>
			<div class="filterblock no-border checkbox popblock-item" title="Без координат" data-id="address">
				<label class="wp100 pl10 mt5 flex-container float">
					<input class="taskss" name="emptygeo" type="checkbox" id="emptygeo" value="true" v-model="cfilter.emptygeo" @change="updateFilter">
					<span class="custom-checkbox"><i class="icon-ok"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span class="block Bold fs-11">{{ language.EmptyGeocoords }}</span>
					</span>
				</label>
			</div>

		</div>

	</div>

</template>

<script>
import Channel from "@/components/elements/channel";
export default {
	name: "Filters",
	components: {Channel},
	model:{
		prop: "cfilter",
		event: "change"
	},
	props:{
		cfilter:{
			type: Object,
			default: () => {
				return {
					content: '',
					metro: '',
					city: '',
					isarchive: '',
					isactive: '',
					emptygeo: '',
					channel: []
				}
			}
		}
	},
	data(){
		return {
			language: this.$store.state.language,
		}
	},
	methods:{
		updateFilter(){
			//console.log("filter", this.cfilter)
			this.$emit("change", this.cfilter)
		},

		clearInput(element){
			this.$store.state.addresses.filters.addresses[element] = '';
		}
	},

	// created() {
	// 	JSON.parse(JSON.stringify(this.addresses))
	// }
}
</script>

<style scoped>

</style>