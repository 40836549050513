<template>

	<div class="flex-string relative material cleared" :class="wclass" v-click-outside="onHide">

		<input type="text" class="wp100" placeholder=" " v-model="type" :class="{'required':required}" autocomplete="on" @keyup="search" @focus="onShow" ref="typeworkinput"
			@keydown.up.exact.stop.prevent="selectPrev()"
			@keydown.down.exact.stop.prevent="selectNext()"
			@keydown.enter.exact.stop.prevent="selectCurrent()"
			@keydown.home.stop.prevent="selectFirst()"
			@keydown.end.stop.prevent="selectLast()"
		>
		<label>{{language.TypeLead}}</label>
		<div class="clearinputs mr5" @click="clean" ref="clearinputs"><i class="icon-block red fs-14"></i></div>
		<ul class="vu-suggestion" v-if="show" ref="suggestionlist">
			<li v-for="(item, index) in types" :class="{'current':index === currentIndex}" :key="index" @click="select(item, index)" v-html="$options.filters.highlight(item.title, type)">{{ item.title }}</li>
		</ul>

	</div>

</template>

<script>

import {KeyControl} from "@/mixins/key-controls";

export default {
	name: "Typelead",
	props: {
		value: String,
		required: Boolean,
		classes: String
	},
	mixins: [
		KeyControl
	],
	computed: {
		require() {
			return this.required === true ? this.required : false
		},
		wclass(){
			return this.classes !== undefined ? this.classes : 'wp100'
		}
	},
	data() {
		return {
			type: '',
			types: [],
			default: this.value,
			typeBase: this.$store.state.guides.lead_types,
			show: false,
			currentIndex: 0,
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
		}
	},
	created() {
		this.type = this.value
		this.types = this.typeBase
	},
	mounted() {
		//this.listenKeys()
	},
	watch: {
		/*currentIndex(){
			console.log(this.currentIndex)
		}*/
	},
	events: {
		onHide: function () {
			this.onHide()
		}
	},
	methods: {
		search() {
			this.loading = true

			//let keyword = this.nationality.toLocaleLowerCase()

			this.types = this.typeBase

			//console.log(keyword)

			/*if (keyword !== '') {

				this.nationalities = this.nationalityBase.filter(function (e) {
					return e.toLowerCase().indexOf(keyword) !== -1;
				})

			}*/

			if (!this.show) {
				this.onShow()
			}

		},
		select(payload, index) {
			//console.log(index)

			this.currentIndex = parseInt(index)
			this.type = payload.title
			this.default = payload.title

			this.onHide()
			this.update()
		},
		selectCurrent(){
			this.type = this.typeBase[this.currentIndex]
			this.onHide()
		},
		selectIndex(index) {
			this.currentIndex = parseInt(index)
			this.type = this.typeBase[this.currentIndex]
		},
		selectFirst() {
			this.currentIndex = 0
			this.type = this.typeBase[this.currentIndex]
		},
		selectNext() {

			let container = this.$refs.suggestionlist

			if(this.currentIndex < this.typeBase.length - 1){

				this.currentIndex++
				container.scrollTop = this.currentIndex * 30;

			}

			this.type = this.typeBase[this.currentIndex]
		},
		selectPrev() {

			let container = this.$refs.suggestionlist

			if(this.currentIndex > 0){

				this.currentIndex--
				container.scrollTop = this.currentIndex * 30;

			}

			this.type = this.typeBase[this.currentIndex]
		},
		selectLast() {
			this.currentIndex = this.typeBase.length
			this.type = this.typeBase[this.currentIndex]
		},
		update() {
			this.$emit("update", this.type)
		},
		clean() {
			this.type = ''
			this.default = ''
			this.update()
		},
		onShow() {
			this.show = true
		},
		onHide() {
			this.show = false
			//this.update()
		},
	}
}
</script>

<style scoped>

</style>