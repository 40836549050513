<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				Settings. Внешние интеграции
			</div>

		</div>

		<div v-if="loading" class="content-loading"></div>

		<div v-if="!loading" class="page--body">

			<div class="page--body">

				<div class="infodiv mt10">
					<div class="Bold">Интеграции с сайтами вакансий</div>
					В этом разделе вы можете управлять настройками интеграций с сайтами, с которых будут собираться отклики на вакансии.
				</div>

				<div class="wp100 mx-auto mt20 pt10">

					<div class="flex-content typelist token p5 tbl--header sticked--top">

						<div class="flex-string">Приложение</div>
						<div class="flex-string">Client ID</div>
						<div class="flex-string">Client Secret</div>
						<div class="flex-string text-center">Действия</div>

					</div>

					<div v-for="item in list" :key="item.id" class="flex-content typelist token ha border-bottom p5">

						<div class="flex-string">
							<div class="Bold fs-12">{{item.name}}</div>
						</div>
						<div class="flex-string">
							<div class="text-wrap">{{item.data.client_id}}</div>
							<div v-if="item.data.client_secret" class="viewdiv fs-09 mt10">
								<div class="gray disable--select">Secret</div>
								<div class="fs-11 mt5 text-wrap">{{item.data.client_secret}}</div>
							</div>
						</div>
						<div class="flex-string">
							<div class="ellipsis">{{item.data.client_secret}}</div>
						</div>
						<div class="flex-string text-center">

							<a href="javascript:void(0)" @click="editItem(item.provider)" title="Редактировать" class="button dotted bluebtn m0"><i class="icon-pencil"></i></a>
							<a href="javascript:void(0)" title="Удалить" class="button dotted redbtn m0"><i class="icon-cancel-circled"></i></a>

						</div>

					</div>

				</div>

				<div class="space-100"></div>

				<div v-if="floading" class="loading"></div>

			</div>

		</div>

		<Dialog ref="dialog" :show="showdialog" :width="dialogwidth" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="(provider !== '')" v-slot:dlgbody>
				<HRIntegrationForm :provider="provider" :time="time" @close="closeDialog" @loaded="dialogLoaded" @update="load"/>
			</template>
		</Dialog>

	</div>

</template>

<script>

import axios from "axios"
import Dialog from "@/components/modal/Dialog"
import HRIntegrationForm from '@/pages/settings/HRIntegrationForm.vue'

export default {
	name: "HRIntegrationSet",
	components: {
		Dialog,
		HRIntegrationForm
	},
	data() {
		return {
			list: {},
			loading: false,
			floading: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			showdialog: false,
			dialogloaded: false,
			dialogwidth: 700,
			provider: '',
			time: 0
		}
	},
	mounted() {
		this.load()
	},
	methods: {
		load() {

			this.loading = true

			let param = {};

			param.action = 'list'

			axios.post(this.apiBaseURL + '/api/hrintegration/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {
						//console.log(data.data)

						this.list = data.data.result

						this.$emit("loaded")

						this.loading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		editItem(payload) {
			this.provider = payload
			this.time = this.$moment()
			this.dialogwidth = 700
			this.showdialog = true
		},
		deleteItem(payload) {

			this.$swal.fire({
				title: "Are You Sure?",
				text: 'Item will be deleted forever',
				icon: 'question',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: '#3085D6',
				cancelButtonColor: '#D33',
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel"
			}).then((result) => {

				if (result.value) {

					// отправка формы здесь
					let param = {};

					param['id'] = payload
					param['action'] = "delete"

					axios.post(this.apiBaseURL + '/api/hrintegration/', param, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							(data) => {

								this.message = data.data.result.result

								this.load()
								this.closeDialog()

								this.$toast.success({
									title: 'Success',
									message: data.data.result.result,
									position: "bottom center",
									timeOut: 3000
								})

							},
						)
						.catch(error => {

							this.$toast.error({
								title: 'Error '+ error.code,
								message: "" + error.text,
								position: "bottom center",
								timeOut: 3000
							})

						})

				}

			});

		},
		closeDialog() {
			this.id = 0
			this.showdialog = false
			this.dialogwidth = 800
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Внешние интеграции / Settings',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.page--title {
	left       : 20px;
	display    : block;
	width      : 250px !important;
	text-align : left;
}

@media (min-width : 768px) and (max-width : 1024px) {

	.page--title {
		left : 0 !important;
	}

}
</style>