<template>

	<div>

		<BaseFilter v-model="projectFilter" @list="getList" :showprojects="false" ref="filters">
			<template v-slot:filters>
				<Filters v-model="pageFilters"></Filters>
			</template>
			<template v-slot:sorts>
				<Sorts v-model="sortFilters"></Sorts>
			</template>
			<template v-slot:title>
				{{language.Marketing.Tarifs}}
			</template>
		</BaseFilter>

		<div class="page--body" @click="closeAllFilters">

			<div v-if="loading" class="loading"></div>

			<h2 class="uppercase">{{language.Marketing.Tarifs}}</h2>

			<div class="wp100 mx-auto mt10 pt10">

				<div class="infodiv mb10">
					<div>Всего <b>{{ total }}</b> записей.</div>
					<div class="mt5" v-if="filters">
						<div v-if="filters.channel && filters.channel > 0" class="infodiv inline bgwhite mr5">{{language.Channel}}: <b>{{filters.channel.join(", ")}}</b></div>
						<div v-if="filters.title != null" class="infodiv inline bgwhite mr5">{{language.Title}}: <b>{{filters.title}}</b></div>
						<div v-if="filters.active" class="infodiv inline bgwhite mr5"><b>{{language.Active}}</b></div>
						<div v-if="filters.archive" class="infodiv inline bgwhite mr5"><b>{{language.Archive}}</b></div>
					</div>
				</div>

				<div class="flex-content2 typelist marketingtarif p5 tbl--header sticked--top">

					<div class="flex-string2">ID</div>
					<div class="flex-string2">{{language.Date}}</div>
					<div class="flex-string2">{{language.Channel}}</div>
					<div class="flex-string2">{{language.Title}}</div>
					<div class="flex-string2">{{language.Period}}, дн.</div>
					<div class="flex-string2">{{language.Marketing.Cost}}</div>
					<div class="flex-string">Вкл.</div>
					<div class="flex-string2 text-center">{{language.Actions}}</div>

				</div>

				<div v-for="(item, index) in items" :key="index" class="flex-content typelist marketingtarif ha border-bottom p5" :class="{'disabled':item.archive}">
					<div class="flex-string">{{ item.id }}</div>
					<div class="flex-string text-right">

						<div class="Bold blue fs-14">{{ $moment(item.date_create).format('H:mm') }}</div>
						<div class="fs-09 gray">{{ $moment(item.date_create).format('DD.MM.YY') }}</div>

					</div>
					<div class="flex-string">
						<div class="Bold ellipsis">{{ item.channelName }}</div>
					</div>
					<div class="flex-string">
						<div class="Bold ellipsis">{{ item.title }}</div>
					</div>
					<div class="flex-string">{{ item.period }}</div>
					<div class="flex-string">{{ item.cost }}</div>
					<div class="flex-string">
						<a href="javascript:void(0)" @click="toggleActivity(item.id)" :title="language.Toggle" class="button dotted" :class="{'green':item.active}">
							<i class="icon-eye green" v-if="item.active"></i>
							<i class="icon-eye-off red" v-if="!item.active"></i>
						</a>
					</div>
					<div class="flex-string text-right">

						<a @click="editItem(item.id)" :title="language.Edit" class="button dotted bluebtn m0">
							<i class="icon-pencil"></i>
						</a>
						<a @click="deleteItem(item.id)" :title="language.Delete" class="button dotted redbtn m0">
							<i class="icon-cancel-circled"></i>
						</a>

					</div>
				</div>

			</div>

			<div class="pagediv">

				<a href="javascript:void(0)" title="Первая" v-if="page > 1" @click="changePage(1)">&nbsp;<i class="icon-angle-double-left"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Предыдущая" v-if="page > 1" @click="changePage(page - 1)">&nbsp;<i class="icon-angle-left"></i>&nbsp;&nbsp;</a>
				{{ page }} из {{ pageall }}
				<a href="javascript:void(0)" title="Следующая" v-if="page >= 1 && page < pageall" @click="changePage(page + 1)">&nbsp;<i class="icon-angle-right"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Последняя" v-if="page < pageall && pageall > 1" @click="changePage(pageall)">&nbsp;<i class="icon-angle-double-right"></i>&nbsp;&nbsp;</a>

			</div>

			<div class="space-100"></div>

			<div class="mt20 p0 bottom-block" data-id="address">

				<A href="javascript:void(0)" @click="editItem(-1)" :title="language.Add">
					<i class="icon-plus"></i>
				</A>

				<A href="javascript:void(0)" @click="marketingExport" title="Экспорт" class="ml10 blue disabled hidden">
					<i class="icon-download-1"></i>
				</A>

			</div>

		</div>

		<SubPanel ref="subpanel" :show="showsubpanel"></SubPanel>

		<Dialog ref="dialog" :show="showdialog" :width="dialogwidth" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="(idcard > 0 || idcard === -1) && !deleteit" v-slot:dlgbody>
				<TarifForm :id="idcard" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="(idcard > 0 || idcard === -1) && deleteit" v-slot:dlgbody>
				<TarifDeleteForm :id="idcard" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
import axios from "axios"

import BaseFilter from "@/components/filters/BaseFilter"
import Filters from "@/pages/marketing/FiltersTarif"
import Sorts from "@/pages/marketing/SortsTarif"

import SubPanel from "@/components/modal/SubPanel"
import Dialog from "@/components/modal/Dialog"

import TarifForm from "@/pages/marketing/TarifForm";
import TarifDeleteForm from "@/pages/marketing/TarifDeleteForm.vue";

export default {
	name: "MarketingTarif",
	components: {
		Dialog,
		BaseFilter,
		Filters,
		Sorts,
		SubPanel,
		TarifForm,
		TarifDeleteForm,
	},
	data() {
		return {
			loading: false,
			filters: {},
			items: null,
			page: 1,
			pageall: 1,
			total: 0,
			dialog: {},
			idcard: 0,
			showsubpanel: false,
			showdialog: false,
			dialogloaded: false,
			dialogwidth: 800,
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			deleteit: false,
		}
	},
	computed: {
		projectFilter:{
			get () {
				return {
					//project: Array.isArray(this.$store.state.marketingtarifs.filters.project) ? this.$store.state.marketingtarifs.filters.project : [],
					order: this.$store.state.marketingtarifs.filters.order !== '' ? this.$store.state.marketingtarifs.filters.order : 'desc',
				}
			},

			set (value) {
				let newfilter = {
					//project: value.project,
					order: value.order,
					tarifs: this.$store.state.marketingtarifs.filters.tarifs,
					sort: this.$store.state.marketingtarifs.filters.sort,
				}
				this.$store.commit("marketingtarifs/SET_FILTERS", newfilter)
			}
		},

		sortFilters:{
			get () {
				return this.$store.state.marketingtarifs.filters.sort !== '' ? this.$store.state.marketingtarifs.filters.sort : 'title'
			},

			set (value) {
				let newfilter = {
					project: this.$store.state.marketingtarifs.filters.project,
					tarifs: this.$store.state.marketingtarifs.filters.tarifs,
					order: this.$store.state.marketingtarifs.filters.order,
					sort: value
				}
				this.$store.commit("marketingtarifs/SET_FILTERS", newfilter)
			}
		},

		pageFilters: {

			get () {
				return this.$store.state.marketingtarifs.filters.tarifs
			},

			set (value) {
				let newfilter = {
					project: this.$store.state.marketingtarifs.filters.project,
					tarifs: value,
					order: this.$store.state.marketingtarifs.filters.order,
					sort: this.$store.state.marketingtarifs.filters.sort,
				}
				this.$store.commit("marketingtarifs/SET_FILTERS", newfilter)
			}
		},

		marketing: {
			get () {
				return this.$store.state.tarifs.filters.marketing
			},

			set (value) {
				this.$store.commit("marketingtarifs/SET_FILTERS", value)
			}
		},

		projects: {
			get() {
				return Array.isArray(this.$store.state.marketingtarifs.filters.project) ? this.$store.state.marketingtarifs.filters.project : []
			},
			set(value) {
				this.$store.commit("marketingtarifs/SET_FILTER_PROJECTS", value)
			}
		},

		order: {
			get () {
				return this.$store.state.marketingtarifs.filters.order !== '' ? this.$store.state.marketingtarifs.filters.order : 'title'
			},
			set(value) {
				this.$store.commit("marketingtarifs/SET_FILTER_ORDER", value)
			}
		}
	},
	comments: {},

	created() {
		this.getList()
		this.pageTitle = this.language.Marketing.Tarifs
	},

	watch: {
		// при изменениях маршрута запрашиваем данные снова
		routes: 'getList'
	},

	methods: {
		updateFilters() {
			this.getList()
		},
		getList() {

			let param = [];

			param = JSON.parse(JSON.stringify(this.$store.state.marketingtarifs.filters))

			let marketing = param.tarifs

			for (let key in marketing) {
				if (marketing[key]) {
					param[key] = marketing[key]
				}
			}

			param['page'] = this.$store.state.marketingtarifs.tarifs.page
			param['limit'] = 100
			param['action'] = "list"

			this.loading = true

			axios.post(this.apiBaseURL + '/api/tarifs/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {
					this.filters = data.data.result.filters
					this.items = data.data.result.list
					this.page = data.data.result.page
					this.pageall = data.data.result.pageall
					this.total = data.data.result.total
					this.editable = data.data.result.editor
					this.$store.commit('marketingtarifs/SET_MEET', data.data.result)

					this.loading = false
				}
			)

		},
		updateList(){

			if( this.showsubpanel && this.idcard > 0 ){

				let c = this.idcard

				this.closeCard()

				let self = this;
				setTimeout(function() {
					self.viewCard(c)
				}, 100);

			}

			this.getList();

		},
		changePage(page) {
			this.page = page
			this.$store.commit("marketingtarifs/CHANGE_PAGE", page)

			this.getList()
		},

		marketingExport(){

			axios.post(this.apiBaseURL + '/api/tarifs/', {'action': 'export'}, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						window.open(this.apiBaseURL + '/' + data.data.result.url)

					},
				)
				.catch(error => {

					this.loading = false

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},

		editItem(payload){
			this.idcard = parseInt(payload)
			this.showdialog = true
			this.deleteit = false
		},
		deleteItem(payload) {

			this.idcard = parseInt(payload)
			this.deleteit = true
			this.dialogwidth = 500
			this.showdialog = true

		},
		toggleActivity(payload){

			// отправка формы здесь
			let param = {};

			param.id = payload
			param.action = "toggle"

			axios.post(this.apiBaseURL + '/api/tarifs/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.updateList()

						this.message = data.data.result.result

						this.$toast.success({
							title: 'Success',
							message: data.data.result.message,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

					},
				)
				.catch(error => {
					console.log(error)

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},

		closeCard() {
			this.idcard = 0
			this.showsubpanel = false
			this.deleteit = false
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
		closeDialog() {
			this.idcard = 0
			this.showdialog = false
			this.dialogwidth = 800
			this.deleteit = false
		},
		closeAllFilters() {
			this.$refs.filters.closeAll()
		},

	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Marketing Tarifs',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.typelist.marketingtarif {
	display               : grid;
	grid-template-columns : 80px 80px 250px auto 120px 140px 60px 140px;
	grid-gap              : 10px 10px;
}
</style>