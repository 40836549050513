<template>

	<div>

		<DIV class="zagolovok">{{ language.Add }}/{{ language.Edit }} Зарплату</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading && showit" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="space-10"></div>

				<div data-id="feedback">

					<div class="flex-container mb10 box--child">

						<div class="flex-string wp100 mt10 relative material mradio">

							<project v-model="form.project" :id="parseInt(form.project)" @select="selectProject" :required="true" :class="{'required--error':$v.form.project.$error}"/>

						</div>

					</div>

					<div class="flex-container mb5 box--child">

						<city-suggestions v-model="form.city" id="city" :value="form.city" :city="city" @update="cityChanged" ref="city"/>

					</div>

					<div class="flex-container mt10 mb5 box--child">

						<div class="flex-string wp100 relative material cleared">

							<input type="text" id="summa" class="wp100" placeholder=" " autocomplete="off" v-model="form.summa">
							<label for="summa">{{ language.Summa }}</label>
							<div class="clearinputs mr5" @click="clearInput('summa')"><i class="icon-block red fs-14"></i></div>

						</div>

					</div>

					<div class="flex-container mt5 box--child">

						<div class="flex-string wp100 relative material">

							<textarea v-model="form.comment" id="comment" class="wp100" placeholder=" " autocomplete="off" spellcheck="false"></textarea>
							<label for="comment">{{language.Description}}</label>

						</div>

					</div>

				</div>

				<div class="space-50"></div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<button @click="formSubmit" class="button"><i class="icon-ok"></i>{{language.Save}}</button>
				<button @click="closeForm" class="button graybtn"><i class="icon-cancel"></i>{{language.Cancel}}</button>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
import {required, minLength} from 'vuelidate/lib/validators';
import Project from "@/components/elements/project";
import Vue from "vue";
import CitySuggestions from "@/components/elements/city-suggestions.vue";

export default {
	name: "ZarplataForm",
	components: {
		Project,
		CitySuggestions,
	},
	props: {
		id: Number
	},
	data() {
		return {
			//loading: false,
			form: {
				card: this.id,
				action: "edit",
				project: null,
				summa: "",
				comment:"",
				city: "",
			},
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			loading: false,
			message: "",
			city: "",
			submitStatus: "",
			dialogplusloaded: false,
			dialogpluswidth: 800,
			showit: true,
			isAdmin: this.$store.state.user.isAdmin,
			excludedChannel: [],
			shortcuts: Vue.prototype.$shortcuts,
		}
	},
	validations: {
		form: {
			project: {
				required,
				minValue: 1
			},
			city: {
				required,
				minLength: minLength(2)
			},
			summa: {
				required,
				minLength: minLength(2)
			},
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.$v.form.$touch()
		this.loadCard()
	},
	mounted() {
		//console.log(this.id)
		//this.loadCard()
	},
	watch: {
	},
	methods: {
		formSubmit() {

			this.$v.form.$touch()

			if (this.$v.form.$error) {

				this.$toast.error({
					title: 'Error',
					message: this.language.msg.EmptyRequiredFields,
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

				return

			} else {

				// do your submit logic here
				this.submitStatus = 'PENDING'

				// отправка формы здесь
				let param = {};

				param['action'] = 'edit'
				param.id = this.card

				for (let key in this.form) {
					if (this.form[key]) {
						param[key] = this.form[key]
					}
				}

				this.loading = true

				axios.post(this.apiBaseURL + '/api/zarplata/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.closeForm()
							this.$emit("update")

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.loading = false

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

				//console.log(this.submitStatus)

			}

		},
		loadCard() {

			this.loading = true

			//console.log(this.card)

			if (this.card > 0) {

				let param = {};

				param.id = this.id
				param.action = 'info'

				axios.post(this.apiBaseURL + '/api/zarplata/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							let mrk = data.data.result

							this.form = {
								action: "edit",
								summa: mrk.summa,
								project: parseInt(mrk.project),
								city: mrk.city,
								comment: mrk.comment,
							}

							this.$emit("loaded")

							this.loading = false

						},
					)
					.catch(error => {
						console.log(error)
					})

			} else {

				this.form = {
					card: this.id,
					action: "edit",
					project: null,
					summa: "",
					city: "",
					comment:""
				}

				this.loading = false

				this.$emit("loaded")

			}

		},
		closeForm() {
			this.$emit("close")
		},
		clearInput(element) {
			this.form[element] = ''
		},

		selectProject(payload) {
			this.form.project = payload
		},
		tarifChanged(payload) {
			if (payload !== undefined) {
				this.form.tarif = payload
			}
		},
		cityChanged(payload) {

			if (payload !== undefined) {

				this.form.city = payload

			}

		},

	}
}
</script>

<style>



</style>