<template>

	<div>

		<div class="closer" :class="{'hidden':hidebuttons}" title="Закрыть" @click="closeCard"><i class="icon-cancel-circled"></i></div>

		<div v-if="loading" class="content-loading"></div>

		<div v-if="!loading" class="ui-card" :class="{'full':hidebuttons}" :data-address="address.address.id" v-click-outside="closeCard">

			<div class="crd--title">Карточка Адреса</div>

			<div class="crd--body">

				<div class="bgwhite flex-vertical p0 border--bottom box--child">

					<div class="flex-container p5 relative no-border">

						<div class="flex-string wp100 gray2">{{language.Archive}}</div>
						<div class="flex-string wp100">
							<div class="red" v-if="address.address.active">Да</div>
							<div class="green" v-if="!address.address.active">Нет</div>
						</div>

						<div v-if="address.address.active" class="pull-aright"><i class="icon-archive fs-40 red"></i></div>
						<div v-if="!address.address.active" class="pull-aright"><i class="icon-tools fs-40 green"></i></div>

						<div class="pull-aright" style="top:25px">
							<span v-if="address.vacancy.urgency" class="icon-fire fs-40"></span>
						</div>

					</div>

					<div class="flex-content p5">

						<div class="flex-string wp100 gray fs-07">{{ language.Project }}</div>
						<div class="flex-string wp95">
							<b class="Bold">{{ address.address.projectName }}</b>
						</div>

					</div>

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">ID</div>
						<div class="flex-string wp100">
							<b class="Bold">{{ address.address.id }}</b>
						</div>

					</div>

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">{{language.Code}}</div>
						<div class="flex-string wp100">
							<b class="Bold">{{ address.address.code }}</b>
						</div>

					</div>

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">{{ language.Title }}</div>
						<div class="flex-string wp100">
							<b class="Bold">{{ address.address.title }}</b>
						</div>

						<div v-if="editable && edit && !hidebuttons" class="pull-aright fs-12">
							<div class="inline hand roundicon" title="Редактировать" @click="editAddress">
								<i class="icon-pencil blue"></i>
							</div>
						</div>

					</div>

					<div class="flex-container p5">

						<div class="flex-string wp100 gray2">{{ language.City }}</div>
						<div class="flex-string wp100">
							<b class="Bold">{{ address.address.city }}</b>
						</div>

					</div>

					<div class="flex-container p5" v-if="address.address.metro">

						<div class="flex-string wp100 gray2">{{ language.Address.Metro }}</div>
						<div class="flex-string wp100">
							<b class="Bold">{{ address.address.metro }}</b>
						</div>

					</div>

					<div class="flex-container p5">

						<div class="flex-string wp100 gray2">{{ language.Address.Address }}</div>
						<div class="flex-string wp100">
							<b class="Bold" v-if="address.address.address">{{ address.address.address }}</b>
							<b class="Bold gray" v-if="!address.address.address">Не указан</b>
						</div>

					</div>

					<div class="flex-container p5">

						<div class="flex-string wp100 gray2">Дополнительно</div>
						<div class="flex-string wp100">
							<b class="Bold" v-if="address.address.content">{{ address.address.content }}</b>
							<b class="Bold gray" v-if="!address.address.content">Не указан</b>
						</div>

					</div>

					<div class="flex-container p5" v-if="address.address.email">

						<div class="flex-string wp100 gray2">Email</div>
						<div class="flex-string wp100">
							<i class="icon-mail-alt blue"></i><b class="Bold">{{ address.address.email }}</b>
						</div>

					</div>

					<div class="flex-container p5 relative">

						<div class="flex-string wp100 gray2">UID</div>
						<div class="flex-string wp100">
							<b class="Bold">{{ address.address.uid }}</b>
						</div>

					</div>

					<div class="flex-container p5 relative" v-if="address.address.externalid">

						<div class="flex-string wp100 gray2">ExternalID</div>
						<div class="flex-string wp100">
							<b class="Bold">{{ address.address.externalid }}</b>
						</div>

					</div>

				</div>

				<div class="divider mt10 mb15">{{language.Vacancy}}</div>

				<div class="bgwhite p0">

					<div class="ha infodiv p10 box--child" v-if="address.vacancy.length !== 0">

						<div class="flex-content">

							<div class="flex-string wp100 gray fs-07">{{language.Date}}</div>
							<div class="flex-string wp100">
								<b class="Bold blue">{{ address.vacancy.rdate_create }}</b>
							</div>

						</div>
						<div class="flex-vertical wp100 inline border--bottom">

							<div class="flex-content mt10">

								<div class="flex-string wp100 gray fs-07">{{ language.Vacancy }}</div>
								<div class="flex-string wp95">
									<span v-if="address.vacancy.urgency" class="icon-fire fs-10"></span><b class="Bold">{{ address.vacancy.content }}</b>
								</div>

							</div>
							<div class="flex-content mt10" v-if="address.vacancy.meetaddress">

								<div class="flex-string wp100 gray fs-07">{{language.Address.Address}}</div>
								<div class="flex-string wp95">
									<b class="Bold blue">{{ address.vacancy.meetaddress }}</b>
								</div>

							</div>

						</div>

					</div>
					<div class="attention" v-if="address.vacancy.length === 0">Вакансий не найдено</div>

				</div>

				<div class="divider mt10 mb15">{{language.Meets}}</div>

				<div class="bgwhite p0">

					<div class="ha infodiv p10 box--child mb2" v-for="item in address.meets" :data-vacancy="item.id" :key="item.id">

						<div class="pull-right fs-09 gray-blue">FID {{item.fid}}, SID {{item.sid}}</div>

						<div class="flex-content">

							<div class="flex-string wp100 gray fs-07">{{language.Date}}</div>
							<div class="flex-string wp100">
								<b class="Bold">{{ item.date }} <span class="blue">{{ item.time }}</span></b>
							</div>

						</div>
						<div class="flex-content mt10">

							<div class="flex-string wp100 gray fs-07">{{language.Candidate}}</div>
							<div class="flex-string wp100">
								<b class="Bold">{{ item.soiskatel.title }}</b>, {{ item.soiskatel.age }}, {{ item.soiskatel.phone }}
							</div>

						</div>

					</div>

					<div class="attention" v-if="address.meets.length === 0">За период -30 : +10 дней собеседований не назначено</div>

				</div>

				<div class="divider mt20 mb15">{{ language.Schedule }}</div>

				<div class="infodiv dotted weeks">
					<div v-for="(item, index) in address.schedules" :key="index">
						<div class="p5" v-if="item.times !== ''">
							<div class="gray fs-09">{{ sheduleDays[index] }}</div>
							<div class="blue fs-10 Bold">{{ item.times }}</div>
						</div>
						<div class="p5" v-if="item.times === ''">
							<div class="gray fs-09">{{ sheduleDays[index] }}</div>
							<div class="gray-dark fs-10">--&nbsp;</div>
						</div>
					</div>
				</div>

				<div class="space-50"></div>

			</div>

			<div class="crd--footer" :class="{'hidden':hidebuttons}">

				<div class="flex-container box--child wp100">

					<div class="flex-string wp50 pl10">
						<A href="javascript:void(0)" @click="closeCard" class="button dotted graybtn"><i class="icon-cancel-circled"></i>{{language.Close}}</A>
					</div>

					<div v-if="edit" class="flex-string wp50 text-right pr10">
						<A href="javascript:void(0)" @click="editAddress" class="button dotted bluebtn"><i class="icon-pencil-circled"></i>{{language.Edit}}</A>
					</div>

				</div>

			</div>

		</div>

	</div>

</template>

<script>
import axios from "axios";

export default {
	name: "AddressCard",
	props: {
		id: Number,
		nobutton: {
			type: Boolean,
			default: () => false
		},
		editabble: {
			type: Boolean,
			default: () => true
		},
	},
	data() {
		return {
			address: {
				address: {},
				vacancy: {},
				meets: {},
				schedules: {},
			},
			loading: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			edit: this.$store.state.user.settings.edit.address === 'on'
		}
	},
	computed:{
		card(){
			return this.id
		},
		sheduleDays() {
			return this.language.WeekName
		},
		hidebuttons(){
			return this.nobutton !== undefined ? this.nobutton : false
		},
		/*editable(){
			return this.editabble !== undefined ? this.editabble : true
		}*/
	},
	watch:{
		id(){
			this.loadAddress()
		}
	},
	created(){
		this.loadAddress()
	},
	methods: {
		loadAddress() {

			this.loading = true

			let param = {};

			param.id = this.card
			param.action = 'card'

			axios.post(this.apiBaseURL + '/api/points/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
				(data) => {
					this.address = data.data.result
					//this.editabble = data.data.result.editor
					this.loading = false
				},
			).catch(error => {

				console.log(error)

			})

		},
		closeCard(){

			this.$emit("close")

			this.address = {
				address: {},
				vacancy: {},
				meets: {},
				schedules: {},
			}

		},
		editAddress(){
			this.$emit("edit", this.id)
		}
	}
}
</script>

<style scoped>

</style>