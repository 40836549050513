<template>

	<div id="subwindow" :class="{'open':show}" ref="subwindow">

		<div class="body">

			<div class="closer" title="Закрыть"><i class="icon-cancel-circled"></i></div>

			<slot name="swbody">
				<div class="ui-card">
					<div class="crd--title">Карточка</div>
					<div class="crd--body">Содержимое карточки</div>
					<div class="footer">

						<div class="flex-container box--child">

							<div class="flex-string wp30 pl10">
								<A href="javascript:void(0)" class="button dotted graybtn"><i class="icon-cancel-circled"></i>Закрыть</A>
							</div>

							<div class="flex-string wp70 text-right pr10">
								<A href="javascript:void(0)" class="button dotted bluebtn"><i class="icon-pencil-circled"></i>Редактировать</A>
							</div>

						</div>

					</div>
				</div>
			</slot>

		</div>

	</div>

</template>

<script>
//import {EventBus} from "@/Events";

export default {
	name: "SubPanel",
	props:{
		show: Boolean
	},
	data() {
		return {
			loading: false
		}
	},
	methods: {
	}
}
</script>

<style>

/*Новое модальное окно для просмотра инфы: справа во всю высоту экрана*/
#subwindow {
	position           : fixed;
	top                : 0;
	left               : 110vw;
	height             : 100vh;
	width              : 35vw;
	background         : #F5F5F5;
	border-left        : 0 solid #E0E0E0;
	z-index            : 80;
	overflow           : hidden;
	box-sizing         : border-box;
	transition         : left 300ms ease;
	-webkit-transition : left 300ms ease;
	-moz-transition    : left 300ms ease;
	box-shadow         : -2px 0 3px rgba(0, 0, 0, 0.3);
	-webkit-box-shadow : -2px 0 3px rgba(0, 0, 0, 0.3);
	-moz-box-shadow    : -2px 0 3px rgba(0, 0, 0, 0.3);
}

#subwindow .header {
	height      : 60px;
	background  : rgba(207, 216, 220, 1);
	box-sizing  : border-box;
	font-weight : 700;
	line-height : 60px;
	padding     : 0 0 0 10px;
	margin      : 0;
	color       : rgba(120, 144, 156, 1);
}

#subwindow .header li {
	line-height : 40px;
	height      : 40px;
}

#subwindow .body {
	height     : calc(100vh);
	overflow   : hidden;
	box-sizing : border-box;
}

#subwindow .footer {
	height      : 60px;
	background  : var(--gray-lite);
	box-sizing  : border-box;
	font-weight : 700;
	line-height : 60px;
	padding     : 0;
	margin      : 0 auto;
	color       : #222;
}

#subwindow .closer {
	position    : absolute;
	top         : 0;
	right       : 20px;
	height      : 1.5rem;
	width       : 1.5rem;
	font-size   : 1.5rem;
	line-height : 60px;
	z-index     : 201;
	cursor      : pointer;
	color       : rgba(120, 144, 156, 1);
}

#subwindow .closer:hover {
	color       : #FFF;
	text-shadow : 0 0 2px #507192;
}

#subwindow a.period {
	display               : inline-block;
	padding               : 10px 20px;
	margin-left           : 2px;
	background            : #ECF0F1;
	border                : 0 solid #E6E6F0;
	font-size             : 1.1rem;
	font-weight           : 700;
	color                 : #485B60;
	cursor                : pointer;
	border-radius         : 2px;
	-moz-border-radius    : 2px;
	-webkit-border-radius : 2px;
	box-shadow            : 0 1px 1px #999;
	-webkit-box-shadow    : 0 1px 1px #999;
	-moz-box-shadow       : 0 1px 1px #999;
}

#subwindow a.period:hover {
	background : rgba(41, 128, 185, 0.5);
	color      : #FFF;
}

#subwindow a.period.active {
	background : rgba(41, 128, 185, 1.0);
	color      : #FFF;
}

#subwindow thead {
	font-size          : 90%;
	color              : #485B60;
	background         : #ECF0F1;
	border-bottom      : 1px solid #E6E6F0;
	box-shadow         : 0 1px 1px #CCC;
	-webkit-box-shadow : 0 1px 1px #CCC;
	-moz-box-shadow    : 0 1px 1px #CCC;
}

#subwindow thead td {
	border-right : 1px solid #EFEFF5;
}

#subwindow thead td:last-child {
	border-right : 0;
}

#subwindow tbody td {
	border-bottom : 1px solid #E6E6F0;
	border-right  : 1px dotted #F7F7FD;
}

#subwindow tbody td:last-child {
	border-right : 0;
}

@media (min-width : 1024px) {

	#subwindow.open {
		width : 40vw;
		left  : 60vw;
	}

}

@media (min-width : 768px) and (max-width : 1024px) {

	#subwindow.open {
		width : 50vw;
		left  : 50vw;
	}

}

@media (max-width : 767px) {

	#subwindow.open {
		width : 100vw;
		left  : 0;
	}

}

</style>