<template>

	<div>

		<BaseFilter v-model="projectFilter" @list="getList" ref="filters">
			<template v-slot:filters>
				<Filters v-model="pageFilters"></Filters>
			</template>
			<template v-slot:sorts>
				<Sorts v-model="sortFilters"></Sorts>
			</template>
			<template v-slot:title>
				{{language.Tasks}}
			</template>
		</BaseFilter>

		<div class="page--body" @click="closeAllFilters">

			<div v-if="loading" class="loading"></div>

			<h2 class="uppercase">{{language.Tasks}}</h2>

			<div class="wp100 mx-auto mt10 pt10">

				<div class="infodiv mb10">
					<div>Всего <b>{{ total }}</b> записей.</div>
					<div class="mt5" v-if="filters">
						<div v-if="filters.projects && filters.projects.length > 0" class="infodiv inline bgwhite mr5">{{language.Projects}}: <b>{{filters.projects.join(", ")}}</b></div>
						<div v-if="filters.results && filters.results.length > 0" class="infodiv inline bgwhite mr5">{{language.Results}}: <b>{{filters.results.join(", ")}}</b></div>
						<div v-if="filters.users && filters.users.length > 0" class="infodiv inline bgwhite mr5">{{language.Operators}}: <b>{{filters.users.join(", ")}}</b></div>
						<div v-if="filters.period != null" class="infodiv inline bgwhite mr5">{{language.Period}}:<b> {{filters.period}}</b></div>
						<div v-if="filters.phone != null" class="infodiv inline bgwhite mr5">{{language.Phone}}: <b>{{filters.phone}}</b></div>
						<div v-if="filters.finished" class="infodiv inline bgwhite mr5"><b>{{language.Finished}}</b></div>
						<div v-if="filters.active" class="infodiv inline bgwhite mr5"><b>{{language.Active}}</b></div>
						<div v-if="filters.archive" class="infodiv inline bgwhite mr5"><b>{{language.Archive}}</b></div>
					</div>
				</div>

				<div class="flex-content2 typelist meets p5 tbl--header sticked--top">

					<div class="flex-string2">ID</div>
					<div class="flex-string2">{{language.Date}} звонка</div>
					<div class="flex-string2">{{language.Project}}</div>
					<div class="flex-string2">{{language.Candidate}}</div>
					<div class="flex-string2">{{language.Result}}</div>
					<div class="flex-string2">{{language.Operator}}</div>
					<div class="flex-string2 text-center">{{language.Actions}}</div>

				</div>

				<div v-for="(item, index) in items" :key="index" class="flex-content typelist meets ha border-bottom p5" :class="{'graybg-sub':item.archive}">
					<div class="flex-string">{{ item.fid }}</div>
					<div class="flex-string text-right">

						<div v-if="item.totime !== null" class=""><div class="Bold fs-14 blue mb5">{{ $moment(item.totime).format('HH:mm') }}</div>{{ $moment(item.totime).format('DD.MM.YY') }}</div>
						<div v-else class="Bold fs-14 red">Dialer</div>
						<div v-if="item.days < 0 && item.totime !== null" class="fs-07 mt10">через {{Math.abs(item.days)}} дн.</div>
						<div v-else-if="item.days > 0 && item.totime !== null" class="fs-07 mt10">{{Math.abs(item.days)}} дн. назад</div>
						<div v-else-if="item.days === 0 && item.totime !== null" class="fs-07 mt10">сегодня</div>

					</div>
					<div class="flex-string hand" @click="viewFeedbackCard(item.fid)" title="Просмотр отклика">

						<div class="text-wrap Bold fs-12">{{item.projectName}}</div>

					</div>
					<div class="flex-string">

						<div v-if="item.sid > 0" :title="item.candidate">

							<div :class="{'hand':item.sid > 0}" @click="viewCandidateCard(item.sid)">
								<div class="fs-11 Bold text-wrap">{{item.candidate}}</div>
								<div class="fs-09 text-wrap mt5">
									<span v-if="item.phone" class=""> {{item.phone}}</span>
								</div>
							</div>
						</div>

					</div>
					<div class="flex-string">

						<div v-if="item.archive"><i class="icon-archive fs-14 green"></i></div>

						<div class="Bold mb10"><i :class="[item.resultIcon.icon, item.resultIcon.color]"></i> {{item.resultName}}</div>
						<div v-if="item.date_work !== null" class="elipsis fs-09 red">{{$moment(item.date_work).format('HH:mm, DD.MM.YY')}}</div>
						<div v-if="item.datum !== null" class="elipsis fs-09 green">{{$moment(item.datum).format('HH:mm, DD.MM.YY')}}</div>

					</div>
					<div class="flex-string">

						<div v-if="item.user !== null" class="ellipsis Bold" :title="item.user">{{item.user}}</div>
						<div v-else-if="item.user === null">-</div>

					</div>
					<div class="flex-string text-right">

						<a v-if="editable" @click="editFeedbackItem(item.fid)" :title="language.Edit" class="button dotted bluebtn m0 hidden">
							<i class="icon-pencil"></i>
						</a>

					</div>
				</div>

			</div>

			<div class="pagediv">

				<a href="javascript:void(0)" title="Первая" v-if="page > 1" @click="changePage(1)">&nbsp;<i class="icon-angle-double-left"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Предыдущая" v-if="page > 1" @click="changePage(page - 1)">&nbsp;<i class="icon-angle-left"></i>&nbsp;&nbsp;</a>
				{{ page }} из {{ pageall }}
				<a href="javascript:void(0)" title="Следующая" v-if="page >= 1 && page < pageall" @click="changePage(page + 1)">&nbsp;<i class="icon-angle-right"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Последняя" v-if="page < pageall && pageall > 1" @click="changePage(pageall)">&nbsp;<i class="icon-angle-double-right"></i>&nbsp;&nbsp;</a>

			</div>

			<div class="space-100"></div>

			<div class="mt20 p0 bottom-block" data-id="address">

				<a v-if="$store.state.user.settings.export === 'on'" title="Экспорт очереди" class="ml10 blue" @click="taskExport">
					<i class="icon-download-1"></i>
				</a>

			</div>

		</div>

		<SubPanel ref="subpanel" :show="showsubpanel">
			<template v-if="feedbackcard > 0" v-slot:swbody>
				<FeedbackCard :id="feedbackcard" @close="closeCard" @edit="editFeedbackItem" @cedit="editCandidateItem" :nobutton="false" :editabble="false"></FeedbackCard>
			</template>
			<template v-else-if="candidatecard > 0" v-slot:swbody>
				<CandidateCard :id="candidatecard" @close="closeCard" @edit="editCandidateItem" :nobutton="false" :editabble="false"></CandidateCard>
			</template>
		</SubPanel>

		<Dialog ref="dialog" :show="showdialog" :width="dialogwidth" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="idcandidate > 0" v-slot:dlgbody>
				<CandidateForm :id="idcandidate" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="idfeedback > 0 || idfeedback === -1" v-slot:dlgbody>
				<FeedbackForm :id="idfeedback" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
import axios from "axios"

import BaseFilter from "@/components/filters/BaseFilter"
import Filters from "@/pages/task/Filters"
import Sorts from "@/pages/task/Sorts"

import SubPanel from "@/components/modal/SubPanel"
import Dialog from "@/components/modal/Dialog"

import CandidateCard from "@/pages/candidate/CandidateCard"
import CandidateForm from "@/pages/candidate/CandidateForm"

import FeedbackCard from "@/pages/feedback/FeedbackCard"
import FeedbackForm from "@/pages/feedback/FeedbackForm"

export default {
	name: "Tasks",
	components: {
		Dialog,
		BaseFilter,
		Filters,
		Sorts,
		SubPanel,
		FeedbackCard,
		FeedbackForm,
		CandidateCard,
		CandidateForm
	},
	data() {
		return {
			loading: false,
			filters: {},
			items: null,
			page: 1,
			pageall: 1,
			total: 0,
			dialog: {},
			feedbackcard: 0,
			idfeedback: 0,
			candidatecard: 0,
			idcandidate: 0,
			showsubpanel: false,
			showdialog: false,
			dialogloaded: false,
			dialogplusloaded: false,
			dialogwidth: 800,
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			editable: true
		}
	},
	computed: {
		projectFilter:{
			get () {
				return {
					project: Array.isArray(this.$store.state.tasks.filters.project) ? this.$store.state.tasks.filters.project : [],
					order: this.$store.state.tasks.filters.order !== '' ? this.$store.state.tasks.filters.order : 'desc',
				}
			},

			set (value) {
				let newfilter = {
					project: value.project,
					order: value.order,
					tasks: this.$store.state.tasks.filters.tasks,
					sort: this.$store.state.tasks.filters.sort,
				}
				this.$store.commit("tasks/SET_FILTERS", newfilter)
			}
		},

		sortFilters:{
			get () {
				return this.$store.state.tasks.filters.sort !== '' ? this.$store.state.tasks.filters.sort : 'date_work'
			},

			set (value) {
				let newfilter = {
					project: this.$store.state.tasks.filters.project,
					meets: this.$store.state.tasks.filters.feedbacks,
					order: this.$store.state.tasks.filters.order,
					sort: value
				}
				this.$store.commit("tasks/SET_FILTERS", newfilter)
			}
		},

		pageFilters: {
			get () {
				return this.$store.state.tasks.filters.tasks
			},

			set (value) {
				let newfilter = {
					project: this.$store.state.tasks.filters.project,
					tasks: value,
					order: this.$store.state.tasks.filters.order,
					sort: this.$store.state.tasks.filters.sort,
				}
				this.$store.commit("tasks/SET_FILTERS", newfilter)
			}
		},

		tasks: {
			get () {
				return this.$store.state.tasks.filters.tasks
			},

			set (value) {
				this.$store.commit("tasks/SET_FILTERS", value)
			}
		},

		projects: {
			get() {
				return Array.isArray(this.$store.state.tasks.filters.project) ? this.$store.state.tasks.filters.project : []
			},
			set(value) {
				this.$store.commit("tasks/SET_FILTER_PROJECTS", value)
			}
		},

		order: {
			get () {
				return this.$store.state.tasks.filters.order !== '' ? this.$store.state.tasks.filters.order : 'date_work'
			},
			set(value) {
				this.$store.commit("tasks/SET_FILTER_ORDER", value)
			}
		}
	},
	comments: {},

	created() {
		this.getList()
		this.pageTitle = this.language.Tasks
	},

	watch: {
		// при изменениях маршрута запрашиваем данные снова
		routes: 'getList'
	},

	methods: {
		updateFilters() {

			this.getList()

		},
		getList() {

			let param = [];

			param = JSON.parse(JSON.stringify(this.$store.state.tasks.filters))

			let meets = param.tasks

			for (let key in meets) {
				if (meets[key]) {
					param[key] = meets[key]
				}
			}

			param['page'] = this.$store.state.tasks.tasks.page
			param['limit'] = 100
			param['action'] = "list"

			this.loading = true

			axios.post(this.apiBaseURL + '/api/tasks/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {
					this.filters = data.data.result.filters
					this.items = data.data.result.list
					this.page = data.data.result.page
					this.pageall = data.data.result.pageall
					this.total = data.data.result.total
					this.editable = data.data.result.editor
					this.$store.commit('tasks/SET_MEET', data.data.result)

					this.loading = false
				},
				/*(response) => {
					console.log("address:", response)
				}*/
			)

		},
		updateList(){

			if( this.showsubpanel && this.feedbackcard > 0 ){

				let c = this.feedbackcard

				this.closeCard()

				let self = this;
				setTimeout(function() {
					self.viewFeedbackCard(c)
				}, 100);

			}
			else if( this.showsubpanel && this.candidatecard > 0 ){

				let c = this.candidatecard

				this.closeCard()

				let self = this;
				setTimeout(function() {
					self.viewCandidateCard(c)
				}, 100);

			}

			this.getList();

		},
		changePage(page) {
			this.page = page
			this.$store.commit("tasks/CHANGE_PAGE", page)

			this.getList()
		},

		viewCandidateCard(payload) {
			this.feedbackcard = 0
			this.candidatecard = parseInt(payload)
			this.showsubpanel = true
		},
		editCandidateItem(payload) {
			this.idfeedback = 0
			this.idcandidate = parseInt(payload)
			this.showdialog = true
		},

		viewFeedbackCard(payload) {
			this.candidatecard = 0
			this.feedbackcard = parseInt(payload)
			this.showsubpanel = true
		},
		editFeedbackItem(payload) {
			this.idcandidate = 0
			this.idfeedback = parseInt(payload)
			this.showdialog = true
		},

		closeCard() {
			this.candidatecard = 0
			this.feedbackcard = 0
			this.showsubpanel = false
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
		closeDialog() {
			this.idfeedback = 0
			this.idcandidate = 0
			this.showdialog = false
			this.dialogwidth = 800
		},
		closeAllFilters() {
			this.$refs.filters.closeAll()
		},

		taskExport(){

			axios.post(this.apiBaseURL + '/api/tasks/', {'action': 'export'}, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						window.open(this.apiBaseURL + '/' + data.data.result.url)

					},
				)
				.catch(error => {

					this.loading = false

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Tasks',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.typelist.meets {
	display               : grid;
	grid-template-columns : 80px 120px auto 250px 200px 140px 140px;
	grid-gap              : 10px 10px;
}
</style>