<template>

	<div>

		<div v-if="!loading">

			<DIV class="zagolovok">Сверка данных по трудоустроенным</DIV>

			<div class="warning1 hidden">!!! Функция работает в тестовом режиме</div>

			<div class="flex-container mt10 mb10 box--child">

				<div class="flex-string wp100 mt10 relative material mradio">

					<project v-model="form.project" :id="parseInt(form.project)" :required="true" @select="selectProject" :class="{'required--error':$v.form.project.$error}"/>

				</div>

			</div>

			<div class="flex-container mt10 box--child">

				<div class="flex-string material like-input wp100 relative mt10 pt20">

					<div class="tagbox monthcount">
						<div v-for="(n, index) in 6" :key="index" class="tag monthcount" :class="{'current':n === current}" @click="current = n">{{n}} мес.</div>
					</div>
					<div class="label">Выбор периода</div>

				</div>

			</div>

			<div class="flex-container mt10 box--child">

				<div class="flex-string wp100 relative mt10 material like-input pt20" :class="{'dragover':over}" ref="dropzone" @dragover="over = true" @dragleave="over = false" @dragover.prevent @drop.stop.prevent="onDrop">

					<div class="filebox hidden">
						<input type="file" ref="file" class="file required wp100" @change="validatefile">
					</div>

					<a @click="$refs.file.click()" class="button m0 mb5 fs-07" id="selectFile"><i class="icon-plus-circled"></i>Выбрать</a>

					<span class="pl20">или перетащите сюда</span>

					<div v-if="selectedFile" class="infodiv bgwhite1 mt10">
						Выбран файл <b class="blue">{{ selectedFile }}</b>
					</div>

<!--					выбор нескольких файлов-->
					<div v-for="item in selectedFiles" :key="item" class="attention bgwhite mt10">
						{{ item }}
					</div>

					<div class="label">Файл по шаблону</div>

					<div class="flex-string wp100 mt5 fs-07">
						Поддерживаются форматы: *.{{allowed.join(", *.").toUpperCase()}}
					</div>

				</div>

			</div>

			<div class="infodiv">
				Сопоставление происходит по вариантам:
				<ul>
					<li>Номер телефона</li>
					<li>Фамилия кандидата</li>
					<li>Фамилия + Имя кандидата</li>
					<li>Фамилия + Номер телефона</li>
					<li>Имя + Номер телефона</li>
					<li>Фамилия + Имя кандидата + Номер телефона</li>
				</ul>
				Поиск происходит только в записях с откликами за выбранный период, у которых нет отметки о принятии на работу
			</div>

			<div class="button--pane text-right fs-12 Bold">

				<button @click="formSubmit" class="button"><i class="icon-ok"></i>{{language.Do}}</button>
				<button @click="closeForm" class="button graybtn"><i class="icon-cancel"></i>{{language.Cancel}}</button>

			</div>

		</div>

		<div v-if="loading">

			<div class="">Обрабатываю запрос..</div>
			<div class="space-50"></div>
			<div class="loading"></div>

		</div>

	</div>

</template>

<script>
import axios from "axios";
import Project from "@/components/elements/project";
import {required} from "vuelidate/lib/validators";

export default {
	name: "FeedbackCompare",
	components: {
		Project,
	},
	data() {
		return {
			form: {
				action: "compare",
				file: "",
				project: 0
			},
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			loading: false,
			message: "",
			submitStatus: "",
			selectedFile: '',
			selectedFiles: [],
			over: false,
			allowed: ["csv", "xlsx", "xls"],
			current: 3
		}
	},
	validations: {
		form: {
			project: {
				required,
				minValue: 1
			},
		},
	},
	mounted() {
		this.form = {
			project: 0,
			action: "compare",
			file: "",
			silence: false
		}
		this.$emit("loaded")

	},
	watch: {},
	methods: {
		formSubmit() {

			if (this.selectedFile === '') {
				this.$toast.error({
					title: 'Error',
					message: "Не выбран файл",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

			}
			else if (this.form.project === 0) {
				this.$toast.error({
					title: 'Error',
					message: "Не выбран проект",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				})

			}
			else {

				// do your submit logic here
				this.submitStatus = 'PENDING'
				this.form.file = this.$refs.file.files[0];

				// отправка формы здесь
				let formData = new FormData();

				formData.append('file', this.form.file);
				formData.append('action', this.form.action);
				formData.append('token', this.$store.state.token);
				formData.append('project', this.form.project);
				formData.append('period', this.current);

				this.$emit('hide')
				this.loading = true

				axios.post(this.apiBaseURL + '/api/feedback/', formData, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'multipart/form-data',
						'Session': this.$store.state.user.session
					},
					timeout: 60000000,
				})
					.then(
						(data) => {

							this.loading = false

							/*
							let file = data.data.result

							if(file !== 'error') {
								window.open(this.apiBaseURL + file)
							}
							else{
								this.$toast.error({
									title: 'Error',
									message: "Oops! Какая-то ошибка",
									position: "bottom center",
									timeOut: 3000,
									progressBar: true
								})
							}
							*/

							this.$toast.success({
								title: 'Ответ',
								message: data.data.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

							this.closeForm()

						},
					)
					.catch(error => {

						//error => this.status = error.response.data.status;
						console.log(error.response)

						this.loading = false
						this.closeForm()

						this.$toast.error({
							title: 'Error',
							message: error.response.status + ": " + error.response.statusText,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

				//console.log(this.submitStatus)

			}

		},
		closeForm() {
			this.form.file = ''
			this.selectedFile = ''
			this.$emit("close")
		},
		validatefile() {

			let filePath = this.$refs.file.value;
			let fileName = this.$refs.file.files[0].name;
			let extn = filePath.substring(filePath.lastIndexOf('.') + 1).toLowerCase();
			let slctd = [];

			if (this.allowed.includes(extn)) {

				this.selectedFile = fileName

				if (typeof (FileReader) != "undefined") {

					let countFiles = this.$refs.file.files.length;

					//loop for each file selected for uploaded.
					for (let i = 0; i < countFiles; i++) {

						let reader = new FileReader()

						reader.onload = function () {

							slctd.push(fileName)

						}
						reader.readAsDataURL(this.$refs.file.files[i])

					}
					//this.selectedFiles = slctd

				} else {

					this.$toast.error({
						title: "Attention!",
						message: "Упс, ваш браузер совсем не поддерживает технологию предпросмотра FileReader.",
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					});

				}

			} else {

				this.$toast.error({
					title: "Attention!",
					message: "Не допустимый формат файла",
					position: "bottom center",
					timeOut: 3000,
					progressBar: true
				});

			}

		},
		onDrop(event){

			let vm = this

			event.preventDefault();
			event.stopPropagation();

			this.over = false
			this.$refs.file.files = event.dataTransfer.files;

			//console.log(this.$refs.file.files)

			for (let i = 0; i < event.dataTransfer.files.length; i++) {
				let file = event.dataTransfer.files.item(i);
				let reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = function () {
					vm.validatefile();
				};
			}

		},
		selectProject(payload) {
			this.form.project = payload
		},
	}
}
</script>

<style scoped>
.loading{
	z-index: 100;
}
.dragover {
	border: 3px dashed var(--blue) !important;
	background: var(--blueliter);
}
.tagbox{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.tag{
	padding: 5px 15px !important;
	text-align: center;
}
.tag.current{
	font-weight: bold;
	color: var(--white);
	background: var(--blue);
}
</style>