<template>

	<div class="ydropDown wp100" @click="toggle" :class="{'checked':checked, 'like-input':label, 'req':required}" ref="covidSelect" v-click-outside="onHide">

		<span title="Показатель"><i class="icon-article-alt black hidden"></i></span>
		<span class="ydropText">{{ text }}</span>
		<i class="icon-down-open pull-aright arrow"></i>

		<div class="yselectBox mt2" style="max-height: 300px; width:100%" v-if="show">

			<div v-if="label" class="ydropString yRadio" aria-disabled="true">
				<label class="mt5 gray-blue" @click="update([])">
					<input type="radio" v-model="covid" value="0" @change="update([])">
					Необходимо выбрать
				</label>
			</div>
			<div v-for="item in covidList" class="ydropString ellipsis checkbox" :key="item.index" :class="{'bluebg-sub':parseInt(item.index) === covid}">
				<label class="mt5" @click="update(item)">
					<input type="radio" v-model="covid" :value="parseInt(item.index)" @change="update(item)">
					<span>{{ item.title }}</span>
				</label>
			</div>

		</div>
		<div v-if="label" class="label">{{ inputName }}</div>

	</div>

</template>

<script>
export default {
	name: "CovidRadio",
	props: {
		id: Number,
		label: Boolean,
		required: Boolean,
		name: String, // Label
		exclude: Array, // исключаемые
		maxlevel: Number
	},
	data() {
		return {
			guides: this.$store.state.guides,
			covid: 0,
			show: false,
			checked: false,
			text: ""
		}
	},
	computed: {
		inputName() {
			return this.name !== undefined ? this.name : "Covid Status"
		},
		excludeID(){
			return this.exclude !== undefined ? this.exclude : []
		},
		// делаем, для того, чтобы исключить не нужные каналы
		covidList(){

			let vm = this

			return this.guides.covids.filter(function (e) {
				return !vm.excludeID.includes(e.index)
			})

		}
	},
	created() {
		this.covid = this.index
	},
	watch: {
		covid() {
			this.select()
			//console.log("watch: ", this.channel)
		}
	},
	methods: {
		select() {

			if (this.covid > 0) {

				for (let key in this.guides.covids) {

					if (this.guides.covids[key].index === this.covid) {
						//console.log(this.guides.channel[key])
						this.text = this.guides.covids[key].title
						this.checked = true
						break
					}

				}

			}

		},
		update(selected) {

			//if(selected.length > 0) {

				this.text = selected.index > 0 ? selected.title : ''
				this.checked = selected.index > 0
				this.covid = parseInt(selected.index)

			/*}
			else{
				this.covid = ""
			}*/

			this.$emit("select", this.covid)

		},
		toggle() {
			this.show = !this.show
		},
		onShow() {
			this.show = true
		},
		onHide() {
			this.show = false
		}
	}
}
</script>

<style scoped>

</style>