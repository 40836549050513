<template>

	<div>

		<BaseFilter v-model="projectFilter" @list="getList" ref="filters">
			<template v-slot:filters>
				<Filters v-model="pageFilters"></Filters>
			</template>
			<template v-slot:sorts>
				<Sorts v-model="sortFilters"></Sorts>
			</template>
			<template v-slot:title>
				{{language.Needs}}
			</template>
		</BaseFilter>

		<div class="page--body" @click="closeAllFilters">

			<div v-if="loading" class="loading"></div>

			<h2 class="uppercase">{{language.Needs}}</h2>

			<div class="wp100 mx-auto mt10 pt10">

				<div class="infodiv mb10">
					<div>Всего <b>{{ total }}</b> записей.</div>
					<div class="mt5">
						<div v-if="filters.projects && filters.projects.length > 0" class="infodiv inline bgwhite mr5">{{language.Projects}}: <b>{{filters.projects.join(", ")}}</b></div>
						<div v-if="filters.word != null" class="infodiv inline bgwhite mr5">{{language.SearchWord}}: <b>{{filters.word}}</b></div>
						<div v-if="filters.city" class="infodiv inline bgwhite mr5">{{language.City}}: <b>{{filters.city}}</b></div>
						<div v-if="filters.isactive" class="infodiv inline bgwhite mr5"><b>{{language.Active}}</b></div>
						<div v-if="filters.isarchive" class="infodiv inline bgwhite mr5"><b>{{language.Archive}}</b></div>
						<div v-if="filters.urgency" class="infodiv inline bgwhite mr5"><b>{{language.Urgency}}</b></div>
					</div>
				</div>

				<div class="flex-content typelist vacancy p5 tbl--header sticked--top">

					<div class="flex-string">{{language.Project}}</div>
					<div class="flex-string">{{language.Need}}</div>
					<div class="flex-string">{{language.Address.Address}}</div>
					<div class="flex-string">{{language.City}}</div>
					<div class="flex-string text-center">Вкл.</div>
					<div class="flex-string text-center">{{language.Actions}}</div>

				</div>

				<div v-for="item in items" :key="item.id" class="flex-content typelist vacancy ha border-bottom p5" :class="{'disabled':!item.active}">
					<div class="flex-string fs-11 Bold">
						<div>{{ item.projectName }}</div>
						<div v-if="item.lastchange" class="fs-07 gray">{{item.lastchange}}</div>
						<div v-else class="fs-07 gray">{{item.date_create}}</div>
					</div>
					<div class="flex-string hand" @click="viewAddressCard(item.address)">
<!--						<div class="fs-11 flh-12 Bold"><span v-if="item.urgency" class="icon-fire fs-10"></span> <span v-html="item.vacancy"></span></div>-->
						<div class="fs-11 flh-12 Bold tagbox">
							<span class="tag" v-for="vac in item.avacancy" :key="vac">
								<div>{{vac}}</div>
							</span>
						</div>
						<div class="infodiv blue p5 mt5 nowidth inline"><b>{{item.count}}</b> чел.</div>
					</div>
					<div class="flex-string hand" @click="viewAddressCard(item.address)">

						<div class="fs-11 flh-12 Bold text-wrap mb5"><span v-if="item.urgency" class="icon-fire fs-14"></span> {{item.point}}</div>
						<div class="fs-10 Bold broun text-wrap">{{item.addressName}}</div>
						<div v-if="item.metro" class="fs-10 mt5 gray-dark"><img :src="apiBaseURL + '/assets/images/moscow-metro-logo.svg'" height="12pt"> {{item.metro}}</div>
					</div>
					<div class="flex-string">{{ item.city }}</div>
					<div class="flex-string">
						<a v-if="editable" href="javascript:void(0)" @click="toggleActivity(item.id)" :title="language.Toggle" class="button dotted" :class="{'green':item.active}">
							<i class="icon-eye green" v-if="item.active"></i>
							<i class="icon-eye-off red" v-if="item.active == null"></i>
						</a>
					</div>
					<div class="flex-string">

						<a v-if="editable" href="javascript:void(0)" @click="editItem(item.id)" :title="language.Edit" class="button dotted bluebtn m0"><i class="icon-pencil"></i></a>
						<a v-if="editable" href="javascript:void(0)" @click="deleteItem(item.id)" :title="language.Delete" class="button dotted redbtn m0"><i class="icon-cancel-circled"></i></a>

					</div>
				</div>

			</div>

			<div class="pagediv">

				<a href="javascript:void(0)" title="Первая" v-if="page > 1" @click="changePage(1)">&nbsp;<i class="icon-angle-double-left"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Предыдущая" v-if="page > 1" @click="changePage(page - 1)">&nbsp;<i class="icon-angle-left"></i>&nbsp;&nbsp;</a>
				{{ page }} из {{ pageall }}
				<a href="javascript:void(0)" title="Следующая" v-if="page >= 1 && page < pageall" @click="changePage(page + 1)">&nbsp;<i class="icon-angle-right"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Последняя" v-if="page < pageall && pageall > 1" @click="changePage(pageall)">&nbsp;<i class="icon-angle-double-right"></i>&nbsp;&nbsp;</a>

			</div>

			<div class="space-100"></div>

			<div class="mt20 p0 bottom-block">

				<a v-if="editable" @click="editItem(-1)" :title="language.Add">
					<i class="icon-plus"></i>
				</a>

				<a v-if="$store.state.user.settings.import === 'on'" @click="importNeeds" :title="language.Import" class="ml10 blue">
					<i class="icon-upload-1"></i>
				</a>

				<a v-if="$store.state.user.settings.export === 'on'" @click="exportNeeds" title="Получить статистику" class="ml10 red">
					<i class="icon-chart-area"></i>
				</a>

			</div>

		</div>

		<SubPanel ref="subpanel" :show="showsubpanel">
			<template v-if="address > 0" v-slot:swbody>
				<AddressCard :id="address" @close="closeCard" @edit="editAddress"></AddressCard>
			</template>
		</SubPanel>

		<Dialog ref="dialog" :show="showdialog" :width="dialogwidth" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="idaddress > 0 || idaddress === -1" v-slot:dlgbody>
				<AddressForm :id="idaddress" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="idneed > 0 || idneed === -1" v-slot:dlgbody>
				<NeedForm :id="idneed" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="vimport" v-slot:dlgbody>
				<NeedImport @close="closeDialog" @loaded="dialogLoaded" @update="updateList" @aimport="importAddress" @working="loading = true"/>
			</template>
			<template v-else-if="vexport" v-slot:dlgbody>
				<NeedExport @close="closeDialog" @loaded="dialogLoaded" @working="loading = true"/>
			</template>
			<template v-else-if="aimport" v-slot:dlgbody>
				<AddressImport @close="closeDialog" @loaded="dialogLoaded" @update="updateList" @working="loading = true"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
import axios from "axios";

import BaseFilter from "@/components/filters/BaseFilter"
import Filters from "@/pages/need/Filters";
import Sorts from "@/pages/need/Sorts";

import SubPanel from "@/components/modal/SubPanel";
import Dialog from "@/components/modal/Dialog";

import AddressCard from "@/pages/address/AddressCard";
import AddressForm from "@/pages/address/AddressForm";
import AddressImport from "@/pages/address/AddressImport";
import NeedImport from "@/pages/need/NeedImport";
import NeedForm from "@/pages/need/NeedForm";
import NeedExport from "@/pages/need/NeedExport";

export default {
	name: "Need",
	components: {
		NeedExport,
		NeedForm,
		Dialog,
		BaseFilter,
		Filters,
		Sorts,
		SubPanel,
		AddressCard,
		AddressForm,
		AddressImport,
		NeedImport
	},
	data() {
		return {
			loading: false,
			items: null,
			filters: {},
			page: 1,
			pageall: 1,
			total: 0,
			dialog: {},
			address: 0,
			idaddress: 0,
			idneed: 0,
			aimport: false,
			vimport: false,
			vexport: false,
			showsubpanel: false,
			showdialog: false,
			dialogloaded: false,
			dialogwidth: 800,
			editable: this.$store.state.user.settings.edit.vacancy === 'on',
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language
		}
	},
	computed: {
		projectFilter:{
			get () {
				return {
					project: Array.isArray(this.$store.state.needs.filters.project) ? this.$store.state.needs.filters.project : [],
					order: this.$store.state.needs.filters.order !== '' ? this.$store.state.needs.filters.order : 'desc',
				}
			},

			set (value) {
				let newfilter = {
					project: value.project,
					order: value.order,
					needs: this.$store.state.needs.filters.needs,
					sort: this.$store.state.needs.filters.sort,
				}
				this.$store.commit("needs/SET_FILTERS", newfilter)
			}
		},

		sortFilters:{
			get () {
				return this.$store.state.needs.filters.sort !== '' ? this.$store.state.needs.filters.sort : 'title'
			},

			set (value) {
				let newfilter = {
					project: this.$store.state.needs.filters.project,
					needs: this.$store.state.needs.filters.needs,
					order: this.$store.state.needs.filters.order,
					sort: value
				}
				this.$store.commit("needs/SET_FILTERS", newfilter)
			}
		},

		pageFilters: {
			get () {
				return this.$store.state.needs.filters.needs
			},

			set (value) {
				let newfilter = {
					project: this.$store.state.needs.filters.project,
					needs: value,
					order: this.$store.state.needs.filters.order,
					sort: this.$store.state.needs.filters.sort,
				}
				this.$store.commit("needs/SET_FILTERS", newfilter)
			}
		},

		needs: {
			get () {
				return this.$store.state.needs.filters.needs
			},

			set (value) {
				this.$store.commit("needs/SET_FILTERS", value)
			}
		},

		projects: {
			get() {
				return Array.isArray(this.$store.state.needs.filters.project) ? this.$store.state.needs.filters.project : []
			},
			set(value) {
				this.$store.commit("needs/SET_FILTER_PROJECTS", value)
			}
		},

		order: {
			get () {
				return this.$store.state.needs.filters.order !== '' ? this.$store.state.needs.filters.order : 'title'
			},
			set(value) {
				this.$store.commit("needs/SET_FILTER_ORDER", value)
			}
		}
	},
	comments: {},

	created() {
		this.getList()
	},

	watch: {
		// при изменениях маршрута запрашиваем данные снова
		routes: 'getList'
	},

	methods: {
		updateFilters() {
			this.getList()
		},
		getList() {

			let param = [];

			param = JSON.parse(JSON.stringify(this.$store.state.needs.filters))

			let needs = param.needs

			for (let key in needs) {
				if (needs[key]) {
					param[key] = needs[key]
				}
			}

			param['page'] = this.$store.state.needs.need.page
			param['limit'] = 100
			param['action'] = "list"

			this.loading = true

			axios.post(this.apiBaseURL + '/api/needs/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {
					this.filters = data.data.result.filters
					this.items = data.data.result.list
					this.page = data.data.result.page
					this.pageall = data.data.result.pageall
					this.total = data.data.result.total
					//this.editable = data.data.result.editor
					this.$store.commit('needs/SET_NEED', data.data.result)

					this.loading = false
				},
				/*(response) => {
					console.log("address:", response)
				}*/
			)

		},
		updateList(){

			if( this.showsubpanel ){

				let c = this.card

				this.closeCard()

				let self = this;
				setTimeout(function() {
					self.viewCard(c)
				}, 100);

			}

			this.aimport = false

			this.getList();

		},
		changePage(page) {
			this.page = page
			this.$store.commit("needs/CHANGE_PAGE", page)

			this.getList()
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
		viewAddressCard(payload) {
			this.card = 0
			this.idneed = 0
			this.address = parseInt(payload)
			this.showsubpanel = true
		},
		editAddress(payload) {
			this.idcard = 0
			this.idneed = 0
			this.idaddress = parseInt(payload)
			this.showdialog = true
			this.aimport = false
		},
		closeCard() {
			this.card = 0
			this.address = 0
			this.showsubpanel = false
		},
		editItem(payload) {
			this.card = 0
			this.idneed = parseInt(payload)
			this.idaddress = 0
			this.showdialog = true
			this.aimport = false
			this.vimport = false
		},
		deleteItem(payload) {

			this.$swal.fire({
				title: "Are You Sure?",
				text: 'Item will be deleted forever',
				icon: 'question',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: '#3085D6',
				cancelButtonColor: '#D33',
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel"
			}).then((result) => {

				if (result.value) {

					// отправка формы здесь
					let param = {};

					param.id = payload
					param.action = "delete"

					axios.post(this.apiBaseURL + '/api/needs/', param, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							(data) => {

								this.message = data.data.result.result
								this.getList()

								this.$toast.success({
									title: 'Success',
									message: data.data.result.result,
									position: "bottom center",
									timeOut: 3000
								})

							},
						)
						.catch(error => {

							this.$toast.error({
								title: 'Error '+ error.code,
								message: "" + error.text,
								position: "bottom center",
								timeOut: 3000
							})

						})

				}

			});

		},
		importNeeds(){
			this.card = 0
			this.aimport = false
			this.vimport = true
			this.vexport = false
			this.showdialog = true
			this.dialogwidth = 600
		},
		exportNeeds(){
			this.card = 0
			this.aimport = false
			this.vimport = false
			this.vexport = true
			this.showdialog = true
			this.dialogwidth = 600
		},
		importAddress(){
			this.card = 0
			this.aimport = true
			this.vimport = false
			this.vexport = false
			this.showdialog = true
			this.dialogwidth = 600
		},
		closeDialog() {
			//this.idcard = 0
			this.idneed = 0
			//this.address = 0
			this.showdialog = false
			this.dialogwidth = 800
			this.aimport = false
			this.vimport = false
			this.vexport = false
		},
		closeAllFilters() {
			this.$refs.filters.closeAll()
		},
		Notify(){

			this.$toast.success({
				title: 'Success',
				message: "Тут текст сообщения, оно какое-то длинное, многострочное и не понятное",
				position: "bottom center",
				timeOut: 3000,
				progressBar: true
			})

			this.$toast.error({
				title: 'Error',
				message: "Тут текст сообщения, оно какое-то длинное, многострочное и не понятное",
				position: "bottom center",
				timeOut: 3000,
				progressBar: true
			})

			this.$toast.warn({
				title: 'Warn',
				message: "Тут текст сообщения, оно какое-то длинное, многострочное и не понятное",
				position: "bottom center",
				timeOut: 3000,
				progressBar: true
			})

			this.$toast.info({
				title: 'Info',
				message: "Тут текст сообщения, оно какое-то длинное, многострочное и не понятное",
				position: "bottom center",
				timeOut: 3000,
				progressBar: true
			})

		},
		toggleActivity(payload){

			// отправка формы здесь
			let param = {};

			param.id = payload
			param.action = "toggle"

			axios.post(this.apiBaseURL + '/api/needs/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.updateList()

						this.message = data.data.result.result

						this.$toast.success({
							title: 'Success',
							message: data.data.result.message,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

					},
				)
				.catch(error => {
					console.log(error)

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		}
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Needs',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style>

</style>