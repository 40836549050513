<template>
	<div id="app">
		<template v-if="!this.$store.state.user.loggedIn">
			<login @update="loginTrue"></login>
		</template>

		<template v-else>
			<div class="ui-layout" ref="uilayout" :class="{'collapsed': menuCollapsedState}">
				<div class="ui-navigation">
					<div class="header">
						<div id="avatar" class="avatar">

							<div class="avatar--image">
								<div class="avatar--logo"></div>
							</div>

						</div>
					</div>
					<div class="tabs disable--select">

						<UL>
							<li class="ytab">
								<router-link to="/" :title="language.Dashboard">
									<span class="icn"><i class="icon-home orange"></i></span>
									<span class="text">{{language.Dashboard}}</span>
								</router-link>
							</li>
							<li class="ytab">
								<router-link to="/operator" :title="language.OperatorPlace">
									<span class="icn"><i class="icon-user-1 yellow"></i></span>
									<span class="text">{{language.OperatorPlace}}</span>
								</router-link>
							</li>
							<li class="ytab">
								<router-link to="/steps" :title="language.NextStep">
									<span class="icn"><i class="icon-th mint"></i></span>
									<span class="text">{{language.NextStep}}</span>
								</router-link>
							</li>
							<li class="ytab">
								<router-link to="/mcontrol" :title="language.MeetControl">
									<span class="icn"><i class="icon-address-book green-lite"></i></span>
									<span class="text">{{language.MeetControl}}</span>
								</router-link>
							</li>
							<li class="ytab">
								<router-link to="/meets" :title="language.Meets">
									<span class="icn"><i class="icon-users-1 yellow"></i></span>
									<span class="text">{{language.Meets}}</span>
								</router-link>
							</li>
							<li v-if="$store.state.user.settings.guides === 'on'" class="ytab">
								<router-link to="/pages/" active-class="current" :title="language.Guides">
									<span class="icn"><i class="icon-list-nested greens"></i></span>
									<span class="text">{{language.Guides}}</span>
								</router-link>
								<ul class="subtab">
									<span class="subtitle">{{language.Guides}}</span>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/pages/feedback" router-link-active="current">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-inbox mint"></i></span>
											<span class="text">{{language.Feedbacks}}</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/pages/task" router-link-active="current">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-tasks blue"></i></span>
											<span class="text">{{language.Tasks}}</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/pages/address" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-address red"></i></span>
											<span class="text">{{language.Addresses}}</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/pages/candidates" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-user-1 orange"></i></span>
											<span class="text">{{language.Candidates}}</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/pages/need" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-jobsoiskatel green-lite"></i></span>
											<span class="text">{{language.Needs}}</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/pages/meets" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-users-1 yellow"></i></span>
											<span class="text">{{language.Meets}}</span>
										</li>
									</router-link>
<!--									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/pages/mcontrol" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-address-book blue-dark"></i></span>
											<span class="text">{{language.MeetControlDoit}}</span>
										</li>
									</router-link>-->
								</ul>
							</li>
							<li class="ytab">
								<router-link to="/reports/" active-class="current" :title="language.Reports">
									<span class="icn"><i class="icon-chart-area"></i></span>
									<span class="text">Reports</span>
								</router-link>
								<ul class="subtab">
									<span class="subtitle">Reports</span>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/reports/:1" router-link-active="current">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-inbox mint"></i></span>
											<span class="text">Report #1</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/reports/:2" router-link-active="current">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-inbox mint"></i></span>
											<span class="text">Report #2</span>
										</li>
									</router-link>
								</ul>
							</li>
							<li v-if="$store.state.user.settings.marketing === 'on' || $store.state.user.isAdmin" class="ytab">
								<router-link to="/marketing/" active-class="current" :title="language.Marketing.Path">
									<span class="icn"><i class="icon-doc-inv-alt"></i></span>
									<span class="text">{{ language.Marketing.Path }}</span>
								</router-link>
								<ul class="subtab">
									<span class="subtitle">{{ language.Marketing.Path }}</span>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/marketing/campaign" router-link-active="current">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-doc-inv-alt red"></i></span>
											<span class="text">{{ language.Marketing.Campaign }}</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/marketing/empty" router-link-active="current">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-doc-alt orange"></i></span>
											<span class="text">Потребности без объявлений</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/marketing/tarif" router-link-active="current">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-rouble mint"></i></span>
											<span class="text">{{ language.Marketing.Tarifs }}</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/marketing/zarplata" router-link-active="current">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-briefcase-1 blue"></i></span>
											<span class="text">Зарплаты</span>
										</li>
									</router-link>
								</ul>
							</li>
							<li v-if="$store.state.user.isAdmin" class="ytab" :title="language.Settings">
								<router-link to="/settings/" active-class="current">
									<span class="icn"><i class="icon-cog-alt greens"></i></span>
									<span class="text">{{language.Settings}}</span>
								</router-link>
								<ul class="subtab">
									<span class="subtitle">Settings</span>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/projects" router-link-active="current">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-building"></i></span>
											<span class="text">{{language.Projects}}</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/channels" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-download-1"></i></span>
											<span class="text">{{language.Channels}}</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/results" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-list-nested"></i></span>
											<span class="text">Результаты обработки</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/leadtype" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-list-nested"></i></span>
											<span class="text">Типы заявок</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/vacancylist" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-article"></i></span>
											<span class="text">Стандартные вакансии</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/users" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-users-1"></i></span>
											<span class="text">Пользователи</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/language" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" class="hidden" :class="[isActive && 'active']">
											<span><i class="icon-flag"></i></span>
											<span class="text">! Локализации</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/backup" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-download-1"></i></span>
											<span class="text">Резервные копии</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/errors" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-wpforms"></i></span>
											<span class="text">Лог ошибок</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/userlogs" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-article-alt"></i></span>
											<span class="text">Лог сотрудников</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/statistic" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-chart-line"></i></span>
											<span class="text">Управление отчетами</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/cronman" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-laptop"></i></span>
											<span class="text">Планировщик заданий</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/about" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-info-circled"></i></span>
											<span class="text">Сведения о компании</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/server" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-tools"></i></span>
											<span class="text">Общие настройки</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/integration" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-file-code"></i></span>
											<span class="text">Входящие интеграции</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/hrintegration" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-file-code"></i></span>
											<span class="text">Внешние интеграции</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/smstemplates" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-chat-1"></i></span>
											<span class="text">СМС-шлюз и шаблоны</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/smtp" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" :class="[isActive && 'active']">
											<span><i class="icon-mail"></i></span>
											<span class="text">Почтовый сервер</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/mailer" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" class="hidden" :class="[isActive && 'active']">
											<span><i class="icon-mail-alt"></i></span>
											<span class="text">! Почтовые ящики</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/translate" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" class="hidden" :class="[isActive && 'active']">
											<span><i class="icon-globe"></i></span>
											<span class="text">Переводы интерфейса</span>
										</li>
									</router-link>
									<router-link custom v-slot="{ link, route, navigate, isActive }" to="/settings/template" router-link-exact-active="active">
										<li @click="navigate" @keypress.enter="navigate" class="hidden" :class="[isActive && 'active']">
											<span><i class="icon-file-code"></i></span>
											<span class="text">Шаблоны email</span>
										</li>
									</router-link>
								</ul>
							</li>
						</UL>

					</div>
					<div class="footer">

						<A href="javascript:void(0)" @click="loginOut" :title="language.LogOut"><span class="icn"><i class="icon-off red"></i></span><span class="text">{{ language.LogOut }}</span></A>

					</div>
				</div>
				<div class="ui-center">
					<div class="header">

						<div class="toggle--menu" @click="toggleNavBar">
							<i class="icon-menu"></i>
						</div>

						<div class="topfxed-box">

							<div class="serverTime disable--select">
								<div class="serverTimeSub"><i class="icon-clock"></i> <span class="yellow fs-25" v-html="timeInServer"></span></div>
							</div>

							<div class="notify--button pl20" data-id="tab-notify" title="Обновить справочники">
								<div class="inline">

									<A href="javascript:void(0)" @click="loadGuides(true)" class="relative white">
										<i class="icon-arrows-cw fs-14 white"></i>
									</A>

								</div>
							</div>

							<div class="notify--button" data-id="tab-notify" title="Notify">
								<div class="popblock inline" :class="{'open':notifyShow}" v-click-outside="notifyHide">

									<A href="javascript:void(0)" @click="notifyToggle" class="relative">
										<i class="icon-bell-alt"><sup v-if="notify.length > 0" class="bullet redbg-dark">{{ notify.length }}</sup></i>
									</A>

									<div class="popblock-menu right">
										<div class="popblock-items notifys">

											<div class="items--head border-bottom fs-09 blue bgwhite sticked--top">
												<a href="javascript:void(0)" @click="notifyListCard" class="p0"><i class="icon-list-nested blue"></i> {{ language.ViewAll }}</a>
											</div>

											<div v-for="item in notify" :key="item.id" class="p10 border-bottom ha popblock-item notify-item" :class="{'Bold bluebg-sub':item.status}">

												<i class="gray fs-09 pull-right"></i>

												<div class="fs-09 gray">{{ $moment(item.datum).format('DD.MM.YY, H:mm') }}</div>
												<div class="mt5" v-html="item.content"></div>

											</div>
											<div v-if="notify.length === 0" class="gray p10">
												<i class="icon-doc-alt"></i> {{ language.msg.Nothing }}
											</div>

										</div>
									</div>

								</div>
							</div>

							<div class="username--button">
								<div class="Bold"><i class="icon-user-1"></i>{{ currentuser.name }}</div>
							</div>

						</div>

					</div>
					<div class="body p10 pl20" ref="main--body" :class="{'no-overflow':this.$route.path === '/operator'}">
						<router-view @guide="loadGuides"></router-view>
					</div>
				</div>
			</div>
		</template>

		<SubPanel ref="subpanel" :show="showsubpanel">
			<template v-if="notifylist" v-slot:swbody>
				<NotifyList @close="closeCard" @update="notifyUpdate"></NotifyList>
			</template>
		</SubPanel>

	</div>
</template>

<script>

import axios from "axios"
import Login from "@/components/Login"
import NotifyList from "@/components/NotifyList"
import SubPanel from "@/components/modal/SubPanel"
import Vue from "vue";
import moment from "moment-timezone";

//import { EventBus } from "@/Events.js";

moment.tz.setDefault("Europe/Moscow");

Vue.prototype.$shortcuts = [];

export default {
	name: 'App',

	components: {
		NotifyList,
		Login,
		SubPanel
	},

	data() {
		return {
			count: 0,
			seen: true,
			guides: {
				projects: {},
				channels: {},
				results: {},
				work_types: {},
				lead_types: {},
				reasons: {},
				other: {},
				users: {}
			},
			address: {},
			candidates: {},
			dialogdata: {},
			dialogshow: false,
			showsubpanel: false,
			showdialog: false,
			//currentuser: this.$store.state.user,
			notify: {},
			notifyid: 0,
			notifyShow: false,
			notifyCheck: null,
			notifylist: false,
			authCheck: null,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			timeInServer: "00:00"
		}
	},
	created() {

		//this.$store.dispatch("loadGuides").then(()=>{})

		this.$moment.tz.setDefault("Europe/Moscow");

		let vm = this

		//let timeoffset = this.$moment().utcOffset()
		//let brztimeoofset = - (new Date().getTimezoneOffset())
		//let delta = timeoffset - brztimeoofset

		let delta = this.$moment().utcOffset() - ( - (new Date().getTimezoneOffset()) )
		vm.$moment.tz.setDefault("Europe/Moscow");

		Vue.prototype.$shortcuts = [
			{
				text: 'Сегодня',
				onClick() {
					return vm.$moment().add(delta, 'minutes').toDate();
				},
			},
			{
				text: '+10 мин',
				onClick() {
					return vm.$moment().add(delta, 'minutes').add(10, 'minutes').toDate();
				},
			},
			{
				text: '+20 мин',
				onClick() {
					return moment().add(delta, 'minutes').add(20, 'minutes').toDate();
				},
			},
			{
				text: '+30 мин',
				onClick() {
					return moment().add(delta, 'minutes').add(30, 'minutes').toDate();
				},
			},
			{
				text: '+1 час',
				onClick() {
					return moment().add(delta, 'minutes').add(1, 'hours').toDate();
				},
			},
			{
				text: '+2 час',
				onClick() {
					return moment().add(delta, 'minutes').add(2, 'hours').toDate();
				},
			},
			{
				text: 'Завтра',
				onClick() {
					return moment().add(delta, 'minutes').add(1, 'day').toDate();
				},
			},
		];

		if (this.$store.state.user.loggedIn) {
			this.loadGuides()
			//this.$store.dispatch("loadGuides").then(()=>{})
		}

		this.serverTime()

		// получение сообщение от comet-сервера
		this.$options.sockets.onmessage = function(event) {

			let d = JSON.parse(event.data)

			//console.log(d)

			if(d.event === 'notify'){

				this.$toast.success({
					title: this.language.Info,
					message: d.message,
					showHideTransition: 'slide',
					bgColor: 'var(--blue)',
					textColor: '#EEE',
					allowToastClose: false,
					useHtml: true,
					stack: 5,
					textAlign: 'left',
					position: "bottom center",
					timeOut: 600000,
					progressBar: true
				});

			}
			if(d.event === 'update'){

				this.$toast.warn({
					title: this.language.Attention,
					message: d.message,
					showHideTransition: 'slide',
					bgColor: 'var(--blue)',
					textColor: '#EEE',
					warningColor: '#E64A19',
					allowToastClose: false,
					useHtml: true,
					stack: 5,
					textAlign: 'left',
					position: "bottom center",
					timeOut: 600000,
					progressBar: true
				});

			}
			if(d.event === 'guides'){

				this.loadGuides()

			}

		}

	},
	watch: {},
	computed: {
		seenState() {
			return this.$store.getters.getSeen;
		},
		menuCollapsedState() {
			return this.$store.state.menuCollapsed
		},
		currentuser(){
			return this.$store.state.user
		}
	},
	mounted() {

		/*EventBus.$on('click', () => {
			this.$refs.filters.show = false
			this.$refs.nationality.show = false
		});*/

		//console.log("status", this.$store.state.user.loggedIn)

		if (this.$store.state.user.loggedIn) {

			this.notifyCheck = setInterval(this.getNotify, 60000)
			this.authCheck = setInterval(this.checkUserAuthStatus, 60000)

			this.checkUserAuthStatus()
			this.getNotify()

		}
		else{

			//this.$router.push('login')

		}

	},
	methods: {
		checkUserAuthStatus() {

			let param = {}

			param['action'] = 'check'

			axios.post(this.apiBaseURL + '/api/auth/check', param, {
				headers: {
					"Token": this.$store.getters.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						if (data.data.result === 'error') {
							this.loginOut()
							this.$toast.error({
								title: 'Authorize',
								message: "Сессия истекла. Требуется авторизация",
								position: "bottom left",
								timeOut: 100,
								progressBar: true
							})
						}
						/*else{
							this.$toast.info({
								title: 'Authorize',
								message: "Авторизация подтверждена",
								position: "bottom left",
								timeOut: 100,
								progressBar: true
							})
						}*/

					},
				)
				.catch(error => {

					//console.log(error)

					this.loading = false

					this.$toast.error({
						title: 'Authorization Error',
						message: error.response.status + ": " + error.response.statusText,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})

					this.loginOut()

				})

		},
		toggleNavBar() {
			this.$store.commit('SET_MENU_COLLAPSED')
		},
		loginOut() {

			clearInterval(this.notifyCheck)
			clearInterval(this.authCheck)
			this.closeCard()

			this.$store.commit("SET_LOGGED_IN", false)
			this.$store.commit("SET_LOGGED_OUT")
			//this.$router.push({name: 'login'})
			//this.$router.push({name: 'main'})

		},
		loginTrue(payload){

			if(payload){

				clearInterval(this.notifyCheck)
				clearInterval(this.authCheck)

				this.notifyCheck = setInterval(this.getNotify, 60000)
				this.authCheck = setInterval(this.checkUserAuthStatus, 60000)

				this.loadGuides()
			}

		},
		loadGuides(hand) {

			axios.post(this.apiBaseURL + '/api/guides/', {}, {
				headers: {
					"Token": this.$store.getters.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {
					this.guides = data.data.result
					this.$store.commit('SET_GUIDES', data.data.result)
					this.$store.commit('SET_USER_SHORT', data.data.user)

					this.$connect(`wss://hunter.100crm.ru:8090/comet?chat_id=${this.$store.state.user.comet.chat}&user_id=${this.$store.state.user.comet.user}`, {
						reconnection: true,
						reconnectionAttempts: 5,
						reconnectionDelay: 3000,
						format: 'json'
					})

					if( hand === true ){

						this.$toast.success({
							title: 'Success',
							message: 'Справочники обновлены',
							position: "bottom center",
							timeOut: 1000,
							progressBar: true
						})

					}

				},
			)

		},
		getNotify() {

			let param = {}

			param['action'] = "pop"

			axios.post(this.apiBaseURL + '/api/notify/', param, {
				headers: {
					"Token": this.$store.getters.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {
					this.notify = data.data.result.payload.list
				},
			)

		},
		notifyListCard() {
			this.showsubpanel = true
			this.notifylist = true
		},
		notifyToggle() {
			this.notifyShow = !this.notifyShow
		},
		notifyHide() {
			this.notifyShow = false
		},
		notifyMark(payload) {

			let param = {}

			param['action'] = "mark"
			param['id'] = payload.id

			axios.post(this.apiBaseURL + '/api/notify/', param, {
				headers: {
					"Token": this.$store.getters.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				() => {
					//console.log(data)
				},
			)

		},
		notifyUpdate(payload) {

			if( payload ) {

				this.notify = this.notify.filter(function (e) {
					return e.id !== payload.id;
				});

			}
			else{

				this.getNotify()

			}

		},
		closeCard() {

			this.$emit("close")
			this.notifylist = false
			this.showsubpanel = false

		},
		serverTime(){

			let vm = this

			setInterval(function () {
				vm.timeInServer = vm.$moment().format('<b>HH</b>') + '<span class="blink">:</span>' + vm.$moment().format('mm')
			}, 1000)

		}
	},
	beforeDestroy() {
		clearInterval(this.notifyCheck)
		clearInterval(this.authCheck)
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Hunter',
		// all titles will be injected into this template
		titleTemplate: '%s | Mass Recruitment App'
	}
}
</script>

<style>

.no-overflow{
	overflow: hidden !important;
	padding: 0 !important;
}

.serverTime{
	display: inline-block;
	margin-right: 10px;
	position: relative;
}
.serverTimeSub{
	position: absolute;
	top: -15px;
	right: 0;
	display: block;
	width: max-content;
}
.blink {
	animation: blinker 1s linear infinite;
}

.button{
	user-select         : none;
	-moz-user-select    : none;
	-webkit-user-select : none;
}

@keyframes blinker {
	50% {
		opacity: 0;
	}
}

.feedback-list-toggler {
	right : -69px;
}

@media (min-width : 1024px) and (max-width : 1200px) {

	.feedback-list-toggler {
		right : -65px !important;
	}

}
@media (min-width : 768px) and (max-width : 1023px) {

	.feedback-list-toggler {
		right : -55px !important;
	}

}

</style>