<template>

	<div class="flex-string relative material cleared" :class="wclass" v-click-outside="onHide">

		<input type="text" placeholder=" " id="address" v-model="address" class="wp100 pl10" :class="{'required':required}" @focusin="search" @keyup="search" ref="addressinput" autocomplete="off">
		<label for="address">{{language.Address.Address}}</label>
		<div class="clearinputs mr5" @click="address = ''"><i class="icon-block red fs-14"></i></div>
		<div v-if="loading" class="vu-suggestion-loader"></div>

		<ul class="vu-suggestion" v-if="show" ref="suggestionlist">
			<li v-for="item in addresses" :key="item.id" @click="select(item)">
				<div class="Bold fs-12 blue mb5">{{ item.title }}</div>
				<div class="Bold fs-10 mb2">{{ item.name }}</div>
				<div class="fs-10 flh-10">г. {{ item.city }}</div>
				<div class="fs-09 broun mt2">{{ item.project }}</div>
			</li>
		</ul>

	</div>

</template>

<script>

import axios from "axios";
import {debounce} from "lodash";

export default {
	name: "addresinput",
	props: {
		value: String,
		name: String,
		required: Boolean,
		classes: String,
		project: Number,
		city: String,
		min: Number,
		time: Object
	},
	data() {
		return {
			address: '',
			addr: {},
			default: this.name,
			addresses: {},
			show: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			loading: false
		}
	},
	computed:{
		require(){
			return this.required === true ? this.required : false
		},
		wclass(){
			return this.classes !== undefined ? this.classes : 'wp100'
		},
		minChars(){
			return this.min !== undefined ? this.min : 0
		},
		/*address:{
			get(){
				//console.log(this.value)
				return this.value
			},
			set(payload){
				console.log(payload)
				//this.$emit("update", payload)
			}
		}*/
	},
	created() {
		this.address = this.value
	},
	watch:{
		/*address(payload){
			//console.log(payload)
			//this.address = this.value
		},*/
		time(){
			this.address = this.value
		}
	},
	mounted() {

	},
	methods: {
		search: debounce(function(){

			if( this.address.length > this.minChars || this.minChars === 0 ) {

				this.loading = true

				let param = {};

				param.action = 'search'
				param.keyword = this.address
				param.project = this.project
				param.city = this.city
				param.limit = 20

				axios.post(this.apiBaseURL + '/api/points/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.addresses = data.data.result

							this.loading = false

							if (!this.show) {
								this.onShow()
							}

						},
					)
					.catch(error => {
						console.log(error)
					})

			}

		}, 500),
		select(payload){
			this.address = payload.name
			this.idaddress = payload.id
			this.default = payload.name
			this.addr = payload

			this.onHide()
			this.update()
		},
		update() {
			//console.log("new:", this.addr)
			this.$emit("update", this.addr)
		},
		clean(){
			this.address = ''
			this.idaddress = ''
			this.addr = {}
			this.update()
		},
		onShow(){
			this.show = true
		},
		onHide(){
			this.show = false
			//this.update()
		},
	}
}
</script>

<style scoped>
ul.vu-suggestion {
	max-height: 200px;
}
</style>