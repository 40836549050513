<template>

	<div>

		<DIV class="zagolovok">Add/Edit Cron Task</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div v-if="card === 0">

					<div class="divider mt20 mb20">Стандартные задания</div>

					<div class="flex-container mt10 mb10 box--child">

						<div class="flex-string wp100 relative material mselect" :class="{'checked':script !== ''}">

							<span class="label">Задание</span>
							<span class="select">
								<select class="wp100" v-model="script">
									<option v-for="(item, index) in scripts" :key="index" :value="item.name" @click="setScript(item)">{{ item.name }}</option>
								</select>
							</span>

						</div>

					</div>

				</div>

				<div class="divider mt20 mb20">Установка задания</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material like-input pt20">

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input v-model="form.active" type="radio" :value="true">
								<span class="custom-radio"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold blue">Активен</span>
							</label>
						</div>

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input v-model="form.active" type="radio" :value="false">
								<span class="custom-radio alert"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold red">Не активен</span>
							</label>
						</div>

						<div class="label">Активен</div>

					</div>

				</div>

				<div class="flex-container mt5 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.name" id="name" class="wp100 required" placeholder=" ">
						<label for="name">Название</label>

					</div>

				</div>

				<div class="flex-container mt5 box--child">

					<div class="flex-string wp100 relative material">

						<Datalist
							v-model="form.bin"
							:value="form.bin"
							:name="'Исполняемая программа'"
							:items="bins"
							:required="true"
							@update="binChanged"
						/>

					</div>

				</div>

				<div class="flex-container mt5 box--child">

					<div class="flex-string wp100 relative material">

						<textarea v-model="form.script" id="script" class="wp100 required" placeholder=" "></textarea>
						<label for="script">Исполняемая команда</label>

					</div>

				</div>

				<div class="divider mt20 mb20">Периодичность</div>

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material mselect" :class="{'checked':form.parent !== ''}">

						<span class="label">Выполнять</span>
						<span class="select">
							<select v-model="form.parent" id="parent" class="wp100">
								<option v-for="item in periods" :key="item.name" :value="item.name" @click="periodChanged(item)">{{ item.value }}</option>
							</select>
						</span>

					</div>

				</div>

				<div class="detales box--child">

					<div v-if="fields.includes('i')" class="detale material relative mb10" data-period="minute">

						<input type="number" v-model="form.period.i" id="period.i" class="wp100" step="5" max="59" min="0" placeholder=" ">
						<label for="period.i">Минуты</label>

					</div>
					<div v-if="fields.includes('h')" class="detale material relative mb10" data-period="hour">

						<input type="number" v-model="form.period.h" id="period.h" class="wp100" step="1" max="23" min="0" placeholder=" ">
						<label for="period.h">Часы</label>

					</div>
					<div v-if="fields.includes('d')" class="detale material relative mb10" data-period="day">

						<input type="text" v-model="form.period.d" id="period.d" class="wp100" placeholder=" ">
						<label for="period.d">Число</label>

					</div>
					<div v-if="fields.includes('w')" class="detale material like-input relative mb10" data-period="week">

						<div class="weeks p10">
							<div class="checkbox">
								<label class="noBold">
									<input v-model="form.period.w" type="checkbox" :value="'monday'">
									<span class="custom-checkbox mt10"><i class="icon-ok"></i></span>
									Пн
								</label>
							</div>
							<div class="checkbox">
								<label class="noBold">
									<input v-model="form.period.w" type="checkbox" :value="'tuesday'">
									<span class="custom-checkbox mt10"><i class="icon-ok"></i></span>
									Вт
								</label>
							</div>
							<div class="checkbox">
								<label class="noBold">
									<input v-model="form.period.w" type="checkbox" :value="'wednesday'">
									<span class="custom-checkbox mt10"><i class="icon-ok"></i></span>
									Ср
								</label>
							</div>
							<div class="checkbox">
								<label class="noBold">
									<input v-model="form.period.w" type="checkbox" :value="'thursday'">
									<span class="custom-checkbox mt10"><i class="icon-ok"></i></span>
									Чт
								</label>
							</div>
							<div class="checkbox">
								<label class="noBold">
									<input v-model="form.period.w" type="checkbox" :value="'friday'">
									<span class="custom-checkbox mt10"><i class="icon-ok"></i></span>
									Пт
								</label>
							</div>
							<div class="checkbox">
								<label class="noBold">
									<input v-model="form.period.w" type="checkbox" :value="'saturday'">
									<span class="custom-checkbox mt10"><i class="icon-ok"></i></span>
									Сб
								</label>
							</div>
							<div class="checkbox">
								<label class="noBold">
									<input v-model="form.period.w" type="checkbox" :value="'sunday'">
									<span class="custom-checkbox mt10"><i class="icon-ok"></i></span>
									Вс
								</label>
							</div>
						</div>
						<div class="label">День недели</div>

					</div>
					<div v-if="fields.includes('m')" class="detale material relative mb10" data-period="month">

						<input type="number" v-model="form.period.m" id="period.m" class="wp100" step="1" max="12" min="1" placeholder=" ">
						<label for="period.m">Месяц</label>

					</div>
					<div v-if="fields.includes('cmd')" class="detale material relative mb10" data-period="expert">

						<input type="text" v-model="form.period.cmd" id="period.cmd" class="wp100" placeholder=" ">
						<label for="period.cmd">Строка периодичности</label>

					</div>

				</div>

				<div class="space-50"></div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<A href="javascript:void(0)" @click="formSubmit" class="button m0">Save</A>
				<A href="javascript:void(0)" @click="closeForm" class="button graybtn">Cancel</A>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
import {required} from "vuelidate/lib/validators";
import Datalist from "@/components/elements/datalist";

export default {
	name: "CronmanForm",
	props: {
		id: Number,
		// передается только для того, чтобы vue обновлял с сервера
		time: Object
	},
	components: {
		Datalist
	},
	validations: {
		form: {
			name: {
				required
			},
		}
	},
	data() {
		return {
			form: {
				id: this.card,
				name: "",
				parent: "everyminutes",
				period: {
					i: null,
					h: null,
					d: null,
					w: [],
					m: null,
					cmd: null
				},
				task: "",
				bin: "",
				script: "",
				args: "",
				active: true
			},
			script: '',
			scripts: {},
			fields: ['i'],
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false,
			message: {},
			bins: [
				'php',
				'/usr/bin/wget',
				'/usr/bin/curl',
				'/opt/php72/bin/php',
				'/opt/php72/sbin/php-fpm'
			],
			periods: [
				{
					name: "everyminutes",
					value: "Каждую минуту",
					fields: ["i"]
				},
				{
					name: "everyhour",
					value: "Каждый час",
					fields: ["h"]
				},
				{
					name: "everyday",
					value: "Ежедневно",
					fields: ["i","h"]
				},
				{
					name: "everyweek",
					value: "Еженедельно",
					fields: ["i","h","w"]
				},
				{
					name: "everymonth",
					value: "Ежемесячно",
					fields: ["i","h","d"]
				},
				{
					name: "everyyear",
					value: "Ежегодно",
					fields: ["i","h","d","m"]
				},
				{
					name: "expert",
					value: "Экспертный режим",
					fields: ["cmd"]
				},
				{
					name: "once",
					value: "Разово",
					fields: []
				}
			]
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.loadCard()
	},
	watch: {
		time() {
			this.loadCard()
		}
	},
	methods: {
		loadCard() {

			this.loading = true

			if( this.card === 0) {

				this.loadScripts()

			}

			if (this.card > 0) {

				let param = {};

				param['id'] = this.card
				param['action'] = 'info'

				axios.post(`${this.apiBaseURL}/api/cronman/`, param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							let rez = data.data.result

							this.form.id = rez.id
							this.form.name = rez.name
							this.form.parent = rez.parent
							this.form.task = rez.task
							this.form.bin = rez.bin
							this.form.script = rez.script
							this.form.args = rez.args
							this.form.active = rez.active === true

							if (rez.period !== null) {

								this.form.period.i = rez.period.i
								this.form.period.h = rez.period.h
								this.form.period.d = rez.period.d
								this.form.period.m = rez.period.m
								this.form.period.w = rez.period.w === null ? [] : rez.period.w
								this.form.period.cmd = rez.period.cmd

							}

							//this.scripts = data.data.result.scripts

							let vm = this
							let pr = {}

							for(let index in this.periods){

								if(vm.periods[index].name === vm.form.parent){
									pr = vm.periods[index]
									break
								}

							}

							this.periodChanged(pr)

							this.$emit("loaded")
							this.loading = false

						},
					)
					.catch(error => {
						console.log(error)
					})

			}

			this.$emit("loaded")
			this.loading = false

		},
		loadScripts(){

			let param = {};
			param['action'] = 'scripts'

			axios.post(this.apiBaseURL + '/api/cronman/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.scripts = data.data.result

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		formSubmit() {

				this.$v.form.$touch()

				if (this.$v.form.$error) {

					this.$toast.error({
						title: 'Error',
						message: "Не заполнены обязательные поля",
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})

					return

				} else {

					let param = {}

					param = this.form
					param['action'] = 'edit'
					param['id'] = this.card

					axios.post(this.apiBaseURL + '/api/cronman/', param, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							(data) => {

								this.message = data.data.result.result
								this.$emit("update")
								this.$emit("guide")
								this.closeForm()

								this.$toast.success({
									title: 'Success',
									message: data.data.result.result,
									position: "bottom center",
									timeOut: 3000,
									progressBar: true
								})

							},
						)
						.catch(error => {
							console.log(error)

							this.$toast.error({
								title: 'Error',
								message: error.text,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})
						})

				}

		},
		closeForm() {
			this.$emit("close")
		},
		setScript(payload) {

			// console.log(payload)

			this.form.name = payload.name
			this.form.task = payload.task
			this.form.bin = payload.bin
			this.form.script = payload.script
			this.form.period = payload.period
			this.form.parent = payload.parent
			this.form.period.w = payload.period.w === null ? [] : payload.period.w

			let vm = this
			let pr = {}

			for(let index in this.periods){

				if(vm.periods[index].name === vm.form.parent){
					pr = vm.periods[index]
					break
				}

			}

			this.periodChanged(pr)

		},
		binChanged(payload) {
			this.form.bin = payload
		},
		periodChanged(payload) {
			//console.log(payload)
			this.fields = payload.fields
		}
	}
}
</script>

<style scoped>

.projectselect {
	display               : grid;
	grid-template-columns : repeat(auto-fill, calc(33.33% - 5px));
	grid-gap              : 5px 5px;
}

.gray .blue {
	color : inherit;
}

</style>