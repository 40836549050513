<template>

	<div>

		<BaseFilter v-model="projectFilter" @list="getList" :showprojects="false" ref="filters">
			<template v-slot:filters>
				<Filters v-model="pageFilters"></Filters>
			</template>
			<template v-slot:sorts>
				<Sorts v-model="sortFilters"></Sorts>
			</template>
			<template v-slot:title>
				{{language.Candidates}}
			</template>
		</BaseFilter>

		<div class="page--body" @click="closeAllFilters">

			<div v-if="loading" class="loading"></div>

			<h2 class="uppercase">{{language.Candidates}}</h2>

			<div class="wp100 mx-auto mt10 pt10">

				<div class="infodiv mb10">
					<div>Всего <b>{{ total }}</b> записей.</div>
					<div class="mt5">
						<div v-if="filters.word != null" class="infodiv inline bgwhite mr5">{{language.SearchWord}}: <b>{{filters.word}}</b></div>
						<div v-if="filters.city" class="infodiv inline bgwhite mr5">{{language.City}}: <b>{{filters.city}}</b></div>
						<div v-if="filters.phone" class="infodiv inline bgwhite mr5">{{language.Phone}}: <b>{{filters.phone}}</b></div>
						<div v-if="filters.email" class="infodiv inline bgwhite mr5">Email: <b>{{filters.email}}</b></div>
						<div v-if="filters.approved" class="infodiv inline bgwhite mr5"><b>{{ language.Approved }}</b></div>
					</div>
				</div>

				<div class="flex-content typelist contacts p5 tbl--header sticked--top">

					<div class="flex-string">ID</div>
					<div class="flex-string">{{language.Date}}</div>
					<div class="flex-string">ФИО</div>
					<div class="flex-string">{{language.City}}</div>
					<div class="flex-string">{{language.Phone}} / Email</div>
					<div class="flex-string">URL</div>
					<div class="flex-string">{{language.Actions}}</div>

				</div>

				<div v-for="item in items" :key="item.id" class="flex-content typelist contacts ha border-bottom p5">
					<div class="flex-string">{{ item.id }}</div>
					<div class="flex-string">{{ item.date_create }}</div>
					<div class="flex-string hand" @click="viewCard(item.id)">
						<div class="Bold fs-11">{{ item.title }}</div>
						<div class="fs-09 green mt5">{{ item.age }}</div>
						<div v-if="item.dateApproved !== null" class="Bold fs-09 success inline width-unset p3 text-wrap mt5">Принят {{item.dateApproved}}</div>
					</div>
					<div class="flex-string">{{ item.city }}</div>
					<div class="flex-string">
						<div class="ellipsis Bold">{{ item.phone }}</div><br>
						<div class="ellipsis fs-09 blue">
							<a :href="['mailto:' + item.email]">{{ item.email }}</a>
						</div>
					</div>
					<div class="flex-string ellipsis">
						<a :href="item.url_1" target="_blank">{{ item.url_1 }}</a>
					</div>
					<div class="flex-string">

						<a v-if="editable" href="javascript:void(0)" @click="editItem(item.id)" :title="language.Edit" class="button dotted bluebtn m0"><i class="icon-pencil"></i></a>
						<a v-if="editable" href="javascript:void(0)" @click="deleteItem(item.id)" :title="language.Delete" class="button dotted redbtn m0"><i class="icon-cancel-circled"></i></a>

					</div>
				</div>

			</div>

			<div class="pagediv">

				<a href="javascript:void(0)" title="Первая" v-if="page > 1" @click="changePage(1)">&nbsp;<i class="icon-angle-double-left"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Предыдущая" v-if="page > 1" @click="changePage(page - 1)">&nbsp;<i class="icon-angle-left"></i>&nbsp;&nbsp;</a>
				{{ page }} из {{ pageall }}
				<a href="javascript:void(0)" title="Следующая" v-if="page >= 1 && page < pageall" @click="changePage(page + 1)">&nbsp;<i class="icon-angle-right"></i>&nbsp;&nbsp;</a>
				<a href="javascript:void(0)" title="Последняя" v-if="page < pageall && pageall > 1" @click="changePage(pageall)">&nbsp;<i class="icon-angle-double-right"></i>&nbsp;&nbsp;</a>

			</div>

			<div class="space-100"></div>

			<div class="mt20 p0 bottom-block" data-id="address">

				<A v-if="editable" href="javascript:void(0)" @click="editItem(-1)" :title="language.Add">
					<i class="icon-plus"></i>
				</A>

				<A v-if="$store.state.user.settings.approved === 'on'" href="javascript:void(0)" @click="importApproved" :title="language.ApprovedImport" class="ml10 blue">
					<i class="icon-download-1"></i>
				</A>

				<A v-if="$store.state.user.settings.export === 'on'" href="javascript:void(0)" :title="language.Export" class="ml10 blue hidden">
					<i class="icon-download-1"></i>
				</A>

			</div>

		</div>

		<SubPanel ref="subpanel" :show="showsubpanel">
			<template v-if="card > 0" v-slot:swbody>
				<CandidateCard :id="card" @close="closeCard" @edit="editItem"></CandidateCard>
			</template>
		</SubPanel>

		<Dialog ref="dialog" :show="showdialog" :loaded="dialogloaded" @close="closeDialog" :width="dialogwidth">
			<template v-if="idcard > 0 || idcard === -1" v-slot:dlgbody>
				<CandidateForm :id="idcard" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"></CandidateForm>
			</template>
			<template v-else-if="importapproved" v-slot:dlgbody>
				<CandidateImportApproved @close="closeDialog" @loaded="dialogLoaded" @update="updateList"></CandidateImportApproved>
			</template>
		</Dialog>

	</div>

</template>

<script>
//import axios from "axios";
import BaseFilter from "@/components/filters/BaseFilter"
import Filters from "@/pages/candidate/Filters";
import Sorts from "@/pages/candidate/Sorts";

import CandidateCard from "@/pages/candidate/CandidateCard";
import CandidateForm from "@/pages/candidate/CandidateForm";
import CandidateImportApproved from "@/pages/candidate/CandidateImportApproved";

import SubPanel from "@/components/modal/SubPanel";
import Dialog from "@/components/modal/Dialog";

import axios from "axios";

export default {
	name: "Candidates",
	components: {
		BaseFilter,
		Filters,
		Sorts,
		Dialog,
		SubPanel,
		CandidateCard,
		CandidateForm,
		CandidateImportApproved
	},
	data() {
		return {
			loading: false,
			filters: {},
			items: null,
			page: 1,
			pageall: 1,
			total: 0,
			dialog: {},
			card: 0,
			showsubpanel: false,
			idcard: 0,
			showdialog: false,
			dialogloaded: false,
			dialogwidth: 700,
			importapproved: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			editable: this.$store.state.user.settings.edit.candidate === 'on'
		}
	},
	computed: {
		projectFilter:{
			get () {
				return {
					project: Array.isArray(this.$store.state.candidates.filters.project) ? this.$store.state.candidates.filters.project : [],
					order: this.$store.state.candidates.filters.order !== '' ? this.$store.state.candidates.filters.order : 'desc',
				}
			},

			set (value) {
				let newfilter = {
					project: value.project,
					order: value.order,
					candidates: this.$store.state.candidates.filters.candidates,
					sort: this.$store.state.candidates.filters.sort,
				}
				this.$store.commit("candidates/SET_FILTERS", newfilter)
			}
		},

		sortFilters:{
			get () {
				return this.$store.state.candidates.filters.sort !== '' ? this.$store.state.candidates.filters.sort : 'title'
			},

			set (value) {
				this.$store.commit("candidates/SET_FILTER_SORT", value)
			}
		},

		pageFilters: {
			get () {
				return this.$store.state.candidates.filters.candidates
			},

			set (value) {
				let newfilter = {
					project: this.$store.state.candidates.filters.project,
					candidates: value,
					order: this.$store.state.candidates.filters.order,
					sort: this.$store.state.candidates.filters.sort,
				}
				this.$store.commit("candidates/SET_FILTERS", newfilter)
			}
		},

		candidates: {
			get () {
				return this.$store.state.candidates.filters.candidates
			},

			set (value) {
				this.$store.commit("candidates/SET_FILTERS", value)
			}
		},

		projects: {
			get() {
				return Array.isArray(this.$store.state.candidates.filters.project) ? this.$store.state.candidates.filters.project : []
			},
			set(value) {
				this.$store.commit("candidates/SET_FILTER_PROJECTS", value)
			}
		},

		order: {
			get () {
				return this.$store.state.candidates.filters.order !== '' ? this.$store.state.candidates.filters.order : 'title'
			},
			set(value) {
				this.$store.commit("candidates/SET_FILTER_ORDER", value)
			}
		}
	},

	created() {
		this.getList()
	},

	watch: {
		// при изменениях маршрута запрашиваем данные снова
		routes: 'getList'
	},

	methods: {
		updateFilters() {

			this.getList()

		},
		getList() {

			let param = [];

			param = JSON.parse(JSON.stringify(this.$store.state.candidates.filters))

			let candidates = param.candidates

			for (let key in candidates) {
				if (candidates[key]) {
					param[key] = candidates[key]
				}
			}

			param['page'] = this.$store.state.candidates.candidate.page
			param['limit'] = 100
			param['action'] = "list"

			this.loading = true

			axios.post(this.apiBaseURL + '/api/candidate/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {
					this.filters = data.data.result.filters
					this.items = data.data.result.list
					this.page = data.data.result.page
					this.pageall = data.data.result.pageall
					this.total = data.data.result.total
					//this.editable = data.data.result.editor
					this.$store.commit('candidates/SET_CANDIDATE', data.data.result)

					this.loading = false
				},
				/*(response) => {
					console.log("address:", response)
				}*/
			)

		},
		updateList(){
			//console.log("showsubpanel", this.showsubpanel)
			if( this.showsubpanel ){

				let c = this.card
				let self = this;

				this.closeCard()

				setTimeout(function() {
					self.viewCard(c)
				}, 100);

			}

			this.getList();
		},
		changePage(page) {
			this.page = page
			this.$store.commit("candidates/CHANGE_PAGE", page)

			this.getList()
		},
		viewCard(payload) {
			this.card = parseInt(payload)
			this.showsubpanel = true
			this.importapproved = false
		},
		closeCard() {
			this.card = 0
			this.showsubpanel = false
			this.importapproved = false
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
		closeDialog() {
			this.idcard = 0
			this.showdialog = false
			this.importapproved = false
			this.dialogwidth = 700
		},
		closeAllFilters() {
			this.$refs.filters.closeAll()
		},
		editItem(payload) {
			//console.log(payload)
			this.idcard = parseInt(payload)
			this.showdialog = true
			this.importapproved = false
			this.dialogwidth = 700
		},
		deleteItem(payload) {

			this.$swal.fire({
				title: "Are You Sure?",
				text: 'Item will be deleted forever',
				icon: 'question',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: '#3085D6',
				cancelButtonColor: '#D33',
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel"
			}).then((result) => {

				if (result.value) {

					// отправка формы здесь
					let param = {};

					param.id = payload
					param.action = "delete"

					axios.post(this.apiBaseURL + '/api/candidate/', param, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							(data) => {

								this.message = data.data.result.result
								this.getList()

								this.$toast.success({
									title: 'Success',
									message: data.data.result.result,
									position: "bottom center",
									timeOut: 3000
								})

							},
						)
						.catch(error => {
							//console.log(error)

							this.$toast.error({
								title: 'Error '+ error.code,
								message: "" + error.text,
								position: "bottom center",
								timeOut: 3000
							})

						})

				}

			});

		},
		importApproved() {
			this.idcard = 0
			this.showdialog = true
			this.importapproved = true
			this.dialogwidth = 500
		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Candidates',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>

</style>