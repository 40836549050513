<template>

	<div class="ydropDown wp100" @click="toggle" :class="{'checked':checked, 'like-input':label, 'req':required}" ref="channelSelect" v-click-outside="onHide">

		<span title="Показатель"><i class="icon-article-alt black hidden"></i></span>
		<span class="ydropText">{{ text }}</span>
		<i class="icon-down-open pull-aright arrow"></i>

		<div class="yselectBox mt2" style="max-height: 300px; width:100%" v-if="show">

			<div v-if="label" class="ydropString yRadio" aria-disabled="true">
				<label class="mt5 gray-blue" @click="update([])">
					<input type="radio" v-model="channel" value="null" @change="update([])">
					Необходимо выбрать
				</label>
			</div>
			<div v-for="item in channelList" class="ydropString ellipsis checkbox" :key="item.id" :class="{'bluebg-sub':parseInt(item.id) === channel}">
				<label class="mt5" @click="update(item)">
					<input type="radio" v-model="channel" :value="parseInt(item.id)" @change="update(item)">
					<span v-if="item.ismain" class="inline" :class="{'pl20':item.level === 1, 'pl40':item.level === 2}"><span class="strelka w5 pl20 mr10"></span></span>
					<span :class="{'gray':item.level === 2}">{{ item.name }}</span>
				</label>
			</div>

		</div>
		<div v-if="label" class="label">{{ inputName }}</div>

	</div>

</template>

<script>
export default {
	name: "ChannelRadio",
	props: {
		id: Number,
		label: Boolean,
		required: Boolean,
		name: String, // Label
		exclude: Array, // исключаемые
		maxlevel: Number
	},
	data() {
		return {
			language: this.$store.state.language,
			guides: this.$store.state.guides,
			channel: 0,
			show: false,
			checked: false,
			text: ""
		}
	},
	computed: {
		inputName() {
			return this.name !== undefined ? this.name : this.language.Channel
		},
		excludeID(){
			return this.exclude !== undefined ? this.exclude : []
		},
		// делаем, для того, чтобы исключить не нужные каналы
		channelList(){

			let vm = this

			return this.guides.channels.filter(function (e) {
				return !vm.excludeID.includes(e.id) && !(vm.maxlevel > 0 && e.level > vm.maxlevel)
			})

		}
	},
	created() {
		this.channel = this.id
	},
	watch: {
		channel() {
			this.select()
			//console.log("watch: ", this.channel)
		}
	},
	methods: {
		select() {

			if (this.channel > 0) {

				for (let key in this.guides.channels) {
					if (this.guides.channels[key].id === this.channel) {
						//console.log(this.guides.channel[key])
						this.text = this.guides.channels[key].name
						this.checked = true
						break
					}
				}
			}

		},
		update(selected) {

			this.text = selected.id > 0 ? selected.name : ''
			this.checked = selected.id > 0
			this.channel = parseInt(selected.id)

			this.$emit("select", this.channel)

		},
		toggle() {
			this.show = !this.show
		},
		onShow() {
			this.show = true
		},
		onHide() {
			this.show = false
		}
	}
}
</script>

<style scoped>

</style>