<template>

	<div>

		<DIV class="zagolovok">Add/Edit Translation</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabse" class="pr5 pl5">

				<div class="flex-container mt10">

					<div class="flex-string wp100 material">

						<div class="relative">

							<codemirror v-model="form.content" :options="cmOptions" />

						</div>

					</div>

				</div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<A href="javascript:void(0)" @click="formSubmit" class="button m0">Save</A>
				<A href="javascript:void(0)" @click="closeForm" class="button graybtn">Cancel</A>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
import 'codemirror/lib/codemirror.css';
import 'codemirror/addon/fold/foldcode.js';
import 'codemirror/addon/fold/brace-fold.js';
import 'codemirror/addon/fold/indent-fold.js';
import 'codemirror/addon/fold/foldgutter.js';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/lint/lint.js';
import 'codemirror/mode/php/php.js';
import 'codemirror/theme/idea.css';

export default {
	name: "TranslateForm",
	components: {},
	props: {
		file: String,
		// передается только для того, чтобы vue обновлял с сервера
		time: Object
	},
	data() {
		return {
			form: {
				file: this.file,
				content: "",
			},
			cmOptions: {
				mode: "php",
				lineNumbers: true,
				lineWrapping: true,
				smartIndent: true,
				tabSize: 4,
				indentWithTabs: true,
				theme: 'idea',
				extraKeys: {"Ctrl-Q": function(cm){ cm.foldCode(cm.getCursor()); }},
				foldGutter: true,
				gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter", "CodeMirror-lint-markers"],
				lint: true
			},
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false,
			message: "",
		}
	},
	computed: {
		card() {
			return this.file !== '' ? this.file : ''
		},
	},
	created() {
		this.loadCard()
	},
	watch: {
		time() {
			this.loadCard()
		}
	},
	methods: {
		loadCard() {

			this.loading = true

			let param = {};

			param['file'] = this.card
			param['action'] = 'info'

			axios.post(this.apiBaseURL + '/api/translate/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						let rez = data.data.result

						this.form.content = rez

					},
				)
				.catch(error => {
					console.log(error)
				})

			this.$emit("loaded")
			this.loading = false

		},
		formSubmit() {

			let param = {}

			param = this.form
			param['action'] = 'edit'
			param['file'] = this.card

			axios.post(this.apiBaseURL + '/api/translate/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.message = data.data.result.result
						this.$emit("update")
						this.closeForm()

						this.$toast.success({
							title: 'Success',
							message: data.data.result.result,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

					},
				)
				.catch(error => {
					console.log(error)

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},
		closeForm() {
			this.$emit("close")
		},
	}
}
</script>

<style>
.CodeMirror{
	font-size: 0.9em;
	line-height: 1.1em;
	height: 60vh !important;
	border: 1px inset var(--gray-dark);
	border-radius: 5px;
}
</style>