<!--Карта для выбора адреса, при редактировании-->
<template>

	<div class="block wp100" style="height: 100%">

		<l-map ref="map"
			v-if="showMap"
			:zoom="zoom"
			:center="center"
			:options="mapOptions"
			style="height: 100%"
			@update:center="centerUpdate"
			@update:zoom="zoomUpdate"
			@fullscreenchange="fullscreenChanged"
		>
			<l-control-fullscreen
				position="topleft"
				:options="fullscreenOptions"
			/>
			<l-tile-layer
				:url="url"
				:attribution="attribution"
			/>
			<l-marker :lat-lng="marker" :draggable="true" @update:latLng="update">
				<l-popup :options="{ permanent: true, interactive: true }">
					<div>
						I am a popup
						<p v-show="showParagraph">
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque
							sed pretium nisl, ut sagittis sapien. Sed vel sollicitudin nisi.
							Donec finibus semper metus id malesuada.
						</p>
					</div>
				</l-popup>
			</l-marker>
		</l-map>

	</div>

</template>

<script>

// https://vue2-leaflet.netlify.app/components/LMarker.html#demo

import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import LControlFullscreen from 'vue2-leaflet-fullscreen';

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
	name: "Map",
	props:{
		latLan: {
			type: Array,
			default: () => [55.755814,37.617635]
		}
	},
	components:{
		LMap,
		LTileLayer,
		LMarker,
		LPopup,
		LControlFullscreen,
	},
	data(){
		return {
			currentLatLan: this.latLan,
			zoom: 18,
			center: latLng(this.latLan[0], this.latLan[1]),
			url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
			attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
			marker: latLng(this.latLan[0], this.latLan[1]),
			currentZoom: 18,
			currentCenter: latLng(this.latLan[0], this.latLan[1]),
			showParagraph: false,
			mapOptions: {
				zoomSnap: 0.5
			},
			showMap: true,
			fullscreenOptions: {
				title: {
					'false': 'Switch to full-screen view',
					'true': 'Exit full-screen mode',
				},
			},
		}
	},
	computed:{
		/*currentLatLan(){

		}*/
	},
	watch:{
		latLan(){
			this.center = latLng(this.latLan[0], this.latLan[1])
			this.marker = latLng(this.latLan[0], this.latLan[1])
			this.currentCenter = latLng(this.latLan[0], this.latLan[1])
		}
	},
	methods: {
		zoomUpdate(zoom) {
			this.currentZoom = zoom;
		},
		centerUpdate(center) {
			this.currentCenter = center;
		},
		update(payload){

			this.currentLatLan = [payload.lat, payload.lng]
			this.$emit('update', payload)

		},
		changedCoords(){

		},
		fullscreenChanged () {
			if (this.$refs.map.mapObject.isFullscreen()) {
				console.log('Entered full-screen mode');
			} else {
				console.log('Left full-screen mode');
			}
		},
	}
}
</script>

<style scoped>

</style>