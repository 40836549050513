<template>

	<div class="gstring pl10 pr10" style="overflow: unset">

		<div class="divider pt10 pb10">{{language.City}}</div>

		<div class="no-border relative cleared">

			<input type="text" id="city" class="wp100 p5 pl10 pr10" :placeholder="language.City" autocomplete="on" v-model="cfilter.city" @keyup="updateFilter" @focusout="updateFilter">
			<div class="clearinputs" @click="clearInput('city')"><i class="icon-block-1 red"></i></div>

		</div>

		<div class="divider pt10 pb10">{{language.Channels}}</div>

		<div class="no-border relative">

			<channel v-model="cfilter.channel" :selected="cfilter.channel" @select="updateFilter"/>

		</div>

		<div class="divider pt10 pb10">{{ language.Other }}</div>

		<div>

			<div class="filterblock no-border checkbox popblock-item" title="Archived" data-id="address">
				<label class="wp100 pl10 mt5 flex-container float">
					<input class="taskss" name="task" type="checkbox" id="task" value="true" v-model="cfilter.task" @change="updateFilter">
					<span class="custom-checkbox"><i class="icon-ok"></i></span>
					<span class="flex-string float pl10 text-wrap">
						<span class="block Bold fs-11 red">!!! Задача Разместить</span>
					</span>
				</label>
			</div>

		</div>

	</div>

</template>

<script>
import Channel from "@/components/elements/channel";
export default {
	name: "FiltersEmpty",
	components: {Channel},
	model:{
		prop: "cfilter",
		event: "change"
	},
	props:{
		cfilter:{
			type: Object,
			default: () => {
				return {
					city: '',
					channel: [],
					task: false
				}
			}
		}
	},
	data(){
		return {
			guides: this.$store.getters.guides,
			language: this.$store.state.language,
		}
	},
	watch: {

	},
	computed:{
	},

	methods:{
		updateFilter(){
			//console.log("filter", this.cfilter)
			this.$emit("change", this.cfilter)
		},

		clearInput(element){
			this.$store.state.marketingempty.filters.marketingempty[element] = '';
		}
	},

}
</script>

<style scoped>

</style>