<template>

	<div>

		<DIV class="zagolovok">View Task log</DIV>

		<div v-if="loading" class="content-loading"></div>

		<div id="formtabs" v-if="!loading" v-cloak>

			<div v-if="log.length > 0">

				<div v-for="item in log" :key="item.id" class="flex-container float border-bottom">
					<div class="flex-string w120 p10">
						<div class="fs-11 Bold">{{item.difftime}} назад</div>
						<div class="fs-09 blue">{{item.datum}}</div>
					</div>
					<div class="flex-string float p10" v-html="item.response"></div>
				</div>

			</div>
			<div v-else class="warning m0 mt5 dotted">Записей нет. Видимо задание еще не выполнялось.</div>


		</div>

		<div class="button--pane text-right fs-12 Bold">

			<A href="javascript:void(0)" @click="closeForm" class="button graybtn">Close</A>

		</div>

	</div>

</template>

<script>
import axios from "axios";

export default {
	name: "CronmanViewForm",
	props: {
		id: Number,
		// передается только для того, чтобы vue обновлял с сервера
		time: Object
	},
	data() {
		return {
			log: {},
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false,
			message: {},
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.loadCard()
	},
	watch: {
		time() {
			this.loadCard()
		}
	},
	methods: {
		loadCard() {

			this.loading = true

			if (this.card > 0) {

				let param = {};

				param['id'] = this.card
				param['action'] = 'log'

				axios.post(this.apiBaseURL + '/api/cronman/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.log = data.data.result

							this.$emit("loaded")
							this.loading = false

						},
					)
					.catch(error => {
						console.log(error)
					})

			}

			this.$emit("loaded")
			this.loading = false

		},
		closeForm() {
			this.$emit("close")
		},
	}
}
</script>

<style scoped>

</style>