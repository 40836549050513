<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				Settings. Backup
			</div>

		</div>

		<div v-if="loading" class="content-loading"></div>

		<div v-if="!loading" class="page--body">

			<div class="infodiv mt10 p10">В этом разделе вы можете управлять резервными копиями БД.</div>

			<DIV class="mt20">

				<div v-if="typeof more === 'object' && more !== null" class="mb10 infodiv">
					Восстановление данных рекомендуется производить через:
					<ul>
						<li>
							Консольную команду (пример):
							<pre>mysql -p -u {{ more.database }} --default-character-set=utf8 &lt; E:\\{{ more.database }}.sql</pre>
						</li>
					</ul>
					<div>
						<div>Расположите в корне диска "{{ more.litera }}:" папку "tools" (<a href="https://download/repo/tools.zip" target="_blank" class="red Bold">скачать</a>) содержащую утилиты, необходимые для создания резервной копии и её упаковки в архив:
						</div>
						<ul>
							<li>Утилиту "<b>\tools\mysqldump.exe</b>" -
								<span v-if="more.mysqldump !== null"><b class="green">Найдено</b> ( <span v-html="more.mysqldump"></span> )</span>
								<span v-else><b class="red">Не найдено</b></span></li>
							<li>Утилиту "<b>\tools\7zip\7za.exe</b>" -
								<span v-if="more.zip !== null"><b class="green">Найдено</b> ( <span v-html="more.zip"></span> )</span><span v-else><b class="red">Не найдено</b></span>
							</li>
						</ul>
					</div>
				</div>
				<div v-else class="attention">
					Убедитесь, что установлены утилиты:
					<ul>
						<li>mysqldump</li>
						<li>zip</li>
					</ul>
				</div>

				<div class="mt10">
					<a href="/vendor/adminer/" class="button greenbtn" target="_blank">Adminer</a>
					<a href="/migrations/" class="button bluebtn" target="_blank">Миграции</a>
				</div>

			</DIV>

			<div v-if="attention">
				<hr>
				<div class="warning">Проверь права на папку /cash/backup/</div>
			</div>

			<div class="mx-auto mt10 pt10">

				<div class="flex-content typelist fourcols p5 tbl--header sticked--top">

					<div class="flex-string">Дата</div>
					<div class="flex-string">Файл</div>
					<div class="flex-string">Размер</div>
					<div class="flex-string text-center">Действия</div>

				</div>

				<div v-for="item in list" :key="item.file" class="flex-content typelist fourcols ha border-bottom p5">

					<div class="flex-string Bold">{{ item.date }}</div>
					<div class="flex-string">{{ item.name }}</div>
					<div class="flex-string text-right">{{ item.size }}</div>
					<div class="flex-string text-center">

						<a href="javascript:void(0)" @click="fileget(item.name)" title="Скачать" class="button small bluebtn dotted m0"><i class="icon-download"></i></a>
						<a href="javascript:void(0)" @click="deleteit(item.name)" title="Удалить" class="button small redbtn dotted m0"><i class="icon-cancel-circled"></i></a>

					</div>

				</div>

			</div>

			<div class="space-100"></div>

			<div class="mt20 p0 bottom-block">

				<A href="javascript:void(0)" @click="create" title="Создать">
					<i class="icon-database"><i class="icon-plus sub"></i></i>
				</A>

				<A href="javascript:void(0)" @click="load" title="Обновить" class="indigo ml5">
					<i class="icon-arrows-ccw"></i>
				</A>

			</div>

			<div v-if="loader" class="loading"></div>

		</div>

	</div>

</template>

<script>
import axios from "axios";

export default {
	name: "BackupSet",
	components: {},
	data() {
		return {
			list: {},
			more: null,
			attention: false,
			loading: false,
			loader: false,
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language
		}
	},
	created() {
		//console.log(this.guides)
	},
	mounted() {
		this.load()
	},
	methods: {
		load() {

			this.loading = true

			let param = {};

			param.action = 'list'

			axios.post(this.apiBaseURL + '/api/backup/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {
						//console.log(data.data)

						this.list = data.data.result.list
						this.more = data.data.result.more
						this.attention = data.data.result.attention

						this.$emit("loaded")

						this.loading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		fileget(file) {

			window.open(this.apiBaseURL + '/api/getfile.php?file=' + file);

		},
		create() {

			this.loader = true

			let param = {}

			param['action'] = "create"

			axios.post(this.apiBaseURL + '/api/backup/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 600000,
			}).then(
				(data) => {

					//let rez = data.data.result

					this.$toast.success({
						title: 'Success',
						message: data.data.result.message,
						position: "bottom center",
						timeOut: 3000
					})

					this.load()
					this.loader = false

				},
			).catch(error => {

				this.$toast.error({
					title: 'Error ' + error.code,
					message: "" + error.text,
					position: "bottom center",
					timeOut: 3000
				})

			})

		},
		deleteit(file) {

			this.loader = true

			let param = {}

			param['action'] = "delete"
			param['file'] = file

			axios.post(this.apiBaseURL + '/api/backup/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			}).then(
				(data) => {

					//let rez = data.data.result

					this.$toast.success({
						title: 'Success',
						message: data.data.result.message,
						position: "bottom center",
						timeOut: 3000
					})

					this.load()
					this.loader = false

				},
			).catch(error => {

				this.$toast.error({
					title: 'Error ' + error.code,
					message: "" + error.text,
					position: "bottom center",
					timeOut: 3000
				})

			})

		}
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Backup / Settings',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.page--title {
	left       : 20px;
	display    : block;
	width      : 200px !important;
	text-align : left;
}

.typelist.fourcols {
	display               : grid;
	grid-template-columns : 250px auto 120px 120px;
	grid-gap              : 10px 10px;
}

.disabled.hidden {
	display : none !important;
}

@media (min-width : 768px) and (max-width : 1024px) {

	.page--title {
		left : 0 !important;
	}

}
</style>