<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				Settings. Lead types
			</div>

		</div>

		<div class="page--body">

			<div class="infodiv mt10">В этом разделе вы можете управлять типами заявок. Это важно для дальнейшей обработки</div>

			<div class="wp100 mx-auto mt10 pt10">

				<div class="flex-content typelist three p5 tbl--header sticked--top">

					<div class="flex-string">Название</div>
					<div class="flex-string text-center">Действия</div>

				</div>

				<div v-for="item in lead_types" :key="item.id" class="flex-content typelist three ha border-bottom p5">

					<div class="flex-string Bold fs-12">
						<span class="flh-14">{{item.title}}</span>
					</div>
					<div class="flex-string text-center">

						<a href="javascript:void(0)" @click="editItem(item.id)" title="Редактировать" class="button dotted bluebtn m0"><i class="icon-pencil"></i></a>
						<a href="javascript:void(0)" @click="deleteItem(item.id)" title="Удалить" class="button dotted redbtn m0"><i class="icon-cancel-circled"></i></a>

					</div>

				</div>

			</div>

			<div class="space-100"></div>

			<div class="mt20 p0 bottom-block">

				<A href="javascript:void(0)" @click="editItem(-1)" title="Добавить">
					<i class="icon-plus"></i>
				</A>

			</div>

		</div>

		<Dialog ref="dialog" :show="showdialog" :width="dialogwidth" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="id > 0 || id === -1" v-slot:dlgbody>
				<LeadtypeForm :id="id" :time="time" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
import Dialog from "@/components/modal/Dialog"
import LeadtypeForm from '@/pages/settings/LeadtypeForm.vue'
import axios from "axios";

export default {
	name: "LeadtypeSet",
	components:{
		Dialog,
		LeadtypeForm,
	},
	data(){
		return {
			showdialog: false,
			dialogloaded: false,
			dialogwidth: 700,
			id: 0,
			ubp: false,
			time: 0,
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language
		}
	},
	computed:{
		lead_types:{
			get(){
				return this.$store.state.guides.lead_types
			},
			set(payload){
				this.$store.commit('SET_GUIDES_LEAD', payload)
				this.sort(payload)
			}
		}
	},
	created() {
		this.$store.dispatch("loadGuides").then(()=>{})
	},
	methods: {
		closeDialog() {
			this.id = 0
			this.showdialog = false
			this.dialogwidth = 800
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
		updateList(){

			// тут надо обновить проекты в this.$store.state.guides.projects
			this.$emit("guide")

		},
		editItem(payload) {
			this.time = this.$moment()
			this.ubp = false
			this.id = parseInt(payload)
			this.dialogwidth = 700
			this.showdialog = true
		},
		deleteItem(payload) {

			this.$swal.fire({
				title: "Are You Sure?",
				text: 'Item will be deleted forever',
				icon: 'question',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: '#3085D6',
				cancelButtonColor: '#D33',
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel"
			}).then((result) => {

				if (result.value) {

					// отправка формы здесь
					let param = {};

					param['id'] = payload
					param['action'] = "delete"

					axios.post(this.apiBaseURL + '/api/leadtype/', param, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							(data) => {

								this.message = data.data.result.result

								this.$emit("guide")
								this.closeDialog()

								this.$toast.success({
									title: 'Success',
									message: data.data.result.result,
									position: "bottom center",
									timeOut: 3000
								})

							},
						)
						.catch(error => {

							this.$toast.error({
								title: 'Error '+ error.code,
								message: "" + error.text,
								position: "bottom center",
								timeOut: 3000
							})

						})

				}

			});

		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Lead types / Settings',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.page--title{
	left: 20px;
	display: block;
	width: 200px !important;
	text-align: left;
}

@media (min-width : 768px) and (max-width : 1024px) {

	.page--title{
		left: 0 !important;
	}

}
</style>