<template>

	<div class="ydropDown like-input wp100" @click="toggle" :class="{'checked':checked, 'req':inputRequired}" ref="projectSelect">

		<span title="Показатель"><i class="icon-article-alt black hidden"></i></span>
		<span class="ydropText">{{text}}</span>
		<i class="icon-down-open pull-aright arrow"></i>

		<div class="yselectBox" style="max-height: 250px; width:100%" v-if="show">
			<div class="ydropString yRadio" aria-disabled="true">
				<label class="wp95 gray-blue" @click="update({})">
					<input type="radio" v-model="project" value="null" @change="update({})">Необходимо выбрать
				</label>
			</div>
			<div v-if="all" class="ydropString yRadio" aria-disabled="true" :class="{'bluebg-sub':project === 0}">
				<label class="wp95 red" @click="update({id:0,name:'Все проекты'})">
					<input type="radio" v-model="project" value="0" @change="update({id:0,name:'Все проекты'})">Все проекты
				</label>
			</div>
			<div v-for="item in projectList" class="ydropString yRadio disable--select" :key="item.id" :class="{'bluebg-sub':parseInt(item.id) === project}">
				<label class="wp95" @click="update(item)">
					<input type="radio" v-model="project" :value="item.id" @change="update(item)">
					{{item.name}}
					<div class="pull-right blue pt2"><img :src="apiBaseURL + item.logofile" height="24px"></div>
				</label>
			</div>
		</div>
		<div class="label">{{ inputName }}</div>

	</div>

</template>

<script>

export default {
	name: "project",
	props: {
		id: Number,
		label: Boolean,
		required: Boolean,
		name: String, // Label
		exclude: Array, // исключаемые
		all: {
			type: Boolean,
			default: () => false
		}
	},
	data(){
		return {
			language: this.$store.state.language,
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			project: null,
			show: false,
			checked: false,
			text: ""
		}
	},
	computed: {
		projectList(){
			let vm = this

			return this.guides.projects.filter(function (e) {
				return !vm.excludeID.includes(e.id) || e.id === vm.id
			})

		},
		inputRequired() {
			return this.required !== undefined ? this.required : true
		},
		inputName() {
			return this.name !== undefined ? this.name : this.language.Project
		},
		/*inputLabel() {
			return this.label !== undefined ? this.label : true
		},*/
		excludeID(){
			return this.exclude !== undefined ? this.exclude : []
		},
	},
	mounted() {
		this.select()
	},
	watch:{
		id(){
			//console.log("id:", this.id)
			this.project = this.id
			this.select()
		},
		project(){
			this.project = this.id
			this.select()
		}
	},
	created() {
		this.project = this.id
	},
	methods:{
		select(){

			//console.log("project:", this.project)

			if(this.project > 0) {
				for (let key in this.projectList) {
					if (this.projectList[key].id === this.project) {
						this.text = this.projectList[key].name
						this.checked = true
						break
					}
				}
			}

		},
		update(selected){

			this.text = selected.id >= 0 ? selected.name : ''
			this.checked = selected.id >= 0
			this.project = selected.id

			//console.log("new prj:", this.project)

			this.$emit("select", this.project)

		},
		toggle(){
			this.show = !this.show
		},
		open(){
			this.show = true
		},
		close(){
			this.show = false
		}
	}
}
</script>

<style scoped>

</style>