<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				Settings. Шлюз и шаблоны
			</div>

		</div>

		<div v-if="loading" class="content-loading"></div>

		<div v-if="!loading" class="page--body">

			<div class="page--body">

				<div class="infodiv mt10">
					В этом разделе вы можете управлять настройками шлюза для отправки СМС, а также шаблонами сообщений
				</div>

				<div class="mt20 p0">

					<A v-if="counts.sms < counts.total" href="javascript:void(0)" @click="editSMSItem(-1)" class="button m0 mr5"><i class="icon-chat-1 mr10"><i class="icon-plus-circled sub"></i></i> СМС шаблон</A>
					<A v-if="counts.email < counts.total" href="javascript:void(0)" @click="editEmailItem(-1)" class="button greenbtn m0 mr5"><i class="icon-mail-alt mr10"><i class="icon-plus-circled sub"></i></i> Email шаблон</A>
					<A href="javascript:void(0)" @click="editGate" class="button orangebtn m0"><i class="icon-database"></i>СМС шлюз</A>

				</div>

				<div class="wp100 mx-auto mt20 pt10">

					<div class="flex-content typelist smstemplate p5 tbl--header sticked--top">

						<div class="flex-string">Дата</div>
						<div class="flex-string">Тип</div>
						<div class="flex-string">Проект</div>
						<div class="flex-string">Содержимое</div>
						<div class="flex-string text-center">Действия</div>

					</div>

					<div v-for="item in list" :key="item.id" class="flex-content typelist smstemplate ha border-bottom p5">

						<div class="flex-string">
							<div class="ellipsis">{{item.date}}</div>
						</div>
						<div class="flex-string">
							<div class="Bold fs-12">{{item.tipName}}</div>
						</div>
						<div class="flex-string" :title="item.projectName">
							<div class="ellipsis1">{{item.projectName}}</div>
						</div>
						<div class="flex-string">
							<div v-if="item.theme !== null">Тема: <b>{{item.theme}}</b></div>
							<div v-else-if="item.tip !== 'sms'">Без темы</div>
							<div v-if="item.text !== null" class="viewdiv fs-09 mt10">{{item.text}}</div>
							<div v-if="item.rezultInfo.type === 'task'" class="ellipsis" :class="item.rezultInfo.color">Этап: <b>{{item.rezultInfo.title}}</b></div>
							<div v-else-if="item.rezultInfo.type === 'na'" class="ellipsis" :class="item.rezultInfo.color"><b>{{item.rezultInfo.title}}</b></div>
							<div v-else class="green Bold">Только согласия</div>
						</div>
						<div class="flex-string text-right">

							<a v-if="item.tip === 'sms'" @click="editSMSItem(item.id)" href="javascript:void(0)" title="Редактировать" class="button dotted bluebtn m0"><i class="icon-pencil"></i></a>
							<a v-if="item.tip === 'email'" @click="cloneEmailItem(item.id)" href="javascript:void(0)" title="Клонировать" class="button dotted orangebtn m0"><i class="icon-docs"></i></a>
							<a v-if="item.tip === 'email'" @click="editEmailItem(item.id)" href="javascript:void(0)" title="Редактировать" class="button dotted bluebtn m0"><i class="icon-pencil"></i></a>
							<a href="javascript:void(0)" @click="deleteItem(item.id)" title="Удалить" class="button dotted redbtn m0"><i class="icon-cancel-circled"></i></a>

						</div>

					</div>

				</div>

				<div class="space-100"></div>

				<div class="mt20 p0 bottom-block">

					<A v-if="counts.sms < counts.total" href="javascript:void(0)" @click="editSMSItem(-1)" class="mr10" title="Добавить SMS">
						<i class="icon-chat-1"><i class="icon-plus-circled sub"></i></i>
					</A>
					<A v-if="counts.email < counts.total" href="javascript:void(0)" @click="editEmailItem(-1)" class="indigo" title="Добавить Email">
						<i class="icon-mail-alt"><i class="icon-plus-circled sub"></i></i>
					</A>

				</div>

				<div v-if="floading" class="loading"></div>

			</div>

		</div>

		<Dialog ref="dialog" :show="showdialog" :width="dialogwidth" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="(id > 0 || id === -1) && !editor" v-slot:dlgbody>
				<SmstemplatesSMSForm :id="id" @close="closeDialog" @loaded="dialogLoaded" @update="load"/>
			</template>
			<template v-else-if="(id > 0 || id === -1) && editor" v-slot:dlgbody>
				<SmstemplatesEmailForm :id="id" :clone="clone" @close="closeDialog" @loaded="dialogLoaded" @update="load"/>
			</template>
			<template v-else-if="showgate" v-slot:dlgbody>
				<SmstemplatesGate :params="gate" @close="closeDialog" @loaded="dialogLoaded" @update="load"/>
			</template>
		</Dialog>

	</div>

</template>

<script>

import axios from "axios"
import Dialog from "@/components/modal/Dialog"
import SmstemplatesSMSForm from '@/pages/settings/SmstemplatesSMSForm.vue'
import SmstemplatesEmailForm from '@/pages/settings/SmstemplatesEmailForm.vue'
import SmstemplatesGate from '@/pages/settings/SmstemplatesGate.vue'

export default {
	name: "SmstemplatesSet",
	components: {
		Dialog,
		SmstemplatesSMSForm,
		SmstemplatesEmailForm,
		SmstemplatesGate
	},
	data() {
		return {
			list: {},
			gate: {},
			counts: {},
			loading: false,
			floading: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			showdialog: false,
			dialogloaded: false,
			dialogwidth: 700,
			id: 0,
			editor: false,
			showgate: false,
			clone: false
		}
	},
	mounted() {
		this.load()
	},
	methods: {
		load() {

			this.loading = true

			let param = {};

			param.action = 'list'

			axios.post(this.apiBaseURL + '/api/smstemplates/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {
						//console.log(data.data)

						this.list = data.data.result.list
						this.gate = data.data.result.gate
						this.counts = data.data.result.counts

						this.$emit("loaded")

						this.loading = false

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		editSMSItem(payload) {
			this.id = parseInt(payload)
			this.editor = false
			this.dialogwidth = 700
			this.showdialog = true
			this.showgate = false
		},
		editEmailItem(payload) {
			this.id = parseInt(payload)
			this.editor = true
			this.dialogwidth = 900
			this.showdialog = true
			this.showgate = false
			this.clone = false
		},
		cloneEmailItem(payload) {
			this.id = parseInt(payload)
			this.editor = true
			this.dialogwidth = 900
			this.showdialog = true
			this.showgate = false
			this.clone = true
		},
		editGate() {
			this.id = 0
			this.editor = false
			this.dialogwidth = 500
			this.showdialog = true
			this.showgate = true
		},
		deleteItem(payload) {

			this.$swal.fire({
				title: "Are You Sure?",
				text: 'Item will be deleted forever',
				icon: 'question',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: '#3085D6',
				cancelButtonColor: '#D33',
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel"
			}).then((result) => {

				if (result.value) {

					// отправка формы здесь
					let param = {};

					param['id'] = payload
					param['action'] = "delete"

					axios.post(this.apiBaseURL + '/api/smstemplates/', param, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							(data) => {

								this.message = data.data.result.result

								this.load()
								this.closeDialog()

								this.$toast.success({
									title: 'Success',
									message: data.data.result.result,
									position: "bottom center",
									timeOut: 3000
								})

							},
						)
						.catch(error => {

							this.$toast.error({
								title: 'Error '+ error.code,
								message: "" + error.text,
								position: "bottom center",
								timeOut: 3000
							})

						})

				}

			});

		},
		closeDialog() {
			this.id = 0
			this.showdialog = false
			this.dialogwidth = 800
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Шлюз и шаблоны уведомлений / Settings',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.page--title {
	left       : 20px;
	display    : block;
	width      : 250px !important;
	text-align : left;
}

@media (min-width : 768px) and (max-width : 1024px) {

	.page--title {
		left : 0 !important;
	}

}
</style>