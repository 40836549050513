<template>

	<div>

		<DIV class="zagolovok">Set Candidate as Worker</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="mt10 p5" data-id="soiskatel">

					<div class="flex-container box--child">

						<div class="flex-string wp100 relative material">

							<div class="fs-07 gray-dark">{{language.Candidate}}</div>
							<div class="Bold fs-12 mt5">{{ params.soiskatel.title }}</div>

						</div>

					</div>
					<div class="flex-container mt5 box--child">

						<div class="flex-string wp100 relative material">

							<div class="fs-07 gray-dark">{{language.Address.Address}}</div>
							<div class="Bold fs-12 flh-11 mt5">{{ params.address.address }}</div>

						</div>

					</div>
					<div class="flex-container mt5 box--child">

						<div class="flex-string wp100 relative material">

							<div class="fs-07 gray-dark">{{language.Project}}</div>
							<div class="Bold fs-12 mt5">{{ params.projectName }}</div>

						</div>

					</div>

				</div>

				<div class="p5">

					<div class="flex-container mt5x--child">

						<div class="flex-string wp100 relative material">

							<div class="fs-07 gray-dark">Месяц приема</div>
							<div class="flex-container mt5">

								<div v-for="(item, index) in $store.state.language.MonthName" :key="item" :class="{'current':index + 1 === form.month}" class="flex-string wp25 mr5 mb5 infodiv bgwhite radio">
									<label class="middle">
										<input type="radio" v-model="form.month" :value="index + 1">
										<span class="custom-radio"><i class="icon-radio-check"></i></span>
										<span class="mt5">{{ item }}</span>
									</label>
								</div>
							</div>

						</div>
						<div class="flex-string wp100 relative material">

							<div class="fs-07 gray-dark">Год приема</div>
							<div class="flex-container mt5">

								<div v-for="item in years" :key="item" class="flex-string wp25 mr5 mb5 infodiv bgwhite radio" :class="{'current':item === form.year}">
									<label class="middle">
										<input type="radio" v-model="form.year" :value="item">
										<span class="custom-radio"><i class="icon-radio-check"></i></span>
										<span class="mt5">{{ item }}</span>
									</label>
								</div>
							</div>

						</div>

					</div>

				</div>

				<div class="space-20"></div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<button @click="formSubmit" class="button"><i class="icon-ok"></i>{{language.Save}}</button>
				<button @click="closeForm" class="button graybtn"><i class="icon-cancel"></i>{{language.Cancel}}</button>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";

export default {
	name: "FeedbackApprove",
	props: {
		params: Object,
	},
	data() {
		return {
			form: {
				id: this.params.id_approved,
				fid: this.params.id,
				action: "approve",
				month: parseInt(this.$moment().format('M')),
				year: parseInt(this.$moment().format('YYYY'))
			},
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language,
			loading: false,
			message: "",
			showit: true,
		}
	},
	mounted() {
		//console.log(this.form)
		this.loading = false
		this.$emit("loaded")
	},
	computed: {
		years() {

			let currentYear = this.$moment().format('YYYY')
			let y = []

			for (let i = 0; i < 3; i++) {

				y.push(currentYear - i)

			}

			return y

		}
	},
	methods: {
		formSubmit() {

			// отправка формы здесь
			let param = {};

			param['action'] = 'approve'
			param.id = this.form.id
			param.fid = this.form.fid
			param.month = this.form.month
			param.year = this.form.year

			this.loading = true

			console.log(param)

			axios.post(this.apiBaseURL + '/api/feedback/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.closeForm()
						this.$emit("update")

						this.$toast.success({
							title: 'Success',
							message: data.data.result.message,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})

					},
				)
				.catch(error => {
					console.log(error)

					this.loading = false

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

			//console.log(this.submitStatus)

		},
		closeForm() {
			this.$emit("close")
		},
	}
}
</script>

<style scoped>
.radio.current {
	background : var(--biruza-lite);
}
</style>