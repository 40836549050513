<template>

	<div class="ydropDown wp100" :class="{'checked':checked, 'like-input':label, 'req':required}" ref="usersSelect" @mouseleave="close">

		<div class="wp100" @click="toggle">
			<span>{{ language.Users }}</span>
			<span class="ydropCount">{{users.length}} {{language.Selected}}</span>
			<span class="icon"><i class="icon-down-open pull-aright"></i></span>
		</div>

		<div class="yselectBox mt2" style="max-height: 30vh; width:100%" v-if="show">

			<div class="right-text">
				<div @click="clearAll" class="yunSelect w0 inline" title="Снять выделение">
					<i class="icon-minus-circled"></i>Сбросить
				</div>
			</div>

			<div v-for="item in guides.users" class="ydropString ellipsis checkbox" :class="{'hidden':!item.active}" :key="item.id">
				<label class="wp100 pl10 mt5 flh-14">
					<input id="users" type="checkbox" v-model="users" :value="item.id" @change="update">
					<span class="custom-checkbox"><i class="icon-ok"></i></span>
					<span class="pl10">{{ item.title }}</span>
				</label>
			</div>

		</div>

	</div>

</template>

<script>

export default {
	name: "users",
	model:{
		prop: "selected",
		event: "select"
	},
	props: {
		id: Number,
		label: Boolean,
		required: Boolean,
		selected: Array,
	},
	data(){
		return {
			guides: this.$store.state.guides,
			language: this.$store.state.language,
			//users: [],
			show: false,
			count: 0,
			checked: false,
		}
	},
	computed:{
		users:{
			get(){
				return JSON.parse(JSON.stringify(this.selected))
			},
			set(value){
				this.$emit("select", value)
			}
		},
	},

	created() {
		this.users = JSON.parse(JSON.stringify(this.selected))
	},

	methods:{
		update(){
			//this.$emit("select", this.users)
		},
		toggle(){
			this.show = !this.show
		},
		open(){
			this.show = true
		},
		close(){
			this.show = false
		},
		clearAll(){
			this.users = []
		}
	}
}
</script>

<style scoped>
	span.custom-checkbox{
		top: initial;
	}
</style>