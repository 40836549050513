<template>

	<div>

		<DIV class="zagolovok">Add/Edit Report</DIV>

		<div v-if="loading" class="content-loading"></div>

		<form v-if="!loading" v-cloak>

			<div id="formtabs" class="pr20 pl20">

				<div class="flex-container mt10 mb10 box--child">

					<div class="flex-string wp100 relative material mselect" :class="{'checked':form.file !== null}">

						<span class="label">Файл отчета</span>
						<span class="select">
							<select id="file" class="wp100 required" v-model="form.file" :class="{'format--error':$v.form.file.$error}">
								<option v-if="files.length === 0" disabled>Все файлы подключены</option>
								<option v-for="(item, index) in files" :key="index" :value="item">{{item}}</option>
							</select>
						</span>

					</div>

				</div>

				<div v-if="files.length === 0" class="attention">Добавлять нечего, т.к. все отчеты подключены!</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material">

						<input type="text" v-model="form.title" id="title" class="required wp100" placeholder=" " autocomplete="off" :class="{'format--error':$v.form.title.$error}">
						<label for="title">Title</label>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative material like-input pt20">

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input v-model="form.active" type="radio" :value="true">
								<span class="custom-radio"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold blue">Активен</span>
							</label>
						</div>

						<div class="radio wp30 inline p5">
							<label class="middle">
								<input v-model="form.active" type="radio" :value="false">
								<span class="custom-radio alert"><i class="icon-radio-check"></i></span>
								<span class="fs-09 Bold red">Не активен</span>
							</label>
						</div>

						<div class="label">Активен</div>

					</div>

				</div>

				<div class="flex-container mb10 mt20 box--child">

					<div class="flex-string wp100 relative mt10 material like-input pt20">

						<div v-for="user in userList" :key="user.id" class="boxx">

							<div class="infodiv p5 wp100 text-wrap fs-09 border-box relative mb2 ha" :title="user.name" :class="{'bgwhite':!form.users.includes(user.id)}">
								<div class="checkbox">
									<label class="middle">
										<input class="taskss" type="checkbox" v-model="form.users" :value="user.id">
										<span class="custom-checkbox" :class="{'secondary':!form.users.includes(user.id)}"><i class="icon-ok"></i></span>
										<div class="ellipsis wp85 h0" :class="{'gray':!form.users.includes(user.id)}">
											<div class="fs-09 Bold">{{user.title}}</div>
											<div class="fs-07 blue">{{user.tip}}</div>
										</div>
									</label>
								</div>
							</div>

						</div>

						<div class="label">Users</div>

					</div>

				</div>

			</div>

			<div class="button--pane text-right fs-12 Bold">

				<A href="javascript:void(0)" @click="formSubmit" class="button m0">Save</A>
				<A href="javascript:void(0)" @click="closeForm" class="button graybtn">Cancel</A>

			</div>

		</form>

	</div>

</template>

<script>
import axios from "axios";
import {required} from "vuelidate/lib/validators";

export default {
	name: "StatisticForm",
	props: {
		id: Number,
		// передается только для того, чтобы vue обновлял с сервера
		time: Object
	},
	data() {
		return {
			form: {
				id: 0,
				file: "",
				title: "",
				active: true,
				users: []
			},
			userList: this.$store.state.guides.users,
			apiBaseURL: this.$store.getters.apiBaseURL,
			loading: false,
			message: {},
			files: [],
			exists: {},
			passtype: "password",
		}
	},
	validations: {
		form: {
			title: {
				required
			},
			file: {
				required
			},
		}
	},
	computed: {
		card() {
			return this.id > 0 ? this.id : 0
		},
	},
	created() {
		this.loadCard()
	},
	watch: {
		time() {
			this.loadCard()
		}
	},
	methods: {
		loadCard() {

			this.loading = true

			let param = {};

			param['id'] = this.card
			param['action'] = 'info'

			axios.post(this.apiBaseURL + '/api/reports/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						let rez = data.data.result

						if( this.card > 0 ) {

							this.form.id = rez.info.id
							this.form.title = rez.info.title
							this.form.file = rez.info.file
							this.form.users = rez.info.users
							this.form.active = rez.info.active === '1'

						}

						this.exists = rez.exists
						this.files = rez.files

					},
				)
				.catch(error => {
					console.log(error)
				})

			this.$emit("loaded")
			this.loading = false

		},
		formSubmit() {

				let param = {}

				param = this.form
				param['action'] = 'edit'

				axios.post(this.apiBaseURL + '/api/reports/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							this.message = data.data.result.result
							this.closeForm()
							this.$emit("update")

							this.$toast.success({
								title: 'Success',
								message: data.data.result.result,
								position: "bottom center",
								timeOut: 3000,
								progressBar: true
							})

						},
					)
					.catch(error => {
						console.log(error)

						this.$toast.error({
							title: 'Error',
							message: error.text,
							position: "bottom center",
							timeOut: 3000,
							progressBar: true
						})
					})

		},
		closeForm() {
			this.$emit("close")
		},
	}
}
</script>

<style scoped>
.boxx{
	float: left;
	width: calc(25% - 2px);
	margin-right: 2px;
	margin-bottom: 2px;
	box-sizing: border-box;
}
</style>