<template>

	<div class="flex-string relative material cleared" :class="wclass" v-click-outside="onHide">

		<input type="text" class="wp100" placeholder=" " :class="{'required':required}" autocomplete="on" v-model="city" @input="search" ref="cityinput">
		<label>{{language.City}}</label>
		<div class="clearinputs mr5" @click="clean"><i class="icon-block red fs-14"></i></div>
		<ul class="vu-suggestion" v-if="show" ref="suggestionlist">
			<li v-for="item in cities" :key="item" @click="select(item)" v-html="$options.filters.highlight(item, city)">{{ item }}</li>
		</ul>

	</div>

</template>

<script>

import axios from "axios";
import {debounce} from "lodash";

export default {
	name: "city-suggestions",
	props: {
		value: String,
		required: Boolean,
		classes: String
	},
	computed:{
		require(){
			return this.required === true ? this.required : false
		},
		wclass(){
			return this.classes !== undefined ? this.classes : 'wp100'
		},
		city:{
			get(){
				return this.value
			},
			set(payload){
				//console.log(payload)
				this.$emit("update", payload)
				//this.search()
			}
		}
	},
	components: {

	},
	data() {
		return {
			language: this.$store.state.language,
			default: this.value,
			cities: {},
			xcity: this.value,
			show: false,
			apiBaseURL: this.$store.getters.apiBaseURL,
			minChars: 3
		}
	},
	methods: {
		search: debounce(function(){

			//console.log(this.city.split('').length)

			/*if(this.city.split('').length < 5){
				return
			}*/

			if (this.city.split('').length >= 0) {

				this.loading = true

				let param = {};

				param.action = 'city'
				param.keyword = this.city
				param.limit = 20

				axios.post(this.apiBaseURL + '/api/points/', param, {
					headers: {
						"Token": this.$store.state.token,
						'Content-Type': 'application/json',
						'Session': this.$store.state.user.session
					},
					timeout: 60000,
				})
					.then(
						(data) => {

							//console.log("data",data)

							this.cities = data.data.result.map(f => {
								return f.city;
							})

							//console.log("city",this.cities)

							if (!this.show) {
								this.onShow()
							}

						},
					)
					.catch(error => {
						console.log(error)
					})

			}

		}, 1000),
		select(payload){
			this.city = payload
			this.default = payload

			this.$emit("select", payload)

			this.onHide()
		},
		clean(){
			this.$emit("update", "")
		},
		onShow(){
			this.show = true
		},
		onHide(){
			this.show = false
		},
	}
}

</script>

<style scoped>
ul.vu-suggestion {
	top: auto;
}
</style>