const state = {
	meets: {
		//items: {},
		page: 1,
		total: 1,
		pageall: 1
	},
	filters: {
		project: [],
		meets: {
			content: '',
			phone: '',
			city: '',
			finished: '',
			pasted: '',
			users: [],
			results: []
		},
		sort: "sch.date_from",
		order: "desc"
	},
};

const mutations = {
	// address
	SET_FILTER_PROJECTS (state, projects) {
		state.filters.project = projects
	},

	SET_FILTER (state, meets) {
		state.filters.meets = meets
	},

	SET_FILTER_ORDER (state, order) {
		state.filters.order = order
	},

	SET_FILTER_SORT (state, sort) {
		state.filters.sort = sort
	},

	SET_CLEAN_FILTERS (state) {
		state.filters = {
			project: [],
			meets: {
				content: '',
				phone: '',
				city: '',
				finished: '',
				pasted: '',
				users: [],
				results: []
			},
			sort: "sch.date_from",
			order: "desc"
		}
	},

	SET_MEET(state, payload) {
		state.meets.page = payload.page
		state.meets.pageall = payload.pageall
		state.meets.total = payload.total
	},
	SET_FILTERS(state, payload) {
		state.filters = payload
	},

	CHANGE_PAGE(state, page) {
		state.meets.page = page
	},
};

export default {
	namespaced: true,
	state,
	mutations
}