<template>

	<div>

		<div id="filter" class="w2002 top--filters">

			<div class="page--title disable--select">
				Settings. Users
			</div>

		</div>

		<div v-if="loading" class="content-loading"></div>

		<div v-if="!loading" class="page--body">

			<div class="infodiv p10 sticked--top">

				<div class="p5 hidden">В этом разделе вы можете управлять записями сотрудников.</div>

				<div class="flex-container float">

					<div class="flex-string w350 relative cleared">

						<input v-model="word" type="text" class="wp95 fs-09 p5" placeholder="Поиск по имени" @keyup="search">
						<div class="clearinputs mr15" @click="clearSearch"><i class="icon-block red fs-14"></i></div>

					</div>
					<div class="flex-string float">

						<div class="checkbox pl5 mt5">
							<label class="middle">
								<input v-model="hidden" type="checkbox" :value="false" @click="toggle">
								<span class="custom-checkbox"><i class="icon-ok"></i></span>
								<span class="fs-10 Bold mt2">Показать архивных</span>
							</label>
						</div>

					</div>

				</div>

				<div class="mt15 p0 hidden">

					<A href="javascript:void(0)" @click="editItem(-1)" class="button m0 mr5"><i class="icon-ok-circled"></i>Добавить</A>
					<A href="javascript:void(0)" @click="usersByProject()" class="button greenbtn m0"><i class="icon-users-1"></i>Сотрудники по проектам</A>

				</div>

			</div>

			<div class="wp100 mx-auto mt10 pt10 userlist">

				<div v-for="item in userlist" :key="item.id" class="inline relative disable--select" :class="{'active':item.active,'disabled':!item.active, 'hidden': !hidden && !item.active}">

					<div class="pull-aright edit--pane">
						<a href="javascript:void(0)" @click="editItem(item.id)" title="Редактировать"><i class="icon-pencil blue"></i></a>
						<a href="javascript:void(0)" @click="deleteItem(item.id)" title="Удалить"><i class="icon-cancel-circled red"></i></a>
					</div>

					<div class="Bold fs-12 enable--select">
						{{item.title}}
					</div>
					<div class="fs-09 mt10" :class="item.color">
						<i v-if="!item.admin" class="icon-user-1" :title="item.tip"></i>
						<i v-else class="icon-star red" title="Администратор"></i>
						{{item.tip}}
					</div>

					<div class="mt10">
						<a href="javascript:void(0)" @click="toggleItem(item.id)" class="fs-09" :class="{'red':item.active,'green':!item.active}">
							<span v-if="item.active">Деактивировать</span>
							<span v-else>Активировать</span>
						</a>
						<span class="gray-blue pull-right">ID <b>{{ item.id }}</b></span>
					</div>

				</div>

			</div>

			<div class="space-100"></div>

			<div class="mt20 p0 bottom-block">

				<A href="javascript:void(0)" @click="editItem(-1)" title="Добавить">
					<i class="icon-plus"></i>
				</A>

				<A href="javascript:void(0)" @click="usersByProject" title="Сотрудники по проектам" class="red ml5">
					<i class="icon-users-1"></i>
				</A>

				<A href="javascript:void(0)" @click="Export" title="Экспорт" class="ml5 violet">
					<i class="icon-download-1"></i>
				</A>

				<A href="javascript:void(0)" @click="load" title="Обновить" class="indigo ml5">
					<i class="icon-arrows-ccw"></i>
				</A>

			</div>

		</div>

		<Dialog ref="dialog" :show="showdialog" :width="dialogwidth" :loaded="dialogloaded" @close="closeDialog">
			<template v-if="id > 0 || id === -1" v-slot:dlgbody>
				<UserForm :id="id" @close="closeDialog" @loaded="dialogLoaded" @update="updateList"/>
			</template>
			<template v-else-if="ubp" v-slot:dlgbody>
				<UsersByProjectsForm :time="time" @close="closeDialog" @loaded="dialogLoaded"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
import axios from "axios"
import UserForm from "@/pages/settings/UserForm"
import Dialog from "@/components/modal/Dialog"
import UsersByProjectsForm from '@/pages/settings/UsersByProjectsForm.vue'

export default {
	name: "UsersSet",
	components:{
		UserForm,
		Dialog,
		UsersByProjectsForm
	},
	data(){
		return {
			userlist: this.$store.state.guides.users,
			//users: this.$store.state.guides.users,
			hidden: false,
			loading: false,
			showdialog: false,
			dialogloaded: false,
			dialogwidth: 700,
			id: 0,
			ubp: false,
			time: 0,
			word: '',
			guides: this.$store.state.guides,
			apiBaseURL: this.$store.getters.apiBaseURL,
			language: this.$store.state.language
		}
	},
	computed:{
		users:{
			get(){
				return this.$store.state.guides.users
			},
			set(payload){
				this.$store.commit('SET_GUIDES_USER', payload)
			}
		}
	},
	created() {
		this.$store.dispatch("loadGuides").then(()=>{})
		this.load()
	},
	/*mounted() {
		this.load()
	},*/
	methods: {
		search() {

			let keyword = this.word.toLocaleLowerCase()

			this.userlist = this.users.filter(function (e) {
				return e.title.toLowerCase().indexOf(keyword) !== -1;
			})

		},
		closeDialog() {
			this.id = 0
			this.showdialog = false
			this.dialogwidth = 800
		},
		dialogLoaded() {
			this.dialogloaded = true
		},
		updateList(){

			// тут надо обновить проекты в this.$store.state.guides.projects
			this.$emit("guide")
			this.load()

		},
		editItem(payload) {
			this.ubp = false
			this.id = parseInt(payload)
			this.dialogwidth = 700
			this.showdialog = true

			this.$emit("guide")
			this.load()

		},
		deleteItem(payload) {

			this.$swal.fire({
				title: "Are You Sure?",
				text: 'Item will be deleted forever',
				icon: 'question',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: '#3085D6',
				cancelButtonColor: '#D33',
				confirmButtonText: "Yes",
				cancelButtonText: "Cancel"
			}).then((result) => {

				if (result.value) {

					// отправка формы здесь
					let param = {};

					param['id'] = payload
					param['action'] = "delete"

					axios.post(this.apiBaseURL + '/api/users/', param, {
						headers: {
							"Token": this.$store.state.token,
							'Content-Type': 'application/json',
							'Session': this.$store.state.user.session
						},
						timeout: 60000,
					})
						.then(
							(data) => {

								this.message = data.data.result.result

								this.$emit("guide")
								this.closeDialog()

								this.$toast.success({
									title: 'Success',
									message: data.data.result.result,
									position: "bottom center",
									timeOut: 3000
								})

							},
						)
						.catch(error => {

							this.$toast.error({
								title: 'Error '+ error.code,
								message: "" + error.text,
								position: "bottom center",
								timeOut: 3000
							})

						})

				}

			});

		},
		toggleItem(payload){

			// отправка формы здесь
			let param = {};

			param['id'] = payload
			param['action'] = "toggle"

			axios.post(this.apiBaseURL + '/api/users/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						this.message = data.data.result.result

						this.$emit("guide")

						this.closeDialog()
						this.load()

						this.$toast.success({
							title: 'Success',
							message: data.data.result.result,
							position: "bottom center",
							timeOut: 3000
						})

					},
				)
				.catch(error => {

					this.$toast.error({
						title: 'Error '+ error.code,
						message: "" + error.text,
						position: "bottom center",
						timeOut: 3000
					})

				})

		},
		load() {

			this.loading = true

			let param = {};

			param.action = 'list'

			axios.post(this.apiBaseURL + '/api/users/', param, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {
						//console.log(data.data)

						this.userlist = data.data.result

						this.$emit("loaded")
						this.loading = false

						this.search()

					},
				)
				.catch(error => {
					console.log(error)
				})

		},
		toggle(){
			this.hidden = !this.hidden
		},
		usersByProject(){
			this.time = this.$moment()
			this.ubp = true
			this.id = 0
			this.dialogwidth = 900
			this.showdialog = true
		},
		clearSearch(){
			this.word = ''
			this.search()
		},
		Export(){

			axios.post(this.apiBaseURL + '/api/users/', {'action': 'export'}, {
				headers: {
					"Token": this.$store.state.token,
					'Content-Type': 'application/json',
					'Session': this.$store.state.user.session
				},
				timeout: 60000,
			})
				.then(
					(data) => {

						window.open(this.apiBaseURL + '/' + data.data.result.url)

					},
				)
				.catch(error => {

					this.loading = false

					this.$toast.error({
						title: 'Error',
						message: error.text,
						position: "bottom center",
						timeOut: 3000,
						progressBar: true
					})
				})

		},
	},

	metaInfo: {
		// if no subcomponents specify a metaInfo.title, this title will be used
		title: 'Users / Settings',
		// all titles will be injected into this template
		titleTemplate: '%s. Hunter'
	}
}
</script>

<style scoped>
.page--title{
	left: 20px;
	display: block;
	width: 200px !important;
	text-align: left;
}

.disabled.hidden{
	display: none !important;
}

.edit--pane{
	position: absolute;
	top: 0;
	right: 0;
	border-radius: 0 5px 0 5px;
	border-left: 1px dashed var(--green);
	border-bottom: 1px dashed var(--green);
	background: var(--greens);
}
.edit--pane a:hover{
	background: var(--white);
}

.edit--pane a{
	width: 30px;
	display: inline-block;
	text-align: center;
	padding: 5px;
}
.edit--pane a:first-child{
	border-right: 1px dashed var(--green);
	border-radius: 0 0 0 5px;
}

.disabled .edit--pane{
	border-left: 1px dashed var(--gray3);
	border-bottom: 1px dashed var(--gray3);
	background: var(--gray-sub);
}

.disabled .edit--pane a:first-child{
	border-right: 1px dashed var(--gray3);
}

@media (min-width : 1500px) {

	.userlist {
		grid-template-columns: repeat(auto-fill, calc(20% - 10px));
	}

}

@media (min-width : 901px) and (max-width : 1024px) {

	.page--title{
		left: 0 !important;
	}

	.userlist {
		grid-template-columns: repeat(auto-fill, calc(33.33% - 10px));
	}

}

@media (max-width : 900px) {

	.userlist {
		grid-template-columns: repeat(auto-fill, calc(50% - 10px));
	}

}
</style>